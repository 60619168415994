import React from 'react'
import PropTypes from 'prop-types'
import { colors } from 'theme'
import { GOOGLE, VISTAPAGE } from 'consts'
import { Text } from 'components/atoms/Typography'
import { Flex } from 'components/atoms/Layout'
import { getProfileDisplay } from 'routes/Settings/Profiles/helpers'
import SocialProfileImage from './SocialProfileImage'

const ProfileItemTooltip = ({ profile, message, error, errorColor }) => {
  const { code, picture_url, name = '', username = '', description } = profile

  let updatedName = username || name

  if (updatedName) {
    updatedName = `@${updatedName}`
  }

  if (code === GOOGLE) {
    updatedName = description
  } else if (code === VISTAPAGE) {
    updatedName = name
  }

  const { display: updatedDisplay } = getProfileDisplay({ profile })

  return (
    <Flex width="300px" p="s" flexDirection="column">
      <Flex alignItems="center">
        {code && <SocialProfileImage picture_url={picture_url} code={code} width="32px" height="32px" />}
        {message ? (
          <Text ml="s">{message}</Text>
        ) : (
          <Flex flexDirection="column" ml="s">
            <Text>{updatedDisplay}</Text>
            <Text color={profile.info?.error ? 'error' : 'secondaryText'}>{updatedName}</Text>
          </Flex>
        )}
      </Flex>
      {error && <Text color={errorColor}>{error}</Text>}
    </Flex>
  )
}
ProfileItemTooltip.defaultProps = {
  message: '',
  error: '',
  errorColor: colors.error,
}

ProfileItemTooltip.propTypes = {
  profile: PropTypes.object.isRequired,
  message: PropTypes.string,
  error: PropTypes.string,
  errorColor: PropTypes.string,
}

export default ProfileItemTooltip
