import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import Alert from 'react-s-alert'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { COLOR_CONSTANTS, radius } from 'theme'
import { ERROR_MESSAGE } from 'consts'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { Box, Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'
import Input from 'components/atoms/Input'
import { Text, H4 } from 'components/atoms/Typography'
import Icon from 'components/atoms/Icon'
import { ROUTE_LABELS } from 'routes/Calendar/consts'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ hasBackground }) =>
      hasBackground ? transparentize(0.2, COLOR_CONSTANTS.SALUTE) : 'transparent'};
    z-index: 2147483004;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    position: relative;
    max-width: 580px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 400px;
    top: 40%;
    transform: translate(0, -50%);
    outline: none;
  }
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  background-color: ${COLOR_CONSTANTS.BOYSENBERRY_SHADOW};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledLabelItemWrapper = styled(Flex)`
  border-top: 1px solid ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
  width: 100%;
  cursor: pointer;
`

const StyledRoundedButton = styled(Button.Gray)`
  min-width: auto;
  padding: 0;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: ${radius.pill};
  &:hover {
    background-color: ${COLOR_CONSTANTS.DAISY};
  }
`

const Excerpt = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: inherit;
`

const StyledInput = styled(Input)`
  padding-top: 0;
  padding-bottom: 0;
  height: 32px;
  width: 100%;
`

const LabelItem = ({ label, value, handleClickSaveLabel, handleClickRemoveLabel, isDataLabelsUpdating }) => {
  const inputRef = useRef(null)

  const [isEditing, setIsEditing] = useState(false)

  const handleClickSave = ({ label }) => {
    const updatedValue = label || value
    const allowed = handleClickSaveLabel({
      value,
      label: updatedValue,
      hasChanged: value !== updatedValue,
    })
    if (allowed) {
      setIsEditing(false)
    } else {
      Alert.error(`You already have a label ${updatedValue}.`)
    }
  }

  const handleClickOpenEdit = () => {
    if (!isEditing && !isDataLabelsUpdating) {
      setIsEditing(true)
    }
  }

  return (
    <Flex
      alignItems="center"
      width="100%"
      justifyContent="space-between"
      py="xs"
      onClick={() => {
        handleClickOpenEdit()
      }}
    >
      <Flex maxWidth="calc(100% - 128px)" width="100%">
        {isEditing ? (
          <Flex flexDirection="column" width="100%">
            <StyledInput
              innerRef={inputRef}
              type="text"
              placeholder="Enter label"
              defaultValue={label}
              onChange={(e) => {
                const { value } = e.target
                inputRef.current.value = value
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  handleClickSave({ label: inputRef.current.value })
                }
              }}
            />
          </Flex>
        ) : (
          <Excerpt as="span">{label}</Excerpt>
        )}
      </Flex>

      <Flex alignItems="center" ml="s" justifyContent="space-between">
        {isEditing ? (
          <StyledRoundedButton
            onClick={(e) => {
              e.stopPropagation()
              handleClickSave({ label: inputRef.current.value })
            }}
          >
            <Icon.Checkmark fill={COLOR_CONSTANTS.COSMIC_ENERGY} width="13px" height="13px" />
          </StyledRoundedButton>
        ) : (
          <StyledRoundedButton
            onClick={(e) => {
              e.stopPropagation()
              handleClickOpenEdit()
            }}
          >
            <Icon.Edit fill={COLOR_CONSTANTS.COSMIC_ENERGY} width="13px" height="13px" />
          </StyledRoundedButton>
        )}
        <StyledRoundedButton
          ml="s"
          onClick={(e) => {
            e.stopPropagation()
            if (!isDataLabelsUpdating) {
              handleClickRemoveLabel({ value })
            }
          }}
        >
          <Icon.Trash fill={COLOR_CONSTANTS.COSMIC_ENERGY} width="13px" height="13px" />
        </StyledRoundedButton>
      </Flex>
    </Flex>
  )
}

LabelItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleClickSaveLabel: PropTypes.func.isRequired,
  handleClickRemoveLabel: PropTypes.func.isRequired,
  isDataLabelsUpdating: PropTypes.bool.isRequired,
}

const LabelsEditModal = ({ isOpen, handleDismiss, handleUpdateDataLabels, type, labelOptions, calledFromModal }) => {
  const inputRef = useRef(null)

  const [data, setData] = useState([])
  const [isDataLabelsUpdating, setIsDataLabelsUpdating] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setData(labelOptions.map((item) => ({ value: item, label: item })))
    }
  }, [isOpen])

  const handleClickSave = async ({ action, oldValue, newValue }) => {
    try {
      setIsDataLabelsUpdating(true)

      const body = {
        labels: data.filter(({ removed }) => !removed).map(({ label }) => label),
        type,
        action,
      }

      if (typeof oldValue !== 'undefined') {
        body.oldValue = oldValue
      }

      if (typeof newValue !== 'undefined') {
        body.newValue = newValue
      }

      data.forEach((label) => {
        label.hasChanged = false
      })

      setData([...data])

      const response = await request({
        method: 'PUT',
        path: ROUTE_LABELS,
        body,
      })

      const { error } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        Alert.success(`${type.charAt(0).toUpperCase()}${type.substr(1).toLowerCase()} labels updated.`, {
          timeout: 5000,
        })
      }
      setIsDataLabelsUpdating(false)
      handleUpdateDataLabels({ labels: data })
    } catch (error) {
      errorHelper({ error, componentName: LabelsEditModal.displayName, functionName: 'handleClickSave' })
      setIsDataLabelsUpdating(false)
    }
  }

  const handleClickSaveLabel = ({ label, value, hasChanged }) => {
    if (hasChanged) {
      const foundSameLabelIndex = data.findIndex((item) => item.label === label && !item.removed)
      if (foundSameLabelIndex > -1) {
        return false
      } else {
        const foundLabelIndex = data.findIndex((item) => item.value === value && !item.removed)

        data[foundLabelIndex] = { label, value: label, oldValue: value, hasChanged: true }

        data.sort((e1, e2) => (e1.label.toLowerCase() > e2.label.toLowerCase() ? 1 : -1))

        setData([...data])

        handleClickSave({ action: 'save', oldValue: value, newValue: label })
      }
    }

    return true
  }

  const handleClickRemoveLabel = ({ value }) => {
    const foundLabelIndex = data.findIndex((item) => item.value === value)
    data[foundLabelIndex].removed = true

    data[foundLabelIndex].hasChanged = true

    setData([...data])

    handleClickSave({ action: 'remove', oldValue: value, newValue: '' })
  }

  const handleClickAddLabel = () => {
    if (!isDataLabelsUpdating) {
      const label = inputRef.current.value

      if (label) {
        const foundSameLabelIndex = data.findIndex((item) => item.label === label && !item.removed)
        if (foundSameLabelIndex === -1) {
          data.unshift({ label, value: label })

          // data.sort((e1, e2) => (e1.label.toLowerCase() > e2.label.toLowerCase() ? 1 : -1))

          setData([...data])

          inputRef.current.value = ''

          handleClickSave({ action: 'add' })
        } else {
          Alert.error(`You already have a label ${label}.`)
        }
      }
    }
  }

  const handleClickCloseModal = () => {
    if (!isDataLabelsUpdating) {
      handleDismiss()
    }
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}} hasBackground={!calledFromModal}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent tabIndex="0">
          <StyledDialogEnvironmentWrapper px="m" tabIndex={0} $isTop>
            <H4 my="m">Manage labels</H4>
          </StyledDialogEnvironmentWrapper>
          <Scrollbars universal>
            <Flex flexDirection="column" my="m" px="m" flex="1" justifyContent="center">
              <Flex alignItems="center" width="100%" justifyContent="space-between" py="xs">
                <Flex flexDirection="column" maxWidth="calc(100% - 38px)" width="100%">
                  <StyledInput
                    innerRef={inputRef}
                    type="text"
                    placeholder="Enter new label"
                    defaultValue=""
                    onChange={(e) => {
                      const { value } = e.target
                      inputRef.current.value = value
                    }}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) {
                        handleClickAddLabel()
                      }
                    }}
                  />
                </Flex>

                <StyledRoundedButton
                  onClick={() => {
                    handleClickAddLabel()
                  }}
                >
                  <Icon.Plus fill={COLOR_CONSTANTS.COSMIC_ENERGY} width="13px" height="13px" />
                </StyledRoundedButton>
              </Flex>
              {data.map(({ value, label, removed }) => {
                if (removed) {
                  return null
                }
                return (
                  <StyledLabelItemWrapper key={value} pl="s">
                    <LabelItem
                      handleClickSaveLabel={handleClickSaveLabel}
                      label={label}
                      value={value}
                      handleClickRemoveLabel={handleClickRemoveLabel}
                      isDataLabelsUpdating={isDataLabelsUpdating}
                    />
                  </StyledLabelItemWrapper>
                )
              })}
            </Flex>
          </Scrollbars>

          <StyledDialogEnvironmentWrapper p="m" justifyContent="flex-end" $isBottom>
            <Flex alignItems="center">
              <Button.Gray onClick={handleClickCloseModal} isSmall>
                Close
              </Button.Gray>
            </Flex>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

LabelsEditModal.defaultProps = {
  calledFromModal: false,
}

LabelsEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  labelOptions: PropTypes.array.isRequired,
  handleUpdateDataLabels: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  calledFromModal: PropTypes.bool,
}

LabelsEditModal.displayName = 'LabelsEditModal'

export default LabelsEditModal
