import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Image from 'components/atoms/Image'
import ButtonControl from './ButtonControl'

const Button = styled(ButtonControl)`
  position: absolute;
  left: ${({ position }) => (position === 'left' ? 0 : 'unset')};
  right: ${({ position }) => (position === 'right' ? 0 : 'unset')};
  top: 50%;
  transform: translate(0, -50%);
`

const ArrowButton = ({ position, onClick }) => {
  return (
    <Button position={position} type="button" onClick={onClick}>
      {position === 'left' && <Image src="/assets/chevron_left.svg" width="20px" height="35px" ml="s" />}
      {position === 'right' && <Image src="/assets/chevron_right.svg" width="20px" height="35px" mr="s" />}
    </Button>
  )
}

ArrowButton.propTypes = {
  position: PropTypes.oneOf(['left', 'right']).isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ArrowButton
