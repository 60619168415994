import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Flex } from 'components/atoms/Layout'
import ErrorLabel from 'components/atoms/ErrorLabel'
import { Text } from 'components/atoms/Typography'
import { colors, space, fontSizes, radius, COLOR_CONSTANTS, fontWeights } from 'theme'

const StyledTextArea = styled.textarea`
  margin-top: ${space.s};
  padding: ${space.m};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  border-radius: ${radius.l};
  border-color: ${({ isError }) => (isError ? colors.error : COLOR_CONSTANTS.SOLITUDE)};
  font-size: ${({ fontSize }) => fontSizes[fontSize] || fontSizes.xs};
  color: ${({ color }) => color || colors.primaryText};
  outline: 0;
  resize: none;
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::placeholder {
    color: ${COLOR_CONSTANTS.COSMIC_ENERGY};
    font-size: ${fontSizes.xs};
    font-weight: ${fontWeights.normal};
  }
`

const TextArea = forwardRef((props, ref) => {
  const { error, fontSize, label, labelFontSize = 's' } = props

  return (
    <Flex flexDirection="column">
      {label && (
        <Text
          mb="xs"
          fontSize={labelFontSize}
          dangerouslySetInnerHTML={{
            __html: label,
          }}
        />
      )}
      <StyledTextArea fontSize={fontSize} isError={error} {...props} ref={ref} />
      {error && <ErrorLabel mt="xxs" textAlign="left" error={error} />}
    </Flex>
  )
})

TextArea.propTypes = {
  error: PropTypes.string,
  fontSize: PropTypes.string,
  label: PropTypes.string,
  labelFontSize: PropTypes.string,
}

export default TextArea
