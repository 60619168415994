import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment-timezone'
import { COLOR_CONSTANTS, fontSizes, space } from 'theme'
import { DEFAULT_TIME_ZONE } from 'consts'
import { initializeStringArrayWithRange } from 'helpers'
import { Box, Flex } from 'components/atoms/Layout'
import Datepicker from 'components/molecules/Datepicker'
import { Text } from 'components/atoms/Typography'
import Select from 'components/atoms/Select'
import { DATE_FORMAT } from 'routes/Calendar/consts'
import BestPublishingTimeSettings from '../../BestPublishingTimeSettings'

const StyledSelect = styled(Select)`
  padding: 0 ${space.xs};
  font-size: ${fontSizes.xs};
  background-position: calc(100% - ${space.s}) center;
  background-color: ${COLOR_CONSTANTS.WHITE};
`

const HOURS = initializeStringArrayWithRange({ start: 1, end: 12 })
const MINUTES = initializeStringArrayWithRange({ end: 59 })

const DatePicker = ({ publishTimeData, handleChangeDate, timezone, selectedProfiles, combined }) => {
  const { date, hour, minute, interval } = publishTimeData

  const handleChangeSelectedDate = ({ type, value }) => {
    if (type === 'date') {
      publishTimeData[type] = moment(value).format(DATE_FORMAT)
    } else if (type === 'hour' || type === 'minute' || type === 'interval') {
      publishTimeData[type] = value
    }

    publishTimeData.date_string = `${publishTimeData.date} ${publishTimeData.hour}:${publishTimeData.minute} ${publishTimeData.interval}`

    handleChangeDate({ publishTimeData })
  }

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between" flexDirection={{ mobile: 'column', tablet: 'row' }}>
        <Box minWidth="170px" width={{ mobile: '100%', tablet: 'initial' }}>
          <Datepicker
            minDate={new Date()}
            label=""
            value={date}
            onChange={(value) => {
              if (value) {
                handleChangeSelectedDate({ type: 'date', value })
              }
            }}
            isClearDateEnabled={false}
          />
        </Box>
        <Flex flexDirection="column" width="100%" px={{ mobile: 0, tablet: 'm' }} mt={{ mobile: 'm', tablet: 0 }}>
          <Flex alignItems="center">
            <Box width="50px">
              <StyledSelect
                name="hours"
                onChange={({ target: { value } }) => {
                  handleChangeSelectedDate({ type: 'hour', value })
                }}
                value={hour}
              >
                {HOURS.map((hour) => (
                  <option key={hour} value={hour}>
                    {hour}
                  </option>
                ))}
              </StyledSelect>
            </Box>
            <Text fontSize="l" mx="s">
              :
            </Text>
            <Box width="50px">
              <StyledSelect
                name="minutes"
                onChange={({ target: { value } }) => {
                  handleChangeSelectedDate({ type: 'minute', value })
                }}
                value={minute}
              >
                {MINUTES.map((minute) => (
                  <option key={minute} value={minute}>
                    {minute}
                  </option>
                ))}
              </StyledSelect>
            </Box>
            <Box ml="s" width="50px">
              <StyledSelect
                name="interval"
                onChange={({ target: { value } }) => {
                  handleChangeSelectedDate({ type: 'interval', value })
                }}
                value={interval}
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </StyledSelect>
            </Box>
            {timezone && (
              <Flex alignItems="center">
                <Text ml="s" fontSize="s">
                  {moment()
                    .tz(timezone)
                    .format('z')}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      {selectedProfiles && (
        <Flex justifyContent="space-between">
          <Box minWidth="170px" mr="m" />
          <Flex width="100%">
            <BestPublishingTimeSettings
              selectedProfiles={selectedProfiles}
              timezone={timezone}
              combined={combined}
              handleChangeSelectedTime={({ hour, minute, interval }) => {
                publishTimeData.hour = hour
                publishTimeData.minute = minute
                publishTimeData.interval = interval
                handleChangeDate({ publishTimeData })
              }}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

DatePicker.defaultProps = {
  timezone: DEFAULT_TIME_ZONE,
  selectedProfiles: null,
  combined: true,
}

DatePicker.propTypes = {
  publishTimeData: PropTypes.object.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  timezone: PropTypes.string,
  selectedProfiles: PropTypes.array,
  combined: PropTypes.bool,
}

export default DatePicker
