import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { List, AutoSizer } from 'react-virtualized'
import { truncate } from 'helpers'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Icon from 'components/atoms/Icon'
import Input from 'components/atoms/Input'
import Checkbox from 'components/atoms/Checkbox'
import { space, colors, radius, COLOR_CONSTANTS } from 'theme'
import Image from 'components/atoms/Image'

const Button = styled(Flex)`
  align-items: center;
  height: ${({ height }) => height || '34px'};
  padding: ${space.xxs} ${space.s};
  justify-content: space-between;
  background-color: ${({ isActive, hasButtonBorder, color }) =>
    hasButtonBorder ? (isActive ? transparentize(0.9, color) : colors.white) : 'none'};
  color: ${({ isActive, color }) => (isActive ? color : colors.black)};
  border-radius: ${radius.l};
  border: ${({ hasButtonBorder }) =>
    hasButtonBorder ? `1px solid ${({ isActive }) => (isActive ? 'transparent' : COLOR_CONSTANTS.SOLITUDE)};` : 'none'};
  cursor: pointer;
`

const Options = styled(Box)`
  position: absolute;
  z-index: 2147483001;
  top: 50px;
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  max-width: 130%;
  min-width: ${({ minOptionsWidth }) => minOptionsWidth || 200}px;
  width: 100%;
  padding: ${space.m} ${space.xs} 0 ${space.m};
  box-shadow: 0px 0px 4px rgba(39, 40, 49, 0.1);
  background-color: ${({ backgroundMenuColor }) => backgroundMenuColor || colors.white};
  border-radius: ${radius.l};
`

const StyledText = styled(Text)`
  cursor: pointer;
`

const StyledIcon = styled(Icon.ArrowDropdown)`
  ${({ isActive }) =>
    isActive &&
    `
      fill: ${({ color }) => transparentize(0.5, color)};
  `}
`

const StyledList = styled(List)`
  outline: none;
`

const TotalBadge = styled(Flex)`
  justify-content: center;
  align-items: center;
  min-width: 16px;
  height: 16px;
  background-color: ${({ color }) => transparentize(0.8, color)};
  border-radius: ${radius.pill};
  padding: ${space.xs};
  margin-left: ${space.xs};
`

const StyledOptionText = styled(Text)`
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const CloseIconWrapper = styled(Flex)`
  position: absolute;
  top: -24px;
  right: -14px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 0px 8px rgb(145 158 171 / 20%);
`

const ROW_HEIGHT = 35
const HEADER_HEIGHT = 80
const INPUT_HEIGHT = 40

class Filter extends React.Component {
  constructor(props) {
    super(props)
    this.nodeRef = createRef()
  }

  state = {
    isOpen: false,
    search: '',
    filtered: [],
    selected: [],
    options: [],
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.options !== this.state.options) {
      this.setState({
        filtered: nextProps.value
          ? nextProps.options.filter(({ id }) => !nextProps.value.map(({ id }) => id).includes(id))
          : nextProps.options,
        selected: nextProps.value || [],
        options: nextProps.options,
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  handleSetSearch = (search) => {
    const { options } = this.state
    this.setState({ search })
    this.setState({ filtered: options.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase())) })
  }

  handleClickClose = () => {
    if (this.state.isOpen) {
      this.props.onSelect(this.state.selected)
      this.setState({ isOpen: false })
    }
  }

  handleClick = (e) => {
    if (this.nodeRef.current) {
      if (this.nodeRef.current.contains(e.target)) {
        return
      }
    }

    this.handleClickClose()
  }

  calculateHeight = () => {
    const { options } = this.state
    return options.length > 10 ? this.props.maxMenuHeight : options.length * ROW_HEIGHT
  }

  handleClickCheckBox = (option) => {
    const { selected } = this.state
    const { handleClickSelectedOption } = this.props
    let updatedSelected = []
    let removed = true
    if (selected.includes(option)) {
      updatedSelected = selected.filter((item) => item.id !== option.id)
    } else {
      updatedSelected = [...selected, option]
      removed = false
    }
    this.setState({ selected: updatedSelected })
    handleClickSelectedOption({ option, removed, options: updatedSelected })
  }

  render() {
    const {
      label,
      placeholder,
      isSearchable,
      truncateText,
      showLabel,
      showMobileIcon,
      removeMobileIcon,
      left,
      right,
      mobileIcon,
      mobileIconHeight,
      mobileIconWidth,
      hasButtonBorder,
      numberOfSelectedItems,
      showFilterCounter,
      color,
      backgroundMenuColor,
      showCloseIcon,
      minOptionsWidth,
    } = this.props
    const { isOpen, selected, search, filtered } = this.state
    const isActive = selected.length !== 0

    return (
      <Box position="relative">
        <Button
          color={color}
          hasButtonBorder={hasButtonBorder}
          isActive={isActive}
          onClick={() => {
            this.setState({
              isOpen: true,
              filtered: [...selected, ...filtered.filter(({ id }) => !selected.map(({ id }) => id).includes(id))],
            })
          }}
          height={this.props.height}
        >
          {(!showMobileIcon || !removeMobileIcon) && (
            <Flex width="100%" justifyContent="space-between" alignItems="center">
              <Text color={color || 'primaryText'}>
                {label} {isActive && `· ${numberOfSelectedItems || selected.length}`}
              </Text>
              <StyledIcon isActive={isActive} color={color} />
            </Flex>
          )}
          {showMobileIcon && removeMobileIcon && (
            <Flex width="100%">
              <Flex width="100%" alignItems="center" justifyContent="space-between" display={removeMobileIcon}>
                <Text>
                  {label} {isActive && `· ${numberOfSelectedItems || selected.length}`}
                </Text>
                <StyledIcon isActive={isActive} color={color} />
              </Flex>
              <Flex
                width="100%"
                alignItems="center"
                justifyContent="center"
                display={showMobileIcon}
                position="relative"
              >
                <Image src={mobileIcon} width={mobileIconWidth} height={mobileIconHeight} />
                {showFilterCounter && (numberOfSelectedItems > 0 || selected.length > 0) && (
                  <TotalBadge color={color}>
                    <Text as="span" color={color || colors.primary} fontSize="9px">
                      {numberOfSelectedItems || selected.length}
                    </Text>
                  </TotalBadge>
                )}
              </Flex>
            </Flex>
          )}
        </Button>
        {isOpen && (
          <div ref={this.nodeRef}>
            <Options
              height={this.calculateHeight() + HEADER_HEIGHT + (isSearchable && INPUT_HEIGHT)}
              left={left}
              right={right}
              backgroundMenuColor={backgroundMenuColor}
              minOptionsWidth={minOptionsWidth}
            >
              {showCloseIcon && (
                <Flex position="relative">
                  <CloseIconWrapper onClick={this.handleClickClose}>
                    <Image width="10px" height="10px" src="/assets/clear.svg" />
                  </CloseIconWrapper>
                </Flex>
              )}
              {showLabel && <Text fontSize="l">{label}</Text>}
              {isSearchable && (
                <Box my="s" mr="m">
                  <Input
                    isSmall
                    placeholder={placeholder}
                    value={search}
                    onChange={(e) => {
                      this.handleSetSearch(e.target.value.trimStart())
                    }}
                  />
                </Box>
              )}
              <Flex my="s">
                <StyledText
                  color="secondaryText"
                  fontSize="xs"
                  onClick={() => {
                    this.setState({ selected: filtered })
                    this.props.handleClickSelectedOption({ options: filtered })
                  }}
                >
                  Select all
                </StyledText>
                <Text color="secondaryText" fontSize="xs">
                  &nbsp; · &nbsp;
                </Text>
                <StyledText
                  color="secondaryText"
                  fontSize="xs"
                  onClick={() => {
                    this.setState({ selected: [] })
                    this.props.handleClickSelectedOption({ options: [] })
                  }}
                >
                  Clear selection
                </StyledText>
              </Flex>
              <Box mt="s">
                <AutoSizer>
                  {({ width }) => (
                    <StyledList
                      width={width}
                      height={this.calculateHeight()}
                      rowHeight={ROW_HEIGHT}
                      overscanRowCount={10}
                      rowCount={filtered.length}
                      rowRenderer={({ index, key, style }) => {
                        const option = filtered[index]
                        const displayName =
                          width < 240
                            ? truncate(option.display || option.name, truncateText ? 25 : 250)
                            : option.display || option.name

                        return (
                          <Flex key={key} style={style}>
                            <Checkbox
                              isChecked={selected.includes(option)}
                              onClick={() => this.handleClickCheckBox(option)}
                            >
                              <StyledOptionText
                                ml="l"
                                key={option.id}
                                style={{ textOverflow: 'ellipsis' }}
                                title={option.display || option.name}
                              >
                                {displayName}
                              </StyledOptionText>
                            </Checkbox>
                          </Flex>
                        )
                      }}
                    />
                  )}
                </AutoSizer>
              </Box>
            </Options>
          </div>
        )}
      </Box>
    )
  }
}

Filter.defaultProps = {
  isSearchable: true,
  options: [],
  truncateText: true,
  showLabel: true,
  placeholder: '',
  value: null,
  handleClickSelectedOption: () => {},
  showMobileIcon: null,
  removeMobileIcon: null,
  left: '0px',
  right: 'auto',
  mobileIcon: '/assets/filter.svg',
  mobileIconWidth: '18px',
  mobileIconHeight: '18px',
  hasButtonBorder: true,
  numberOfSelectedItems: null,
  showFilterCounter: false,
  color: colors.primary,
  backgroundMenuColor: colors.white,
  maxMenuHeight: 160,
  showCloseIcon: false,
  minOptionsWidth: 200,
}

Filter.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.array,
  options: PropTypes.array,
  isSearchable: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  truncateText: PropTypes.bool,
  showLabel: PropTypes.bool,
  handleClickSelectedOption: PropTypes.func,
  showMobileIcon: PropTypes.object,
  removeMobileIcon: PropTypes.object,
  left: PropTypes.string,
  right: PropTypes.string,
  mobileIcon: PropTypes.string,
  mobileIconWidth: PropTypes.string,
  mobileIconHeight: PropTypes.string,
  hasButtonBorder: PropTypes.bool,
  numberOfSelectedItems: PropTypes.number,
  showFilterCounter: PropTypes.bool,
  color: PropTypes.string,
  backgroundMenuColor: PropTypes.string,
  maxMenuHeight: PropTypes.number,
  showCloseIcon: PropTypes.bool,
  minOptionsWidth: PropTypes.number,
}

export default Filter
