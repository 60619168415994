import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Alert from 'react-s-alert'
import { AutoSizer, MultiGrid } from 'react-virtualized'
import { Paper } from '@material-ui/core'
import ClipLoader from 'react-spinners/ClipLoader'
import debounce from 'lodash.debounce'
import { space, COLOR_CONSTANTS, colors, radius, fontSizes, fontWeights } from 'theme'
import { ERROR_MESSAGE, FEATURE_PROFILE_VARIABLES } from 'consts'
import request from 'utils/request'
import { getSocialNetworkIconByName } from 'utils/socialNetworks'
import { checkIfPlanHasFeatureEnabled } from 'utils/feature'
import errorHelper from 'utils/errorHelper'
import DropDown from 'shared/DropDown'
import { Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Card from 'components/molecules/Card'
import Image from 'components/atoms/Image'
import Icon from 'components/atoms/Icon'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Input from 'components/atoms/Input'
import { ROUTE_VARIABLE, BLOCKED_PUBLISHING_SOCIAL_PROFILES } from 'routes/Calendar/consts'
import CalendarItemTooltip from 'routes/Calendar/components/CalendarItemTooltip'
import AddVariableModal from './AddVariableModal'

const StyledFlex = styled(Flex)`
  cursor: pointer;
`

const Excerpt = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: inherit;
  text-decoration: none;
  color: ${colors.primaryText};
`

const StyledPicture = styled(ImageWithFallback)`
  border-radius: ${radius.pill};
`

const NetworkImageWrapper = styled(Flex)`
  position: absolute;
  bottom: 1px;
  right: 1px;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
  border-radius: ${radius.m};
`

const StyledInput = styled.input`
  padding: ${space.m} ${space.l} ${space.m} ${space.m};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  border-radius: ${radius.l};
  height: ${space.l};
  color: ${colors.primaryText};
  font-size: ${fontSizes.xs};
  :focus {
    outline-style: none;
  }
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::placeholder {
    color: ${COLOR_CONSTANTS.COSMIC_ENERGY};
    font-size: ${fontSizes.xs};
    font-weight: ${fontWeights.normal};
  }

  width: 100%;
`

const StyledInputIcon = styled(Flex)`
  right: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  opacity: 0;
  transition: opacity 0.6s linear;
`

const StyledProgressWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
  background-color: ${COLOR_CONSTANTS.WHITE};
`

const DEFAULT_SOURCE = '/assets/company.svg'

const DEFAULT_ADD_VARIABLE_MODAL_OPTIONS = {
  isOpen: false,
  data: null,
}

const MAX_PROFILES_IN_GRID = 10

const { NAME, VARIABLE, NETWORK, ENTITY } = {
  NAME: 'name',
  VARIABLE: 'variable',
  NETWORK: 'network',
  ENTITY: 'entity',
}

const DEFAULT_PROFILES_FILTER = {
  [NAME]: '',
  [NETWORK]: null,
  [ENTITY]: null,
}

const DEFAULT_VARIABLES_FILTER = {
  [VARIABLE]: '',
}

const ProfileVariablesComponent = forwardRef(
  (
    {
      user,
      profiles: profilesForVariables,
      variables: specialVariables,
      handleAfterProfileVariableSave,
      hideGridWithoutVariables,
      showProfileNameFilter,
      showVariableNameFilter,
      showProfileNetworkFilter,
      showProfileGroupFilter,
    },
    profileVariablesComponentRef
  ) => {
    const multiGridRef = useRef(null)
    const tooltipRef = useRef(null)

    const [isGettingData, setIsGettingData] = useState(false)
    const [profiles, setProfiles] = useState([])
    const [filteredProfiles, setFilteredProfiles] = useState([])
    const [networks, setNetworks] = useState([])
    const [entities, setEntities] = useState([])
    const [profilesFilter, setProfilesFilter] = useState(DEFAULT_PROFILES_FILTER)
    const [variablesFilter, setVariablesFilter] = useState(DEFAULT_VARIABLES_FILTER)

    const [variables, setVariables] = useState([])
    const [filteredVariables, setFilteredVariables] = useState([])
    const [addVariableModalOptions, setAddVariableModalOptions] = useState({ ...DEFAULT_ADD_VARIABLE_MODAL_OPTIONS })
    const [isVariableModalSaving, setIsVariableModalSaving] = useState(false)
    const [isVariableModalDeleting, setIsVariableModalDeleting] = useState(false)

    useImperativeHandle(profileVariablesComponentRef, () => ({
      getVariables() {
        return { variables, profiles }
      },
    }))

    const getData = async () => {
      try {
        setIsGettingData(true)

        const promiseArr = []

        promiseArr.push(
          request({
            path: `${ROUTE_VARIABLE}?profiles=${
              profilesForVariables && profilesForVariables.length > 0
                ? profilesForVariables.map(({ id }) => id).join(',')
                : ''
            }`,
          })
        )

        if (!profilesForVariables || profilesForVariables.length === 0) {
          promiseArr.push(
            request({
              path: 'profiles?initial=true',
            })
          )
        }

        const { 0: variables_response, 1: profiles_response } = await Promise.all(promiseArr)

        const { list, profile_variables = [] } = variables_response || {}

        let variables_list = list

        if (specialVariables) {
          variables_list = variables_list.filter(({ label }) =>
            specialVariables.find(({ variable, for_edit }) => for_edit && variable === `{{${label}}}`)
          )
        }

        let profiles = []
        let networks = []
        let entities = []
        if (profiles_response) {
          ;({ profiles = [], networks = [], entities = [] } = profiles_response || {})
          setNetworks(networks)
          setEntities(entities)
        } else {
          profiles = profilesForVariables
        }

        profiles = profiles.filter(({ network: { code } = {} }) => !BLOCKED_PUBLISHING_SOCIAL_PROFILES.includes(code))

        const profilesForTable = []

        if (profiles) {
          profiles.forEach((profile) => {
            profile.hasEmptyVariable = false
            profile.profile_variables = {}

            variables_list.forEach(({ label, id }) => {
              const { [profile.id]: profileVariable } = profile_variables

              const { [id]: variable } = profileVariable || {}

              profile.profile_variables[id] = {
                label,
                value: variable ? variable.value : '',
                variableId: id,
                profileVariableValueId: variable ? variable.id : null,
              }

              if (!profile.profile_variables[id].value) {
                profile.hasEmptyVariable = true
              }
            })

            profilesForTable.push(profile)
          })
        }

        setProfiles(profilesForTable)

        setVariables([{ label: 'Profile' }, ...variables_list])
      } catch (error) {
        errorHelper({ error, componentName: ProfileVariablesComponent.displayName, functionName: 'getData' })
      } finally {
        setIsGettingData(false)
      }
    }

    useEffect(() => {
      const features = checkIfPlanHasFeatureEnabled({
        user,
        features: [FEATURE_PROFILE_VARIABLES],
      })

      if (features[FEATURE_PROFILE_VARIABLES].enabled) {
        getData()
      }
    }, [])

    useEffect(() => {
      // eslint-disable-next-line no-use-before-define
      onChangeVariablesFilter()
    }, [variables, variablesFilter])

    useEffect(() => {
      if (profiles.length !== 0) {
        // eslint-disable-next-line no-use-before-define
        onChangeProfilesFilter()
      }
    }, [profiles, profilesFilter])

    const handleClickOpenAddVariableModal = ({ data }) => {
      if (profiles.length > 0) {
        setAddVariableModalOptions({ ...{ isOpen: true, data } })
      } else {
        Alert.error(`Please connect at least one social profile.`, { timeout: 5000 })
      }
    }

    const handleClickCloseAddVariableModal = () => {
      setAddVariableModalOptions({ ...DEFAULT_ADD_VARIABLE_MODAL_OPTIONS })
    }

    const handleSaveVariable = async ({ variable }) => {
      try {
        setIsVariableModalSaving(true)

        let options = {
          method: 'POST',
          path: ROUTE_VARIABLE,
          body: {
            name: variable.label,
            defaultValue: variable.defaultValue,
            profiles: profiles.map(({ id, entityId }) => ({ id, entityId })),
          },
        }

        if (variable.id) {
          options = {
            method: 'PATCH',
            path: `${ROUTE_VARIABLE}/${variable.id}`,
            body: {
              name: variable.label,
              defaultValue: variable.defaultValue,
              profiles: profiles
                .filter(
                  ({ profile_variables }) => !profile_variables[variable.id] || !profile_variables[variable.id].value
                )
                .map(({ id, entityId }) => ({ id, entityId })),
            },
          }
        }

        const response = await request(options)
        setIsVariableModalSaving(false)

        const { error, id: responseId, profile_variables = [] } = response || {}

        if (error || !response) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          if (variable.id) {
            const foundVariableIndex = variables.findIndex((item) => item.id === variable.id)
            if (foundVariableIndex > -1) {
              variables[foundVariableIndex] = { ...variable }
            }
          } else {
            variables.push({ ...variable, id: responseId })
          }

          profiles.forEach((profile) => {
            if (variable.id) {
              if (profile.profile_variables[responseId]) {
                profile.profile_variables[responseId].label = variable.label
              }
            } else {
              profile.profile_variables[responseId] = {
                label: variable.label,
                value: '',
                variableId: responseId,
                profileVariableValueId: null,
              }
            }

            if (profile_variables.length !== 0) {
              const { id } = profile

              const foundProfileVariable = profile_variables.find(({ profile_gid }) => profile_gid === id)

              if (foundProfileVariable) {
                if (!profile.profile_variables[responseId].value) {
                  const inputEl = document.getElementById(`${id}_${foundProfileVariable.variableId}`)
                  if (inputEl) {
                    inputEl.value = foundProfileVariable.value
                  }
                }

                profile.profile_variables[responseId].value = foundProfileVariable.value
                profile.profile_variables[responseId].profileVariableValueId =
                  foundProfileVariable.profileVariableValueId
              }
            }

            profile.hasEmptyVariable = !!Object.values(profile.profile_variables).find(({ value }) => !value)
          })

          setProfiles([...profiles])

          setVariables([{ label: 'Profile' }, ...variables.slice(1).sort((t1, t2) => (t1.label > t2.label ? 1 : -1))])
          Alert.success(`Profile variable has been ${variable.id ? 'updated' : 'created'}.`)
          handleClickCloseAddVariableModal()
        }
      } catch (error) {
        setIsVariableModalSaving(false)
        errorHelper({ error, componentName: ProfileVariablesComponent.displayName, functionName: 'handleSaveVariable' })
      }
    }

    const handleRemoveVariable = async ({ id }) => {
      try {
        setIsVariableModalDeleting(true)

        const response = await request({
          method: 'DELETE',
          path: `${ROUTE_VARIABLE}/${id}`,
        })

        const { error } = response || {}

        if (error || !response) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
          setIsVariableModalDeleting(false)
        } else {
          const foundVariableIndex = variables.findIndex((item) => item.id === id)
          if (foundVariableIndex > -1) {
            variables.splice(foundVariableIndex, 1)
            setVariables([...variables])

            profiles.forEach((profile) => {
              delete profile.profile_variables[id]
              profile.hasEmptyVariable = !!Object.values(profile.profile_variables).find(({ value }) => !value)
            })

            setProfiles([...profiles])
          }

          setIsVariableModalDeleting(false)
          Alert.success(`Profile variable has been removed.`)

          if (addVariableModalOptions.isOpen) {
            handleClickCloseAddVariableModal()
          }
        }
      } catch (error) {
        setIsVariableModalDeleting(false)
        errorHelper({
          error,
          componentName: ProfileVariablesComponent.displayName,
          functionName: 'handleRemoveVariable',
        })
      }
    }

    const handleSaveProfileVariable = async ({
      inputId,
      entityId,
      profileId,
      variableId,
      profileVariableValueId,
      label: variableLabel,
      value: variableValue,
    }) => {
      const inputEl = document.getElementById(inputId)

      let hasChanged = false

      if (inputEl && !inputEl.disabled) {
        const newValue = inputEl.value
        if (newValue !== variableValue) {
          hasChanged = true
        }
      }

      if (hasChanged) {
        try {
          inputEl.disabled = true

          const response = await request({
            method: 'POST',
            path: `${ROUTE_VARIABLE}/profile/value`,
            body: {
              entity_gid: entityId,
              profile_gid: profileId,
              value: inputEl.value,
              variable_gid: variableId,
              profile_variable_value_gid: profileVariableValueId,
            },
          })

          inputEl.disabled = false
          const { error, id: profile_variable_value_gid } = response || {}

          if (error || !response) {
            inputEl.value = variableValue
            Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
            handleAfterProfileVariableSave({ hasError: true })
          } else {
            const foundProfileIndex = profiles.findIndex(({ id }) => id === profileId)

            if (foundProfileIndex > -1) {
              if (profiles[foundProfileIndex].profile_variables[variableId]) {
                profiles[foundProfileIndex].profile_variables[variableId] = {
                  value: inputEl.value,
                  label: variableLabel,
                  variableId,
                  profileVariableValueId: profile_variable_value_gid,
                }

                profiles[foundProfileIndex].hasEmptyVariable = !!Object.values(
                  profiles[foundProfileIndex].profile_variables
                ).find(({ value }) => !value)

                const elIcon = document.getElementById(`${inputId}_icon`)
                if (elIcon) {
                  elIcon.style.opacity = '1'
                  setTimeout(() => {
                    elIcon.style.opacity = '0'
                  }, 1500)
                }
                setProfiles([...profiles])
              }
            }

            handleAfterProfileVariableSave({ hasError: false })
          }
        } catch (error) {
          inputEl.value = variableValue
          errorHelper({
            error,
            componentName: ProfileVariablesComponent.displayName,
            functionName: 'handleSaveProfileVariable',
          })
          inputEl.disabled = false
          handleAfterProfileVariableSave({ hasError: true })
        }
      }
    }

    const renderHeaderColumns = (columnIndex) => {
      const variable = filteredVariables[columnIndex]

      return variable ? (
        <StyledFlex
          alignItems="center"
          key={variable.label}
          onClick={() => {
            if (variable.id) {
              handleClickOpenAddVariableModal({ data: variable })
            }
          }}
          width="100%"
        >
          <Excerpt fontWeight="medium">{variable.label}</Excerpt>
        </StyledFlex>
      ) : null
    }

    const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
      const bg = rowIndex % 2 === 1 ? COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL : COLOR_CONSTANTS.WHITE

      let cellTemplate = null
      if (rowIndex === 0) {
        cellTemplate = renderHeaderColumns(columnIndex)
      } else {
        const profile = filteredProfiles[rowIndex - 1]
        const {
          id,
          name,
          username,
          nickname = '',
          picture_url,
          network: { code },
          profile_variables,
          entityId,
          hasEmptyVariable = false,
        } = profile

        const networkImage = getSocialNetworkIconByName(code).enabled

        if (columnIndex === 0) {
          cellTemplate = (
            <Flex alignItems="center" justifyContent="space-between" width="100%">
              <Flex alignItems="center" width="calc(100% - 24px)">
                <Flex width="24px" height="24px" justifyContent="center" alignItems="center" position="relative">
                  <StyledPicture
                    width="24px"
                    height="24px"
                    source={picture_url || DEFAULT_SOURCE}
                    fallbackSource={DEFAULT_SOURCE}
                    objectFit={picture_url ? 'cover' : 'contain'}
                    id={id}
                  />
                  <NetworkImageWrapper>
                    <Image src={networkImage} width="12px" height="12px" />
                  </NetworkImageWrapper>
                </Flex>
                <Excerpt
                  ml="s"
                  as={specialVariables ? 'span' : 'a'}
                  href={specialVariables ? '' : `/settings/profiles/${id}`}
                >
                  {nickname || name || username}
                </Excerpt>
              </Flex>
              {hasEmptyVariable && (
                <Flex alignItems="center" justifyContent="center">
                  <Flex alignItems="center" justifyContent="center">
                    <StyledFlex
                      alignItems="center"
                      justifyContent="center"
                      ml="s"
                      onMouseEnter={(e) => {
                        tooltipRef.current.handleShowTooltip({
                          contentComp: <Text p="m">Some variables are missing values</Text>,
                          wrapperComp: e.currentTarget,
                        })
                      }}
                      onMouseLeave={() => {
                        tooltipRef.current.handleHideTooltip()
                      }}
                    >
                      <Icon.Warning fill={colors.error} />
                    </StyledFlex>
                  </Flex>
                </Flex>
              )}
            </Flex>
          )
        } else {
          const variable = filteredVariables[columnIndex]

          if (variable && profile_variables[variable.id]) {
            const { value, variableId } = profile_variables[variable.id]

            const inputId = `${id}_${variableId}`

            cellTemplate = (
              <Flex flexDirection="column" position="relative" key={inputId}>
                <StyledInput
                  autoComplete="none"
                  id={inputId}
                  placeholder=""
                  defaultValue={value}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      handleSaveProfileVariable({
                        inputId,
                        entityId,
                        profileId: id,
                        ...profile_variables[variable.id],
                      })
                    }
                  }}
                  onBlur={() =>
                    handleSaveProfileVariable({
                      inputId,
                      entityId,
                      profileId: id,
                      ...profile_variables[variable.id],
                    })
                  }
                />
                <StyledInputIcon p="s" justifyContent="center" alignItems="center" id={`${inputId}_icon`}>
                  <Image source="/assets/checkmark_circle_checked.svg" width="18px" height="18px" />
                </StyledInputIcon>
              </Flex>
            )
          }
        }
      }
      return (
        <Flex
          key={key}
          style={style}
          bg={bg}
          px="s"
          alignItems="center"
          borderLeft={columnIndex === 0 ? '' : `1px solid ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL}`}
        >
          {cellTemplate}
        </Flex>
      )
    }

    const handleUpdateProfilesFilter = (name, value) => {
      setProfilesFilter({ ...profilesFilter, [name]: value })
    }

    const handleUpdateVariablesFilter = (name, value) => {
      setVariablesFilter({ ...variablesFilter, [name]: value })
    }

    const onChangeProfilesFilter = () => {
      const body = {
        [NAME]: profilesFilter[NAME].toLowerCase() || undefined,
        [NETWORK]: profilesFilter[NETWORK] || undefined,
        [ENTITY]: profilesFilter[ENTITY] || undefined,
      }

      let filteredData = profiles

      for (const i in body) {
        if (body[i]) {
          if (i === NAME) {
            filteredData = filteredData.filter(({ username = '', name = '' }) =>
              `${(username || '').toLowerCase()} ${(name || '').toLowerCase()}`.includes(body[i].toLowerCase())
            )
          }
          if (i === NETWORK) {
            filteredData = filteredData.filter(({ network: { code } }) => code === body[i])
          }
          if (i === ENTITY) {
            filteredData = filteredData.filter((item) => {
              const { entities } = item

              const foundEntity = entities.find(({ entity_gid }) => entity_gid === body[i])

              if (foundEntity) {
                return item
              }
              return null
            })
          }
        }
      }
      setFilteredProfiles([...filteredData])
    }

    const onChangeVariablesFilter = () => {
      const body = {
        [VARIABLE]: variablesFilter[VARIABLE].toLowerCase() || undefined,
      }

      let filteredData = variables

      for (const i in body) {
        if (body[i]) {
          if (i === VARIABLE) {
            filteredData = variables.filter(({ label = '' }) => {
              if (label === 'Profile') {
                return true
              }
              return label.toLowerCase().includes(body[i].toLowerCase())
            })
          }
        }
      }
      setFilteredVariables([...filteredData])
    }

    const handleUpdateProfilesFilterDebounced = debounce((name, value) => {
      handleUpdateProfilesFilter(name, value)
    }, 300)

    const handleUpdateVariablesFilterDebounced = debounce((name, value) => {
      handleUpdateVariablesFilter(name, value)
    }, 300)

    const hasFilters =
      showProfileNameFilter || showVariableNameFilter || showProfileNetworkFilter || showProfileGroupFilter

    return (
      <Flex flexDirection="column" position="relative">
        <Flex mb="m" justifyContent="flex-end">
          <StyledFlex alignItems="center" onClick={handleClickOpenAddVariableModal}>
            <Image source="/assets/plus.svg" mr="s" />
            <Text fontWeight="bold" color="primary">
              Add custom field
            </Text>
          </StyledFlex>
        </Flex>
        <StyledProgressWrapper display={isGettingData ? 'flex' : 'none'} alignItems="center" justifyContent="center">
          <ClipLoader size="40" color={colors.primary} />
        </StyledProgressWrapper>

        {!hideGridWithoutVariables || (hideGridWithoutVariables && variables.length > 1) ? (
          <Card px={0} pb={0} pt={0}>
            <Paper
              style={{
                height:
                  (filteredProfiles.length > MAX_PROFILES_IN_GRID
                    ? 40 * MAX_PROFILES_IN_GRID
                    : 40 * (filteredProfiles.length + 1)) + (hasFilters ? 70 : 0),
                width: '100%',
              }}
            >
              <Flex flexDirection="column" position="relative" width="100%">
                <Fragment>
                  {hasFilters && (
                    <Grid px="m" py="m" gridTemplateColumns={['1fr', 'repeat(4, 1fr)']} gridGap="l" alignItems="center">
                      {showProfileNameFilter && (
                        <Input
                          onChange={(e) => {
                            handleUpdateProfilesFilterDebounced(NAME, e.target.value)
                          }}
                          placeholder="Search profiles"
                          iconComp={<Image source="/assets/magnifier.svg" />}
                          width="100%"
                          height="38px"
                        />
                      )}

                      {showVariableNameFilter && (
                        <Input
                          onChange={(e) => {
                            handleUpdateVariablesFilterDebounced(VARIABLE, e.target.value)
                          }}
                          placeholder="Search fields"
                          iconComp={<Image source="/assets/magnifier.svg" />}
                          width="100%"
                          height="38px"
                        />
                      )}

                      {showProfileNetworkFilter && (
                        <Flex alignItems="center">
                          <DropDown
                            onChange={({ value }) => {
                              handleUpdateProfilesFilter(NETWORK, value)
                            }}
                            value={networks.map(({ code, display }) => {
                              if (code === profilesFilter[NETWORK]) {
                                return {
                                  value: code,
                                  label: display,
                                }
                              }
                              return null
                            })}
                            options={[
                              { value: '', label: 'Any Network' },
                              ...networks.map(({ code, display }) => ({ label: display, value: code })),
                            ]}
                            width="100%"
                            isSearchable={false}
                            placeholder="Select network"
                          />
                        </Flex>
                      )}

                      {showProfileGroupFilter && (
                        <Flex alignItems="center">
                          <DropDown
                            onChange={({ value }) => {
                              handleUpdateProfilesFilter(ENTITY, value)
                            }}
                            value={entities.map(({ entity_gid, name }) => {
                              if (entity_gid === profilesFilter[ENTITY]) {
                                return {
                                  value: entity_gid,
                                  label: name,
                                }
                              }
                              return null
                            })}
                            options={[
                              { value: '', label: 'All profile groups' },
                              ...entities.map(({ entity_gid, name }) => ({ value: entity_gid, label: name })),
                            ]}
                            width="100%"
                            placeholder="Select group"
                          />
                        </Flex>
                      )}
                    </Grid>
                  )}
                  <AutoSizer>
                    {({ width }) => (
                      <MultiGrid
                        ref={multiGridRef}
                        cellRenderer={cellRenderer}
                        styleBottomLeftGrid={{}}
                        styleTopLeftGrid={{}}
                        fixedColumnCount={1}
                        fixedRowCount={1}
                        height={
                          filteredProfiles.length > MAX_PROFILES_IN_GRID
                            ? 40 * MAX_PROFILES_IN_GRID
                            : 40 * (filteredProfiles.length + 1) + 17
                        }
                        width={width}
                        columnCount={filteredVariables.length}
                        columnWidth={({ index }) => {
                          return index === 0 ? 250 : 200
                        }}
                        rowCount={filteredProfiles.length + 1}
                        rowHeight={40}
                        style={{}}
                        styleBottomRightGrid={{}}
                        styleTopRightGrid={{}}
                        classNameBottomLeftGrid=""
                        classNameBottomRightGrid=""
                        classNameTopLeftGrid=""
                        classNameTopRightGrid=""
                        enableFixedColumnScroll
                        enableFixedRowScroll={false}
                        hideBottomLeftGridScrollbar
                      />
                    )}
                  </AutoSizer>
                </Fragment>
              </Flex>
            </Paper>
          </Card>
        ) : (
          <Flex height="100px" />
        )}
        {addVariableModalOptions.isOpen && (
          <AddVariableModal
            isOpen={addVariableModalOptions.isOpen}
            handleDismiss={handleClickCloseAddVariableModal}
            selectedVariable={addVariableModalOptions.data}
            isVariableModalSaving={isVariableModalSaving}
            isVariableModalDeleting={isVariableModalDeleting}
            handleSubmitVariableModal={handleSaveVariable}
            handleRemoveVariable={handleRemoveVariable}
            variables={variables}
          />
        )}
        <CalendarItemTooltip ref={tooltipRef} />
      </Flex>
    )
  }
)

ProfileVariablesComponent.defaultProps = {
  profiles: null,
  variables: null,
  handleAfterProfileVariableSave: () => {},
  hideGridWithoutVariables: true,
  showProfileNameFilter: true,
  showVariableNameFilter: true,
  showProfileNetworkFilter: true,
  showProfileGroupFilter: true,
}

ProfileVariablesComponent.propTypes = {
  user: PropTypes.object.isRequired,
  profiles: PropTypes.array,
  variables: PropTypes.array,
  handleAfterProfileVariableSave: PropTypes.func,
  hideGridWithoutVariables: PropTypes.bool,
  showProfileNameFilter: PropTypes.bool,
  showVariableNameFilter: PropTypes.bool,
  showProfileNetworkFilter: PropTypes.bool,
  showProfileGroupFilter: PropTypes.bool,
}

ProfileVariablesComponent.displayName = 'ProfileVariablesComponent'

export default ProfileVariablesComponent
