import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal from '@material-ui/core/Modal'
import { transparentize } from 'polished'
import ClipLoader from 'react-spinners/ClipLoader'
import { space as styledSpace } from 'styled-system'
import { COLOR_CONSTANTS, colors, radius } from 'theme'
import { IMAGE, VIDEO, DOCUMENT } from 'consts'
import { H4, Text } from 'components/atoms/Typography'
import { Box, Flex } from 'components/atoms/Layout'
import { POST_IMAGE, POST_VIDEO, POST_DOCUMENT, POST_MIXED_MEDIA } from '../../consts'

const StyledDialogOverlay = styled(Modal)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483002 !important;
    > * {
      &:first-child {
        background: none !important;
      }
    }
  }
`

const StyledDialogContent = styled(Flex)`
  &&& {
    position: relative;
    max-width: 750px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    flex-direction: column;
    background-color: ${COLOR_CONSTANTS.WHITE};
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  background-color: ${COLOR_CONSTANTS.BOYSENBERRY_SHADOW};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  height: 100%;
  overflow: hidden;
`

const StyledProgressWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
  background-color: ${COLOR_CONSTANTS.DAISY};
`

const MediaUploadDummy = ({ isOpen, data, uploadMediaToServerByStringData, isSettingsModalOpen }) => {
  const [posts, setPosts] = useState([])

  useEffect(() => {
    if (isOpen) {
      setPosts([])
      setPosts(data.listOfPosts)
    }
  }, [isOpen])

  useEffect(() => {
    if (posts.length) {
      posts.forEach((post) => {
        const {
          selected,
          postVideos = [],
          postImages = [],
          postDocuments = [],
          postMixedMedias = [],
          postComponent,
        } = post
        if (selected) {
          if (postComponent === POST_IMAGE) {
            postImages.forEach(({ url, uploadingId }) => {
              if (uploadingId) {
                uploadMediaToServerByStringData({
                  mediaURL: url,
                  uploadingId,
                  type: IMAGE,
                })
              }
            })
          } else if (postComponent === POST_VIDEO) {
            postVideos.forEach(({ url, uploadingId }) => {
              if (uploadingId) {
                uploadMediaToServerByStringData({
                  mediaURL: url,
                  uploadingId,
                  type: VIDEO,
                })
              }
            })
          } else if (postComponent === POST_DOCUMENT) {
            postDocuments.forEach(({ url, uploadingId }) => {
              if (uploadingId) {
                uploadMediaToServerByStringData({
                  mediaURL: url,
                  uploadingId,
                  type: DOCUMENT,
                })
              }
            })
          } else if (postComponent === POST_MIXED_MEDIA) {
            postMixedMedias.forEach(({ url, uploadingId }) => {
              if (uploadingId) {
                uploadMediaToServerByStringData({
                  mediaURL: url,
                  uploadingId,
                  type: '',
                })
              }
            })
          }
        }
      })
    }
  }, [posts])

  return (
    <StyledDialogOverlay
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={isOpen}
      onClose={() => {}}
      isSettingsModalOpen={isSettingsModalOpen}
    >
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent height="500px">
          <StyledDialogEnvironmentWrapper px="m" $isTop>
            <H4 my="m">Uploading media...</H4>
          </StyledDialogEnvironmentWrapper>
          <StyledDialogBodyWrapper flex="1" flexDirection="column">
            <Text my="m" color="secondaryText" px="m">
              Please wait a few moments while we upload and process your media.
            </Text>
            <Flex width="100%" height="100%" position="relative">
              <StyledProgressWrapper alignItems="center" justifyContent="center">
                <ClipLoader size="50" color={colors.primary} />
              </StyledProgressWrapper>
            </Flex>
          </StyledDialogBodyWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

MediaUploadDummy.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  uploadMediaToServerByStringData: PropTypes.func.isRequired,
  isSettingsModalOpen: PropTypes.bool.isRequired,
}

export default MediaUploadDummy
