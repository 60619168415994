import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { COLOR_CONSTANTS, radius } from 'theme'
import { FEATURE_PROFILE_VARIABLES } from 'consts'
import { checkIfPlanHasFeatureEnabled } from 'utils/feature'
import { H4, Text } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import Image from 'components/atoms/Image'
import Link from 'components/atoms/Link'
import ProfileVariablesComponent from './ProfileVariablesComponent'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    position: relative;
    max-width: ${({ isSmall }) => (isSmall ? '800px' : '1440px')};
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
    top: 50%;
    transform: translate(0, -50%);
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  background-color: ${COLOR_CONSTANTS.BOYSENBERRY_SHADOW};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const MessageWrapper = styled(Flex)`
  background-color: ${({ color }) => transparentize(0.9, color || COLOR_CONSTANTS.FLUORESCENT_RED)};
  border-radius: ${radius.l};
`

const SPECIAL_STATE_SUBMITTING_DATA = { isSubmitting: false, isDismissed: false }

const VariablesModal = ({ user, isOpen, handleSave, profiles, variables, callbackFunction, needValidation }) => {
  const profileVariablesComponentRef = useRef(null)

  const [description, setDescription] = useState('')
  const [featuresEnabled, setFeaturesEnabled] = useState({
    [FEATURE_PROFILE_VARIABLES]: { enabled: true },
  })

  useEffect(() => {
    if (isOpen) {
      SPECIAL_STATE_SUBMITTING_DATA.isSubmitting = false
      SPECIAL_STATE_SUBMITTING_DATA.isDismissed = false

      setFeaturesEnabled({
        ...checkIfPlanHasFeatureEnabled({
          user,
          features: [FEATURE_PROFILE_VARIABLES],
        }),
      })

      if (needValidation) {
        const variablesString = variables.map(({ variable }) => variable.replace(/{|}/g, '')).join(', ')

        const description = variablesString
          ? `Custom fields must be defined for all selected profiles: ${variablesString}. If a custom field is missing,
                please create it.`
          : ''

        setDescription(description)
      }
    }
  }, [isOpen])

  const handleSubmitForm = async ({ isDismissed }) => {
    if (profileVariablesComponentRef && profileVariablesComponentRef.current) {
      const { profiles, variables } = profileVariablesComponentRef.current.getVariables()

      let foundNotSavedProfile = false
      for (let i = 0; i < profiles.length; i++) {
        const { profile_variables = {}, id } = profiles[i]

        const profile_variables_temp = Object.values(profile_variables)

        for (let j = 0; j < profile_variables_temp.length; j++) {
          const { variableId } = profile_variables_temp[j]

          const inputId = `${id}_${variableId}`

          const inputEl = document.getElementById(inputId)

          if (inputEl && inputEl.disabled) {
            foundNotSavedProfile = true
          }

          if (foundNotSavedProfile) {
            break
          }
        }

        if (foundNotSavedProfile) {
          break
        }
      }

      if (foundNotSavedProfile) {
        SPECIAL_STATE_SUBMITTING_DATA.isSubmitting = true
        SPECIAL_STATE_SUBMITTING_DATA.isDismissed = isDismissed
      } else {
        variables.splice(0, 1)
        handleSave({ profiles, variables, callbackFunction: isDismissed ? null : callbackFunction })
      }
    } else {
      handleSave({ profiles: [], variables: [], callbackFunction: null })
    }
  }

  const handleClickContinue = () => {
    handleSubmitForm({ isDismissed: false })
  }

  const handleAfterProfileVariableSave = (data) => {
    if (SPECIAL_STATE_SUBMITTING_DATA.isSubmitting) {
      const { hasError } = data
      if (hasError) {
        SPECIAL_STATE_SUBMITTING_DATA.isSubmitting = false
        SPECIAL_STATE_SUBMITTING_DATA.isDismissed = false
      } else {
        handleSubmitForm({ isDismissed: SPECIAL_STATE_SUBMITTING_DATA.isDismissed })
      }
    }
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent isSmall={needValidation}>
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" alignItems="center" $isTop>
            <H4 my="m">{needValidation ? 'Please setup missing custom fields' : 'Setup custom fields'}</H4>
          </StyledDialogEnvironmentWrapper>

          {description && (
            <Flex flexDirection="column" px="m" mt="m">
              <MessageWrapper alignItems="center" px="m" py="s" color={COLOR_CONSTANTS.FLUORESCENT_RED}>
                <Text color={COLOR_CONSTANTS.FLUORESCENT_RED}>
                  {description}{' '}
                  <Link
                    color="primary"
                    href="https://support.vistasocial.com/hc/en-us/articles/14439524021531"
                    target="_blank"
                  >
                    Learn more.
                  </Link>
                </Text>
              </MessageWrapper>
            </Flex>
          )}

          {!featuresEnabled[FEATURE_PROFILE_VARIABLES].enabled ? (
            <Flex flexDirection="column" my="m" justifyContent="center" alignItems="center" height="100%">
              <Text textAlign="center">
                Please upgrade to use custom fields.{' '}
                <Link
                  color="primary"
                  href="https://support.vistasocial.com/hc/en-us/articles/14439524021531"
                  target="_blank"
                >
                  Learn more
                </Link>
              </Text>
            </Flex>
          ) : (
            <Flex flexDirection="column" px="m" pb="m">
              <ProfileVariablesComponent
                ref={profileVariablesComponentRef}
                user={user}
                profiles={profiles}
                variables={variables}
                handleAfterProfileVariableSave={handleAfterProfileVariableSave}
                hideGridWithoutVariables={false}
                showProfileNameFilter={false}
                showVariableNameFilter={false}
                showProfileNetworkFilter={false}
                showProfileGroupFilter={false}
              />
            </Flex>
          )}

          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Button.Gray
              mr="m"
              isSmall
              onClick={() => {
                handleSubmitForm({ isDismissed: true })
              }}
            >
              Cancel
            </Button.Gray>

            <ButtonWithLoading
              onClick={() => {
                handleClickContinue()
              }}
              isSmall
              type="button"
              isLoading={SPECIAL_STATE_SUBMITTING_DATA.isSubmitting}
            >
              <Text fontWeight="medium">{needValidation ? 'Continue' : 'Save'}</Text>
            </ButtonWithLoading>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper
            className="modal-close-icon"
            onClick={() => {
              handleSubmitForm({ isDismissed: true })
            }}
          >
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

VariablesModal.defaultProps = {
  callbackFunction: null,
  needValidation: true,
}

VariablesModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  profiles: PropTypes.array.isRequired,
  variables: PropTypes.array.isRequired,
  callbackFunction: PropTypes.func,
  needValidation: PropTypes.bool,
}

export default VariablesModal
