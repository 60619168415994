import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  space as styledSpace,
  width as styledWidth,
  maxWidth as styledMaxWidth,
  minWidth as styledMinWidth,
} from 'styled-system'
import Alert from 'react-s-alert'
import Modal from '@material-ui/core/Modal'
import { transparentize } from 'polished'
import { Scrollbars } from 'react-custom-scrollbars-2'
import debounce from 'lodash.debounce'
import ClipLoader from 'react-spinners/ClipLoader'
import { breakpoints, COLOR_CONSTANTS, colors, radius } from 'theme'
import { PERMISSION_PUBLISH, TWITTER } from 'consts'
import withConfirm from 'utils/withConfirm'
import { H4 } from 'components/atoms/Typography'
import { Box, Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'
// eslint-disable-next-line import/no-cycle
import TextAreaComponentWithControls from 'routes/Calendar/components/Posts/components/CommentsComponent/TextAreaComponentWithControls'
import CalendarItemTooltip from 'routes/Calendar/components/CalendarItemTooltip'
// eslint-disable-next-line import/no-cycle
import {
  getEntities,
  getPostComponentByNetworks,
  getPublicationDataFromLocalStorage,
  removePublicationDataFromLocalStorage,
  setPublicationDataToLocalStorageDebounce,
  transformTextVariantsToUnicodeVariant,
} from 'routes/Calendar/helpers'
import { LOCAL_STORAGE_TWITTER_THREADS_MAKER_MODAL } from 'routes/Calendar/consts'

const StyledDialogOverlay = styled(Modal)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(Flex)`
  &&& {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    ${styledWidth};
    ${styledMaxWidth};
    ${styledMinWidth};
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    background: transparent;
    max-height: 590px;
    top: 50%;
    transform: translate(0, -50%);
  }
`

const StyledLeftColumnWrapper = styled(Flex)`
  outline: none;
  background: ${COLOR_CONSTANTS.WHITE};
  border-radius: ${radius.l};
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  background-color: ${COLOR_CONSTANTS.BOYSENBERRY_SHADOW};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  outline: none;
  height: 100%;
  overflow: hidden;
`

const textTransformer = ({ text }) => {
  let postTextUpdated = ''
  let comments = []

  if (text) {
    const splittedText = text.split('\n\n')

    // eslint-disable-next-line prefer-destructuring
    postTextUpdated = splittedText[0]

    if (splittedText.length > 1) {
      comments = splittedText.slice(1).map((comment) => ({
        id: (new Date().getTime() + Math.floor(Math.random() * 1000)).toString(),
        message: comment,
      }))
    }
  }

  return { postTextUpdated, comments }
}

const SPECIAL_CUSTOMIZATIONS_PROFILE_ID = 1

const TwitterThreadMakerModal = ({ user, isOpen, handleDismiss, confirm, handleClickCreatePost }) => {
  const tooltipRef = useRef(null)

  const [entities, setEntities] = useState([])
  const [selectedEntities, setSelectedEntities] = useState([])
  const [selectedProfiles, setSelectedProfiles] = useState([])
  const [isGettingEntities, setIsGettingEntities] = useState(true)
  const [post, setPost] = useState({
    postText: '',
  })
  const [textVariantOffsets, setTextVariantOffsets] = useState([])
  const [postCreationComponentKey, setPostCreationComponentKey] = useState(new Date().getTime())
  const [dataToLocalStorageOptions, setDataToLocalStorageOptions] = useState({
    canSave: false,
    lastTimeSaved: 0,
    lastTimeSavedMessage: '',
  })
  const [isSmallPreview, setIsSmallPreview] = useState(false)

  const { postText = '' } = post

  const {
    permissions: {
      [PERMISSION_PUBLISH]: { canManage: canManagePublishing },
    },
  } = user

  const getEntitiesData = async () => {
    await getEntities({
      user,
      setEntities,
      setSelectedProfiles,
      setSelectedEntities,
      handleSaveSelectedEntities: () => {},
    })

    setIsGettingEntities(false)
  }

  const handleResizeWindow = () => {
    if (process.browser) {
      const { desktop } = breakpoints

      const desktopNumber = Number(desktop.replace('px', ''))

      if (window.innerWidth < desktopNumber) {
        setIsSmallPreview(true)
      } else {
        setIsSmallPreview(false)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResizeWindow)
    return () => {
      window.removeEventListener('resize', handleResizeWindow)
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      getEntitiesData()

      setTextVariantOffsets([...transformTextVariantsToUnicodeVariant()])
      getPublicationDataFromLocalStorage({
        key: LOCAL_STORAGE_TWITTER_THREADS_MAKER_MODAL,
        message: `You have an auto saved Twitter thread. Do you want to load it?`,
        confirm,
        action: 'Yes, recover thread',
        setData: (post) => {
          setPost({ ...post })
          setPostCreationComponentKey(new Date().getTime())
        },
        setDataToLocalStorageOptions,
      })
    }
  }, [isOpen])

  const setIfTwitterThreadHasChanged = () => {
    if (dataToLocalStorageOptions.canSave) {
      setPublicationDataToLocalStorageDebounce({
        key: LOCAL_STORAGE_TWITTER_THREADS_MAKER_MODAL,
        data: post,
        dataToLocalStorageOptions,
        setDataToLocalStorageOptions,
      })
    }
  }

  const handleUpdatePostTextDebounce = debounce(({ text }) => {
    post.postText = text
    setPost({ ...post })

    if (text) {
      setIfTwitterThreadHasChanged()
    } else {
      removePublicationDataFromLocalStorage({ key: LOCAL_STORAGE_TWITTER_THREADS_MAKER_MODAL })
    }
  }, 300)

  const handleClickCollectPostData = () => {
    if (!postText) {
      Alert.error(`Please enter your caption.`, { timeout: 5000 })
    } else {
      let selectedEntitiesForSelectedPost = []
      let selectedProfilesForSelectedPost = []

      const foundTwitterProfile = selectedProfiles.find(({ code }) => code === TWITTER)

      const collectProfile = ({ profile, entity }) => {
        const { type: entityType } = entity
        const {
          id,
          entityId,
          name,
          username,
          picture_url,
          profile_url,
          network: { code, display },
          description,
          type,
        } = profile

        const data = {
          id,
          entityId,
          picture_url,
          code,
          name,
          entityType,
          username,
          display,
          profile_url,
          description,
          type,
        }

        return data
      }

      const collectEntities = ({ entities }) => {
        let hasTwitterProfileInSelectedEntities = false

        entities.forEach((entity) => {
          const { profiles = [] } = entity

          let foundTwitterProfile = false

          profiles.forEach((profile) => {
            const { network } = profile
            const { code } = network || {}

            if (code === TWITTER) {
              hasTwitterProfileInSelectedEntities = true
              foundTwitterProfile = true
              selectedProfilesForSelectedPost.push(collectProfile({ profile, entity }))
            }
          })

          if (foundTwitterProfile) {
            selectedEntitiesForSelectedPost.push(entity)
          }
        })

        return { hasTwitterProfileInSelectedEntities }
      }

      if (foundTwitterProfile) {
        selectedEntitiesForSelectedPost = selectedEntities
        selectedProfilesForSelectedPost = selectedProfiles
      } else {
        const { hasTwitterProfileInSelectedEntities } = collectEntities({ entities: selectedEntities })

        if (!hasTwitterProfileInSelectedEntities) {
          collectEntities({ entities })
        }
      }

      const { postTextUpdated, comments } = textTransformer({ text: postText })

      selectedProfilesForSelectedPost.sort((a) => (a.code === TWITTER ? -1 : 1))

      removePublicationDataFromLocalStorage({ key: LOCAL_STORAGE_TWITTER_THREADS_MAKER_MODAL })

      handleClickCreatePost({
        post: { postText: postTextUpdated, defaultFields: { comments }, isFromFindContentModal: true },
        selectedEntitiesForSelectedPost,
        selectedProfilesForSelectedPost,
      })
    }
  }

  const handleClickDismiss = () => {
    removePublicationDataFromLocalStorage({ key: LOCAL_STORAGE_TWITTER_THREADS_MAKER_MODAL })
    handleDismiss()
  }

  const handleClickCloseModal = () => {
    if (postText) {
      confirm({
        fn: () => () => {
          handleClickDismiss()
        },
        message: `Your Twitter thread won't be saved. Do you want to continue?`,
        action: `Yes, I'm sure`,
        cancel: 'No, cancel',
      })
    } else {
      handleClickDismiss()
    }
  }

  const PostComponent = getPostComponentByNetworks(TWITTER)

  const { postTextUpdated, comments } = textTransformer({ text: postText })

  return (
    <StyledDialogOverlay disableEnforceFocus open={isOpen} onClose={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent
          maxWidth={{ mobile: '560px', desktop: postText ? '1132px' : '560px' }}
          minWidth={{ mobile: 'auto', desktop: '560px' }}
        >
          <StyledLeftColumnWrapper
            flexDirection="column"
            width={{ mobile: '100%', mobileLarge: postText ? '50%' : '100%' }}
            height="100%"
          >
            <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" $isTop>
              <H4 my="m">Twitter thread maker</H4>
            </StyledDialogEnvironmentWrapper>

            <StyledDialogBodyWrapper tabIndex="0">
              <Flex
                width="100%"
                height="100%"
                flexDirection="column"
                key={postCreationComponentKey}
                position="relative"
              >
                <Scrollbars universal className="scrollbars-hidden-x">
                  {isGettingEntities ? (
                    <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
                      <ClipLoader size="50" color={colors.primary} />
                    </Flex>
                  ) : (
                    <Flex flexDirection="column" width="100%" px="m" height="100%" justifyContent="space-between">
                      <Flex flexDirection="column">
                        <Flex flexDirection="column" position="relative">
                          <TextAreaComponentWithControls
                            user={user}
                            handleUpdateText={(text) => {
                              handleUpdatePostTextDebounce({ text })
                            }}
                            text={postText}
                            textVariantOffsets={textVariantOffsets}
                            textareaPlaceholder={`Enter your caption, separate each thread by a paragraph ...\n\nThread item\n\nThread item\n\netc...`}
                            errorText=""
                            height="400px"
                            borderColor="transparent"
                            showOnTop
                            withoutSelectedEntitiesAndProfiles
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  )}
                </Scrollbars>
              </Flex>
            </StyledDialogBodyWrapper>
            <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
              <Button.Gray isSmall onClick={handleClickCloseModal}>
                Cancel
              </Button.Gray>
              <Flex alignItems="center">
                {canManagePublishing && (
                  <Button.Gradient isSmall onClick={handleClickCollectPostData}>
                    Create a post
                  </Button.Gradient>
                )}
              </Flex>
            </StyledDialogEnvironmentWrapper>
          </StyledLeftColumnWrapper>
          {postText && (
            <Flex
              display={{ mobile: 'none', mobileLarge: 'flex' }}
              width="50%"
              height="100%"
              flexDirection="column"
              minWidth={{ mobile: '250px', desktop: '560px' }}
              bg={COLOR_CONSTANTS.WHITE}
              borderRadius={radius.l}
              ml="m"
            >
              <Scrollbars universal className="scrollbars-hidden-x">
                <PostComponent
                  user={user}
                  post={{
                    postText: postTextUpdated,
                    customizations: {
                      [TWITTER]: {
                        [SPECIAL_CUSTOMIZATIONS_PROFILE_ID]: {
                          kind: TWITTER,
                          profile_gid: SPECIAL_CUSTOMIZATIONS_PROFILE_ID,
                          comments,
                        },
                      },
                    },
                    profileNetwork: TWITTER,
                  }}
                  profile={{
                    profileId: SPECIAL_CUSTOMIZATIONS_PROFILE_ID,
                    profileName: 'Twitter profile',
                    profilePictureUrl: '',
                    network: TWITTER,
                    display: 'Twitter',
                    profileUsername: '',
                    fallbackLogoImage: '/assets/company.svg',
                  }}
                  agencyMentions={post.agencyMentions || []}
                  isPostFormDirty
                  isEditable={false}
                  isSmallPreview={isSmallPreview}
                />
              </Scrollbars>
            </Flex>
          )}

          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>

        <CalendarItemTooltip ref={tooltipRef} />
      </Box>
    </StyledDialogOverlay>
  )
}

TwitterThreadMakerModal.defaultProps = {}

TwitterThreadMakerModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  handleClickCreatePost: PropTypes.func.isRequired,
}

TwitterThreadMakerModal.displayName = 'TwitterThreadMakerModal'

export default withConfirm(TwitterThreadMakerModal)
