import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR_CONSTANTS, space } from 'theme'
import Image from 'components/atoms/Image'
import ButtonControl from './ButtonControl'

const GallerySubheading = styled.h4`
  margin: 0;
  font-weight: normal;
  color: ${COLOR_CONSTANTS.COSMIC_ENERGY};
`

const PageIndicator = styled.span`
  white-space: nowrap;
  min-width: 60px;
  text-align: center;
`

const RightSideContainer = styled.div`
  width: 85px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: ${space.s};
`

const CloseButton = styled(ButtonControl)`
  height: 100%;
  display: flex;
  color: inherit;
`

const LeftSideDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left-width: 3px;
  border-left-color: ${COLOR_CONSTANTS.SALUTE};
  border-left-style: solid;
  padding: 8px 0 8px 10px;
`

const TopHeaderBar = styled.header`
  z-index: 10;
  cursor: auto;
  display: flex;
  justify-content: space-between;
  padding: 10px 2px 10px 20px;
  color: ${COLOR_CONSTANTS.COSMIC_ENERGY};
  background-color: hsla(0, 0%, 6.3%, 0.5);
`

const LightboxHeader = ({ images, currentIndex, onClose }) => (
  <TopHeaderBar>
    <LeftSideDescriptionContainer>
      <GallerySubheading>{images[currentIndex].alt}</GallerySubheading>
    </LeftSideDescriptionContainer>
    <RightSideContainer>
      <PageIndicator>
        {currentIndex + 1} / {images.length}
      </PageIndicator>
      <CloseButton onClick={onClose} type="button">
        <Image src="/assets/clear.svg" width="20px" height="20px" ml="xs" mr="s" />
      </CloseButton>
    </RightSideContainer>
  </TopHeaderBar>
)

LightboxHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default LightboxHeader
