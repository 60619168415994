import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { transparentize } from 'polished'
import Alert from 'react-s-alert'
import { space as styledSpace } from 'styled-system'
import { Scrollbars } from 'react-custom-scrollbars-2'
import ClipLoader from 'react-spinners/ClipLoader'
import debounce from 'lodash.debounce'
import Cookies from 'js-cookie'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import {
  ERROR_MESSAGE,
  VIDEO,
  IMAGE,
  AUDIO,
  DOCUMENT,
  LABELS_TYPE_MEDIA,
  COOKIE_TRENDING_SOUNDS_PROMPT_COLLAPSED,
  COOKIE_EXPIRATION_DAYS,
} from 'consts'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { H4, Text } from 'components/atoms/Typography'
import { Flex, Box, Grid } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import Image from 'components/atoms/Image'
import NoData from 'components/molecules/NoData'
import AudioPlayer from 'components/molecules/AudioPlayer'
import Icon from 'components/atoms/Icon'
import Input from 'components/atoms/Input'
import { ROUTE_MEDIA } from 'routes/Calendar/consts'
import CalendarItemTooltip from 'routes/Calendar/components/CalendarItemTooltip'
import MediaPreviewComponent from './MediaPreviewComponent'
import MediaFilters from './MediaFilters'
import { getLabels } from '../../../shared/LabelEditModal/helpers'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    position: relative;
    max-width: 900px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  background-color: ${COLOR_CONSTANTS.BOYSENBERRY_SHADOW};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  height: 100%;
  overflow: hidden;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Tab = styled(Box)`
  padding: ${space.s} 0;
  margin-left: ${space.s};
  margin-right: ${space.m};
  text-decoration: none;
  ${({ isActive }) =>
    isActive &&
    `
    border-bottom: 2px solid ${colors.primary};
  `}
  &:visited {
    color: initial;
  }
  &:hover {
    border-bottom: 2px solid ${({ isActive }) => (isActive ? colors.primary : colors.secondaryText)};
  }
`

const StyledProgressWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
  background-color: ${transparentize(0.5, COLOR_CONSTANTS.DAISY)};
`

const SelectWrapper = styled(Flex)`
  border-radius: ${radius.pill};
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? colors.primary : transparentize(0.2, COLOR_CONSTANTS.SALT))};
  border: 2px solid ${({ selected }) => (selected ? colors.primary : COLOR_CONSTANTS.COSMIC_ENERGY)};
  &:hover {
    border-color: ${COLOR_CONSTANTS.WHITE};
  }
`

const StyledIconCheckmark = styled(Icon.Checkmark)`
  color: ${({ selected }) => (selected ? colors.white : COLOR_CONSTANTS.COSMIC_ENERGY)};
`

const StyledWrapper = styled(Flex)`
  width: 100%;
  position: relative;
  background: ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
  border-radius: ${radius.l};
  ${({ selected }) => selected && `box-shadow: 0px 0px 5px 0px rgb(145 147 169 / 50%);`}
`

const StyledMediaWrapper = styled(Box)`
  width: 100%;
  height: 150px;
  border-top-left-radius: ${radius.l};
  border-top-right-radius: ${radius.l};
  transition: all 0.5s ease-in-out;
  transform: scale(1);
  ${({ selected }) =>
    selected &&
    `border-bottom-right-radius: ${radius.l};  border-bottom-left-radius: ${radius.l}; transform: scale(0.8);`}
`

const StyledText = styled(Text)`
  text-align: center;
`

const StyledTitleWrapper = styled(Flex)`
  height: 13px;
  overflow: hidden;
  justify-content: center;
`

const StyledPromptWrapper = styled(Flex)`
  border-radius: 3px;
  background: ${transparentize(0.9, colors.primaryText)};
`

const StyledClosePromptWrapper = styled(Flex)`
  cursor: pointer;
`

const { TABS_TYPE_IMAGE, TABS_TYPE_VIDEO, TABS_TYPE_AUDIO } = {
  TABS_TYPE_IMAGE: IMAGE,
  TABS_TYPE_VIDEO: VIDEO,
  TABS_TYPE_AUDIO: AUDIO,
}

const TABS = [
  { name: 'Images', show: IMAGE, type: TABS_TYPE_IMAGE },
  { name: 'Videos', show: VIDEO, type: TABS_TYPE_VIDEO },
  {
    name: 'Audios',
    show: AUDIO,
    type: TABS_TYPE_AUDIO,
    prompt: `Trending sounds are curated by Vista Social product team. <a style="color: ${colors.primary};" href="https://support.vistasocial.com/hc/en-us/articles/10237329729307-Using-Trending-Sounds-for-your-video-posts" target="_blank">Learn more</a>.`,
  },
]

const DEFAULT_MENU_HEIGHT = '34px'

const SearchInputComponent = forwardRef(({ handleFilterMediaBySearch }, inputSearchText) => {
  const [searchString, setSearchString] = useState('')

  useImperativeHandle(inputSearchText, () => ({
    getSearchString() {
      return searchString
    },
  }))

  const handleChangePostText = (text) => {
    setSearchString(text)
    handleFilterMediaBySearch(text)
  }

  return (
    <Input
      placeholder="Search"
      label=""
      value={searchString}
      onChange={(e) => {
        handleChangePostText(e.target.value)
      }}
      height={DEFAULT_MENU_HEIGHT}
      width={{ mobile: 'auto', tablet: '135px', desktopWide: '150px' }}
    />
  )
})

SearchInputComponent.propTypes = {
  handleFilterMediaBySearch: PropTypes.func.isRequired,
}

const MediaTrendingModal = ({ isOpen, handleDismiss, handleClickAddMedias, type }) => {
  const firstTab = TABS.filter((item) => item.show === type)[0] || {}

  const tooltipRef = useRef(null)
  const mediaFiltersRef = useRef(null)
  const inputSearchText = useRef(null)
  const scrollbarsRef = useRef(null)

  const [activeTab, setActiveTab] = useState(firstTab)
  const [isGettingMedia, setIsGettingMedia] = useState(false)
  const [trendingMedias, setTrendingMedias] = useState([])
  const [isFinalPage, setIsFinalPage] = useState(false)
  const [page, setPage] = useState(0)
  const [isInitial, setIsInitial] = useState(true)
  const [selectedMedias, setSelectedMedias] = useState([])
  const [labels, setLabels] = useState([])
  const [showPrompt, setShowPrompt] = useState(false)

  const searchForTrendingMedias = async ({ firstTab, page, selectedFilters }) => {
    try {
      setIsGettingMedia(true)

      let path = `${ROUTE_MEDIA}?types=${firstTab ? firstTab.type : activeTab.type}&page=${page}&common=true`

      const updatedSelectedFilters =
        selectedFilters ||
        (mediaFiltersRef && mediaFiltersRef.current ? mediaFiltersRef.current.getFiltersData() : { filters: {} })

      const { filters: { selectedLabels = [] } = {} } = updatedSelectedFilters

      if (selectedLabels.length !== 0) {
        path += `&labels=${encodeURIComponent(selectedLabels.map(({ value }) => value).join(','))}`
      }

      const searchTextValue =
        inputSearchText && inputSearchText.current ? inputSearchText.current.getSearchString() : ''

      path += `&q=${encodeURIComponent(searchTextValue)}`

      const response = await request({
        path,
      })

      const { error, data, hasNextPage } = response || {}
      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        setIsFinalPage(!hasNextPage)

        if (page === 0) {
          setTrendingMedias([...data])
        } else {
          setTrendingMedias([...trendingMedias, ...data])
        }
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: MediaTrendingModal.displayName,
        functionName: 'searchForTrendingMedias',
        errorMessage: `Can't get trending medias. Please contact support.`,
      })
    } finally {
      setIsGettingMedia(false)
    }
  }

  const checkPrompt = async () => {
    const isCookieAccepted = await Cookies.get(COOKIE_TRENDING_SOUNDS_PROMPT_COLLAPSED)
    if (!isCookieAccepted) {
      setShowPrompt(!isCookieAccepted)
    }
  }

  useEffect(() => {
    getLabels({
      setLabels: (data) => {
        setLabels(data.map((label) => ({ value: label, label })))
      },
      type: LABELS_TYPE_MEDIA,
      isCommon: true,
    })
    checkPrompt()
  }, [])

  useEffect(() => {
    if (!isInitial) {
      if (page === 0) {
        searchForTrendingMedias({ page })
      }
    }
  }, [activeTab])

  useEffect(() => {
    if (!isGettingMedia && !isFinalPage) {
      if (isInitial) {
        setIsInitial(false)
      }

      if (process.browser && page === 0 && scrollbarsRef && scrollbarsRef.current) {
        let top = 0

        const el = document.getElementById('media-section-wrapper')

        if (el) {
          const bounding = el.getBoundingClientRect()

          // eslint-disable-next-line prefer-destructuring
          top = bounding.top
        }

        scrollbarsRef.current.scrollTop(top)
      }

      searchForTrendingMedias({ page })
    }
  }, [page])

  const handleFilterMediaBySearch = debounce(() => {
    if (page === 0) {
      searchForTrendingMedias({ page })
    } else {
      setIsFinalPage(false)
      setPage(0)
    }
  }, 300)

  const handleMouseOver = ({ currentTarget, defaultXPosition, ...audio }) => {
    if (tooltipRef && tooltipRef.current) {
      tooltipRef.current.handleShowTooltip({
        contentComp: <MediaPreviewComponent {...audio} />,
        wrapperComp: currentTarget,
        defaultXPosition,
      })
    }
  }

  const handleClickClosePrompt = () => {
    Cookies.set(COOKIE_TRENDING_SOUNDS_PROMPT_COLLAPSED, true, { expires: COOKIE_EXPIRATION_DAYS })
    setShowPrompt(false)
  }

  const debouncedMouseOver = debounce(handleMouseOver, 200)

  let titleType = type
  if (type === AUDIO) {
    titleType = 'sound'
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" $isTop>
            <H4 my="m">Find trending {titleType}s</H4>
          </StyledDialogEnvironmentWrapper>
          <StyledDialogBodyWrapper flex="1" flexDirection="column">
            <Flex my="s" px="s" justifyContent="space-between">
              <Flex justifyContent="center">
                {TABS.map((item) => {
                  const { name, show, type: tabType } = item
                  const { type: activeTabType } = activeTab || {}
                  if (show === type) {
                    return (
                      <Tab
                        key={name}
                        onClick={() => {
                          setActiveTab(item)
                        }}
                        isActive={activeTabType && tabType === activeTabType}
                      >
                        <Text
                          color={activeTabType && tabType === activeTabType ? colors.primary : colors.secondaryText}
                          fontSize="m"
                        >
                          {name}
                        </Text>
                      </Tab>
                    )
                  }
                })}
              </Flex>
              <Flex alignItems="center">
                <SearchInputComponent handleFilterMediaBySearch={handleFilterMediaBySearch} ref={inputSearchText} />

                <Flex
                  justifyContent="center"
                  flexDirection="column"
                  width={{ mobile: 'auto', tablet: '135px', desktopWide: '150px' }}
                  mx="s"
                >
                  <MediaFilters
                    ref={mediaFiltersRef}
                    handleFetchMedia={({ filters }) => {
                      if (page === 0) {
                        searchForTrendingMedias({ selectedFilters: { filters }, page })
                      } else {
                        setIsFinalPage(false)
                        setPage(0)
                      }
                    }}
                    labels={labels}
                    extensions={{ [IMAGE]: [], [VIDEO]: [], [AUDIO]: [], [DOCUMENT]: [] }}
                    users={[]}
                    types={[]}
                    usage={[]}
                    showMobileIcon={{ mobile: 'flex', desktop: 'none' }}
                    removeMobileIcon={{ mobile: 'none', desktop: 'flex' }}
                  />
                </Flex>
              </Flex>
            </Flex>
            {activeTab.prompt && showPrompt && (
              <StyledPromptWrapper mx="m" alignItems="center" py="xs" px="s" mb="xs" justifyContent="space-between">
                <StyledText color="primary" dangerouslySetInnerHTML={{ __html: activeTab.prompt }} />
                <StyledClosePromptWrapper onClick={handleClickClosePrompt} width="10px" ml="s">
                  <Icon.Dismiss fill={colors.primary} />
                </StyledClosePromptWrapper>
              </StyledPromptWrapper>
            )}
            <Flex width="100%" height="100%" position="relative">
              <StyledProgressWrapper
                display={isGettingMedia ? 'flex' : 'none'}
                alignItems="center"
                justifyContent="center"
              >
                <ClipLoader size="100" color={colors.primary} />
              </StyledProgressWrapper>
              <Fragment>
                {trendingMedias.length === 0 ? (
                  <Flex
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    px="m"
                    bg={COLOR_CONSTANTS.DAISY}
                  >
                    <NoData
                      showHeaderText={false}
                      message={isGettingMedia ? '' : `No trending ${activeTab.type}s found`}
                      image="/assets/noResultsIllustration.svg"
                    />
                  </Flex>
                ) : (
                  <Flex width="100%" height="100%" id="media-section-wrapper">
                    <Scrollbars
                      ref={scrollbarsRef}
                      universal
                      style={{ display: 'flex', flexWrap: 'wrap' }}
                      onScrollFrame={({ top }) => {
                        if (top >= 0.99) {
                          if (!isGettingMedia) {
                            setPage((page) => {
                              return page + 1
                            })
                          }
                        }
                      }}
                    >
                      <Grid
                        gridTemplateColumns={{
                          mobile: 'repeat(1, 1fr)',
                          mobileLarge: 'repeat(2, 1fr)',
                          tablet: 'repeat(3, 1fr)',
                          desktop: 'repeat(4, 1fr)',
                        }}
                        width="100%"
                        gridGap="m"
                        justifyItems="center"
                        px="m"
                        pb="m"
                      >
                        {trendingMedias.map((audio, index) => {
                          const {
                            id,
                            url,
                            thumbnail_url,
                            thumbnail_url_small,
                            title,
                            filename,
                            duration,
                            metaInformation = '',
                          } = audio

                          const isCheckedIndex = selectedMedias.findIndex((media) => media.id === id)
                          const isChecked = isCheckedIndex > -1

                          const column = Math.floor((index - Math.floor(index / 4) * 4) / 2)

                          let mediaComponent = ''
                          if (activeTab.type === AUDIO) {
                            mediaComponent = (
                              <AudioPlayer
                                playlist={[
                                  {
                                    id: `trending-audio-${id}`,
                                    source: url,
                                    trackName: filename,
                                    trackArtist: title,
                                    trackImage: thumbnail_url_small || thumbnail_url || '',
                                    loop: false,
                                    duration,
                                  },
                                ]}
                                isSmall={false}
                              />
                            )
                          }
                          return (
                            <StyledWrapper key={id} flexDirection="column" selected={isChecked}>
                              <Flex width="100%" height="100%">
                                <Flex
                                  flexDirection="column"
                                  width="100%"
                                  height="100%"
                                  onMouseEnter={(e) => {
                                    if (url) {
                                      debouncedMouseOver({
                                        type: AUDIO,
                                        ...audio,
                                        currentTarget: e.currentTarget,
                                        defaultXPosition: column === 0 ? 'right' : 'left',
                                      })
                                    }
                                  }}
                                  onMouseLeave={() => {
                                    debouncedMouseOver.cancel()
                                    if (url) {
                                      tooltipRef.current.handleHideTooltip()
                                    }
                                  }}
                                >
                                  <StyledMediaWrapper selected={isChecked}>{mediaComponent}</StyledMediaWrapper>
                                  <StyledTitleWrapper>
                                    <StyledText color="secondaryText" fontSize="xxs" px="xs">
                                      {title}
                                    </StyledText>
                                  </StyledTitleWrapper>
                                  {metaInformation && (
                                    <StyledText color="secondaryText" fontSize="xxs" mt="xs" px="xs">
                                      {metaInformation}
                                    </StyledText>
                                  )}
                                </Flex>
                              </Flex>
                              <Box
                                position="absolute"
                                top={space.xs}
                                left={space.xs}
                                zIndex="2"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  if (isChecked) {
                                    selectedMedias.splice(isCheckedIndex, 1)
                                  } else {
                                    selectedMedias.push({
                                      id,
                                      tempId: new Date().getTime(),
                                      isDuplicate: true,
                                      entity_gids: [],
                                    })
                                  }
                                  setSelectedMedias([...selectedMedias])
                                }}
                              >
                                <SelectWrapper alignItems="center" justifyContent="center" selected={isChecked}>
                                  <StyledIconCheckmark selected={isChecked} width="10px" height="10px" />
                                </SelectWrapper>
                              </Box>
                            </StyledWrapper>
                          )
                        })}
                      </Grid>
                    </Scrollbars>
                  </Flex>
                )}
              </Fragment>
            </Flex>
          </StyledDialogBodyWrapper>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
            <Button.Gray mr="m" isSmall onClick={handleDismiss}>
              Cancel
            </Button.Gray>
            <Button.Gradient
              onClick={() => {
                if (selectedMedias.length === 0) {
                  Alert.error(`Please select at least one media for adding to media library.`, { timeout: 5000 })
                } else {
                  handleClickAddMedias({ selectedMedias })
                }
              }}
              isSmall
            >
              <Text fontWeight="medium">Select</Text>
            </Button.Gradient>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleDismiss}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
        <CalendarItemTooltip ref={tooltipRef} />
      </Box>
    </StyledDialogOverlay>
  )
}

MediaTrendingModal.defaultProps = {}

MediaTrendingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  handleClickAddMedias: PropTypes.func.isRequired,
}

MediaTrendingModal.displayName = 'MediaTrendingModal'

export default MediaTrendingModal
