import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { Scrollbars } from 'react-custom-scrollbars-2'
import Alert from 'react-s-alert'
import { COLOR_CONSTANTS, radius } from 'theme'
import { Box, Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import { H4, Text } from 'components/atoms/Typography'
import Button from 'components/atoms/Button'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    position: relative;
    height: 90vh;
    max-width: 800px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    top: 50%;
    transform: translate(0, -50%);
  }
`

const StyledDialogBodyWrapper = styled(Flex)`
  outline: none;
  height: 100%;
  overflow: hidden;
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  background-color: ${COLOR_CONSTANTS.BOYSENBERRY_SHADOW};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledText = styled(Text)`
  white-space: break-spaces;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const ReadMoreModal = ({ isOpen, handleDismiss, text, title }) => {
  const handleClickCopyText = async () => {
    await navigator.clipboard.writeText(text)
    Alert.success('Text has been copied.')
  }

  const handleClickCloseModal = () => {
    handleDismiss()
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" $isTop>
            <H4 my="m">{title}</H4>
          </StyledDialogEnvironmentWrapper>

          <StyledDialogBodyWrapper>
            <Scrollbars universal autoHide>
              <Flex width="100%" height="100%" flexDirection="column" position="relative" p="m">
                <StyledText
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              </Flex>
            </Scrollbars>
          </StyledDialogBodyWrapper>

          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
            <Button.Gray isSmall onClick={handleClickCloseModal}>
              Close
            </Button.Gray>

            <Button.Gray isSmall onClick={handleClickCopyText}>
              Copy
            </Button.Gray>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

ReadMoreModal.defaultProps = {}

ReadMoreModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

ReadMoreModal.displayName = 'ReadMoreModal'

export default ReadMoreModal
