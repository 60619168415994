import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { space as styledSpace, width as styledWidth } from 'styled-system'
import Alert from 'react-s-alert'
import Modal from '@material-ui/core/Modal'
import { transparentize } from 'polished'
import { ERROR_MESSAGE } from 'consts'
import { COLOR_CONSTANTS, radius } from 'theme'
import { countAlertTimeout } from 'helpers'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { H4, Text } from 'components/atoms/Typography'
import { Box, Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import { ROUTE_PUBLISH } from '../../consts'
import AdvocacyComponent from '../PostCreationComponent/components/AdvocacyComponent'

const StyledDialogOverlay = styled(Modal)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(Flex)`
  &&& {
    position: relative;
    height: auto;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    ${styledWidth};
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    background: transparent;
    max-width: 560px;
    top: 50%;
    transform: translate(0, -50%);
  }
`

const StyledLeftColumnWrapper = styled(Flex)`
  outline: none;
  background: ${COLOR_CONSTANTS.WHITE};
  border-radius: ${radius.l};
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  background: linear-gradient(90deg, rgba(83, 158, 255, 0.1) 0%, rgba(97, 0, 255, 0.1) 100%);
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  outline: none;
  height: 100%;
`

const AddVistaSocialPostToAdvocacy = ({ isOpen, handleDismiss, postForEdit }) => {
  const [post, setPost] = useState({ ...postForEdit })
  const [isCreatingAdvocacyPost, setIsCreatingAdvocacyPost] = useState(false)

  const { publication_group_id, advocacy, timezone } = post

  const handleClickCreateAdvocacyPost = async () => {
    try {
      setIsCreatingAdvocacyPost(true)

      const response = await request({
        path: `${ROUTE_PUBLISH}/advocacy`,
        method: 'POST',
        body: { publication_group_id },
      })

      const { error, advocacy_groups } = response

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: countAlertTimeout({ text: error || ERROR_MESSAGE }) })
        setIsCreatingAdvocacyPost(false)
      } else {
        Alert.success('Advocacy post has been created.', { timeout: 5000 })

        handleDismiss({ advocacy_groups, publication_group_id })
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: AddVistaSocialPostToAdvocacy.displayName,
        functionName: 'handleClickCreateAdvocacyPost',
        errorMessage: `Can't create post for advocacy group. Please contact support.`,
      })

      setIsCreatingAdvocacyPost(false)
    }
  }

  const handleClickCloseModal = () => {
    if (isCreatingAdvocacyPost) {
      Alert.error('Please wait a bit longer while post are being created.', { timeout: 5000 })
    } else {
      handleDismiss()
    }
  }

  return (
    <StyledDialogOverlay disableEnforceFocus open={isOpen} onClose={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledLeftColumnWrapper flexDirection="column" width="100%" height="100%">
            <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" $isTop>
              <H4 my="m">Create advocacy post</H4>
              <Text
                my="m"
                as="a"
                href="https://support.vistasocial.com/hc/en-us/articles/28180775280155-What-is-employee-advocacy"
                color="primary"
                target="_blank"
              >
                What is advocacy?
              </Text>
            </StyledDialogEnvironmentWrapper>

            <StyledDialogBodyWrapper tabIndex="0">
              <Flex width="100%" height="100%" flexDirection="column" position="relative" px="m" pb="m">
                <AdvocacyComponent
                  advocacy={advocacy}
                  isAdvocacyOnly
                  postDispatch={({ data: { advocacy } }) => {
                    post.advocacy = advocacy
                    setPost({ ...post })
                  }}
                  isAdvocate={false}
                  selectedEntities={[{ timezone }]}
                />
              </Flex>
            </StyledDialogBodyWrapper>
            <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
              <Button.Gray isSmall onClick={handleClickCloseModal}>
                Cancel
              </Button.Gray>

              <ButtonWithLoading isSmall onClick={handleClickCreateAdvocacyPost} isLoading={isCreatingAdvocacyPost}>
                {isCreatingAdvocacyPost ? 'Creating' : 'Create advocacy post'}
              </ButtonWithLoading>
            </StyledDialogEnvironmentWrapper>
          </StyledLeftColumnWrapper>

          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

AddVistaSocialPostToAdvocacy.defaultProps = {}

AddVistaSocialPostToAdvocacy.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  postForEdit: PropTypes.object.isRequired,
}

AddVistaSocialPostToAdvocacy.displayName = 'AddVistaSocialPostToAdvocacy'

export default AddVistaSocialPostToAdvocacy
