import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ClipLoader from 'react-spinners/ClipLoader'
import { IMAGE_DYNAMIC, IMAGE, VIDEO, VIDEO_DYNAMIC, DEFAULT_VIDEO_THUMBNAIL } from 'consts'
import { COLOR_CONSTANTS, colors, space } from 'theme'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import DynamicMedia from 'components/molecules/DynamicMedia'
import MediaCarousel from 'components/molecules/MediaCarousel'
import { PREVIEW_MEDIA_CLASS_NAME, THREADS_COLORS } from '../../../consts'

const StyledBox = styled(Box)`
  cursor: pointer;
  ${({ hasError }) => hasError && `border: 3px solid ${colors.error};`}
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const StyledVideoThumbnailImage = styled(ImageWithFallback)`
  object-fit: scale-down;
`

const StyledDynamicMediaWrapper = styled(Box)`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
`

const MediaTemplate = ({
  media,
  post,
  maxHeight,
  socialPreviewProfiles,
  network,
  noVideoAttachment,
  smallPreview,
  index,
}) => {
  const { id, url, thumbnail_url, [network]: networkErrors = {}, isNew, type } = media
  const { hasAspectRatioError = false, hasSizeError = false } = networkErrors || {}
  const hasError = hasAspectRatioError || hasSizeError

  return (
    <StyledBox key={id || index} width="100%" height="100%" position="relative" $hasError={hasError}>
      {isNew ? (
        <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
          <ClipLoader size="50" color={colors.primary} />
        </Flex>
      ) : (
        <Box width="100%" height="100%">
          {type === IMAGE && (
            <ImageWithFallback
              id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
              name={id}
              source={url}
              height="100%"
              width="100%"
              objectFit="cover"
              fallbackSource="/assets/landscape.svg"
              borderRadius="0.5em"
              maxHeight={maxHeight}
            />
          )}

          {type === VIDEO && (
            <Fragment>
              {noVideoAttachment ? (
                <Fragment>
                  <Box position="absolute" top={space.xs} right={space.xs}>
                    <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
                  </Box>
                  <StyledVideoThumbnailImage
                    id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
                    name={id}
                    source={thumbnail_url || DEFAULT_VIDEO_THUMBNAIL}
                    height="100%"
                    width="100%"
                    objectFit="cover"
                    fallbackSource={DEFAULT_VIDEO_THUMBNAIL}
                    borderRadius="0.5em"
                    maxHeight={maxHeight}
                  />
                </Fragment>
              ) : (
                <VideoWithFallback
                  id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
                  url={url}
                  controls
                  width="100%"
                  height="100%"
                  style={{
                    height: '100%',
                    background: COLOR_CONSTANTS.BLACK,
                    borderRadius: '0.5em',
                  }}
                  config={{
                    file: {
                      attributes: {
                        poster: thumbnail_url,
                      },
                    },
                  }}
                  name={id}
                />
              )}
            </Fragment>
          )}

          {(type === IMAGE_DYNAMIC || type === VIDEO_DYNAMIC) && (
            <StyledDynamicMediaWrapper>
              <DynamicMedia media={media} post={post} profiles={socialPreviewProfiles} isSmall={smallPreview} />
            </StyledDynamicMediaWrapper>
          )}
        </Box>
      )}
    </StyledBox>
  )
}

MediaTemplate.defaultProps = {
  post: null,
  socialPreviewProfiles: [],
  noVideoAttachment: false,
  smallPreview: false,
}

MediaTemplate.propTypes = {
  maxHeight: PropTypes.string.isRequired,
  media: PropTypes.object.isRequired,
  post: PropTypes.object,
  socialPreviewProfiles: PropTypes.array,
  index: PropTypes.number.isRequired,
  network: PropTypes.string.isRequired,
  noVideoAttachment: PropTypes.bool,
  smallPreview: PropTypes.bool,
}

const ThreadsMediaPreview = ({
  medias,
  post,
  maxHeight,
  maxCarouselWidth,
  socialPreviewProfiles,
  network,
  noVideoAttachment,
  smallPreview,
  slideToIndex,
}) => {
  const mediasCount = medias.length

  const groupedMedias = []

  if (mediasCount > 1) {
    for (let i = 0; i < medias.length; i += 2) {
      groupedMedias.push(medias.slice(i, i + 2))
    }
  }

  return (
    <Flex maxHeight={maxHeight}>
      {mediasCount === 1 && (
        <Fragment>
          {(medias[0].type === IMAGE || medias[0].type === IMAGE_DYNAMIC) && (
            <Flex flexDirection="column" width={medias[0].type === IMAGE ? 'auto' : '100%'}>
              <MediaTemplate
                index={0}
                media={medias[0]}
                maxHeight={maxHeight}
                post={post}
                network={network}
                socialPreviewProfiles={socialPreviewProfiles}
                noVideoAttachment={noVideoAttachment}
                smallPreview={smallPreview}
              />
            </Flex>
          )}

          {(medias[0].type === VIDEO || medias[0].type === VIDEO_DYNAMIC) && (
            <Flex
              flexDirection="column"
              width={medias[0].aspect_ratio < 1 ? '50%' : '100%'}
              height={medias[0].type === VIDEO ? maxHeight : 'auto'}
            >
              <MediaTemplate
                index={0}
                media={medias[0]}
                maxHeight={maxHeight}
                post={post}
                network={network}
                socialPreviewProfiles={socialPreviewProfiles}
                noVideoAttachment={noVideoAttachment}
                smallPreview={smallPreview}
              />
            </Flex>
          )}
        </Fragment>
      )}

      {mediasCount > 1 && (
        <MediaCarousel
          post={post}
          width={maxCarouselWidth}
          height={Number(maxHeight.replace('em', '')) * (smallPreview ? 10.666666 : 16)}
          medias={groupedMedias}
          showLegend={false}
          slideToIndex={slideToIndex}
          sliderElements={groupedMedias.map((group, index) => {
            return (
              <Grid
                key={index}
                gridGap="0.375em"
                gridTemplateColumns="repeat(2, 1fr)"
                width={maxCarouselWidth}
                height={maxHeight}
                gap="0.25em"
              >
                {group.map((media, index) => {
                  return (
                    <Flex key={media.id || index} flexDirection="column">
                      <MediaTemplate
                        index={index}
                        media={media}
                        maxHeight={maxHeight}
                        post={post}
                        network={network}
                        socialPreviewProfiles={socialPreviewProfiles}
                        noVideoAttachment={noVideoAttachment}
                        smallPreview={smallPreview}
                      />
                    </Flex>
                  )
                })}
              </Grid>
            )
          })}
          activeDotsColor={THREADS_COLORS.LAST_LIGHT_BLUE}
        />
      )}
    </Flex>
  )
}

ThreadsMediaPreview.defaultProps = {
  medias: [],
  post: null,
  socialPreviewProfiles: [],
  noVideoAttachment: false,
  smallPreview: false,
}

ThreadsMediaPreview.propTypes = {
  maxHeight: PropTypes.string.isRequired,
  maxCarouselWidth: PropTypes.number.isRequired,
  medias: PropTypes.array,
  post: PropTypes.object,
  socialPreviewProfiles: PropTypes.array,
  network: PropTypes.string.isRequired,
  noVideoAttachment: PropTypes.bool,
  smallPreview: PropTypes.bool,
  slideToIndex: PropTypes.number.isRequired,
}

export default ThreadsMediaPreview
