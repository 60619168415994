import React from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import styled from 'styled-components'
import { Box, Flex } from 'components/atoms/Layout'
import { colors, COLOR_CONSTANTS, space, radius } from 'theme'

const DropdownMenuWrapper = styled(Flex)`
  z-index: ${({ zIndex }) => zIndex};
  position: fixed;
  top: ${({ top }) => top}px;
  flex-direction: column;
  ${({ left }) =>
    left &&
    `
      left: ${left};
  `}
  width: fit-content;
  padding: ${space.xs} 0;
  box-shadow: 0px 4px 10px rgba(39, 40, 49, 0.03);
  background-color: ${colors.white};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  border-radius: ${radius.l};
`

class AttachmentsDropdownMenu extends React.Component {
  state = { isOpen: false, top: -1000, left: -1000 }
  handleToggleMenu = (e) => {
    const { isOpen } = this.state
    this.setState({
      isOpen: !isOpen,
      top: e.clientY + 16,
      left: e.clientX + 5,
    })
  }
  handleClickOutside = () => {
    this.setState({ isOpen: false })
  }
  handleClickInside = () => {
    const { isDismissedOnClickInside } = this.props
    if (isDismissedOnClickInside) {
      const { isOpen } = this.state
      if (isOpen) {
        this.setState({ isOpen: false })
      }
    }
  }
  render() {
    const { isOpen, top, left } = this.state
    const { WrapperComp, children, zIndex } = this.props

    return (
      <Box position="relative" onClick={this.handleClickInside}>
        {React.cloneElement(WrapperComp, {
          onClick: this.handleToggleMenu,
        })}
        {isOpen && (
          <DropdownMenuWrapper left={left} top={top} zIndex={zIndex}>
            {children}
          </DropdownMenuWrapper>
        )}
      </Box>
    )
  }
}

AttachmentsDropdownMenu.defaultProps = {
  isDismissedOnClickInside: false,
  zIndex: 3,
  xPositionAdjustment: 0,
  yPositionAdjustment: 0,
}

AttachmentsDropdownMenu.propTypes = {
  WrapperComp: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isDismissedOnClickInside: PropTypes.bool,
  zIndex: PropTypes.number,
}

export default onClickOutside(AttachmentsDropdownMenu)
