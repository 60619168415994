import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import styled from 'styled-components'
import Alert from 'react-s-alert'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'
import { Scrollbars } from 'react-custom-scrollbars-2'
import ClipLoader from 'react-spinners/ClipLoader'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { getSocialNetworkIconByName } from 'utils/socialNetworks'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import Button from 'components/atoms/Button'
import Icon from 'components/atoms/Icon'
import { Text } from 'components/atoms/Typography'
import { Flex } from 'components/atoms/Layout'
import Input from 'components/atoms/Input'
import { ROUTE_MENTIONS, MENTIONS_NETWORKS } from '../../consts'
import MentionsModal from './MentionsModal'

const Wrapper = styled(Flex)`
  background: ${COLOR_CONSTANTS.WHITE};
  height: 260px;
  overflow: auto;
`

const StyledButton = styled(Button.Gray)`
  min-width: auto;
  padding: 0 ${space.s};
`

const StyledFlexMentionWrapper = styled(Flex)`
  overflow: hidden;
`

const MentionTextWrapper = styled(Text)`
  height: 100%;
  width: 100%;
  line-height: 30px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:hover {
    background-color: ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
  }
`

const StyledImg = styled.img`
  width: 16px;
  margin-bottom: -2px;
  margin-right: ${space.xxs};
  border-radius: ${radius.s};
  object-fit: contain;
  cursor: pointer;
  ${({ isDisabled }) =>
    isDisabled &&
    `filter: grayscale(1);
    opacity: 0.2;
    pointer-events: none;
    cursor: not-allowed;
  `}
`

const StyledFlex = styled(Flex)`
  cursor: pointer;
`

const StyledText = styled(Text)`
  cursor: pointer;
`

const SearchInputComponent = ({ handleFilterMentionsBySearch }) => {
  const [searchString, setSearchString] = useState('')

  const handleChangePostText = (text) => {
    setSearchString(text)
    handleFilterMentionsBySearch(text)
  }

  return (
    <Input
      placeholder="Search"
      label=""
      value={searchString}
      onChange={(e) => {
        handleChangePostText(e.target.value)
      }}
      mr="m"
      height="30px"
    />
  )
}

SearchInputComponent.propTypes = {
  handleFilterMentionsBySearch: PropTypes.func.isRequired,
}

const MentionSelector = forwardRef(
  (
    {
      user,
      setUser,
      handleClickAddMentionToPost,
      agencyMentions,
      agencyMentionsLoading,
      agencyMentionsSkipped,
      handleUpdateMentions,
      mentionsModalData,
      handleClearMentionsModalData,
      handleUpdateMentionsSkipped,
      selectedProfiles,
      ...props
    },
    mentionSelectorRef
  ) => {
    const [mentions, setMentions] = useState([...agencyMentions])
    const [isMentionsModalOpen, setIsMentionsModalOpen] = useState(false)
    const [mentionsForEdit, setMentionsForEdit] = useState({})
    const [isMentionsSaving, setIsMentionsSaving] = useState(false)
    const [isRemoving, setIsRemoving] = useState(false)
    const [searchString, setSearchString] = useState('')

    useImperativeHandle(mentionSelectorRef, () => ({
      getMentionsModalState() {
        return isMentionsModalOpen
      },
    }))

    const filterMentionsBySearchString = () => {
      if (searchString) {
        setMentions(agencyMentions.filter(({ name }) => name.toLowerCase().includes(searchString.toLowerCase())))
      } else {
        setMentions([...agencyMentions])
      }
    }

    useEffect(() => {
      filterMentionsBySearchString()
    }, [agencyMentions])

    useEffect(() => {
      setIsMentionsModalOpen(props.isMentionsModalOpen)
    }, [props.isMentionsModalOpen])

    useEffect(() => {
      filterMentionsBySearchString()
    }, [searchString])

    const handleOpenMentionsModal = (item) => {
      Object.keys(item).forEach((key) => {
        if (MENTIONS_NETWORKS.find((mention_network) => mention_network.code === key && mention_network.active)) {
          // if (MENTIONS_NETWORKS.includes(key)) {
          const { id, name } = item[key]
          item[key].value = id
          item[key].label = name
        }
      })
      setMentionsForEdit({ ...item })
      setIsMentionsModalOpen(true)
    }

    const handleCloseMentionsModal = () => {
      setMentionsForEdit({})
      setIsMentionsModalOpen(false)
      handleClearMentionsModalData({})
    }

    const handleSaveMentions = async (body, data) => {
      try {
        setIsMentionsSaving(true)
        const response = await request({
          method: 'POST',
          body,
          path: ROUTE_MENTIONS,
        })
        if (response) {
          if (response.error) {
            Alert.error(response.error, { timeout: 5000 })
          } else {
            if (body.id) {
              for (let i = 0; i < agencyMentions.length; i++) {
                if (agencyMentions[i].id === body.id) {
                  agencyMentions[i] = data
                  break
                }
              }
            } else {
              data.id = response.id
              agencyMentions.unshift(data)
            }
            setMentions([...agencyMentions])
            handleUpdateMentions(agencyMentions)
            handleCloseMentionsModal()
          }
        }
      } catch (error) {
        errorHelper({ error, componentName: MentionSelector.displayName, functionName: 'handleSaveMentions' })
      } finally {
        setIsMentionsSaving(false)
      }
    }

    const handleSkipMention = (data) => {
      handleUpdateMentionsSkipped([...agencyMentionsSkipped, data])
      handleCloseMentionsModal()
    }

    const handleRemoveMentions = async ({ id }) => {
      try {
        setIsRemoving(true)
        const response = await request({
          method: 'DELETE',
          path: `${ROUTE_MENTIONS}/${id}`,
        })
        if (response) {
          if (response.error) {
            Alert.error(response.error, { timeout: 5000 })
          } else {
            const foundIndex = mentions.findIndex((item) => item.id === id)
            if (foundIndex > -1) {
              mentions.splice(foundIndex, 1)
            }
            handleUpdateMentions(mentions)
            handleCloseMentionsModal()
            Alert.success(`Universal mention has been removed.`, { timeout: 5000 })
          }
        }
      } catch (error) {
        errorHelper({ error, componentName: MentionSelector.displayName, functionName: 'handleRemoveMentions' })
      } finally {
        setIsRemoving(false)
      }
    }

    const handleFilterMentionsBySearch = debounce((text) => {
      setSearchString(text)
    }, 300)

    return (
      <Wrapper flexDirection="column" width="100%" py="s">
        <Flex justifyContent="space-between" pr="m" alignItems="center" mb="xs">
          <Text color="secondaryText">Saved mentions</Text>
          <StyledButton isSmall onClick={() => handleOpenMentionsModal({})}>
            <Icon.Plus width="13px" />
          </StyledButton>
        </Flex>
        <SearchInputComponent handleFilterMentionsBySearch={handleFilterMentionsBySearch} />

        {agencyMentionsLoading ? (
          <Flex alignItems="center" justifyContent="center" height="100%" width="100%">
            <ClipLoader size="50" color={colors.primary} />
          </Flex>
        ) : (
          <Flex flexDirection="column" height="100%">
            {mentions.length !== 0 ? (
              <Flex flexDirection="column" height="100%">
                <Scrollbars universal>
                  {mentions.map((item) => (
                    <Flex key={item.id} py="xs" pr="m" alignItems="center" width="100%">
                      <Flex alignItems="center">
                        {MENTIONS_NETWORKS.map(({ code, active }) => {
                          const isDisabled = !item[code] || !active
                          const ImageTemplate = (
                            <StyledImg
                              key={code}
                              src={getSocialNetworkIconByName(code).enabled}
                              isDisabled={isDisabled}
                            />
                          )
                          if (isDisabled) {
                            return ImageTemplate
                          }
                          return (
                            <Flex key={code} alignItems="center">
                              <StyledFlex as="a" href={item[code].link} target="_blank">
                                {ImageTemplate}
                              </StyledFlex>
                            </Flex>
                          )
                        })}
                      </Flex>
                      <StyledFlexMentionWrapper
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        key={item.id}
                      >
                        <MentionTextWrapper
                          px="s"
                          onClick={() => {
                            handleClickAddMentionToPost(item)
                          }}
                        >
                          <Text as="span" fontWeight="bold">
                            {item.name}
                          </Text>
                        </MentionTextWrapper>
                        <StyledButton isSmall onClick={() => handleOpenMentionsModal(item)}>
                          <Icon.Edit />
                        </StyledButton>
                      </StyledFlexMentionWrapper>
                    </Flex>
                  ))}
                </Scrollbars>
              </Flex>
            ) : (
              <Flex py="m" alignItems="center" justifyContent="center" height="100%" pr="m">
                <Text textAlign="center" color="secondaryText">
                  You have not created any saved mentions yet. Click{' '}
                  <StyledText as="span" color="primary" onClick={() => handleOpenMentionsModal({})}>
                    here
                  </StyledText>{' '}
                  to create
                </Text>
              </Flex>
            )}
          </Flex>
        )}

        {isMentionsModalOpen && (
          <MentionsModal
            user={user}
            setUser={setUser}
            handleDismiss={handleCloseMentionsModal}
            handleSave={handleSaveMentions}
            isSubmitting={isMentionsSaving}
            isOpen={isMentionsModalOpen}
            mentionsForEdit={mentionsForEdit}
            allowedNetworks={MENTIONS_NETWORKS}
            isRemoving={isRemoving}
            handleRemove={handleRemoveMentions}
            mentionsModalData={mentionsModalData}
            agencyMentions={agencyMentions}
            handleSkip={handleSkipMention}
            selectedProfiles={selectedProfiles}
          />
        )}
      </Wrapper>
    )
  }
)

MentionSelector.defaultProps = {
  agencyMentions: [],
  agencyMentionsLoading: false,
  agencyMentionsSkipped: [],
  isMentionsModalOpen: false,
  mentionsModalData: null,
  selectedProfiles: [],
}

MentionSelector.propTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  handleClickAddMentionToPost: PropTypes.func.isRequired,
  agencyMentions: PropTypes.array,
  agencyMentionsSkipped: PropTypes.array,
  agencyMentionsLoading: PropTypes.bool,
  handleUpdateMentions: PropTypes.func.isRequired,
  isMentionsModalOpen: PropTypes.bool,
  mentionsModalData: PropTypes.object,
  handleClearMentionsModalData: PropTypes.func.isRequired,
  handleUpdateMentionsSkipped: PropTypes.func.isRequired,
  selectedProfiles: PropTypes.array,
}

MentionSelector.displayName = 'MentionSelector'

export default MentionSelector
