import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'
import { transparentize } from 'polished'
import { colors, radius } from 'theme'
import Modal from 'shared/Modal'
import { Text } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import TextArea from 'components/atoms/TextArea'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'

const { MESSAGE } = { MESSAGE: 'message' }

const WarningWrapper = styled(Flex)`
  background-color: ${() => transparentize(0.9, colors.warning)};
  border-radius: ${radius.l};
`

const DeletePostModal = ({ isOpen, handleDismiss, handleDelete, isPostSubmitting, headerText }) => {
  const handleSubmitForm = (values) => {
    const body = {
      [MESSAGE]: values[MESSAGE],
    }
    handleDelete(body)
  }

  return (
    <Modal isOpen={isOpen} handleDismiss={handleDismiss} isDisabled={isPostSubmitting}>
      <Text fontSize="l" mb="m">
        Remove post
      </Text>
      {headerText.includes('Due to limitations') ? (
        <WarningWrapper alignItems="center" px="m" py="s" mb="m">
          <Text fontSize="s" color={colors.warning}>
            {headerText}
          </Text>
        </WarningWrapper>
      ) : (
        <Text fontSize="s">{headerText}</Text>
      )}

      <Formik
        initialValues={{
          [MESSAGE]: '',
        }}
        onSubmit={handleSubmitForm}
        autocomplete="off"
        enableReinitialize
      >
        {({ values, handleChange, handleSubmit, errors, touched }) => (
          <Box>
            <Box>
              <TextArea
                value={values[MESSAGE]}
                onChange={handleChange(MESSAGE)}
                placeholder="Please provide a brief reason for removing this post"
                rows={5}
                error={errors[MESSAGE] && touched[MESSAGE] && errors[MESSAGE]}
              />
            </Box>
            <Flex justifyContent="flex-end" mt="m">
              <Button.Gray mr="m" isSmall onClick={handleDismiss} isDisabled={isPostSubmitting}>
                Cancel
              </Button.Gray>
              <ButtonWithLoading onClick={handleSubmit} isSmall type="button" isLoading={isPostSubmitting}>
                <Text fontWeight="medium">{isPostSubmitting ? `Removing` : `Yes, I'm sure`}</Text>
              </ButtonWithLoading>
            </Flex>
          </Box>
        )}
      </Formik>
    </Modal>
  )
}

DeletePostModal.defaultProps = {
  headerText: 'Are you sure you want to remove this post?',
}

DeletePostModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isPostSubmitting: PropTypes.bool.isRequired,
  headerText: PropTypes.string,
}

export default DeletePostModal
