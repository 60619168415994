import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import styled from 'styled-components'
import { Box, Flex } from 'components/atoms/Layout'
import { colors, COLOR_CONSTANTS, space, radius } from 'theme'
import Icon from 'components/atoms/Icon'
import { Text } from 'components/atoms/Typography'

const DropdownMenuWrapper = styled(Flex)`
  z-index: 16;
  position: absolute;
  flex-direction: column;
  left: unset;
  right: 0;
  bottom: ${({ bottom }) => bottom || 'unset'};
  top: ${({ top }) => top || `calc(100% + 6px)`};
  width: fit-content;
  padding: ${space.xs} 0;
  box-shadow: 0px 4px 10px rgba(39, 40, 49, 0.03);
  background-color: ${colors.white};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  border-radius: ${radius.l};
  ${({ isTriangleVisible }) =>
    isTriangleVisible &&
    `
  &::after,
  &::before {
    content: '';
    pointer-events: none;
    position: absolute;
    bottom: 100%;
    height: 0;
    width: 0;
    border: solid transparent;
  }
  &::after {
    border-bottom-color: ${colors.white};
    border-width: 6px;
    right: 11px;
  }
  &::before {
    border-bottom-color: ${COLOR_CONSTANTS.SOLITUDE};
    border-width: 7px;
    right: 10px;
  }
  `}
`

const IconWrapper = styled(Box)`
  padding: 0 ${space.s};
  width: 36px;
  height: 36px;
  cursor: pointer;
  svg {
    fill: ${COLOR_CONSTANTS.GLACIER_PEARL};
  }
  ${({ isActive }) =>
    isActive &&
    `
   svg {
      fill: ${colors.primary};
   }
  `}
  &:hover {
    svg {
      fill: ${colors.primary};
    }
  }
`

let specialCloseInterval = null

class ShowComponentDropdownMenu extends React.Component {
  state = { isOpen: false, IconComp: <Fragment /> }

  componentDidMount() {
    const { iconName, iconHeight, iconWidth } = this.props
    const IconComp = iconName ? Icon[iconName] : null
    const StyledIconComp =
      iconName &&
      styled(IconComp)`
        max-width: 20px;
        width: ${iconWidth || '20px'};
        height: ${iconHeight || '20px'};
      `
    this.setState({ IconComp: <StyledIconComp /> })
  }

  componentDidUpdate(prevProps, prevState) {
    const { isActive } = this.props
    if (prevProps.isActive && !isActive) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: false })
    }
  }

  componentWillUnmount() {
    clearInterval(specialCloseInterval)
  }

  handleClickOutside = () => {
    const { isOpen } = this.state
    if (isOpen) {
      this.setState({ isOpen: false })
    }
  }

  handleClickInside = () => {
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen })
  }

  render() {
    const { isOpen, IconComp } = this.state
    const {
      children,
      isActive,
      handleClickToggleMenu,
      showIsActiveIndicator,
      bottom,
      top,
      isTriangleVisible,
      tooltipRef,
      tooltipMessage,
    } = this.props

    return (
      <Box>
        <Box>
          <Box
            position="relative"
            onClick={() => {
              this.handleClickInside()
              handleClickToggleMenu()
            }}
            onMouseEnter={(e) => {
              if (tooltipRef && tooltipMessage) {
                tooltipRef.current.handleShowTooltip({
                  contentComp: (
                    <Box p="s" maxWidth="350px">
                      <Text textAlign="center">{tooltipMessage}</Text>
                    </Box>
                  ),
                  wrapperComp: e.currentTarget,
                  defaultYPosition: 'top',
                })
              }
            }}
            onMouseLeave={() => {
              if (isOpen) {
                clearInterval(specialCloseInterval)
                specialCloseInterval = setInterval(() => {
                  this.handleClickOutside()
                  clearInterval(specialCloseInterval)
                }, 300)
              }

              if (tooltipRef && tooltipMessage) {
                tooltipRef.current.handleHideTooltip()
              }
            }}
            onMouseOver={() => {
              if (isActive) {
                clearInterval(specialCloseInterval)
                if (!isOpen) {
                  this.setState({ isOpen: true })
                }
              }
            }}
          >
            <IconWrapper isActive={isActive && showIsActiveIndicator}>{IconComp}</IconWrapper>
            {isOpen && (
              <DropdownMenuWrapper
                minWidth="min-content"
                onClick={(e) => {
                  e.stopPropagation()
                  this.handleClickInside()
                }}
                bottom={bottom}
                top={top}
                isTriangleVisible={isTriangleVisible}
              >
                {children}
              </DropdownMenuWrapper>
            )}
          </Box>
        </Box>
      </Box>
    )
  }
}

ShowComponentDropdownMenu.defaultProps = {
  isActive: false,
  showIsActiveIndicator: true,
  bottom: null,
  top: null,
  isTriangleVisible: true,
  iconHeight: '',
  iconWidth: '',
  tooltipRef: null,
  tooltipMessage: '',
}

ShowComponentDropdownMenu.propTypes = {
  children: PropTypes.node.isRequired,
  handleClickToggleMenu: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  iconName: PropTypes.string.isRequired,
  iconHeight: PropTypes.string,
  iconWidth: PropTypes.string,
  showIsActiveIndicator: PropTypes.bool,
  bottom: PropTypes.string,
  top: PropTypes.string,
  isTriangleVisible: PropTypes.bool,
  tooltipRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  tooltipMessage: PropTypes.string,
}

export default onClickOutside(ShowComponentDropdownMenu)
