import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Alert from 'react-s-alert'
import { Box, Flex } from 'components/atoms/Layout'
import { COLOR_CONSTANTS, radius } from 'theme'
import { truncate } from 'helpers'
import withConfirm from 'utils/withConfirm'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import DropdownMenu from 'components/molecules/DropdownMenu'
import { Text } from 'components/atoms/Typography'
import Icon from 'components/atoms/Icon'
import Divider from 'components/atoms/Divider'

const ControlsWrapper = styled(Flex)`
  z-index: 2;
  height: 27px;
  width: 24px;
  min-width: 16px;
  cursor: pointer;
  background-color: ${COLOR_CONSTANTS.WHITE};
`

const StyledWrapper = styled(Flex)`
  border-radius: ${radius.l};
  position: relative;
  cursor: pointer;
  justify-content: space-between;
`

const Excerpt = styled(Flex)`
  text-overflow: ellipsis;
  overflow: hidden;
  width: inherit;
  text-align: left;
  word-break: break-word;
  display: -webkit-box;
  line-clamp: 3px;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

const FolderItem = ({
  media,
  confirm,
  isSmall,
  hasControls,
  isFirstInRow,
  handleClickOpenAddFolderModal,
  handleClickRemoveFolderMedia,
  handleClickFolderNavigation,
}) => {
  const { id, title, description } = media

  const [isDropDownOpen, setIsDropDownOpen] = useState(false)

  const mediaDescription = truncate(title || description, 25)

  const handleClickCopyFolderPath = async () => {
    await navigator.clipboard.writeText(`${process.env.NEXT_PUBLIC_SITE_URL}/media/${id}`)

    Alert.success(`Media link has been copied`, { timeout: 5000 })
  }

  return (
    <StyledWrapper
      zIndex={isDropDownOpen ? 10 : 3}
      bg={COLOR_CONSTANTS.WHITE}
      onClick={(e) => {
        e.preventDefault()
        handleClickFolderNavigation()
      }}
      maxWidth={isSmall ? '100px' : 'auto'}
      p={isSmall ? 's' : 'sm'}
    >
      <Flex alignItems="center" width={isSmall ? 'calc(100% - 16px)' : 'auto'}>
        <Box minWidth={isSmall ? '12px' : '20px'}>
          <Icon.Folder
            width={isSmall ? '12px' : '20px'}
            height={isSmall ? '12px' : '20px'}
            stroke={COLOR_CONSTANTS.COSMIC_ENERGY}
            fill={COLOR_CONSTANTS.COSMIC_ENERGY}
          />
        </Box>

        <Excerpt ml="s">
          <Text color="primaryText" fontSize="xs">
            {mediaDescription}
          </Text>
        </Excerpt>
      </Flex>

      {hasControls && (
        <ControlsWrapper
          alignItems="center"
          justifyContent="flex-end"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <DropdownMenu
            handleClickToggleMenu={({ isOpen }) => {
              setIsDropDownOpen(isOpen)
            }}
            WrapperComp={<ImageWithFallback width="100%" height="100%" source="/assets/vistasocial/more.svg" />}
            isDismissedOnClickInside
            left={isSmall && isFirstInRow ? '-85px' : 'unset'}
            isTriangleVisible={!isSmall}
          >
            <DropdownMenu.Item
              label="Settings"
              iconName="Gear"
              iconWidth="14px"
              onClick={() => {
                handleClickOpenAddFolderModal({ media })
              }}
              isCursorPointer
            />

            <DropdownMenu.Item
              label="Copy direct link"
              iconName="Link"
              iconWidth="13px"
              iconHeight="13px"
              iconColor={COLOR_CONSTANTS.COSMIC_ENERGY}
              onClick={handleClickCopyFolderPath}
              isCursorPointer
            />

            <Divider height="1px" />

            <DropdownMenu.Item
              label="Remove"
              iconName="Trash"
              onClick={() => {
                confirm({
                  fn: () => () => handleClickRemoveFolderMedia({ media: { id } }),
                  message:
                    'Are you sure you want to remove this folder? Removing action would affect all medias and folders inside.',

                  action: 'Remove',
                })
              }}
              isCursorPointer
            />
          </DropdownMenu>
        </ControlsWrapper>
      )}
    </StyledWrapper>
  )
}

FolderItem.defaultProps = {}

FolderItem.propTypes = {
  media: PropTypes.object.isRequired,
  confirm: PropTypes.func.isRequired,
  isSmall: PropTypes.bool.isRequired,
  hasControls: PropTypes.bool.isRequired,
  isFirstInRow: PropTypes.bool.isRequired,
  handleClickOpenAddFolderModal: PropTypes.func.isRequired,
  handleClickRemoveFolderMedia: PropTypes.func.isRequired,
  handleClickFolderNavigation: PropTypes.func.isRequired,
}

FolderItem.displayName = 'FolderItem'

export default withConfirm(FolderItem)
