import React, { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { display } from 'styled-system'
import moment from 'moment-timezone'
import ClipLoader from 'react-spinners/ClipLoader'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { space, fontSizes, fontWeights, COLOR_CONSTANTS, colors, radius } from 'theme'
import { IMAGE, VIDEO, IMAGE_DYNAMIC, VIDEO_DYNAMIC, DOCUMENT } from 'consts'
import { getFileIconByExtension, truncate } from 'helpers'
import { Text } from 'components/atoms/Typography'
import Card from 'components/molecules/Card'
import Checkbox from 'components/atoms/Checkbox'
import Link from 'components/atoms/Link'
import Icon from 'components/atoms/Icon'
import { Flex } from 'components/atoms/Layout'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import DynamicMedia from 'components/molecules/DynamicMedia'
import Badge from 'components/atoms/Badge'
import Button from 'components/atoms/Button'
import {
  PER_CSV,
  IMPORT_FROM_CSV,
  IMPORT_FROM_RSS,
  IMPORT_FROM_MULTIPLE_MEDIA,
  IMPORT_FROM_INSTAGRAM_USER,
  IMPORT_FROM_INSTAGRAM_HASHTAG,
  POST_IMAGE,
  POST_VIDEO,
  POST_DOCUMENT,
  POST_MIXED_MEDIA,
  POST_MEDIA_CLASS_NAME,
  DRAFT,
  IDEA,
} from '../../consts'
import CalendarItemTooltip from '../CalendarItemTooltip'
// eslint-disable-next-line import/no-cycle
import { getHeaderTitleAndColor } from '../../helpers'

const cellStyle = `
  padding-left: ${space.m};
  border: solid ${COLOR_CONSTANTS.SOLITUDE};
  border-width: 1px 0;
`

const StyledTable = styled('table')`
  width: 100%;
  font-size: ${fontSizes.s};
  text-align: left;
`

const Row = styled('tr')`
  height: 50px;
`

const HeaderCell = styled('th')`
  ${display};
  font-weight: ${fontWeights.medium};
  ${cellStyle};
  white-space: nowrap;
`

const BodyCell = styled('td')`
  ${display};
  ${cellStyle};
  & > * {
    vertical-align: middle;
  }
`

const PostText = styled(Text)`
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${colors.primary};
  }
`

const StyledIconOpenInNew = styled(Icon.OpenInNew)`
  cursor: pointer;
  &:hover {
    fill: ${COLOR_CONSTANTS.FLUORESCENT_RED};
  }
`

const MultipleAttachmentsWrapper = styled(Flex)`
  position: absolute;
  justify-content: center;
  align-items: center;
  min-width: 0px;
  padding: ${space.xs};
  height: 10px;
  background-color: ${colors.primaryText};
  border-radius: ${radius.pill};
`

const StyledFlex = styled(Flex)`
  cursor: pointer;
`

const StyledMediaWrapper = styled(Flex)`
  width: 40px;
  height: 40px;
`

const StyledStatusWrapper = styled(Flex)`
  background-color: ${({ borderColor }) => borderColor || 'transparent'};
  height: 32px;
  width: 3px;
`

const StyledBadge = styled(Badge.Status)`
  padding: ${space.xxs} ${space.xs};
  font-size: ${fontSizes.xxxs};
  margin: ${space.xxs} ${space.xxs} 0 0;
  min-width: 0;
`

const StyledRoundedButton = styled(Button.Gray)`
  min-width: auto;
  padding: 0;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: ${radius.pill};
  ${({ hasError }) => hasError && `background-color:${colors.error}`};
  &:hover {
    background-color: ${COLOR_CONSTANTS.DAISY};
  }
`

const StyledUploadProgressIndicatorText = styled(Text)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${fontSizes.xxs};
  color: ${colors.primary};
`

const Table = ({ data, handleClickEditPost, setListOfPosts, whenToPublish, importFrom, selectedQueueLabel }) => {
  const tooltipRef = useRef(null)

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const reordered_items = reorder(data, result.source.index, result.destination.index)

    setListOfPosts([...reordered_items])
  }

  return (
    <Card px={0} pb={0} pt={0}>
      <StyledTable>
        <thead>
          <Row>
            <HeaderCell />
            <HeaderCell>Message</HeaderCell>
            {(importFrom === IMPORT_FROM_RSS ||
              importFrom === IMPORT_FROM_INSTAGRAM_USER ||
              importFrom === IMPORT_FROM_INSTAGRAM_HASHTAG) && <HeaderCell>Published</HeaderCell>}

            {(importFrom === IMPORT_FROM_RSS ||
              importFrom === IMPORT_FROM_CSV ||
              importFrom === IMPORT_FROM_MULTIPLE_MEDIA ||
              importFrom === IMPORT_FROM_INSTAGRAM_USER ||
              importFrom === IMPORT_FROM_INSTAGRAM_HASHTAG) && <HeaderCell>Media</HeaderCell>}
            <HeaderCell>Scheduled</HeaderCell>
            <HeaderCell />
          </Row>
        </thead>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {data.map((item, index) => {
                  const {
                    tempId,
                    postText,
                    autoFilledPostText = false,
                    link,
                    selected = false,
                    publish_at,
                    defaultPublishAt,
                    date,
                    date_transformed,
                    postImages = [],
                    postVideos = [],
                    postDocuments = [],
                    postMixedMedias = [],
                    hasPostError,
                    postComponent,
                    status = DRAFT,
                    selectedQueueLabel: postSelectedQueueLabel,
                  } = item
                  const { url: linkUrl } = link || {}
                  let mediaLength = 0
                  let medias = []

                  if (postComponent === POST_IMAGE) {
                    mediaLength = postImages.length
                    medias = postImages
                  } else if (postComponent === POST_VIDEO) {
                    mediaLength = postVideos.length
                    medias = postVideos
                  } else if (postComponent === POST_DOCUMENT) {
                    mediaLength = postDocuments.length
                    medias = postDocuments
                  } else if (postComponent === POST_MIXED_MEDIA) {
                    mediaLength = postMixedMedias.length
                    medias = postMixedMedias
                  }

                  let showPublishAtTime = whenToPublish
                  let showQueueLabel = false

                  if (publish_at) {
                    if (publish_at === DRAFT) {
                      showPublishAtTime = 'Draft'
                    } else if (publish_at === IDEA) {
                      showPublishAtTime = 'Idea'
                    } else {
                      showPublishAtTime = publish_at
                    }
                  } else if (whenToPublish === PER_CSV) {
                    showPublishAtTime = defaultPublishAt
                  } else if (whenToPublish === DRAFT) {
                    showPublishAtTime = 'Draft'
                  } else if (whenToPublish === IDEA) {
                    showPublishAtTime = 'Idea'
                  }

                  if (showPublishAtTime && showPublishAtTime !== 'Draft' && showPublishAtTime !== 'Idea') {
                    if (showPublishAtTime.includes('queue') || showPublishAtTime.includes('now')) {
                      showQueueLabel = true
                      showPublishAtTime = `${showPublishAtTime.charAt(0).toUpperCase()}${showPublishAtTime
                        .slice(1)
                        .replace(/_/g, ' ')}`
                    } else {
                      showPublishAtTime = moment(showPublishAtTime).format('MMM D, YYYY h:mm a')
                    }
                  }

                  const { bgColor } = getHeaderTitleAndColor({ status })

                  return (
                    <Draggable key={tempId} draggableId={`draggable-${tempId}`} index={index}>
                      {(provided) => (
                        <Row
                          id={`bulk-post-${tempId}`}
                          borderColor={bgColor}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          draggable={status === DRAFT}
                        >
                          <BodyCell>
                            <Flex alignItems="center">
                              <StyledStatusWrapper borderColor={bgColor} py="s" />
                              {status === DRAFT && (
                                <Flex ml="s" alignItems="center" justifyContent="center">
                                  <Checkbox
                                    isChecked={selected}
                                    onClick={() => {
                                      data[index].selected = !selected
                                      setListOfPosts([...data])
                                    }}
                                  />
                                </Flex>
                              )}
                            </Flex>
                          </BodyCell>
                          <BodyCell>
                            <Flex alignItems="center" justifyContent="space-between">
                              <Flex alignItems="center">
                                <PostText
                                  pl="s"
                                  as="a"
                                  target="_blank"
                                  fontSize="xs"
                                  color={selected && (hasPostError || !postText) ? 'error' : 'primary'}
                                  onClick={() => {
                                    handleClickEditPost(item, index)
                                  }}
                                >
                                  {truncate(
                                    postText || 'You do not have any post text. Click here to edit the post',
                                    100
                                  )}
                                </PostText>
                                {linkUrl && (
                                  <Link href={linkUrl} target="_blank" ml="s">
                                    <StyledIconOpenInNew width="12px" height="12px" />
                                  </Link>
                                )}
                              </Flex>
                              {autoFilledPostText && (
                                <StyledFlex alignItems="center" width="20px">
                                  <Flex alignItems="center">
                                    <Flex
                                      alignItems="center"
                                      onMouseEnter={(e) => {
                                        tooltipRef.current.handleShowTooltip({
                                          contentComp: (
                                            <Flex p="s" maxWidth="325px">
                                              <Text>
                                                Name of the file was used to prefill the message. Please verify if this
                                                will work. if not, please click on the message to edit it.
                                              </Text>
                                            </Flex>
                                          ),
                                          wrapperComp: e.currentTarget,
                                        })
                                      }}
                                      onMouseLeave={() => {
                                        tooltipRef.current.handleHideTooltip()
                                      }}
                                    >
                                      <Icon.Warning fill={colors.warning} />
                                    </Flex>
                                  </Flex>
                                </StyledFlex>
                              )}
                            </Flex>
                          </BodyCell>
                          {(importFrom === IMPORT_FROM_RSS ||
                            importFrom === IMPORT_FROM_INSTAGRAM_USER ||
                            importFrom === IMPORT_FROM_INSTAGRAM_HASHTAG) && (
                            <BodyCell>
                              {(date || date_transformed) && (
                                <Text fontSize="xs">{date ? moment(date).fromNow() : date_transformed}</Text>
                              )}
                            </BodyCell>
                          )}

                          {(importFrom === IMPORT_FROM_RSS ||
                            importFrom === IMPORT_FROM_CSV ||
                            importFrom === IMPORT_FROM_MULTIPLE_MEDIA ||
                            importFrom === IMPORT_FROM_INSTAGRAM_USER ||
                            importFrom === IMPORT_FROM_INSTAGRAM_HASHTAG) && (
                            <BodyCell>
                              {medias.map((media, index) => {
                                const { id, url, thumbnail_url, isNew, aspect_ratio, duration, type, extension } = media

                                if (index > 0) {
                                  return null
                                }

                                return (
                                  <StyledMediaWrapper
                                    key={id || index}
                                    alignItems="center"
                                    justifyContent="center"
                                    position="relative"
                                  >
                                    {isNew ? (
                                      <Fragment>
                                        <ClipLoader size="40" color={colors.primary} />
                                        <StyledUploadProgressIndicatorText id={`media-upload-progress-${id}`} />
                                      </Fragment>
                                    ) : (
                                      <Flex position="relative" height="100%" width="100%">
                                        {mediaLength > 1 && (
                                          <MultipleAttachmentsWrapper bottom="-2px" left="22px">
                                            <Text as="span" color={colors.white} fontSize="6px">
                                              +{mediaLength - 1}
                                            </Text>
                                          </MultipleAttachmentsWrapper>
                                        )}

                                        {type === IMAGE && (
                                          <ImageWithFallback
                                            id={`${POST_MEDIA_CLASS_NAME}-${id}`}
                                            source={thumbnail_url || url}
                                            fallbackSource={url}
                                            width={index === 0 ? '40px' : 0}
                                            height={index === 0 ? '40px' : 0}
                                          />
                                        )}

                                        {type === VIDEO && (
                                          <VideoWithFallback
                                            id={`${POST_MEDIA_CLASS_NAME}-${id}`}
                                            url={url}
                                            controls={false}
                                            width={index === 0 ? '40px' : 0}
                                            height={index === 0 ? '40px' : 0}
                                            style={{ maxHeight: '40px', background: '#ffffff' }}
                                            fallbackSourceImage={thumbnail_url}
                                            config={{
                                              file: {
                                                attributes: {
                                                  poster:
                                                    typeof isNew === 'undefined' && aspect_ratio && duration
                                                      ? thumbnail_url || ''
                                                      : '',
                                                },
                                              },
                                            }}
                                          />
                                        )}

                                        {(type === IMAGE_DYNAMIC || type === VIDEO_DYNAMIC) && (
                                          <DynamicMedia media={media} isSmall />
                                        )}

                                        {type === DOCUMENT && (
                                          <ImageWithFallback
                                            id={`${POST_MEDIA_CLASS_NAME}-${id}`}
                                            source={thumbnail_url || getFileIconByExtension({ extension })}
                                            fallbackSource={getFileIconByExtension({ extension })}
                                            width={index === 0 ? '40px' : 0}
                                            height={index === 0 ? '40px' : 0}
                                          />
                                        )}
                                      </Flex>
                                    )}
                                  </StyledMediaWrapper>
                                )
                              })}
                            </BodyCell>
                          )}
                          <BodyCell>
                            <Flex alignItems="flex-start" width="130px" fontSize="xs" flexDirection="column">
                              <Text fontSize="xs">{showPublishAtTime}</Text>
                              {showQueueLabel &&
                                ((postSelectedQueueLabel && postSelectedQueueLabel.value) ||
                                  (selectedQueueLabel && selectedQueueLabel.value)) && (
                                  <StyledBadge>
                                    {postSelectedQueueLabel ? postSelectedQueueLabel.value : selectedQueueLabel.value}
                                  </StyledBadge>
                                )}
                            </Flex>
                          </BodyCell>
                          <BodyCell>
                            {status === DRAFT && (
                              <StyledRoundedButton>
                                <Icon.Drag fill={COLOR_CONSTANTS.COSMIC_ENERGY} width="13px" height="13px" />
                              </StyledRoundedButton>
                            )}
                          </BodyCell>
                        </Row>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </StyledTable>
      <CalendarItemTooltip ref={tooltipRef} />
    </Card>
  )
}

Table.defaultProps = {
  selectedQueueLabel: null,
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  handleClickEditPost: PropTypes.func.isRequired,
  setListOfPosts: PropTypes.func.isRequired,
  whenToPublish: PropTypes.string.isRequired,
  importFrom: PropTypes.string.isRequired,
  selectedQueueLabel: PropTypes.object,
}

export default Table
