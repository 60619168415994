import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors, radius, space } from 'theme'
import { getSocialNetworkIconByName } from 'utils/socialNetworks'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Input from 'components/atoms/Input'
import { filterSocialProfiles } from '../../helpers'

const StyledFlex = styled(Flex)`
  cursor: pointer;
`

const StyledBox = styled(Box)`
  position: relative;
`

const StyledImg = styled.img`
  width: 15px;
  margin-bottom: -2px;
  padding-bottom: ${space.xs};
  border-radius: ${radius.s};
  object-fit: contain;
  cursor: pointer;
  border-bottom: solid ${colors.white};
  ${({ isActive }) =>
    isActive &&
    `
    opacity: 1;
    border-bottom: solid ${colors.primary};
  `}
`

const MAX_NETWORKS_IN_A_ROW = 10

const SocialNetworksFilterBodyTemplate = ({
  socialNetworkFilter,
  setSocialNetworkFilter,
  selectedEntities,
  selectedProfiles,
  entitySelectorRef,
}) => {
  let foundProfilesCounter = 0
  const socialNetworksForSocialNetworksFilterTemp = {}
  let allSelectedEntitiesProfilesCounter = 0
  if (selectedEntities) {
    selectedEntities.forEach(({ profiles = [] }) => {
      if (profiles && profiles.length > 0) {
        allSelectedEntitiesProfilesCounter += profiles.length
        foundProfilesCounter += filterSocialProfiles({ data: profiles, filter: true, socialNetworkFilter }).length
        profiles.forEach(({ network: { code } = {} }) => {
          if (code && !socialNetworksForSocialNetworksFilterTemp[code]) {
            socialNetworksForSocialNetworksFilterTemp[code] = code
          }
        })
      }
    })
  }

  const socialNetworksForSocialNetworksFilter = Object.keys(socialNetworksForSocialNetworksFilterTemp).sort((a, b) =>
    a.toLowerCase() < b.toLowerCase() ? -1 : 1
  )

  const allSelectedProfilesCounter = selectedProfiles ? selectedProfiles.length : 0

  const handleChangeSocialNetworkFilterByName = (value) => {
    socialNetworkFilter.socialNetworkFilterName = value || ''
    setSocialNetworkFilter({ ...socialNetworkFilter })
  }

  const handleClickSocialNetworkFilterByNetwork = (value) => {
    const foundIndex = socialNetworkFilter.socialNetworkFilterSelectedProfiles.findIndex((network) => network === value)
    if (foundIndex > -1) {
      socialNetworkFilter.socialNetworkFilterSelectedProfiles.splice(foundIndex, 1)
    } else {
      socialNetworkFilter.socialNetworkFilterSelectedProfiles.push(value)
    }
    setSocialNetworkFilter({ ...socialNetworkFilter })
  }

  return (
    <Fragment>
      <Input
        placeholder="Profile name ..."
        value={socialNetworkFilter.socialNetworkFilterName}
        onChange={(e) => {
          handleChangeSocialNetworkFilterByName(e.target.value)
        }}
        width="100%"
        isSmall
      />
      <Text color="secondaryText" fontSize="xs">
        {`${foundProfilesCounter} ${foundProfilesCounter === 1 ? 'profile' : 'profiles'} found `}
      </Text>
      <Grid
        mt="s"
        gridTemplateColumns={`repeat(${
          socialNetworksForSocialNetworksFilter.length < MAX_NETWORKS_IN_A_ROW
            ? socialNetworksForSocialNetworksFilter.length
            : MAX_NETWORKS_IN_A_ROW
        }, 1fr)`}
        gridGap="xs"
      >
        {socialNetworksForSocialNetworksFilter.map((network) => {
          const isActive = socialNetworkFilter.socialNetworkFilterSelectedProfiles.find((item) => item === network)
          return (
            <StyledBox
              key={network}
              onClick={() => {
                handleClickSocialNetworkFilterByNetwork(network)
              }}
            >
              <StyledImg src={getSocialNetworkIconByName(network).enabled} isActive={isActive} />
            </StyledBox>
          )
        })}
      </Grid>
      {entitySelectorRef && entitySelectorRef.current && allSelectedEntitiesProfilesCounter > 0 && (
        <Flex alignItems="center" justifyContent="space-between">
          {allSelectedEntitiesProfilesCounter !== allSelectedProfilesCounter ? (
            <StyledFlex
              onClick={() => {
                entitySelectorRef.current.handleClickSelectAllProfiles({ clickType: 'select' })
              }}
            >
              <Text color="secondaryText" fontSize="xs">
                Select all
              </Text>
            </StyledFlex>
          ) : (
            <StyledFlex />
          )}
          {allSelectedProfilesCounter > 0 && (
            <StyledFlex
              onClick={() => {
                entitySelectorRef.current.handleClickSelectAllProfiles({ clickType: 'clear' })
              }}
            >
              <Text color="secondaryText" fontSize="xs">
                Unselect all
              </Text>
            </StyledFlex>
          )}
        </Flex>
      )}
    </Fragment>
  )
}

SocialNetworksFilterBodyTemplate.defaultProps = {
  entitySelectorRef: null,
}

SocialNetworksFilterBodyTemplate.propTypes = {
  selectedEntities: PropTypes.array.isRequired,
  selectedProfiles: PropTypes.array.isRequired,
  socialNetworkFilter: PropTypes.object.isRequired,
  setSocialNetworkFilter: PropTypes.func.isRequired,
  entitySelectorRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
}

export default SocialNetworksFilterBodyTemplate
