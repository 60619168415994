import React, { useState } from 'react'
import { ThemeProvider } from 'styled-components'
import Modal from 'shared/Modal'
import { COLOR_CONSTANTS, theme } from 'theme'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Button from 'components/atoms/Button'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'

const withConfirm = (Component) => {
  const WithConfirmComponent = ({ ...props }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [fn, setFn] = useState()
    const [cancelFn, setCancelFn] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingCancel, setIsLoadingCancel] = useState(false)
    const [message, setMessage] = useState('Are you sure?')
    const [action, setAction] = useState('Delete')
    const [cancel, setCancel] = useState('Cancel')
    return (
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <Component
            confirm={({ fn, message, action, cancel, cancelFn }) => {
              setAction(action)
              setFn(fn)
              setMessage(message)
              if (cancel !== undefined) {
                setCancel(cancel)
              }
              if (cancelFn) {
                setCancelFn(cancelFn)
              }
              setIsModalOpen(true)
            }}
            {...props}
          />
          <Modal
            isOpen={isModalOpen}
            handleDismiss={() => {
              setIsModalOpen(false)
            }}
            isDisabled={isLoading || isLoadingCancel}
          >
            <Text dangerouslySetInnerHTML={{ __html: message }} />
            <Flex mt="l" justifyContent="flex-end">
              <Box>
                {cancel && (
                  <ButtonWithLoading
                    buttonComp={Button.Gray}
                    loaderColor={COLOR_CONSTANTS.SALUTE}
                    isLoading={isLoadingCancel}
                    onClick={async () => {
                      if (cancelFn) {
                        setIsLoadingCancel(true)
                        await cancelFn()
                        setIsLoadingCancel(false)
                      }
                      setIsModalOpen(false)
                    }}
                    isSmall
                  >
                    <Text>{cancel}</Text>
                  </ButtonWithLoading>
                )}
              </Box>
              {action && (
                <ButtonWithLoading
                  ml="m"
                  isLoading={isLoading}
                  onClick={async () => {
                    setIsLoading(true)
                    await fn()
                    setIsLoading(false)
                    setIsModalOpen(false)
                  }}
                  isSmall
                >
                  <Text>{action}</Text>
                </ButtonWithLoading>
              )}
            </Flex>
          </Modal>
        </React.Fragment>
      </ThemeProvider>
    )
  }

  WithConfirmComponent.getInitialProps = (context) => {
    if (Component.getInitialProps) {
      return Component.getInitialProps(context)
    } else {
      return {}
    }
  }
  return WithConfirmComponent
}

export default withConfirm
