import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import ClipLoader from 'react-spinners/ClipLoader'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import { IMAGE, VIDEO, AUDIO } from 'consts'
import { Box, Flex } from 'components/atoms/Layout'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import Icon from 'components/atoms/Icon'

const SelectWrapper = styled(Flex)`
  border-radius: ${radius.pill};
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? colors.primary : transparentize(0.2, COLOR_CONSTANTS.SALT))};
  border: 2px solid ${({ selected }) => (selected ? colors.primary : COLOR_CONSTANTS.COSMIC_ENERGY)};
`

const DownloadedWrapper = styled(Flex)`
  border-radius: ${radius.pill};
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? colors.primary : transparentize(0.2, COLOR_CONSTANTS.WHITE))};
`

const StyledWrapper = styled(Flex)`
  width: 100%;
  background-color: ${COLOR_CONSTANTS.WHITE};
  border-radius: ${radius.l};
  width: 100%;
  height: 180px;
  position: relative;
  border-radius: ${radius.l};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  cursor: pointer;
  ${({ selected }) => selected && `box-shadow: 0px 0px 5px 0px rgb(145 147 169 / 50%);`}
  &:hover {
    svg {
      fill: ${COLOR_CONSTANTS.WHITE};
    }
    ${SelectWrapper} {
      border-color: ${COLOR_CONSTANTS.WHITE};
    }
  }
`

const MediaWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  border-top-left-radius: ${radius.l};
  border-top-right-radius: ${radius.l};
  transition: all 0.5s ease-in-out;
  transform: scale(1);
  ${({ selected }) =>
    selected &&
    `border-bottom-right-radius: ${radius.l};  border-bottom-left-radius: ${radius.l}; transform: scale(0.8);`}
`

const StyledImage = styled(ImageWithFallback)`
  width: 100%;
  height: 100%;
`

const StyledIconCheckmark = styled(Icon.Checkmark)`
  color: ${({ selected }) => (selected ? colors.white : COLOR_CONSTANTS.COSMIC_ENERGY)};
`

const StyledLoadingFlex = styled(Flex)`
  position: absolute;
  z-index: 3;
  align-items: center;
  justify-content: center;
  background: ${transparentize(0.5, colors.white)};
  width: 100%;
  height: 100%;
`

const MediaItem = ({ data, handleClickSelectMedia, handleClickOpenMediaDetail }) => {
  const { media_type = IMAGE, selected = false, isLoading, mediaData } = data
  let { image: url, thumbnail_url } = data

  if (mediaData) {
    if (mediaData.type === AUDIO) {
      // eslint-disable-next-line prefer-destructuring
      url = mediaData.url
      // eslint-disable-next-line prefer-destructuring
      thumbnail_url = mediaData.thumbnail_url
    }
  }

  return (
    <StyledWrapper
      flexDirection="column"
      onClick={(e) => {
        e.preventDefault()
        handleClickOpenMediaDetail({ data })
      }}
      selected={selected}
    >
      <MediaWrapper selected={selected}>
        {media_type.toLowerCase() === VIDEO || media_type.toLowerCase() === AUDIO ? (
          <VideoWithFallback
            url={url}
            controls={false}
            width="100%"
            style={{ position: 'relative', borderTopLeftRadius: radius.l, borderTopRightRadius: radius.l }}
            height="180px"
            fallbackSourceImage={thumbnail_url}
            config={{
              file: {
                attributes: {
                  poster: thumbnail_url || '',
                },
              },
            }}
            playing={false}
          />
        ) : (
          <Flex position="relative" width="100%" height="100%">
            {media_type === 'CAROUSEL_ALBUM' && (
              <Box position="absolute" top={space.xs} right={space.xs} zIndex="2">
                <Image src="/assets/vistasocial/instagram_carousel.svg" />
              </Box>
            )}
            <StyledImage
              source={url}
              selected={selected}
              fallbackSource="/assets/landscape.svg"
              fallbackSourceWidth="50%"
              fallbackSourceHeight="50%"
            />
          </Flex>
        )}
      </MediaWrapper>
      <Box
        position="absolute"
        top={space.xs}
        left={space.xs}
        zIndex="2"
        width="100%"
        onClick={(event) => {
          event.stopPropagation()
          handleClickSelectMedia()
        }}
      >
        <SelectWrapper alignItems="center" justifyContent="center" selected={selected}>
          <StyledIconCheckmark selected={selected} width="10px" height="10px" />
        </SelectWrapper>
      </Box>
      {mediaData && (
        <Box position="absolute" bottom={space.xs} right={space.xs} zIndex="2">
          <DownloadedWrapper alignItems="center" justifyContent="center">
            <Icon.Download width="16px" height="14px" color={COLOR_CONSTANTS.COSMIC_ENERGY} />
          </DownloadedWrapper>
        </Box>
      )}
      {isLoading && (
        <StyledLoadingFlex>
          <ClipLoader size="40" color={colors.primary} />
        </StyledLoadingFlex>
      )}
    </StyledWrapper>
  )
}

MediaItem.propTypes = {
  data: PropTypes.object.isRequired,
  handleClickSelectMedia: PropTypes.func.isRequired,
  handleClickOpenMediaDetail: PropTypes.func.isRequired,
}

export default MediaItem
