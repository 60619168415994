import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import Alert from 'react-s-alert'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { COLOR_CONSTANTS, radius } from 'theme'
import { ERROR_MESSAGE, ADMIN } from 'consts'
import { getTimeZones } from 'helpers'
import withConfirm from 'utils/withConfirm'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { H4, Text } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import ErrorLabel from 'components/atoms/ErrorLabel'
import Image from 'components/atoms/Image'
import DropDown from 'shared/DropDown'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 530px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
  }
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  background-color: ${COLOR_CONSTANTS.BOYSENBERRY_SHADOW};
  outline: none;
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  overflow-y: auto;
  height: 100%;
`

const SetTimezoneModal = ({
  user,
  isOpen,
  handleDismiss,
  entitiesForSettingTimezone,
  handleUpdateEntities,
  showAllEntities,
}) => {
  const [canManageEntities, setCanManageEntities] = useState(false)
  const [entities, setEntities] = useState([])
  const [isSavingTimezone, setIsSavingTimezone] = useState(false)

  useEffect(() => {
    if (isOpen) {
      const entitiesForSettingTimezoneTemp = JSON.parse(JSON.stringify(entitiesForSettingTimezone))

      let canManage = false

      if (user.isManager) {
        canManage = true
      } else {
        user.entities.forEach((entity) => {
          const {
            entity_user: { role },
            id,
          } = entity
          if (role === ADMIN) {
            canManage = true
          } else {
            const foundEntityIndex = entitiesForSettingTimezoneTemp.findIndex((entity) => entity.id === id)
            if (foundEntityIndex > -1) {
              entitiesForSettingTimezoneTemp.splice(foundEntityIndex, 1)
            }
          }
        })
      }

      setCanManageEntities(canManage)

      const entitiesWithoutTimezone = []

      if (showAllEntities) {
        const timeZoneNames = getTimeZones()
        entitiesForSettingTimezoneTemp.forEach((item) => {
          if (item.timezone) {
            for (let i = 0; i < timeZoneNames.length; i++) {
              if (timeZoneNames[i].value === item.timezone) {
                item.timezone = timeZoneNames[i]
                break
              }
            }
          }
          entitiesWithoutTimezone.push(item)
        })
      } else {
        entitiesForSettingTimezoneTemp.forEach((item) => {
          if (!item.timezone) {
            entitiesWithoutTimezone.push(item)
          }
        })
      }
      setEntities([...entitiesWithoutTimezone])
    }
  }, [isOpen])

  const handleClickCloseModal = () => {
    if (!isSavingTimezone) {
      setEntities([])
      handleDismiss()
    }
  }

  const handleClickSaveTimezones = async () => {
    const updatedEntities = entities.filter(({ timezone }) => !timezone)
    if (updatedEntities.length !== 0) {
      return
    }
    try {
      setIsSavingTimezone(true)
      const body = entities.map(({ id, type, timezone }) => ({
        entity_id: id,
        entity_type: type, // not used anymore
        timezone: timezone.value,
      }))
      const response = await request({
        method: 'POST',
        path: 'publishing/timezones',
        body,
      })
      if (!response || response.error) {
        const { error = '' } = response || {}
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        handleUpdateEntities(body, true)
        Alert.success('Timezones settings have been saved.')
      }
    } catch (error) {
      errorHelper({ error, componentName: SetTimezoneModal.displayName, functionName: 'handleClickSaveTimezones' })
    } finally {
      setIsSavingTimezone(false)
      handleClickCloseModal()
    }
  }

  const entitiesMissingTimezoneSettingsString = entities
    .filter(({ timezone }) => !timezone)
    .map(({ name, type }) => `${name} (${type})`)
    .join(', ')
  const timeZoneNames = getTimeZones()

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" tabIndex={0} $isTop>
            <H4 my="m">Timezone settings</H4>
          </StyledDialogEnvironmentWrapper>
          <StyledDialogBodyWrapper flex="1" flexDirection="column" p="m">
            {!canManageEntities ? (
              <Text fontSize="m" pr="m">
                {!showAllEntities
                  ? `Timezone settings are missing for ${entitiesMissingTimezoneSettingsString}. Please contact profile group administrator.`
                  : `You have no access for setting timezone settings. Please contact profile group administrator.`}
              </Text>
            ) : (
              <Fragment>
                {!showAllEntities && (
                  <Text fontSize="m" mb="m" pr="m">
                    Timezone settings are missing for {entitiesMissingTimezoneSettingsString}.
                  </Text>
                )}

                {entities.map(({ name, type, id, timezone }, index) => (
                  <Flex mb="m" key={id} flexDirection="column">
                    <Flex alignItems="center">
                      <Flex alignItems="center" minWidth="200px" width="200px">
                        <Text>{`${name} (${type})`}</Text>
                      </Flex>
                      <Flex flexDirection="column" width="100%">
                        <DropDown
                          placeholder="Set timezone"
                          label=""
                          value={timezone}
                          onChange={(option) => {
                            entities[index].timezone = option
                            setEntities([...entities])
                          }}
                          options={timeZoneNames}
                          openMenuOnFocus
                        />
                      </Flex>
                    </Flex>
                    <Flex alignItems="center">
                      <Flex alignItems="center" minWidth="200px" width="200px" />
                      <ErrorLabel error={!timezone ? 'Please select the timezone' : ''} />
                    </Flex>
                  </Flex>
                ))}
              </Fragment>
            )}
          </StyledDialogBodyWrapper>

          <StyledDialogEnvironmentWrapper p="m" justifyContent="flex-end" $isBottom>
            <Flex alignItems="center">
              <Button.Gray onClick={handleClickCloseModal} isSmall isDisabled={isSavingTimezone}>
                Close
              </Button.Gray>
              {canManageEntities && (
                <ButtonWithLoading ml="m" onClick={handleClickSaveTimezones} isSmall isLoading={isSavingTimezone}>
                  Update
                </ButtonWithLoading>
              )}
            </Flex>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal} isDisabled={isSavingTimezone}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

SetTimezoneModal.defaultProps = {
  handleUpdateEntities: () => {},
  showAllEntities: false,
}

SetTimezoneModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  entitiesForSettingTimezone: PropTypes.array.isRequired,
  handleUpdateEntities: PropTypes.func,
  showAllEntities: PropTypes.bool,
}

SetTimezoneModal.displayName = 'SetTimezoneModal'

export default withConfirm(SetTimezoneModal)
