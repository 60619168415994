import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space as styledSpace } from 'styled-system'
import { SwipeableDrawer } from '@material-ui/core'
import { Scrollbars } from 'react-custom-scrollbars-2'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import { DEFAULT_TIME_ZONE, DEFAULT_SOCIAL_PROFILE_SOURCE } from 'consts'
import request from 'utils/request'
import { getSpecialPostsIconByType } from 'utils/socialNetworks'
import errorHelper from 'utils/errorHelper'
import { Box, Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import { Text } from 'components/atoms/Typography'
import {
  POST_IMAGE,
  POST_LINK,
  POST_VIDEO,
  POST_DOCUMENT,
  ROUTE_CALENDAR,
  STATUS_FILTERS,
  POST_TYPE_SOCIAL_POST,
  POST_TYPE_HOLIDAY,
  POST_TYPE_EXTERNAL_CALENDAR_EVENT,
  POST_TYPE_NOTE,
} from '../consts'
import { convertPostToEvent, generateTextHeaderForDeletePostModal, setPostComponent } from '../helpers'
import PreviewComponent from './PreviewModal/PreviewComponent'
import DeletePostModal from './DeletePostModal'
import Filter from '../../Search/Filter'
import PreviewExternalCalendarEventComponent from './PreviewExternalCalendarEventModal/PreviewExternalCalendarEventComponent'

const StyledPreviewWrapper = styled(Flex)`
  position: relative;
  width: ${({ $isSmallPreview, showPerformance }) =>
    $isSmallPreview ? '280px' : `${showPerformance ? '1060px' : '560px'}`};
  padding: 0;
  border-radius: ${radius.l};
  ${styledSpace};
  margin: ${space.m} auto;
  display: flex;
  flex-direction: column;
  height: auto;
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
`

const StyledHeader = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  ${({ backgroundImage }) => backgroundImage && `background-image: ${backgroundImage}`};
  border-radius: ${radius.m} ${radius.m} 0 0;
`

const SourceWrapper = styled(Box)`
  width: 64px;
  height: 64px;
  border: 1px solid;
  border-color: rgb(204, 214, 221);
`

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

const SliderPostsPreview = ({
  user,
  isOpen,
  data,
  setSliderPostsPreviewData,
  timezone,
  handleClickEvent,
  handleDeletePost,
  isPostDeleting,
  handleUpdateCalendarPostLabels,
  hasComponentControls,
  sharedCalendarData,
  isSmallPreview,
  showPerformance,
}) => {
  const [isGettingPosts, setIsGettingPosts] = useState(true)
  const [posts, setPosts] = useState([])
  const [isDeletePostModalOpen, setIsDeletePostModalOpen] = useState(false)
  const [postForDeleting, setPostForDeleting] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState([])
  const [statusOptions, setStatusOptions] = useState([])

  const { sharedCalendarId, sign } = sharedCalendarData || {}

  const getPosts = async () => {
    const { eventIds = [], specialEvents = [], type = '', type_id } = data
    try {
      if (eventIds.length !== 0 || type_id) {
        let path = `${ROUTE_CALENDAR}/list?timezone=${timezone}&group=false`

        if (sharedCalendarId) {
          path = `${ROUTE_CALENDAR}/share/links/list?shared_id=${sharedCalendarId}`
        }

        const response = await request({
          method: 'POST',
          path,
          body: { postsIds: eventIds, type, type_id },
        })

        if (response && !response.error) {
          const { groupedPosts } = response
          setPosts([...specialEvents, ...groupedPosts])
        }
      } else {
        setPosts(specialEvents)
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: SliderPostsPreview.displayName,
        functionName: 'getPosts',
        errorMessage: `Can't get posts. Please contact support.`,
      })
    } finally {
      setIsGettingPosts(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      setSelectedFilters([])
      getPosts()
    }
  }, [isOpen])

  useEffect(() => {
    if (!isGettingPosts) {
      setStatusOptions([...STATUS_FILTERS])
    }
  }, [isGettingPosts])

  const handleClickCloseSlider = () => {
    setSliderPostsPreviewData(null)
  }

  const handleClickOpenDeletePostModal = ({ post }) => {
    setPostForDeleting({ ...post })
    setIsDeletePostModalOpen(true)
  }

  const handleClickCloseDeletePostModal = () => {
    setIsDeletePostModalOpen(false)
    setPostForDeleting(null)
  }

  const handleClickDeletePost = async (body) => {
    body.items = [{ id: postForDeleting.id, type: postForDeleting.type }]
    const { success } = await handleDeletePost({ data: body, type: 'slider' })
    if (success) {
      setPosts([...posts.filter(({ id }) => id !== postForDeleting.id)])
    }
    handleClickCloseDeletePostModal()
  }

  const handleClickDuplicatePost = ({ post }) => {
    const eventInfo = convertPostToEvent({ post })
    handleClickEvent({ eventInfo, sendToCompose: true })
  }

  const handleClickOpenEvent = ({ post }) => {
    const eventInfo = convertPostToEvent({ post })

    handleClickEvent({ eventInfo })
  }

  const handleUpdateCommentsCounter = ({ id, commentsCounter }) => {
    const foundPostIndex = posts.findIndex((post) => post.id === id)
    if (foundPostIndex > -1) {
      posts[foundPostIndex].comments = commentsCounter
      setPosts([...posts])
    }
  }

  const handleUpdatePostBoostedData = ({ id, target_result_boosted, post }) => {
    const foundPostIndex = posts.findIndex((post) => post.id === id)
    if (foundPostIndex > -1) {
      if (post) {
        posts[foundPostIndex] = { ...post }
      } else if (target_result_boosted) {
        posts[foundPostIndex].result.boosted = target_result_boosted
      }

      posts[foundPostIndex].result.boosted = target_result_boosted

      setPosts([...posts])
    }
  }

  const handleSelectStatusFilter = async ({ option, removed, options }) => {
    let updatedSelectedFilters = options
    if (option) {
      const { id, name } = option
      updatedSelectedFilters = [...selectedFilters]
      if (!removed) {
        updatedSelectedFilters.push({ name, id })
      } else {
        updatedSelectedFilters = updatedSelectedFilters.filter((item) => item.id !== id)
      }
    }
    setSelectedFilters([...updatedSelectedFilters])
  }

  let headerText = ''
  if (postForDeleting) {
    headerText = generateTextHeaderForDeletePostModal({ post: postForDeleting })
  }

  const filteredPosts = posts.filter((item) => {
    const selectedFiltersIds = selectedFilters.map(({ id }) => id)
    if (selectedFiltersIds.length !== 0) {
      if (selectedFiltersIds.includes(item.status)) {
        return item
      }
      return null
    } else {
      return item
    }
  })

  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      style={{ zIndex: 2147483001 }}
      anchor="right"
      open={!!isOpen}
      onClose={() => {
        handleClickCloseSlider()
      }}
      disableAutoFocus
      disableEnforceFocus
      onOpen={() => {}}
    >
      {isOpen && (
        <Flex minWidth={isSmallPreview ? '300px' : `${showPerformance ? '1150px' : '650px'}`} height="100%" mx="auto">
          <Scrollbars universal>
            <Flex flexDirection="column" mx={isSmallPreview ? 's' : 'l'} height="calc(80%)">
              {isGettingPosts ? (
                <Flex alignItems="center" justifyContent="center" height="100%" width="100%">
                  <ClipLoader size="50" color={colors.primary} />
                </Flex>
              ) : (
                <Flex flexDirection="column" mt="m" height="100%">
                  <Flex
                    justifyContent="center"
                    flexDirection="column"
                    width={{ mobile: 'auto', desktopWide: '150px' }}
                    mx={{ mobile: 's', desktopWide: 'm' }}
                  >
                    <Filter
                      label="Select Filters"
                      placeholder="Select Filters ..."
                      value={selectedFilters}
                      options={statusOptions}
                      onSelect={() => {}}
                      handleClickSelectedOption={handleSelectStatusFilter}
                      showLabel={false}
                      showMobileIcon={{ mobile: 'flex', desktopWide: 'none' }}
                      removeMobileIcon={{ mobile: 'none', desktopWide: 'flex' }}
                      left="auto"
                      right="0px"
                    />
                  </Flex>
                  <Fragment>
                    {filteredPosts.length === 0 ? (
                      <Flex justifyContent="center" alignItems="center" flexDirection="column" height="100%">
                        <Image mt="xl" source="/assets/buildingIllustration.svg" />
                        <Text mt="l" fontSize="l">
                          No results have been found
                        </Text>
                      </Flex>
                    ) : (
                      <Flex flexDirection="column">
                        {filteredPosts.map((post, index) => {
                          const {
                            title,
                            start,
                            postImages = [],
                            postVideos = [],
                            postDocuments = [],
                            link,
                            type,
                          } = post
                          if (
                            type &&
                            (type === POST_TYPE_HOLIDAY ||
                              type === POST_TYPE_EXTERNAL_CALENDAR_EVENT ||
                              type === POST_TYPE_NOTE)
                          ) {
                            if (type === POST_TYPE_HOLIDAY) {
                              const { holiday_type, typeDisplayName } = post
                              const icon = getSpecialPostsIconByType(holiday_type)
                              return (
                                <StyledPreviewWrapper key={index} $isSmallPreview={isSmallPreview}>
                                  <StyledHeader
                                    px="m"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    bg={COLOR_CONSTANTS.ALICE}
                                    flexWrap="wrap"
                                  >
                                    {typeDisplayName && (
                                      <Flex my="m" flexDirection="column">
                                        <Text fontSize="s" fontWeight="bold" color="white">
                                          {typeDisplayName}
                                        </Text>
                                      </Flex>
                                    )}
                                  </StyledHeader>
                                  <Flex width="100%" mx="m" py="s" alignItems="center">
                                    {icon && (
                                      <SourceWrapper mr="s">
                                        <Image src={icon} width="100%" height="100%" />
                                      </SourceWrapper>
                                    )}
                                    <Flex>
                                      <Text>{title}</Text>
                                    </Flex>
                                  </Flex>
                                </StyledPreviewWrapper>
                              )
                            } else if (type === POST_TYPE_EXTERNAL_CALENDAR_EVENT) {
                              return (
                                <StyledPreviewWrapper key={index} $isSmallPreview={isSmallPreview}>
                                  <PreviewExternalCalendarEventComponent postForEdit={post} />
                                </StyledPreviewWrapper>
                              )
                            } else if (type === POST_TYPE_NOTE) {
                              const { description, event_background_color, event_text_color, createdBy } = post
                              return (
                                <StyledPreviewWrapper key={index} $isSmallPreview={isSmallPreview}>
                                  <StyledHeader
                                    p="m"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    backgroundImage={event_background_color}
                                    flexWrap="wrap"
                                  >
                                    <Text
                                      fontSize="s"
                                      fontWeight="bold"
                                      color={event_text_color || COLOR_CONSTANTS.WHITE}
                                    >
                                      Calendar note
                                    </Text>

                                    {createdBy && (
                                      <Text color={event_text_color || COLOR_CONSTANTS.WHITE} fontSize="s">
                                        {createdBy}
                                      </Text>
                                    )}
                                  </StyledHeader>
                                  <Flex width="100%" px="m" py="s" alignItems="center">
                                    <Flex flexDirection="column">
                                      <Text>{title}</Text>
                                      {description && (
                                        <Text mt="s" fontSize="xs" color="secondaryText">
                                          {description}
                                        </Text>
                                      )}
                                    </Flex>
                                  </Flex>
                                </StyledPreviewWrapper>
                              )
                            }
                          } else {
                            if (!type) {
                              post.postText = title
                              post.profile.fallbackLogoImage = DEFAULT_SOCIAL_PROFILE_SOURCE
                              post.publish_at = start
                            }

                            if (!type || type === POST_TYPE_SOCIAL_POST) {
                              if (link && link.active) {
                                link.picture_url = link.picture_url || post.linkPicture
                                if (link.title || link.description || link.picture_url) {
                                  link.hasLinkUrlInformation = true
                                  link.isLinkUrlCorrect = true
                                  link.isLinkUrlParsed = true
                                }
                              }

                              setPostComponent({ data: post })
                            }

                            return (
                              <StyledPreviewWrapper
                                id={post.id}
                                key={post.id}
                                $isSmallPreview={isSmallPreview}
                                showPerformance={showPerformance}
                              >
                                <PreviewComponent
                                  user={user}
                                  postForEdit={post}
                                  hasComponentControls={hasComponentControls && !sharedCalendarId}
                                  timezone={timezone}
                                  handleClickOpenDeletePostModal={handleClickOpenDeletePostModal}
                                  handleClickDuplicatePost={handleClickDuplicatePost}
                                  handleClickOpenEvent={handleClickOpenEvent}
                                  handleUpdateCalendarPostLabels={handleUpdateCalendarPostLabels}
                                  handleUpdateCommentsCounter={({ commentsCounter }) => {
                                    handleUpdateCommentsCounter({ id: post.id, commentsCounter })
                                  }}
                                  sharedCalendarData={sharedCalendarData}
                                  posts={[post]}
                                  handleUpdatePostBoostedData={handleUpdatePostBoostedData}
                                  isSmallPreview={isSmallPreview}
                                  showPerformance={showPerformance}
                                />
                              </StyledPreviewWrapper>
                            )
                          }
                        })}
                      </Flex>
                    )}
                  </Fragment>
                </Flex>
              )}
            </Flex>
          </Scrollbars>
          {isDeletePostModalOpen && (
            <DeletePostModal
              handleDismiss={handleClickCloseDeletePostModal}
              isOpen={isDeletePostModalOpen}
              isPostSubmitting={isPostDeleting}
              handleDelete={handleClickDeletePost}
              headerText={headerText}
            />
          )}
          <Flex width="0px" height="0px" onClick={() => handleClickCloseSlider()} className="modal-close-icon" />
        </Flex>
      )}
    </SwipeableDrawer>
  )
}

SliderPostsPreview.defaultProps = {
  user: null,
  data: {},
  handleClickEvent: () => {},
  timezone: DEFAULT_TIME_ZONE,
  hasComponentControls: true,
  sharedCalendarData: null,
  isSmallPreview: false,
  showPerformance: false,
}

SliderPostsPreview.propTypes = {
  user: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  setSliderPostsPreviewData: PropTypes.func.isRequired,
  data: PropTypes.object,
  handleClickEvent: PropTypes.func,
  timezone: PropTypes.string,
  handleDeletePost: PropTypes.func.isRequired,
  isPostDeleting: PropTypes.bool.isRequired,
  handleUpdateCalendarPostLabels: PropTypes.func.isRequired,
  hasComponentControls: PropTypes.bool,
  sharedCalendarData: PropTypes.object,
  isSmallPreview: PropTypes.bool,
  showPerformance: PropTypes.bool,
}

SliderPostsPreview.displayName = 'SliderPostsPreview'

export default SliderPostsPreview
