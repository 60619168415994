import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR_SCHEMES } from 'consts'
import { radius, colors } from 'theme'
import { Flex } from 'components/atoms/Layout'
import { H1, Text } from 'components/atoms/Typography'

const DIMENSIONS = {
  SMALL: '30px',
  MEDIUM: '70px',
  LARGE: '100px',
}

const Wrapper = styled(Flex)`
  border-radius: ${({ isCircle }) => (!isCircle ? radius.l : radius.circle)};
  align-items: center;
  justify-content: center;
  width: ${({ isSmall, isLarge, width }) =>
    Object.keys(width).length ? width : isSmall ? DIMENSIONS.SMALL : isLarge ? DIMENSIONS.LARGE : DIMENSIONS.MEDIUM};
  height: ${({ isSmall, isLarge, height }) =>
    Object.keys(height).length ? height : isSmall ? DIMENSIONS.SMALL : isLarge ? DIMENSIONS.LARGE : DIMENSIONS.MEDIUM};
`

const getInitialsFromName = (name) => {
  const words = name.split(' ')
  const firstLetter = words[0].charAt(0)
  const secondLetter = words.length > 1 ? words[1].charAt(0) : words[0].charAt(1)
  return `${firstLetter.toUpperCase()}${secondLetter.toUpperCase()}`
}

const Initials = ({
  name,
  isSmall,
  isLarge,
  isDefaultColor,
  isCalculatedColor,
  backgroundColor,
  textColor,
  isCircle,
  width,
  height,
}) => {
  const [color, setColor] = useState({})

  useEffect(() => {
    if (isDefaultColor) {
      const { bg, text } = COLOR_SCHEMES[3]
      setColor({ bg, text })
    } else if (backgroundColor) {
      setColor({ bg: backgroundColor, text: textColor || colors.white })
    } else if (isCalculatedColor) {
      let quotient = 0
      for (let i = 0; i < name.length; i++) {
        if (name[i]) {
          quotient += name[i].charCodeAt(0)
        }
      }

      while (quotient >= COLOR_SCHEMES.length) {
        quotient = Math.floor(quotient / COLOR_SCHEMES.length)
      }

      const { bg, text } = COLOR_SCHEMES[quotient]
      setColor({ bg, text })
    } else {
      const { bg, text } = COLOR_SCHEMES[Math.floor(Math.random() * COLOR_SCHEMES.length)]
      setColor({ bg, text })
    }
  }, [name])

  const { bg, text } = color
  const TextComponent = isSmall ? Text : H1
  return (
    <Wrapper bg={bg} isSmall={isSmall} isLarge={isLarge} width={width} height={height} isCircle={isCircle}>
      <TextComponent color={text}>{getInitialsFromName(name)}</TextComponent>
    </Wrapper>
  )
}

Initials.defaultProps = {
  isSmall: false,
  isLarge: false,
  isDefaultColor: false,
  isCircle: false,
  width: {},
  height: {},
  backgroundColor: null,
  textColor: null,
  isCalculatedColor: false,
}

Initials.propTypes = {
  name: PropTypes.string.isRequired,
  isSmall: PropTypes.bool,
  isLarge: PropTypes.bool,
  isDefaultColor: PropTypes.bool,
  isCircle: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  isCalculatedColor: PropTypes.bool,
}

export default Initials
