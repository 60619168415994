export const collectTiktokIdentities = ({ data }) => {
  return data
    ? data.map(({ profile_image, display_name, identity_id, identity_type }) => ({
        value: identity_id,
        label: display_name,
        picture_url: profile_image,
        identity_type,
      }))
    : []
}

export const collectTiktokData = ({ data }) => {
  return data
    ? data.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    : []
}

export const collectTwitterFundingInstruments = ({ data }) => {
  return data
    ? data.map(({ id, description, ...other }) => ({
        value: id,
        label: description,
        id,
        description,
        ...other,
      }))
    : []
}

export const collectMetaAudiences = ({ data }) => {
  return data
    ? data.map(({ id, name, description, type }) => ({
        value: id,
        label: name,
        id,
        description,
        type,
      }))
    : []
}

export const collectTwitterAudiences = ({ data }) => {
  return data
    ? data.map(({ id, name, description }) => ({
        value: id,
        label: name,
        id,
        description,
      }))
    : []
}

export const collectLinkedInAudiences = ({ data }) => {
  return data
    ? data.map(({ id, name, ...other }) => ({
        value: id,
        label: name,
        id,
        ...other,
      }))
    : []
}
