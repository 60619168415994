import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'theme'
import { Flex } from 'components/atoms/Layout'
import Tooltip from 'components/molecules/Tooltip'
import Icon from 'components/atoms/Icon'
import { HELPER_TEXTS } from '../consts'

const InfoIcon = styled(Icon.Info)`
  fill: ${colors.secondaryText};
  cursor: pointer;
`

const TooltipPromptComponent = ({ optionType, ...tooltipProps }) => {
  const data = HELPER_TEXTS[optionType]

  const { text, link } = data || {}

  if (!text) {
    return null
  }

  return (
    <Tooltip
      onClick={() => {
        if (link) {
          window.open(link, '_blank')
        }
      }}
      wrapperComp={
        <Flex alignItems="center" ml="xs">
          <InfoIcon />
        </Flex>
      }
      message={`${text}${link ? ' Click to learn more.' : ''}`}
      width="285px"
      isTriangleVisible={false}
      left="0"
      right="unset"
      defaultTopOffset={10}
      {...tooltipProps}
    />
  )
}

TooltipPromptComponent.defaultProps = {}

TooltipPromptComponent.propTypes = {
  optionType: PropTypes.string.isRequired,
}

export default TooltipPromptComponent
