import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import moment from 'moment-timezone'
import Alert from 'react-s-alert'
import Modal from '@material-ui/core/Modal'
import { useDropzone } from 'react-dropzone'
import ClipLoader from 'react-spinners/ClipLoader'
import SyncLoader from 'react-spinners/SyncLoader'
import { COLOR_CONSTANTS, radius, space, fontSizes, colors } from 'theme'
import { ACCESS_MANAGE, DEFAULT_TIME_ZONE, FEATURE_QUEUE_LABELS, PERMISSION_PUBLISH } from 'consts'
import { initializeStringArrayWithRange } from 'helpers'
import { hasEntitiesAccess } from 'utils/feature'
import withConfirm from 'utils/withConfirm'
import { H4, Text } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Datepicker from 'components/molecules/Datepicker'
import Select from 'components/atoms/Select'
import Image from 'components/atoms/Image'
import Radio from 'components/atoms/Radio'
import Button from 'components/atoms/Button'
import DropDown from 'shared/DropDown'
import { handleMediaFileChange } from 'routes/Media/helper'
import PostCreationComponent from '../PostCreationComponent'
import SpecialMediaProcessingDataComponent from '../SpecialMediaProcessingDataComponent'
import { DATE_FORMAT, QUEUE_LAST, QUEUE_NEXT, PER_CSV, DEFAULT_QUEUE_LABEL, DRAFT, IDEA } from '../../consts'
import { PostCreationProvider } from '../../contexts/PostCreationContext'

const StyledSelect = styled(Select)`
  padding: 0 ${space.xs};
  font-size: ${fontSizes.xs};
  background-position: calc(100% - ${space.s}) center;
`

const StyledDialogOverlay = styled(Modal)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483001 !important;
    > * {
      &:first-child {
        background: none !important;
      }
    }
  }
`

const StyledDialogContent = styled(Flex)`
  &&& {
    position: relative;
    max-width: 100%;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: 100%;
    flex-direction: column;
    background-color: ${COLOR_CONSTANTS.WHITE};
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  background-color: ${COLOR_CONSTANTS.BOYSENBERRY_SHADOW};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  overflow: hidden;
  height: 100%;
  outline: none;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledFilesWrapper = styled(Flex)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: rgba(39, 40, 49, 0.3);
  z-index: 2147483002;
  transition: border 0.24s ease-in-out;
  border: 2px dashed ${COLOR_CONSTANTS.WHITE};
  visibility: hidden;
  ${({ show }) => show && `visibility: visible;`}
`

const { QUEUE, SPECIFIC_TIME } = {
  QUEUE: 'queue',
  SPECIFIC_TIME: 'specific_time',
}

const CHOOSE_TIME = [
  { name: 'Choose specific time', id: SPECIFIC_TIME },
  { name: 'Save as draft', id: DRAFT },
  { name: 'Save as idea', id: IDEA },
  { name: 'Queue', id: QUEUE },
]

const QUEUE_TIME = [
  { name: 'Queue next', id: QUEUE_NEXT },
  { name: 'Queue last', id: QUEUE_LAST },
]

const HOURS = initializeStringArrayWithRange({ start: 1, end: 12 })
const MINUTES = initializeStringArrayWithRange({ end: 59 })

const BulkEditPostModal = ({
  user,
  isOpen,
  handleDismiss,
  handleUpdatePost,
  dataForCustomizations,
  selectedEntities,
  usedQueueLabels,
  featuresEnabled,
  hasTimeDraftOption,
  hasTimeIdeaOption,
  selectedTimezone = DEFAULT_TIME_ZONE,
  confirm,
  ...props
}) => {
  const postCreationComponentRef = useRef(null)
  const specialMediaProcessingDataComponentRef = useRef(null)

  const [postForEdit, setPostForEdit] = useState({})
  const [whenToPublish, setWhenToPublish] = useState(QUEUE)
  const [whenToQueue, setWhenToQueue] = useState(QUEUE_LAST)
  const [publishTime, setPublishTime] = useState({})
  const [selectedProfiles, setSelectedProfiles] = useState([])
  const [uploadingMediasAmount, setUploadingMediasAmount] = useState(0)
  const [selectedQueueLabel, setSelectedQueueLabel] = useState(null)
  const [chooseTimeOptions, setChooseTimeOptions] = useState([...CHOOSE_TIME])
  const [postCreationComponentDataIsLoading, setPostCreationComponentDataIsLoading] = useState(true)

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0 && uploadingMediasAmount === 0) {
      handleMediaFileChange({
        acceptedFiles,
        setUploadingMedias: (medias) => {
          postCreationComponentRef.current.setUploadingMedias(medias)
        },
        setUploadingMediasAmount,
      })
    }
  }, [])

  const { getRootProps, isDragActive } = useDropzone({ onDrop })

  const generateTimeData = (publish_at) => {
    const now = publish_at ? moment.tz(publish_at, true, selectedTimezone) : moment.tz(moment(), true, selectedTimezone)
    const date = now.format(DATE_FORMAT)
    const hour = now.format('hh')
    const minute = now.format('mm')
    const interval = now.format('A')
    return {
      date,
      hour,
      minute,
      interval,
    }
  }

  useEffect(() => {
    if (isOpen) {
      const updatedChooseTimeOptions = [...chooseTimeOptions]

      if (!hasTimeDraftOption) {
        const foundDraftIndex = updatedChooseTimeOptions.findIndex(({ id }) => id === DRAFT)
        if (foundDraftIndex > -1) {
          chooseTimeOptions.splice(foundDraftIndex, 1)
        }
      }

      if (!hasTimeIdeaOption) {
        const foundIdeaIndex = updatedChooseTimeOptions.findIndex(({ id }) => id === IDEA)
        if (foundIdeaIndex > -1) {
          chooseTimeOptions.splice(foundIdeaIndex, 1)
        }
      }

      setChooseTimeOptions([...updatedChooseTimeOptions])

      const { publish_at, defaultPublishAt, selectedQueueLabel } = props.postForEdit

      let showPublishAtTime = props.whenToPublish || QUEUE_LAST

      if (publish_at) {
        showPublishAtTime = publish_at
      } else if (props.whenToPublish === PER_CSV) {
        showPublishAtTime = defaultPublishAt || QUEUE_LAST
      }

      if (showPublishAtTime.includes('queue')) {
        setWhenToPublish(QUEUE)
        setWhenToQueue(showPublishAtTime)
        setPublishTime({ ...generateTimeData() })
      } else if (showPublishAtTime.includes('now')) {
        setWhenToPublish(SPECIFIC_TIME)
        setPublishTime({ ...generateTimeData() })
      } else if (showPublishAtTime === DRAFT || publish_at === DRAFT || props.postForEdit.draft) {
        setWhenToPublish(DRAFT)
        setPublishTime({ ...generateTimeData() })
      } else if (showPublishAtTime === IDEA || publish_at === IDEA) {
        setWhenToPublish(IDEA)
        setPublishTime({ ...generateTimeData() })
      } else if (publish_at || showPublishAtTime) {
        if (publish_at) {
          setPublishTime({ ...generateTimeData(publish_at) })
        } else {
          setPublishTime({ ...generateTimeData(showPublishAtTime) })
        }
        setWhenToPublish(SPECIFIC_TIME)
      } else {
        setWhenToPublish(QUEUE)
        setWhenToQueue(QUEUE_LAST)
        setPublishTime({ ...generateTimeData() })
      }

      if (selectedQueueLabel) {
        setSelectedQueueLabel(selectedQueueLabel)
      }

      setPostForEdit({ ...props.postForEdit })
    }
  }, [isOpen])

  useEffect(() => {
    setSelectedProfiles([
      ...props.selectedProfiles.filter(
        ({ entityId }) =>
          !hasEntitiesAccess({
            user,
            permission: PERMISSION_PUBLISH,
            entity_gid: entityId,
            accessLevels: [ACCESS_MANAGE],
          })
      ),
    ])
  }, [props.selectedProfiles])

  const handleChangeSelectedDate = ({ type, value }) => {
    if (type === 'date') {
      publishTime[type] = moment(value).format(DATE_FORMAT)
    } else if (type === 'hour' || type === 'minute' || type === 'interval') {
      publishTime[type] = value
    }
    setPublishTime({ ...publishTime })
  }

  const handleClickUpdatePost = async () => {
    const { post } = await postCreationComponentRef.current.handleCheckPostForError()

    let error = ''
    let showErrorAlert = true
    if (selectedProfiles.length === 0) {
      error = 'Please select at least one social profile.'
    }

    let networksString = ''
    const { networksErrors = {}, mediaUploadingError: { isMediaUploading = false, mediaType } = {} } = post.errors || {}

    for (const i in networksErrors) {
      if (networksErrors[i] && networksErrors[i].hasErrors) {
        networksString = `${networksString ? `${networksString},` : ''} ${networksErrors[i].code
          .charAt(0)
          .toUpperCase()}${networksErrors[i].code.substr(1)}`
      }
    }
    if (networksString) {
      error = `Please check your content on the following social networks: ${networksString}`
    }

    const doesntHaveUniversalMentions = postCreationComponentRef.current.handleCheckPostTextForNotCreatedMentions({
      callbackFunction: handleClickUpdatePost,
    })
    if (doesntHaveUniversalMentions) {
      error = `Please create a 'universal mention' by specifying the handle on each social network.`
      showErrorAlert = false
    } else {
      const doesntHaveVariables = postCreationComponentRef.current.handleCheckPostTextForNotCreatedVariables({
        callbackFunction: handleClickUpdatePost,
      })
      if (doesntHaveVariables) {
        error = `Please create a 'variable' by specifying on each social profile.`
        showErrorAlert = false
      }
    }

    if (!error && isMediaUploading) {
      error = `Your ${mediaType} is still uploading. Please wait just a bit longer before continuing.`
    }

    if (error) {
      if (showErrorAlert) {
        Alert.error(error)
      }
    } else {
      post.selectedQueueLabel = null

      if (whenToPublish === SPECIFIC_TIME) {
        post.publish_at = moment(
          `${publishTime.date} ${publishTime.hour}:${publishTime.minute} ${publishTime.interval}`,
          `${DATE_FORMAT} hh:mm A`
        ).format(`${DATE_FORMAT} HH:mm`)
      } else if (whenToPublish === DRAFT) {
        post.publish_at = DRAFT
      } else if (whenToPublish === IDEA) {
        post.publish_at = IDEA
      } else {
        post.publish_at = whenToQueue
        post.selectedQueueLabel = selectedQueueLabel
      }

      specialMediaProcessingDataComponentRef.current.removeAllMediasFromProcessingInterval()

      handleUpdatePost(post)
    }
  }

  const handleClearAllIntervalsAndDismiss = () => {
    specialMediaProcessingDataComponentRef.current.removeAllMediasFromProcessingInterval()

    handleDismiss()
  }

  const handleClickCloseModal = () => {
    handleClearAllIntervalsAndDismiss()
  }

  return (
    <StyledDialogOverlay disableEnforceFocus open={isOpen} onClose={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent {...getRootProps({ isDragActive })}>
          <StyledFilesWrapper
            show={isDragActive || uploadingMediasAmount > 0}
            alignItems="center"
            justifyContent="center"
          >
            {uploadingMediasAmount === 0 ? (
              <Text fontSize="l" fontWeight="bold" color={COLOR_CONSTANTS.WHITE}>
                Drag 'n' drop some files here
              </Text>
            ) : (
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                px="m"
              >
                <Text mb="s" fontSize="l" fontWeight="bold" color={COLOR_CONSTANTS.WHITE}>
                  {`${uploadingMediasAmount} ${uploadingMediasAmount === 1 ? 'file' : 'files'} left. `}
                  Please wait a moment...
                </Text>
                <ClipLoader size="50" color={colors.primary} />
              </Flex>
            )}
          </StyledFilesWrapper>
          <StyledDialogEnvironmentWrapper px="m" $isTop>
            <H4 my="m">Edit bulk post</H4>
          </StyledDialogEnvironmentWrapper>
          <StyledDialogBodyWrapper flex="1" tabIndex={0}>
            <Box minWidth="256px" width="256px" px="m">
              <Flex flexDirection="column">
                <Text fontSize="m" mt="m">
                  Scheduled date and time
                </Text>
                <Flex flexDirection="column" mt="m">
                  {chooseTimeOptions.map(({ name, id }) => (
                    <Flex flexDirection="column" key={id}>
                      <Flex alignItems="center" mr="m" mb="s">
                        <Radio
                          id={id}
                          name="publishTimeBulkEditPostModal"
                          onChange={({ target: { id } }) => {
                            setWhenToPublish(id)
                          }}
                          value={whenToPublish}
                          checked={whenToPublish === id}
                        />
                        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                        <label htmlFor={id}>
                          <Text ml="m" pl="xs" mt="-2px" fontSize="s">
                            {name}
                          </Text>
                        </label>
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
                {whenToPublish === QUEUE && (
                  <Flex flexDirection="column">
                    {QUEUE_TIME.map(({ name, id }) => (
                      <Flex key={`bulkEditPostModal-${id}`} alignItems="center" mx="m" mb="s">
                        <Radio
                          id={`bulkEditPostModal-${id}`}
                          name="publishQueueTimeBulkEditPostModal"
                          onChange={({ target: { id } }) => {
                            setWhenToQueue(id.split('-')[1])
                          }}
                          value={whenToQueue}
                          checked={whenToQueue === id}
                        />
                        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                        <label htmlFor={`bulkEditPostModal-${id}`}>
                          <Text ml="m" pl="xs" mt="-2px" fontSize="m">
                            {name}
                          </Text>
                        </label>
                      </Flex>
                    ))}

                    {usedQueueLabels && usedQueueLabels.length > 1 && (
                      <Flex flexDirection="column" width="200px" ml="m">
                        <Text mb="xs">based on</Text>
                        <DropDown
                          onChange={(option) => {
                            if (featuresEnabled[FEATURE_QUEUE_LABELS].enabled) {
                              setSelectedQueueLabel({ ...option })
                            } else {
                              Alert.error(featuresEnabled[FEATURE_QUEUE_LABELS].description, {
                                timeout: 5000,
                              })
                            }
                          }}
                          value={
                            selectedQueueLabel
                              ? usedQueueLabels.find(({ value }) => value === selectedQueueLabel.value) ||
                                DEFAULT_QUEUE_LABEL
                              : DEFAULT_QUEUE_LABEL
                          }
                          options={usedQueueLabels}
                          width="100%"
                        />
                      </Flex>
                    )}
                  </Flex>
                )}
                {whenToPublish === SPECIFIC_TIME && (
                  <Flex flexDirection="column">
                    <Box>
                      <Datepicker
                        minDate={new Date()}
                        label="Date"
                        value={publishTime.date}
                        onChange={(value) => {
                          if (value) {
                            handleChangeSelectedDate({ type: 'date', value })
                          }
                        }}
                        isClearDateEnabled={false}
                      />
                    </Box>
                    <Flex mt="m" flexDirection="column" width="100%">
                      <Flex>
                        <Text>Time</Text>
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Flex alignItems="center">
                          <Box width="50px">
                            <StyledSelect
                              name="hours"
                              onChange={({ target: { value } }) => {
                                handleChangeSelectedDate({ type: 'hour', value })
                              }}
                              value={publishTime.hour}
                            >
                              {HOURS.map((hour) => (
                                <option key={hour} value={hour}>
                                  {hour}
                                </option>
                              ))}
                            </StyledSelect>
                          </Box>
                          <Text fontSize="l" mx="s">
                            :
                          </Text>
                          <Box width="50px">
                            <StyledSelect
                              name="minutes"
                              onChange={({ target: { value } }) => {
                                handleChangeSelectedDate({ type: 'minute', value })
                              }}
                              value={publishTime.minute}
                            >
                              {MINUTES.map((minute) => (
                                <option key={minute} value={minute}>
                                  {minute}
                                </option>
                              ))}
                            </StyledSelect>
                          </Box>
                        </Flex>
                        <Box ml="s" width="50px">
                          <StyledSelect
                            name="interval"
                            onChange={({ target: { value } }) => {
                              handleChangeSelectedDate({ type: 'interval', value })
                            }}
                            value={publishTime.interval}
                          >
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </StyledSelect>
                        </Box>
                        <Flex alignItems="center">
                          <Text ml="s">
                            {moment()
                              .tz(selectedTimezone)
                              .format('z')}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Box>
            <PostCreationProvider>
              {Object.keys(postForEdit).length !== 0 && (
                <Flex width="calc(100% - 256px)" height="100%">
                  <PostCreationComponent
                    user={user}
                    selectedProfiles={selectedProfiles}
                    selectedEntities={selectedEntities}
                    postForEdit={postForEdit}
                    dataForCustomizations={dataForCustomizations}
                    ref={postCreationComponentRef}
                    uploadingMediasAmount={uploadingMediasAmount}
                    setUploadingMediasAmount={setUploadingMediasAmount}
                    confirm={confirm}
                    postCreationComponentDataLoadingCallback={({ isLoading }) => {
                      if (isLoading !== postCreationComponentDataIsLoading) {
                        setPostCreationComponentDataIsLoading(isLoading)
                      }
                    }}
                  />
                </Flex>
              )}

              <SpecialMediaProcessingDataComponent ref={specialMediaProcessingDataComponentRef} />
            </PostCreationProvider>
          </StyledDialogBodyWrapper>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="flex-end" $isBottom>
            <Flex alignItems="center">
              <Button.Gray mr="m" onClick={handleClickCloseModal} isSmall>
                Cancel
              </Button.Gray>
              {postCreationComponentDataIsLoading ? (
                <Flex alignItems="center">
                  <Text fontSize="xs" mr="s">
                    Loading
                  </Text>
                  <SyncLoader size="3" color={colors.primary} />
                </Flex>
              ) : (
                <Button.Gradient isSmall onClick={handleClickUpdatePost}>
                  Save changes
                </Button.Gradient>
              )}
            </Flex>
          </StyledDialogEnvironmentWrapper>

          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

BulkEditPostModal.defaultProps = {
  postForEdit: {},
  dataForCustomizations: {},
  usedQueueLabels: [],
  whenToPublish: '',
  hasTimeDraftOption: true,
  hasTimeIdeaOption: true,
  selectedTimezone: DEFAULT_TIME_ZONE,
}

BulkEditPostModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  postForEdit: PropTypes.object,
  selectedProfiles: PropTypes.array.isRequired,
  selectedEntities: PropTypes.array.isRequired,
  selectedTimezone: PropTypes.string,
  handleUpdatePost: PropTypes.func.isRequired,
  dataForCustomizations: PropTypes.object,
  whenToPublish: PropTypes.string,
  usedQueueLabels: PropTypes.array,
  featuresEnabled: PropTypes.object.isRequired,
  hasTimeDraftOption: PropTypes.bool,
  hasTimeIdeaOption: PropTypes.bool,
  confirm: PropTypes.func.isRequired,
}

export default withConfirm(BulkEditPostModal)
