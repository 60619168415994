import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'
import SocialNetworksFilterBodyTemplate from './SocialNetworksFilterBodyTemplate'

const StyledArrowWrapper = styled(Flex)`
  border-radius: ${radius.pill};
  cursor: pointer;
  &:hover {
    background-color: ${COLOR_CONSTANTS.DAISY};
  }
`

const SocialNetworksFilter = styled(Flex)`
  position: absolute;
  z-index: 2147483001;
  top: 50px;
  left: ${({ left }) => left}px;
  right: ${({ right }) => right};
  max-width: 130%;
  min-width: 270px;
  width: 100%;
  padding: ${space.m};
  background-color: ${colors.white};
  box-shadow: 0px 4px 15px rgba(39, 40, 49, 0.1);
  right: auto;
  min-height: 100px;
  border-radius: ${radius.l};
`

const StyledSocialsNetworksFiltersCounterWrapper = styled(Flex)`
  position: absolute;
  bottom: 0;
  right: -6px;
  min-width: 16px;
  height: 16px;
  border-radius: ${radius.pill};
`

const SocialNetworksFilterTemplate = ({
  socialNetworkFilter,
  showInline,
  selectedEntities,
  selectedProfiles,
  setSocialNetworkFilter,
  handleClickOpenSocialNetworksFilterBody,
  entitySelectorRef,
}) => {
  const socialNetworksFilterRef = useRef(null)
  const [isSocialNetworksFilterOpen, setIsSocialNetworksFilterOpen] = useState(false)

  const handleClickOpenSocialNetworksFilter = () => {
    if (showInline) {
      handleClickOpenSocialNetworksFilterBody()
    } else {
      setIsSocialNetworksFilterOpen(!isSocialNetworksFilterOpen)
    }
  }

  const handleClickCloseSocialNetworksFilter = () => {
    setIsSocialNetworksFilterOpen(false)
  }

  const handleClickOutsideSocialNetworksFilter = (e) => {
    if (!showInline) {
      if (socialNetworksFilterRef.current) {
        if (!socialNetworksFilterRef.current.contains(e.target)) {
          handleClickCloseSocialNetworksFilter()
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideSocialNetworksFilter, false)
    return () => {
      window.removeEventListener('mousedown', handleClickOutsideSocialNetworksFilter, false)
    }
  }, [])

  const numberOfSocialNetworksFilters =
    (socialNetworkFilter.socialNetworkFilterName ? 1 : 0) +
    (socialNetworkFilter.socialNetworkFilterSelectedProfiles
      ? socialNetworkFilter.socialNetworkFilterSelectedProfiles.length
      : 0)

  return (
    <Flex alignItems="center">
      <StyledArrowWrapper
        alignItems="center"
        justifyContent="center"
        width="32px"
        height="32px"
        onClick={handleClickOpenSocialNetworksFilter}
        position="relative"
      >
        <Image src="/assets/vistasocial/filter.svg" width="18px" height="18px" />
        {numberOfSocialNetworksFilters > 0 && (
          <StyledSocialsNetworksFiltersCounterWrapper>
            <Text as="span" color={colors.primary} fontSize="9px" fontWeight="bold">
              {numberOfSocialNetworksFilters}
            </Text>
          </StyledSocialsNetworksFiltersCounterWrapper>
        )}
      </StyledArrowWrapper>
      {isSocialNetworksFilterOpen && (
        <SocialNetworksFilter ref={socialNetworksFilterRef} flexDirection="column" left={showInline ? 0 : 40}>
          <SocialNetworksFilterBodyTemplate
            selectedEntities={selectedEntities}
            selectedProfiles={selectedProfiles}
            setSocialNetworkFilter={setSocialNetworkFilter}
            socialNetworkFilter={socialNetworkFilter}
            entitySelectorRef={entitySelectorRef}
          />
        </SocialNetworksFilter>
      )}
    </Flex>
  )
}

SocialNetworksFilterTemplate.defaultProps = {
  showInline: true,
  handleClickOpenSocialNetworksFilterBody: () => {},
  entitySelectorRef: null,
  selectedProfiles: [],
}

SocialNetworksFilterTemplate.propTypes = {
  selectedEntities: PropTypes.array.isRequired,
  selectedProfiles: PropTypes.array,
  socialNetworkFilter: PropTypes.object.isRequired,
  setSocialNetworkFilter: PropTypes.func.isRequired,
  showInline: PropTypes.bool,
  handleClickOpenSocialNetworksFilterBody: PropTypes.func,
  entitySelectorRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
}

export default SocialNetworksFilterTemplate
