import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { COLOR_CONSTANTS } from 'theme'
import { DEFAULT_SEARCH_PROMPT_TEXT } from 'consts'
import { pxToRem } from 'helpers'
import { Flex, Box } from 'components/atoms/Layout'
import Input from 'components/atoms/Input'
import DropDown from 'shared/DropDown'

import {
  PROFILE,
  INCLUDE_AUDIENCES,
  EXCLUDE_AUDIENCES,
  INTERESTS,
  WORK_POSITIONS,
  COUNTRIES,
  REGIONS,
  CITIES,
  GENDERS,
  AGE_MIN,
  AGE_MAX,
  ACCOUNT,
} from '../consts'

const DEFAULT_HEIGHT = pxToRem(40)

const FacebookConfiguration = ({ values, setFieldValue, errors, touched, formDataProfile, loadSuggestedOptions }) => {
  let audiences = []

  if (values[ACCOUNT]) {
    audiences = formDataProfile[values[ACCOUNT].value] ? formDataProfile[values[ACCOUNT].value].audiences : []
  }

  return (
    <Fragment>
      <Box mt="m">
        <DropDown
          label="Included audiences"
          placeholder="Select audiences"
          value={values[INCLUDE_AUDIENCES]}
          onChange={(options) => {
            setFieldValue(INCLUDE_AUDIENCES, options || [])
          }}
          options={audiences.filter(
            ({ value }) => !values[EXCLUDE_AUDIENCES] || !values[EXCLUDE_AUDIENCES].find((item) => item.value === value)
          )}
          openMenuOnFocus
          isMulti
          height={DEFAULT_HEIGHT}
          error={errors[INCLUDE_AUDIENCES] && touched[INCLUDE_AUDIENCES] && errors[INCLUDE_AUDIENCES]}
        />
      </Box>

      <Box mt="m">
        <DropDown
          label="Excluded audiences"
          placeholder="Select audiences"
          value={values[EXCLUDE_AUDIENCES]}
          onChange={(options) => {
            setFieldValue(EXCLUDE_AUDIENCES, options || [])
          }}
          options={audiences.filter(
            ({ value }) => !values[INCLUDE_AUDIENCES] || !values[INCLUDE_AUDIENCES].find((item) => item.value === value)
          )}
          openMenuOnFocus
          isMulti
          height={DEFAULT_HEIGHT}
          error={errors[EXCLUDE_AUDIENCES] && touched[EXCLUDE_AUDIENCES] && errors[EXCLUDE_AUDIENCES]}
        />
      </Box>

      <Box mt="m">
        <DropDown
          label="Interests"
          placeholder="Search for interests"
          value={values[INTERESTS]}
          height={DEFAULT_HEIGHT}
          onChange={(options) => {
            setFieldValue(INTERESTS, options || [])
          }}
          cacheOptions
          loadOptions={(value, callback) =>
            loadSuggestedOptions(
              {
                value,
                network: values[PROFILE].network.code,
                id: values[PROFILE].value,
                method: 'searchInterests',
              },
              callback
            )
          }
          defaultValue={[]}
          isMulti
          isClearable
          // defaultOptions={formDataProfile[INTERESTS] || []}
          openMenuOnFocus
          dropDownType="AsyncSelect"
          showDropDownArrow={false}
          noOptionsMessage={({ inputValue }) => {
            return inputValue ? 'No results found' : DEFAULT_SEARCH_PROMPT_TEXT
          }}
          error={errors[INTERESTS] && touched[INTERESTS] && errors[INTERESTS]}
        />
      </Box>

      <Box mt="m">
        <DropDown
          label="Work positions"
          placeholder="Search for work positions"
          value={values[WORK_POSITIONS]}
          height={DEFAULT_HEIGHT}
          onChange={(options) => {
            setFieldValue(WORK_POSITIONS, options || [])
          }}
          cacheOptions
          loadOptions={(value, callback) =>
            loadSuggestedOptions(
              {
                value,
                network: values[PROFILE].network.code,
                id: values[PROFILE].value,
                method: 'searchWorkPositions',
              },
              callback
            )
          }
          defaultValue={[]}
          isMulti
          isClearable
          //  defaultOptions={formDataProfile[WORK_POSITIONS] || []}
          openMenuOnFocus
          dropDownType="AsyncSelect"
          showDropDownArrow={false}
          noOptionsMessage={({ inputValue }) => {
            return inputValue ? 'No results found' : DEFAULT_SEARCH_PROMPT_TEXT
          }}
          error={errors[WORK_POSITIONS] && touched[WORK_POSITIONS] && errors[WORK_POSITIONS]}
        />
      </Box>

      <Box mt="m">
        <DropDown
          label="Countries"
          placeholder="Search for countries"
          value={values[COUNTRIES]}
          height={DEFAULT_HEIGHT}
          onChange={(options) => {
            setFieldValue(COUNTRIES, options || [])
          }}
          cacheOptions
          loadOptions={(value, callback) =>
            loadSuggestedOptions(
              {
                value,
                network: values[PROFILE].network.code,
                id: values[PROFILE].value,
                method: 'searchCountries',
              },
              callback
            )
          }
          defaultValue={[]}
          isMulti
          isClearable
          // defaultOptions={formDataProfile[COUNTRIES] || []}
          openMenuOnFocus
          dropDownType="AsyncSelect"
          showDropDownArrow={false}
          noOptionsMessage={({ inputValue }) => {
            return inputValue ? 'No results found' : DEFAULT_SEARCH_PROMPT_TEXT
          }}
          menuPlacement="top"
          error={errors[COUNTRIES] && touched[COUNTRIES] && errors[COUNTRIES]}
        />
      </Box>

      {values[COUNTRIES] && values[COUNTRIES].length > 0 && (
        <Box mt="m">
          <DropDown
            label="Regions"
            placeholder="Search for regions"
            value={values[REGIONS]}
            height={DEFAULT_HEIGHT}
            onChange={(options) => {
              setFieldValue(REGIONS, options || [])
            }}
            cacheOptions
            loadOptions={(value, callback) =>
              loadSuggestedOptions(
                {
                  value,
                  network: values[PROFILE].network.code,
                  id: values[PROFILE].value,
                  method: 'searchRegions',
                  countries:
                    values[COUNTRIES] && values[COUNTRIES].length > 0
                      ? values[COUNTRIES].map(({ value }) => value).join(',')
                      : null,
                },
                callback
              )
            }
            defaultValue={[]}
            isMulti
            isClearable
            // defaultOptions={formDataProfile[REGIONS] || []}
            openMenuOnFocus
            dropDownType="AsyncSelect"
            showDropDownArrow={false}
            noOptionsMessage={({ inputValue }) => {
              return inputValue ? 'No results found' : DEFAULT_SEARCH_PROMPT_TEXT
            }}
            menuPlacement="top"
            error={errors[REGIONS] && touched[REGIONS] && errors[REGIONS]}
          />
        </Box>
      )}

      <Box mt="m">
        <DropDown
          label="Cities"
          placeholder="Search for cities"
          value={values[CITIES]}
          height={DEFAULT_HEIGHT}
          onChange={(options) => {
            setFieldValue(CITIES, options || [])
          }}
          cacheOptions
          loadOptions={(value, callback) =>
            loadSuggestedOptions(
              {
                value,
                network: values[PROFILE].network.code,
                id: values[PROFILE].value,
                method: 'searchCities',
                regions:
                  values[REGIONS] && values[REGIONS].length > 0
                    ? values[REGIONS].map(({ value }) => value).join(',')
                    : null,
              },
              callback
            )
          }
          defaultValue={[]}
          isMulti
          isClearable
          // defaultOptions={formDataProfile[CITIES] || []}
          openMenuOnFocus
          dropDownType="AsyncSelect"
          showDropDownArrow={false}
          noOptionsMessage={({ inputValue }) => {
            return inputValue ? 'No results found' : DEFAULT_SEARCH_PROMPT_TEXT
          }}
          menuPlacement="top"
          error={errors[CITIES] && touched[CITIES] && errors[CITIES]}
        />
      </Box>

      <Box mt="m">
        <DropDown
          label="Genders"
          placeholder="Select genders"
          value={values[GENDERS]}
          onChange={(options) => {
            setFieldValue(GENDERS, options || [])
          }}
          options={formDataProfile[GENDERS] || []}
          openMenuOnFocus
          isMulti
          height={DEFAULT_HEIGHT}
          error={errors[GENDERS] && touched[GENDERS] && errors[GENDERS]}
        />
      </Box>

      <Flex mt="m" alignItems="center" width="100%">
        <Flex width="100%" justifyContent="space-between">
          <Flex flexDirection="column" width="49%">
            <Input
              placeholder="Min age"
              label="Min age"
              value={values[AGE_MIN]}
              onChange={(e) => {
                let { value } = e.target
                if (value) {
                  value = Number(value)
                }
                if (value >= 0 && value <= 99) {
                  setFieldValue(AGE_MIN, value)
                }
              }}
              type="number"
              width="100%"
              min={0}
              max={99}
              color={COLOR_CONSTANTS.DENIM}
              error={errors[AGE_MIN] && touched[AGE_MIN] && errors[AGE_MIN]}
            />
          </Flex>
          <Flex flexDirection="column" width="49%">
            <Input
              placeholder="Max age"
              label="Max age"
              value={values[AGE_MAX]}
              onChange={(e) => {
                let { value } = e.target
                if (value) {
                  value = Number(value)
                }
                if (value >= 0 && value <= 99) {
                  setFieldValue(AGE_MAX, value)
                }
              }}
              type="number"
              width="100%"
              min={0}
              max={99}
              color={COLOR_CONSTANTS.DENIM}
              error={errors[AGE_MAX] && touched[AGE_MAX] && errors[AGE_MAX]}
            />
          </Flex>
        </Flex>
      </Flex>
    </Fragment>
  )
}

FacebookConfiguration.defaultProps = {
  formDataProfile: {},
}

FacebookConfiguration.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  formDataProfile: PropTypes.object,
  loadSuggestedOptions: PropTypes.func.isRequired,
}

FacebookConfiguration.displayName = 'FacebookConfiguration'

export default FacebookConfiguration
