import React, { useState, useRef, forwardRef, Fragment, useImperativeHandle } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { MentionsInput, Mention } from 'react-mentions'
import { fontSizes, COLOR_CONSTANTS, radius, colors } from 'theme'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'

const StyledMentionsInput = styled(MentionsInput)`
  width: 100%;
  font-size: ${fontSizes.s};
  resize: none;
  outline: none;
  border-width: 0;
  ::placeholder {
    color: ${COLOR_CONSTANTS.SAPPHIRE};
    font-size: ${fontSizes.s};
  }
  ${({ isNote }) => `background-color: ${isNote ? COLOR_CONSTANTS.CARROT : COLOR_CONSTANTS.WHITE}`};
  border: none;
`

const StyledFlex = styled(Flex)`
  cursor: pointer;
`

const ProfileImage = styled(ImageWithFallback)`
  border-radius: ${radius.pill};
  vertical-align: inherit;
`

const DEFAULT_SOURCE = '/assets/avatar.svg'

const InputComponent = forwardRef(({ users, comment, minHeight, className, placeholder }, styledInputCommentRef) => {
  const textAreaRef = useRef(null)
  const [textAreaValue, setTextAreaValue] = useState(comment)
  const [focused, setFocused] = useState(false)
  const [isEnterBlocked, setIsEnterBlocked] = useState(false)

  useImperativeHandle(styledInputCommentRef, () => ({
    setCommentTextAreaValue(text) {
      setTextAreaValue(text)
    },
    getCommentTextAreaValue() {
      return textAreaValue
    },
    getFocus() {
      return focused
    },
    setFocus() {
      textAreaRef.current.selectionStart = textAreaValue.length

      textAreaRef.current.focus()
    },
    getEnterState() {
      return isEnterBlocked
    },
    unblockEnterState() {
      setIsEnterBlocked(false)
    },
  }))

  const getUsers = async (query = '', callback) => {
    if (query) {
      return callback(users.filter(({ display }) => display.toLowerCase().includes(query.toLowerCase())))
    }
    return callback(users)
  }

  return (
    <Fragment>
      <StyledMentionsInput
        id="styled-mentions-input-comment"
        value={textAreaValue}
        onChange={(e) => {
          if (!focused) {
            setFocused(true)
          }
          setTextAreaValue(e.target.value)
        }}
        rows={5}
        inputRef={textAreaRef}
        placeholder={placeholder}
        allowSuggestionsAboveCursor
        className={className || 'inbox-mention-textarea-note'}
        style={{
          input: {
            overflow: 'auto',
            height: 'auto',
          },
          highlighter: {
            boxSizing: 'border-box',
            overflow: 'hidden',
            height: 'auto',
            maxHeight: 115,
            minHeight,
            fontSize: fontSizes.xs,
          },
          suggestions: {
            list: {
              backgroundColor: 'white',
              border: `1px solid ${COLOR_CONSTANTS.COTTON}`,
              fontSize: 14,
              maxHeight: 400,
              overflowY: 'auto',
            },
            item: {
              borderBottom: `1px solid ${COLOR_CONSTANTS.COTTON}`,
              color: colors.primaryText,
              '&focused': {
                backgroundColor: COLOR_CONSTANTS.DAISY,
              },
            },
          },
        }}
        onBlur={() => {
          if (focused) {
            setFocused(false)
          }
        }}
      >
        <Mention
          trigger="@"
          data={getUsers}
          appendSpaceOnAdd
          className="inbox-suggestions inbox-mention"
          renderSuggestion={(entry, search, highlightedDisplay) => {
            return (
              <Flex alignItems="center" p="s">
                <StyledFlex width="24px" height="24px" justifyContent="center" alignItems="center" position="relative">
                  <ProfileImage
                    width="24px"
                    height="24px"
                    source={entry.picture_url || DEFAULT_SOURCE}
                    fallbackSource={DEFAULT_SOURCE}
                  />
                </StyledFlex>
                <Flex flexDirection="column" ml="s">
                  <Text>{highlightedDisplay}</Text>
                </Flex>
              </Flex>
            )
          }}
          onAdd={() => {
            setIsEnterBlocked(true)
            setTimeout(() => {
              setIsEnterBlocked(false)
            }, 300)
          }}
        />
      </StyledMentionsInput>
    </Fragment>
  )
})

InputComponent.defaultProps = {
  comment: '',
  minHeight: 88,
  className: '',
  placeholder: 'Your comment (only viewable to team members) ...',
}

InputComponent.propTypes = {
  users: PropTypes.array.isRequired,
  comment: PropTypes.string,
  minHeight: PropTypes.number,
  className: PropTypes.string,
  placeholder: PropTypes.string,
}

export default InputComponent
