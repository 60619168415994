import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { radius } from 'theme'
import { Box, Flex } from 'components/atoms/Layout'

const StyledBox = styled(Box)`
  box-shadow: 0px 4px 10px rgba(39, 40, 49, 0.03);
`

const Card = ({ children, pt, pb, px, className }) => (
  <StyledBox className={className}>
    <Flex pt={pt} pb={pb} px={px} bg="white" flexDirection="column" borderRadius={radius.l}>
      {children}
    </Flex>
  </StyledBox>
)

Card.defaultProps = {
  pt: 'm',
  pb: 'xxl',
  px: 'l',
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  pt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  px: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  className: PropTypes.node,
}

export default Card
