import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { transparentize } from 'polished'
import Alert from 'react-s-alert'
import { space as styledSpace } from 'styled-system'
import { Scrollbars } from 'react-custom-scrollbars-2'
import debounce from 'lodash.debounce'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import { VIDEO, IMAGE, ERROR_MESSAGE } from 'consts'
import { isValidInstagramHashtag, isValidInstagramUsername } from 'helpers'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { H4, Text } from 'components/atoms/Typography'
import { Flex, Box, Grid } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Icon from 'components/atoms/Icon'
import Input from 'components/atoms/Input'
import Image from 'components/atoms/Image'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import NoData from 'components/molecules/NoData'
import ErrorLabel from 'components/atoms/ErrorLabel'
import {
  POST_IMAGE,
  POST_VIDEO,
  ROUTE_MEDIA,
  MAX_IMAGES_ATTACHMENTS_ALLOWED,
  MAX_VIDEOS_ATTACHMENTS_ALLOWED,
  INSTAGRAM_REG_EXP,
  TABS_TYPE_IMAGE_UNSPLASH,
  TABS_TYPE_IMAGE_PEXELS,
  TABS_TYPE_IMAGE_GIPHY,
  TABS_TYPE_IMAGE_HASHTAGS,
  TABS_TYPE_IMAGE_USERS,
  TABS_TYPE_VIDEO_PEXELS,
  TABS_TYPE_VIDEO_HASHTAGS,
  TABS_TYPE_VIDEO_USERS,
} from '../consts'
import CalendarItemTooltip from './CalendarItemTooltip'
import MediaPreviewComponent from '../../Media/components/MediaPreviewComponent'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    position: relative;
    max-width: 900px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  background-color: ${COLOR_CONSTANTS.BOYSENBERRY_SHADOW};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  height: 100%;
  overflow: hidden;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Tab = styled(Box)`
  padding: ${space.s} 0;
  margin-left: ${space.s};
  margin-right: ${space.m};
  text-decoration: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    `
    border-bottom: 2px solid ${colors.primary};
  `}
  &:visited {
    color: initial;
  }
  &:hover {
    border-bottom: 2px solid ${({ isActive }) => (isActive ? colors.primary : colors.secondaryText)};
  }
`

const SelectWrapper = styled(Flex)`
  border-radius: ${radius.pill};
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? colors.primary : transparentize(0.2, COLOR_CONSTANTS.SALT))};
  border: 2px solid ${({ selected }) => (selected ? colors.primary : COLOR_CONSTANTS.COSMIC_ENERGY)};
`

const MediaItemWrapper = styled(Flex)`
  width: 200px;
  position: relative;
  border-radius: ${radius.l};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  margin: 0 ${space.s} ${space.s};
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 10px 5px rgb(39 40 49 / 5%);
  }
  ${({ disabled }) => disabled && `opacity:0.6;`}
  ${({ selected }) => selected && `box-shadow: 0px 0px 5px 0px rgb(145 147 169 / 50%);`}
    &:hover {
    svg {
      fill: ${COLOR_CONSTANTS.WHITE};
    }
    ${SelectWrapper} {
      border-color: ${COLOR_CONSTANTS.WHITE};
    }
  }
`

const StyledImage = styled(ImageWithFallback)`
  width: 100%;
  height: 100%;
`

const StyledIconCheckmark = styled(Icon.Checkmark)`
  color: ${({ selected }) => (selected ? colors.white : colors.secondaryText)};
`

const StyledDialogEnvironmentSelectedMediasWrapper = styled(Flex)`
  overflow-x: auto;
  overflow-y: hidden;
`

const StyledSelectedMediasWrapper = styled(Box)`
  background-color: ${COLOR_CONSTANTS.WHITE};
  &:hover {
    div {
      display: flex;
    }
  }
`

const SelectedMediasImage = styled(ImageWithFallback)`
  width: 24px;
  height: 24px;
  border-radius: ${radius.m};
  vertical-align: inherit;
`

const StyledDismissWrapper = styled(Flex)`
  display: none;
  cursor: pointer;
  background: #000;
  z-index: 999;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
`

const StyledSearchForMediasButtonWithLoading = styled(ButtonWithLoading)`
  min-width: 125px;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`

const StyledInputSearch = styled(Input)`
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
`

const StyledText = styled(Text)`
  text-decoration: none;
`

const StyledUsernameText = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
`

const MediaWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  border-top-left-radius: ${radius.l};
  border-top-right-radius: ${radius.l};
  transition: all 0.5s ease-in-out;
  transform: scale(1);
  ${({ selected }) =>
    selected &&
    `border-bottom-right-radius: ${radius.l};  border-bottom-left-radius: ${radius.l}; transform: scale(0.8);`}
`

const AVAILABLE_TABS = [
  {
    name: 'Unsplash',
    show: POST_IMAGE,
    system: TABS_TYPE_IMAGE_UNSPLASH,
    placeholder: 'Search for images on Unsplash',
    isActive: true,
  },
  {
    name: 'Pexels',
    show: POST_IMAGE,
    system: TABS_TYPE_IMAGE_PEXELS,
    placeholder: 'Search for images on Pexels',
    // powered_by: '/assets/poweredByPexels.png',
    isActive: true,
  },
  {
    name: 'Giphy',
    show: POST_IMAGE,
    system: TABS_TYPE_IMAGE_GIPHY,
    placeholder: `Search for GIFs on Giphy`,
    //   powered_by: '/assets/poweredByGiphy.png',
    isActive: true,
  },
  {
    name: '# Hashtags',
    show: POST_IMAGE,
    system: TABS_TYPE_IMAGE_HASHTAGS,
    placeholder: `Search for recent images for a given Instagram hashtag`,
    isActive: true,
  },
  {
    name: '@ Users',
    show: POST_IMAGE,
    system: TABS_TYPE_IMAGE_USERS,
    placeholder: `Search for recent images for a given Instagram business user`,
    isActive: true,
  },
  {
    name: 'Pexels',
    show: POST_VIDEO,
    system: TABS_TYPE_VIDEO_PEXELS,
    placeholder: 'Search for videos on Pexels',
    // powered_by: '/assets/poweredByPexels.png',
    isActive: true,
  },
  {
    name: '# Hashtags',
    show: POST_VIDEO,
    system: TABS_TYPE_VIDEO_HASHTAGS,
    placeholder: `Search for recent videos for a given Instagram hashtag`,
    isActive: true,
  },
  {
    name: '@ Users',
    show: POST_VIDEO,
    system: TABS_TYPE_VIDEO_USERS,
    placeholder: `Search for videos on Instagram by user`,
    isActive: true,
  },
]

const MediaDiscoverModal = ({
  isOpen,
  handleDismiss,
  handleClickAddMedias,
  type = POST_IMAGE,
  postMedias,
  maxImageAttachmentsAllowed,
  maxVideoAttachmentsAllowed,
  maxMixedMediaAttachmentsAllowed,
  replaceMediasWithoutLimitError,
}) => {
  const TABS = AVAILABLE_TABS.filter((tab) => tab.show === type && tab.isActive)
  const firstTab = TABS[0] || {}
  const searchType = type === POST_IMAGE ? IMAGE : VIDEO

  const searchInputRef = useRef(null)
  const tooltipRef = useRef(null)
  const [activeTab, setActiveTab] = useState(firstTab)
  const [searchedMedias, setSearchedMedias] = useState([])
  const [isSearchingForMedia, setIsSearchingForMedia] = useState(false)
  const [searchedForMedia, setSearchedForMedia] = useState(false)
  const [selectedMedias, setSelectedMedias] = useState(postMedias)
  const [isUploadingMedia, setIsUploadingMedia] = useState(false)
  const [error, setError] = useState('')

  const handleClickFindMedia = async () => {
    let value = ''

    if (searchInputRef && searchInputRef.current) {
      // eslint-disable-next-line prefer-destructuring
      value = searchInputRef.current.value
    }

    let error = ''

    const updatedValue = value.replace(INSTAGRAM_REG_EXP, '').trim()

    let requiresSearchValue = false

    let path = `${ROUTE_MEDIA}/search?q=${updatedValue}&system=${activeTab.system}&type=${searchType}`

    if (activeTab.system === TABS_TYPE_IMAGE_HASHTAGS || activeTab.system === TABS_TYPE_VIDEO_HASHTAGS) {
      if (!isValidInstagramHashtag(`#${updatedValue}`)) {
        error = `Please enter a valid hashtag`
      }

      requiresSearchValue = true

      path = `instagram/hashtag/recent?q=${updatedValue}&type=${searchType}`
    } else if (activeTab.system === TABS_TYPE_IMAGE_USERS || activeTab.system === TABS_TYPE_VIDEO_USERS) {
      if (!isValidInstagramUsername(`@${updatedValue}`)) {
        error = `Please enter a valid Instagram username`
      }

      requiresSearchValue = true

      path = `instagram/user/recent?q=${updatedValue}&type=${searchType}`
    }

    if (!updatedValue && requiresSearchValue) {
      error = 'Please enter search criteria'
    }

    if (!error) {
      try {
        setIsSearchingForMedia(true)
        const response = await request({
          path,
        })
        const { error: responseError, data = [] } = response

        if (!response || responseError) {
          error = responseError || ERROR_MESSAGE
          setSearchedForMedia(false)
        } else {
          setSearchedForMedia(true)
          setSearchedMedias([...data])
        }
      } catch (e) {
        errorHelper({
          error: e,
          componentName: MediaDiscoverModal.displayName,
          functionName: 'handleClickFindMedia',
          showAlert: false,
          sendError: false,
        })
        error = `Can't find anything. Please try again.`
        setSearchedMedias([])
        setSearchedForMedia(false)
      } finally {
        setIsSearchingForMedia(false)
      }
      setError(error)
    } else {
      setError(error)
    }
  }

  useEffect(() => {
    setActiveTab(firstTab)
    setSearchedMedias([])
    setIsUploadingMedia(false)
    setError('')
    setSelectedMedias([...postMedias])
  }, [])

  useEffect(() => {
    if (activeTab) {
      setError('')
      handleClickFindMedia()
      setSearchedMedias([])
    }
  }, [activeTab])

  const handleClickSelectMedia = (item) => {
    let maxAttachments
    let maxAttachmentsErrorText

    if (activeTab.show === POST_IMAGE) {
      maxAttachments = maxImageAttachmentsAllowed || MAX_IMAGES_ATTACHMENTS_ALLOWED

      if (maxAttachments === 1) {
        maxAttachmentsErrorText = `Only one image can be attached!`
      } else {
        maxAttachmentsErrorText = `You have reached the limit of ${maxAttachments} attachments!`
      }
    } else if (activeTab.show === POST_VIDEO) {
      maxAttachments = maxVideoAttachmentsAllowed || MAX_VIDEOS_ATTACHMENTS_ALLOWED
      if (maxAttachments === 1) {
        maxAttachmentsErrorText = `Only one video can be attached!`
      } else {
        maxAttachmentsErrorText = `You have reached the limit of ${maxAttachments} ${
          maxAttachments === 1 ? 'attachment' : 'attachments'
        }!`
      }
    }

    const key = item.id ? 'id' : 'tempId'
    const foundMediaIndex = selectedMedias.findIndex((media) => item[key] === media[key])
    if (foundMediaIndex > -1) {
      selectedMedias.splice(foundMediaIndex, 1)
      setSelectedMedias([...selectedMedias])
    } else if (selectedMedias.length < maxAttachments) {
      selectedMedias.push(item)
      setSelectedMedias([...selectedMedias])
    } else if (replaceMediasWithoutLimitError) {
      let selectedMediasTemp = [...selectedMedias, item]
      const selectedMediasUpdated = []

      while (selectedMediasTemp.length > 0) {
        const temp = selectedMediasTemp.slice(0, maxAttachments)
        temp.forEach((item, index) => {
          selectedMediasUpdated[index] = item
        })
        selectedMediasTemp = selectedMediasTemp.slice(maxAttachments)
      }
      setSelectedMedias([...selectedMediasUpdated])
    } else {
      Alert.warning(maxAttachmentsErrorText)
    }
  }

  const handleMouseOver = ({ type, url, description, labels, entities, meta, currentTarget, defaultXPosition }) => {
    tooltipRef.current.handleShowTooltip({
      contentComp: (
        <MediaPreviewComponent
          type={type}
          url={url}
          description={description}
          labels={labels}
          entities={entities}
          meta={meta}
        />
      ),
      wrapperComp: currentTarget,
      defaultXPosition,
    })
  }

  const debouncedMouseOver = debounce(handleMouseOver, 200)

  const handleClickCloseModal = () => {
    if (!isUploadingMedia) {
      handleDismiss()
    }
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" alignItems="center" py="m" $isTop>
            <H4>Discover {searchType}s</H4>

            <Text>{selectedMedias.length} selected</Text>

            {/* {activeTab.powered_by && <Image heigth="50px" maxWidth="150px" src={activeTab.powered_by} ml="s" />} */}
          </StyledDialogEnvironmentWrapper>
          <StyledDialogBodyWrapper flex="1" flexDirection="column">
            <Flex my="s" px="s">
              {TABS.map((item) => {
                const { name, system: tabSystem } = item
                const { system: activeTabSystem } = activeTab || {}
                return (
                  <Tab
                    key={name}
                    onClick={() => {
                      setActiveTab(item)
                    }}
                    isActive={activeTabSystem && tabSystem === activeTabSystem}
                  >
                    <Text
                      color={activeTabSystem && tabSystem === activeTabSystem ? colors.primary : colors.secondaryText}
                      fontSize="m"
                    >
                      {name}
                    </Text>
                  </Tab>
                )
              })}
            </Flex>

            <Flex flexDirection="column" p="m" bg={COLOR_CONSTANTS.DAISY}>
              <Flex alignItems="center" justifyContent="space-between">
                <Flex flexDirection="column" width="100%">
                  <StyledInputSearch
                    innerRef={searchInputRef}
                    placeholder={activeTab.placeholder}
                    label=""
                    onChange={({ target: { value } }) => {
                      searchInputRef.current.value = value.replace(INSTAGRAM_REG_EXP, '')
                    }}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13 && !isSearchingForMedia) {
                        handleClickFindMedia()
                      }
                    }}
                    width="100%"
                    isSmall
                  />
                </Flex>
                <StyledSearchForMediasButtonWithLoading
                  buttonComp={Button.Gray}
                  loaderColor={COLOR_CONSTANTS.SALUTE}
                  height="34px"
                  isLoading={isSearchingForMedia}
                  onClick={() => {
                    if (!isSearchingForMedia) {
                      handleClickFindMedia()
                    }
                  }}
                >
                  <Text fontSize="s">{isSearchingForMedia ? 'Searching' : 'Search'}</Text>
                </StyledSearchForMediasButtonWithLoading>
              </Flex>
              {error && <ErrorLabel mt="xxs" textAlign="left" error={error} />}
            </Flex>

            <Flex width="100%" height="100%">
              {searchedMedias.length === 0 ? (
                <Flex
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  px="m"
                  bg={COLOR_CONSTANTS.DAISY}
                >
                  <NoData
                    showHeaderText={false}
                    message={searchedForMedia ? 'No recent media found! Only media from last 24 hours are shown.' : ''}
                    image="/assets/noResultsIllustration.svg"
                  />
                </Flex>
              ) : (
                <Flex width="100%" height="100%" px="s" bg={COLOR_CONSTANTS.DAISY}>
                  <Scrollbars universal style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Grid gridTemplateColumns="repeat(4, 1fr)">
                      {searchedMedias.map((item, index) => {
                        const {
                          url,
                          tempId,
                          thumbnail_url,
                          username_link = '',
                          username,
                          link,
                          published,
                          description,
                        } = item
                        const selected = selectedMedias.find((media) => media.tempId === tempId)
                        const column = Math.floor((index - Math.floor(index / 4) * 4) / 2)
                        const meta =
                          username || published
                            ? `${username ? `Published by ${username} ` : ''}${published || ''}`
                            : ''
                        return (
                          <MediaItemWrapper key={tempId} flexDirection="column" selected={selected}>
                            <Flex
                              flexDirection="column"
                              width="100%"
                              onClick={() => {
                                if (searchType === IMAGE) {
                                  handleClickSelectMedia(item)
                                }
                              }}
                            >
                              <Flex alignItems="center" justifyContent="center" width="100%">
                                <Flex alignItems="center" justifyContent="center" width="100%">
                                  <Flex
                                    width="100%"
                                    height="150px"
                                    alignItems="center"
                                    justifyContent="center"
                                    onMouseEnter={(e) => {
                                      if (url) {
                                        debouncedMouseOver({
                                          url,
                                          description,
                                          currentTarget: e.currentTarget,
                                          defaultXPosition: column === 0 ? 'right' : 'left',
                                          type: activeTab.show === POST_IMAGE ? IMAGE : VIDEO,
                                          meta,
                                        })
                                      }
                                    }}
                                    onMouseLeave={() => {
                                      debouncedMouseOver.cancel()
                                      if (url) {
                                        tooltipRef.current.handleHideTooltip()
                                      }
                                    }}
                                  >
                                    <MediaWrapper selected={selected}>
                                      {activeTab.show === POST_VIDEO ? (
                                        <VideoWithFallback
                                          url={url}
                                          controls
                                          width="100%"
                                          style={{ position: 'relative' }}
                                          height="150px"
                                          config={{
                                            file: {
                                              attributes: {
                                                poster: thumbnail_url || '',
                                              },
                                            },
                                          }}
                                        />
                                      ) : (
                                        <StyledImage
                                          source={url}
                                          selected={selected}
                                          fallbackSource="/assets/landscape.svg"
                                          fallbackSourceWidth="50%"
                                          fallbackSourceHeight="50%"
                                        />
                                      )}
                                    </MediaWrapper>
                                  </Flex>
                                  <Box
                                    position="absolute"
                                    top={space.xs}
                                    left={space.xs}
                                    zIndex="2"
                                    width="100%"
                                    onClick={(event) => {
                                      event.stopPropagation()
                                      handleClickSelectMedia(item)
                                    }}
                                  >
                                    <SelectWrapper alignItems="center" justifyContent="center" selected={selected}>
                                      <StyledIconCheckmark selected={selected} width="10px" height="10px" />
                                    </SelectWrapper>
                                  </Box>
                                </Flex>
                              </Flex>
                            </Flex>
                            {(username || published) && (
                              <Flex mt="xs" alignItems="center" justifyContent="space-between" width="100%">
                                <Flex width={username ? '110px' : 0} alignItems="center">
                                  <StyledUsernameText
                                    color="secondaryText"
                                    fontSize="xxs"
                                    as={username_link ? 'a' : 'span'}
                                    href={username_link || ''}
                                    target="_blank"
                                  >
                                    {username}
                                  </StyledUsernameText>
                                </Flex>
                                <StyledText
                                  color="secondaryText"
                                  fontSize="xxs"
                                  as={link ? 'a' : 'span'}
                                  href={link || ''}
                                  target="_blank"
                                >
                                  {published}
                                </StyledText>
                              </Flex>
                            )}
                          </MediaItemWrapper>
                        )
                      })}
                    </Grid>
                  </Scrollbars>
                </Flex>
              )}
            </Flex>
          </StyledDialogBodyWrapper>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
            <Text fontSize="m">Selected {searchType}s</Text>
            <Flex alignItems="center" mx="m" flex="1" maxWidth="500px">
              <StyledDialogEnvironmentSelectedMediasWrapper>
                {selectedMedias.map((item) => {
                  const { id, url, tempId } = item
                  return (
                    <Flex alignItems="center" key={id || tempId} mr="m">
                      <StyledSelectedMediasWrapper
                        width="24px"
                        height="24px"
                        justifyContent="center"
                        alignItems="center"
                        position="relative"
                      >
                        {activeTab.show === POST_VIDEO ? (
                          <VideoWithFallback
                            url={url}
                            controls={false}
                            width="100%"
                            style={{ position: 'relative' }}
                            height="100%"
                          />
                        ) : (
                          <SelectedMediasImage source={url} />
                        )}

                        <StyledDismissWrapper
                          position="absolute"
                          onClick={() => {
                            handleClickSelectMedia(item)
                          }}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Image width="12px" height="12px" src="/assets/clear.svg" />
                        </StyledDismissWrapper>
                      </StyledSelectedMediasWrapper>
                    </Flex>
                  )
                })}
              </StyledDialogEnvironmentSelectedMediasWrapper>
            </Flex>
            <Flex alignItems="center" justifyContent="flex-end">
              <Button.Gray mr="m" isSmall onClick={handleClickCloseModal}>
                Cancel
              </Button.Gray>
              <ButtonWithLoading
                onClick={() => {
                  if (selectedMedias.length === 0) {
                    Alert.error(`Please select at least one ${searchType}.`, { timeout: 5000 })
                  } else {
                    setIsUploadingMedia(true)
                    handleClickAddMedias({ selectedMedias, postComponent: activeTab.show })
                  }
                }}
                isSmall
                isLoading={isUploadingMedia}
              >
                <Text fontWeight="medium">{isUploadingMedia ? 'Uploading' : 'Upload'}</Text>
              </ButtonWithLoading>
            </Flex>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
      <CalendarItemTooltip ref={tooltipRef} />
    </StyledDialogOverlay>
  )
}

MediaDiscoverModal.defaultProps = {
  type: POST_IMAGE,
  postMedias: [],
  maxImageAttachmentsAllowed: MAX_IMAGES_ATTACHMENTS_ALLOWED,
  maxVideoAttachmentsAllowed: MAX_VIDEOS_ATTACHMENTS_ALLOWED,
  replaceMediasWithoutLimitError: false,
}

MediaDiscoverModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  type: PropTypes.string,
  handleClickAddMedias: PropTypes.func.isRequired,
  postMedias: PropTypes.array,
  maxImageAttachmentsAllowed: PropTypes.number,
  maxVideoAttachmentsAllowed: PropTypes.number,
  replaceMediasWithoutLimitError: PropTypes.bool,
}

MediaDiscoverModal.displayName = 'MediaDiscoverModal'

export default MediaDiscoverModal
