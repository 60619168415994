import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { colors, radius, COLOR_CONSTANTS } from 'theme'

const StyledFlex = styled(Flex)`
  justify-content: ${({ isOn }) => (isOn ? 'flex-end' : 'flex-start')};
  align-items: center;
  padding: 2px 4px;
  width: 40px;
  min-width: 40px;
  height: 20px;
  border-radius: ${radius.pill};
  background-color: ${({ isOn, backgroundColorOn, backgroundColorOff }) =>
    isOn ? backgroundColorOn : backgroundColorOff};
  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 0.7;
  `}
  cursor: pointer;
`

const StyledBox = styled(Box)`
  width: 14px;
  height: 14px;
  border-radius: ${radius.pill};
  background-color: ${colors.white};
`

const StyledText = styled(Text)`
  cursor: pointer;
`

const doNothing = () => null

const Switch = ({
  isOn,
  isDisabled,
  onClick = undefined,
  backgroundColorOn,
  backgroundColorOff,
  text,
  fontSize,
  fontWeight,
  color,
}) => (
  <Flex alignItems="center" onClick={!isDisabled ? onClick : doNothing()}>
    <StyledFlex
      isOn={isOn}
      isDisabled={isDisabled}
      backgroundColorOn={backgroundColorOn}
      backgroundColorOff={backgroundColorOff}
    >
      <StyledBox isOn={isOn} />
    </StyledFlex>
    {text && (
      <StyledText ml="s" fontWeight={fontWeight} fontSize={fontSize} color={color}>
        {text}
      </StyledText>
    )}
  </Flex>
)

Switch.propTypes = {
  isOn: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  backgroundColorOn: PropTypes.string,
  backgroundColorOff: PropTypes.string,
  text: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  color: PropTypes.string,
}

Switch.defaultProps = {
  isOn: false,
  isDisabled: false,
  onClick: () => {},
  backgroundColorOn: colors.primary,
  backgroundColorOff: COLOR_CONSTANTS.COSMIC_ENERGY,
  text: '',
  fontSize: 'xs',
  fontWeight: 'light',
  color: colors.primaryText,
}

export default Switch
