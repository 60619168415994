export const {
  NAME,
  PROFILE,
  ACCOUNT,
  BUDGET_IN_DOLLARS,
  DAYS,
  BID_AMOUNT,
  DELAY,
  INTERESTS,
  WORK_POSITIONS,
  COUNTRIES,
  REGIONS,
  CITIES,
  GENDERS,
  AGE_MIN,
  AGE_MAX,
  IDENTITY,
  OBJECTIVE,
  LOCATIONS,
  LANGUAGES,
  AGES,
  FUNDING_INSTRUMENT,
  FUNCTIONS,
  INDUSTRIES,
  OBJECTIVES,
  GENDER,
  AGE_BUCKET,
  INTEREST,
  GEO,
  LOCALE,
  LOCALES,
  INCLUDE_AUDIENCES,
  EXCLUDE_AUDIENCES,
  INTEREST_CATEGORIES,
} = {
  NAME: 'name', // all
  PROFILE: 'profile', // all
  ACCOUNT: 'account', // all
  BUDGET_IN_DOLLARS: 'budget_in_dollars', // all
  DAYS: 'days', // all
  BID_AMOUNT: 'bid_amount', // facebook
  DELAY: 'delay', // all
  INTERESTS: 'interests', // facebook, instagram, twitter, pinterest
  WORK_POSITIONS: 'work_positions', // facebook
  COUNTRIES: 'countries', // facebook, instagram
  REGIONS: 'regions', // facebook, instagram
  CITIES: 'cities', // facebook, instagram
  GENDERS: 'genders', // facebook, instagram, linkedin, pinterest
  AGE_MIN: 'age_min', // facebook, instagram
  AGE_MAX: 'age_max', // facebook, instagram
  IDENTITY: 'identity', // tiktok
  OBJECTIVE: 'objective', // tiktok, pinterest
  LOCATIONS: 'locations', // tiktok, twitter, linkedin, pinterest
  LANGUAGES: 'languages', // tiktok, twitter
  AGES: 'ages', // tiktok, linkedin, pinterest
  FUNDING_INSTRUMENT: 'funding_instrument', // twitter
  FUNCTIONS: 'functions', // linkedin
  INDUSTRIES: 'industries', // linkedin
  OBJECTIVES: 'objectives', // pinterest
  GENDER: 'gender', // pinterest
  AGE_BUCKET: 'age_bucket', // pinterest
  INTEREST: 'interest', // pinterest
  GEO: 'geo', // pinterest
  LOCALE: 'locale', // pinterest
  LOCALES: 'locales', // pinterest
  INCLUDE_AUDIENCES: 'include_audiences', // facebook, instagram, tiktok, pinterest, twitter, linkedin
  EXCLUDE_AUDIENCES: 'exclude_audiences', // facebook, instagram, tiktok, pinterest
  INTEREST_CATEGORIES: 'interest_categories', // tiktok
}

export const { DELAY_TYPE_MINUTES, DELAY_TYPE_HOURS, DELAY_TYPE_DAYS } = {
  DELAY_TYPE_MINUTES: 'MINUTES',
  DELAY_TYPE_HOURS: 'HOURS',
  DELAY_TYPE_DAYS: 'DAYS',
}

export const DELAY_OPTIONS = [
  { value: DELAY_TYPE_MINUTES, label: 'Minutes' },
  { value: DELAY_TYPE_HOURS, label: 'Hours' },
  { value: DELAY_TYPE_DAYS, label: 'Days' },
]
