import React from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import styled from 'styled-components'
import { Box, Flex } from 'components/atoms/Layout'
import { colors, COLOR_CONSTANTS, space, radius } from 'theme'

const DropdownMenuWrapper = styled(Flex)`
  z-index: ${({ zIndex }) => zIndex};
  position: ${({ useFixedPosition }) => (useFixedPosition ? 'fixed' : 'absolute')};
  top: ${({ top }) => (top === 'unset' ? 'unset' : `calc(100% + ${top})`)}; 
  flex-direction: column;
  ${({ right }) =>
    right &&
    `
      right: ${right};
  `}
  ${({ left }) =>
    left &&
    `
      left: ${left};
  `}
    ${({ bottom }) =>
      bottom &&
      `
      bottom: ${bottom};
  `}
  width: fit-content;
  padding: ${space.xs} 0;
  box-shadow: 0px 4px 10px rgba(39, 40, 49, 0.03);
  background-color: ${colors.white};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  border-radius: ${radius.l};

  ${({ isTriangleVisible, useFixedPosition }) =>
    isTriangleVisible &&
    `
      &::after,
      &::before {
        content: '';
        pointer-events: none;
        position: absolute;
        bottom: 100%;
        height: 0;
        width: 0;
        border: solid transparent;
      }
      &::after {
        border-bottom-color: ${colors.white};
        border-width: 6px;
        right: ${useFixedPosition ? 'unset' : '11px'};
        left: ${useFixedPosition ? '11px' : 'unset'};
      }
      &::before {
        border-bottom-color: ${COLOR_CONSTANTS.SOLITUDE};
        border-width: 7px;
        right: ${useFixedPosition ? 'unset' : '10px'};
        left: ${useFixedPosition ? '10px' : 'unset'};
      }
  `}
`

class DropdownMenu extends React.Component {
  state = { isOpen: false }

  handleToggleMenu = (event) => {
    const { isOpen } = this.state

    const { top, left, height } = event.currentTarget.getBoundingClientRect()

    this.setState({ isOpen: !isOpen, position_left: left - 5, position_top: top + height + 6 })

    this.props.handleClickToggleMenu({ isOpen: !isOpen })
  }

  handleClickOutside = () => {
    this.setState({ isOpen: false })
    this.props.handleClickToggleMenu({ isOpen: false })
  }

  handleClickInside = () => {
    const { isDismissedOnClickInside, handleClickInsideDropdownCallback } = this.props
    if (isDismissedOnClickInside) {
      const { isOpen } = this.state
      if (isOpen) {
        this.setState({ isOpen: false })
        this.props.handleClickToggleMenu({ isOpen: false })
        handleClickInsideDropdownCallback()
      }
    }
  }

  render() {
    const { isOpen, position_left = 0, position_top = 0 } = this.state
    const {
      WrapperComp,
      children,
      left,
      right,
      top,
      bottom,
      isTriangleVisible,
      zIndex,
      removeOnMouseLeave,
      className,
      minWidth,
      useFixedPosition,
    } = this.props

    return (
      <Box
        className={className}
        position="relative"
        onClick={this.handleClickInside}
        onMouseLeave={() => {
          if (removeOnMouseLeave) {
            this.handleClickOutside()
          }
        }}
      >
        {React.cloneElement(WrapperComp, {
          onClick: this.handleToggleMenu,
        })}
        {isOpen && (
          <DropdownMenuWrapper
            left={useFixedPosition ? position_left : left}
            right={useFixedPosition ? 'unset' : right}
            top={useFixedPosition ? position_top : top}
            bottom={useFixedPosition ? 'unset' : bottom}
            isTriangleVisible={isTriangleVisible}
            zIndex={zIndex}
            minWidth={minWidth}
            useFixedPosition={useFixedPosition}
          >
            {children}
          </DropdownMenuWrapper>
        )}
      </Box>
    )
  }
}

DropdownMenu.defaultProps = {
  isDismissedOnClickInside: false,
  isTriangleVisible: true,
  right: 0,
  left: 'unset',
  top: '6px',
  bottom: 'unset',
  zIndex: 3,
  removeOnMouseLeave: false,
  className: '',
  handleClickInsideDropdownCallback: () => {},
  minWidth: 'min-content',
  handleClickToggleMenu: ({}) => {},
  useFixedPosition: false,
}

DropdownMenu.propTypes = {
  WrapperComp: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isDismissedOnClickInside: PropTypes.bool,
  isTriangleVisible: PropTypes.bool,
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  zIndex: PropTypes.number,
  removeOnMouseLeave: PropTypes.bool,
  className: PropTypes.string,
  handleClickInsideDropdownCallback: PropTypes.func,
  minWidth: PropTypes.string,
  handleClickToggleMenu: PropTypes.func,
  useFixedPosition: PropTypes.bool,
}

export default onClickOutside(DropdownMenu)
