import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space as styledSpace } from 'styled-system'
import { transparentize } from 'polished'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Alert from 'react-s-alert'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { COLOR_CONSTANTS, radius } from 'theme'
import { IMAGE, VIDEO, AUDIO } from 'consts'
import { Box, Flex } from 'components/atoms/Layout'
import { H4, Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    position: relative;
    max-width: 450px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: auto;
    display: flex;
    flex-direction: column;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  background-color: ${COLOR_CONSTANTS.BOYSENBERRY_SHADOW};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledInput = styled(Input)`
  z-index: 2;
`

const URL = 'url'

const ValidationSchema = Yup.object().shape({
  [URL]: Yup.string()
    .required('URL is required')
    .url('Must be a valid URL address'),
})

const MediaUploadByLinkModal = ({ isOpen, handleDismiss, handleClickAddMedias, type, postMedias, hasError }) => {
  let searchType = 'media'
  let helperText = ''

  if (type.includes(IMAGE)) {
    searchType = IMAGE
  } else if (type.includes(VIDEO)) {
    searchType = VIDEO
  } else if (type.includes(AUDIO)) {
    searchType = AUDIO
    helperText = `This can be a link to an audio file, a link to an mp4/mov file that we will extract the sound from.`
  }

  const formRef = useRef(null)
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (hasError) {
      setError(hasError)
      setIsLoading(false)
    }
  }, [hasError])

  const handleSubmitForm = async () => {
    formRef.current.handleSubmit()
  }

  const handleSubmitData = async (values) => {
    setIsLoading(true)
    handleClickAddMedias({
      selectedMedias: [...postMedias, { [URL]: values[URL], tempId: new Date().getTime(), type: searchType }],
      postComponent: type,
    })
  }

  const handleClickCloseModal = () => {
    if (isLoading) {
      Alert.error('Please wait a bit longer while your media is uploaded', { timeout: 5000 })
    } else {
      handleDismiss()
    }
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" $isTop>
            <H4 my="m">Upload {searchType} from URL</H4>
          </StyledDialogEnvironmentWrapper>
          <Flex>
            <Formik
              ref={formRef}
              initialValues={{ [URL]: '' }}
              validationSchema={ValidationSchema}
              onSubmit={handleSubmitData}
            >
              {({ values, errors, touched, setFieldValue }) => (
                <Flex m="m" flexDirection="column" width="100%">
                  {helperText && <Text mb="m">{helperText}</Text>}
                  <StyledInput
                    value={values[URL]}
                    onChange={({ target: { value } }) => {
                      if (error) {
                        setError('')
                      }
                      setFieldValue(URL, value)
                    }}
                    label=""
                    error={(errors[URL] && touched[URL] && errors[URL]) || error}
                    placeholder={`Please enter ${searchType} URL`}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) {
                        handleSubmitForm()
                      }
                    }}
                  />
                </Flex>
              )}
            </Formik>
          </Flex>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
            <Button.Gray mr="m" isSmall onClick={handleClickCloseModal}>
              Cancel
            </Button.Gray>
            <ButtonWithLoading onClick={handleSubmitForm} isSmall isLoading={isLoading} type="button">
              <Text fontWeight="medium">{isLoading ? 'Uploading' : 'Upload'}</Text>
            </ButtonWithLoading>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

MediaUploadByLinkModal.defaultProps = {
  type: '',
  postMedias: [],
  hasError: '',
}

MediaUploadByLinkModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  type: PropTypes.string,
  handleClickAddMedias: PropTypes.func.isRequired,
  postMedias: PropTypes.array,
  hasError: PropTypes.string,
}

export default MediaUploadByLinkModal
