import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import debounce from 'lodash.debounce'
import { transparentize } from 'polished'
import ClipLoader from 'react-spinners/ClipLoader'
import { radius, COLOR_CONSTANTS, colors, space, breakpoints } from "theme"
import { DEFAULT_VIDEO_THUMBNAIL, PINTEREST, IMAGE, IMAGE_DYNAMIC, VIDEO, VIDEO_DYNAMIC } from 'consts'
import { linkTransformer, truncate } from 'helpers'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'
import Counter from 'components/molecules/Counter'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import Input from 'components/atoms/Input'
import DynamicMedia from 'components/molecules/DynamicMedia'
import DropDown from 'shared/DropDown'
import NoPost from './NoPost'
import {
  NETWORK_LIMITS,
  POST_IMAGE,
  POST_LINK,
  POST_VIDEO,
  POST_MIXED_MEDIA,
  PINTEREST_COLORS,
  URL_REGEXP,
  MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS,
  PREVIEW_MEDIA_CLASS_NAME,
} from '../../consts'
import SocialProfileImage from '../SocialProfileImage'
import CalendarItemTooltip from '../CalendarItemTooltip'
import ProfileItemTooltip from '../ProfileItemTooltip'
import PreviewPromptComponent from './components/PreviewPromptComponent'
// eslint-disable-next-line import/no-cycle
import {
  fitMediaProportionsToBox,
  getPinterestBoards,
  getPinterestSections,
  transformTextVariantsToUnicodeVariant,
  variableTransformer,
  mentionsTransformer,
} from '../../helpers'
// eslint-disable-next-line import/no-cycle
import TextAreaComponentWithControls from './components/CommentsComponent/TextAreaComponentWithControls'
// eslint-disable-next-line import/no-cycle
import PublicationOptionComponent from './components/PublicationOptionComponent'
import CustomizationsSlider from './components/CustomizationsSlider'
import TooltipPromptComponent from './components/TooltipPromptComponent'
import CustomizationsMediaComponent from './components/CustomizationsMediaComponent'
import BoostsDropdownComponent from './components/BoostsDropdownComponent'

const mapArrayToReactSelect = (arr) => arr.map(({ id, name }) => ({ value: id, label: name }))

const transformToReactSelectData = (data) => {
  if (data) {
    return { value: data.id, label: data.name }
  } else {
    return null
  }
}

const StyledFlex = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.WAYWARD_WIND};
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 20px 0px;
  border-radius: 2em;
  font-size: ${({ isEditable }) => (isEditable ? '10.666666px' : '16px')};
  height: ${({ isPostFormDirty }) => (isPostFormDirty ? 'fit-content' : '100%')};
  ${({ isPublished }) => isPublished && `cursor: pointer;`}
`
const StyledImg = styled(ImageWithFallback)`
  border-radius: ${radius.pill};
  object-fit: contain;
`

const Thumbnail = styled(ImageWithFallback)`
  object-fit: contain;
  width: 100%;
  max-height: 37.5em;
`

const SourceWrapper = styled.div`
  width: 100%;
  background-color: rgb(247, 246, 243);
  border-radius: 2em 2em 0px 0px;
`

const StyledLink = styled(Text)`
  text-decoration: none;
  cursor: pointer;
`

const StyledHostLink = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
`

const BodyWrapper = styled.div`
  cursor: pointer;
  position: relative;
  a {
    color: ${colors.primary};
  }
`

const StyledFirstImage = styled(Image)`
  object-fit: cover;
  border-radius: 2em 2em 0px 0px;
`

const StyledIconWrapper = styled(Flex)`
  min-height: 3em;
  min-width: 3em;
  border-radius: ${radius.pill};
  cursor: pointer;
  &:hover {
    background-color: #ddd;
  }
`

const StyledButtonWrapper = styled(Flex)`
  height: 3em;
  border-radius: 2.5em;
  cursor: pointer;
`

const StyledVideoThumbnailImage = styled(ImageWithFallback)`
  object-fit: cover;
  border-radius: 2em 2em 0px 0px;
`

const StyledLinkWrapper = styled(Flex)`
  align-items: center;
  transition: transform 85ms ease-out;
  cursor: pointer;
  height: 2.75em;
  left: 1.25em;
  bottom: 0.75em;
  position: absolute;
  z-index: 1;
  padding: 0 1em;
  border-radius: ${radius.pill};
  opacity: 0.9;
  background-color: ${transparentize(0.1, COLOR_CONSTANTS.WHITE)};
  display: none;
  &:hover {
    background-color: #e2e2e2;
  }
`

const StyledBox = styled(Flex)`
  cursor: pointer;
  ${({ hasError }) => hasError && `border: 3px solid ${colors.error};`}
  &:hover ${StyledLinkWrapper}{
      display: flex;
  }
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: 6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: rgb(145 158 171 / 30%) 0px 0px 4px;
  z-index: 2;
`

const StyledCaptionWrapper = styled(Box)`
  padding: ${space.s};
  border-radius: ${radius.l};
  background: ${COLOR_CONSTANTS.WHITE};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  cursor: pointer;
`

const networkLimits = NETWORK_LIMITS.find(({ code }) => code === PINTEREST)

const {
  descriptionTextMaxLimit: MAX_DESCRIPTION_LENGTH,
  postTextMaxLimit: MAX_TEXT_LENGTH,
  hasMobileNotificationOption,
} = networkLimits

const PROCESSED_VIDEO_ENDING = '_facebook_reel'

const PinterestPost = ({
  user,
  onChangeUpdatePost,
  dataForCustomizations,
  setDataForCustomizations,
  isPostFormDirty,
  postErrors,
  isEditable,
  hasPreview,
  noVideoAttachment,
  featuresEnabled,
  previewProfileNumber,
  selectedEntities,
  selectedProfiles,
  devices,
  savedVariables,
  applyCustomizationsToAllNetworksTemplate,
  hasCustomCaption,
  hasCustomMedias,
  forceCloseNetworkPreviewCaption,
  boosts,
  isSmallPreview,
  isAdvocacy,
  ...props
}) => {
  const tooltipRef = useRef(null)
  const uploadMediaComponentRef = useRef(null)

  const [isPostTextEditOpen, setIsPostTextEditOpen] = useState(false)
  const [isDescriptionTextEditOpen, setIsDescriptionTextEditOpen] = useState(false)
  const [profile, setProfile] = useState({ ...props.profile })
  const [post, setPost] = useState({ ...props.post })
  const [textVariantOffsets, setTextVariantOffsets] = useState([])
  const [agencyMentions, setAgencyMentions] = useState([])
  const [isCustomizationsOpen, setIsCustomizationsOpen] = useState(true)

  const smallPreview = (isEditable && isCustomizationsOpen) || isSmallPreview

  const MAX_PREVIEW_WIDTH = smallPreview ? 250 : 510
  const MAX_PREVIEW_HEIGHT = smallPreview ? 250 : 510

  const isMultipleProfilesPreview = previewProfileNumber > MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS

  const {
    profileId,
    profileName,
    profilePictureUrl,
    fallbackLogoImage,
    network,
    display,
    profileUrl,
    profileUsername,
    profile_id,
    type: profile_type,
  } = profile
  const {
    postText,
    postComponent,
    postImages = [],
    postVideos = [],
    postMixedMedias = [],
    link,
    customizations = {},
    result,
  } = post

  useEffect(() => {
    if (isEditable && isPostTextEditOpen) {
      setIsPostTextEditOpen(false)
    }
  }, [postText, forceCloseNetworkPreviewCaption])

  const { published_link } = result || {}
  const { url, picture_url, title: linkTitle, description: linkDescription, id: linkId } = link || {}

  const { [profileId]: networkDataForCustomizations } = dataForCustomizations.networkDataForCustomizations || {}
  const { boards = [], board_sections = {} } = networkDataForCustomizations || {}

  const handleUpdateCustomization = ({ type, value }) => {
    if (!post.customizations) {
      post.customizations = {}
    }
    if (!post.customizations[network]) {
      post.customizations[network] = {}
    }
    if (!post.customizations[network][profileId]) {
      post.customizations[network][profileId] = {}
    }

    post.customizations[network][profileId][type] = value
    onChangeUpdatePost(post)
  }

  let updatedPostText = postText || ''
  let customizedPostComponent = postComponent
  let customizedPostImages = postImages
  let customizedPostVideos = postVideos
  let customizedPostMixedMedias = postMixedMedias
  let description = ''
  let board_id = ''
  let board_id_name
  let section_id_name
  let section_id = ''
  let link_customization
  let sections = []
  let device_gid = null
  let publishing_option = null
  let boost_gid = null

  if (customizations[network]) {
    const profileCustomizations = customizations[network][profileId] || {}

    updatedPostText = profileCustomizations.message || postText || linkTitle || ''
    customizedPostComponent = profileCustomizations.postComponent

    if (typeof customizedPostComponent === 'undefined') {
      customizedPostComponent = postComponent
    }

    customizedPostImages = profileCustomizations.postImages || postImages
    customizedPostVideos = profileCustomizations.postVideos || postVideos
    customizedPostMixedMedias = profileCustomizations.postMixedMedias || postMixedMedias
    ;({
      description = '',
      board_id = '',
      board_id_name,
      section_id_name,
      section_id = '',
      link: link_customization,
      device_gid = null,
      publishing_option = null,
      boost_gid = null,
    } = profileCustomizations)
    if (typeof description === 'undefined' && linkDescription) {
      description = linkDescription
    }

    if (!board_id && board_id_name && boards && boards.length !== 0) {
      const board = boards.find((board) => board.name.trim().toLowerCase() === board_id_name.trim().toLowerCase())

      if (board) {
        handleUpdateCustomization({
          type: 'board_id',
          value: board.id,
        })
      } else {
        handleUpdateCustomization({
          type: 'board_id_name',
          value: '',
        })
      }
    }

    if (board_id && board_sections[board_id]) {
      sections = board_sections[board_id] || []

      if (!section_id && section_id_name && sections && sections.length !== 0) {
        if (section_id_name) {
          const section = sections.find(
            (section) => section.name.trim().toLowerCase() === section_id_name.trim().toLowerCase()
          )

          if (section) {
            handleUpdateCustomization({
              type: 'section_id',
              value: section.id,
            })
          }
        } else {
          handleUpdateCustomization({
            type: 'section_id_name',
            value: '',
          })
        }
      }
    }
  } else if (customizedPostComponent === POST_LINK) {
    const isMessageLink = updatedPostText.trim().match(URL_REGEXP)
    if (isMessageLink) {
      updatedPostText = linkTitle || ''
    } else {
      updatedPostText = postText || linkTitle || ''
    }
    description = linkDescription
  }

  useEffect(() => {
    setTextVariantOffsets([...transformTextVariantsToUnicodeVariant()])
  }, [])

  useEffect(() => {
    setPost({ ...props.post })
  }, [props.post])

  useEffect(() => {
    setProfile({ ...props.profile })
    if (isEditable) {
      getPinterestBoards({
        id: profileId,
        dataForCustomizations,
        setDataForCustomizations,
      })
    }
  }, [props.profile])

  useEffect(() => {
    if (isEditable && board_id && boards.length) {
      getPinterestSections({
        // eslint-disable-next-line no-use-before-define
        id: profileId,
        board_id,
        dataForCustomizations,
        setDataForCustomizations,
      })
    }
  }, [board_id, boards])

  useEffect(() => {
    setAgencyMentions([...props.agencyMentions])
  }, [props.agencyMentions])

  const {
    transformed_text_to_html_for_textarea,
    transformed_description_to_html_for_textarea,
    updatedPostTextWithMentions = '',
  } = useMemo(() => {
    const { variableTransformedText } = variableTransformer({ str: updatedPostText, savedVariables, post, profileId })

    const { updatedTextTransformedMentions: updatedPostTextWithMentions = '', updatedText } = mentionsTransformer({
      body: variableTransformedText,
      color: PINTEREST_COLORS.MENTION,
      fontWeight: 400,
      agencyMentions,
      network,
    })

    const transformed_text_to_html_for_textarea = linkTransformer({
      body: updatedText,
      color: PINTEREST_COLORS.LAST_LIGHT_BLUE,
      transformHashstag: true,
      hashtagColor: PINTEREST_COLORS.LAST_LIGHT_BLUE,
    })

    const transformed_description_to_html_for_textarea = linkTransformer({
      body: description,
      color: PINTEREST_COLORS.LAST_LIGHT_BLUE,
      transformHashstag: true,
      hashtagColor: PINTEREST_COLORS.LAST_LIGHT_BLUE,
    })

    return {
      transformed_text_to_html_for_textarea,
      transformed_description_to_html_for_textarea,
      updatedPostTextWithMentions,
    }
  }, [updatedPostText, savedVariables, description, agencyMentions])

  const { profiles: profilesWithErrors = {} } = postErrors.networksErrors[network] || {}
  const {
    hasErrors,
    flags: {
      postTextMaxLimit,
      descriptionTextLengthLimit,
      noBoardSelected,
      postVideoDurationLimit,
      postVideoExtensionError,
      postVideoSizeLimit,
      postVideoAspectRatioLimit,
      notificationDeviceLengthLimit,
    } = {},
    flags,
    warning_flags,
  } = profilesWithErrors[profileId] || {}

  const handleUpdateCustomizationDebounce = debounce(({ type, value }) => {
    handleUpdateCustomization({ type, value })
  }, 300)

  if (updatedPostText) {
    updatedPostText = updatedPostText
      .split('\n')
      .map((line) => {
        return line
          .split(' ')
          .filter((word) => {
            const res = word.match(URL_REGEXP)
            if (res !== null && customizedPostComponent !== POST_LINK && typeof link_customization !== 'string') {
              handleUpdateCustomizationDebounce({ type: 'link', value: res[0] })
            }
            return !res
          })
          .join(' ')
      })
      .join('\n')
  }

  let urlHost

  if (link_customization) {
    try {
      if (link_customization.indexOf('http') === 0) {
        urlHost = new URL(link_customization).host
      } else {
        urlHost = new URL(`https://${link_customization}`).host
      }
    } catch (e) {
      urlHost = link_customization
    }
  } else if (customizedPostComponent === POST_LINK && url && typeof link_customization !== 'string') {
    handleUpdateCustomizationDebounce({ type: 'link', value: url })
  }

  const postVideoError =
    postVideoAspectRatioLimit || postVideoDurationLimit || postVideoExtensionError || postVideoSizeLimit

  const postImagesUpdated =
    customizedPostComponent === POST_IMAGE ? customizedPostImages.filter(({ tempId }) => !tempId) : []

  let postMixedMediasUpdated = []

  if (customizedPostComponent === POST_MIXED_MEDIA) {
    const filtered_medias = customizedPostMixedMedias.filter(({ tempId }) => !tempId)
    if (filtered_medias.length !== 0) {
      postMixedMediasUpdated = [filtered_medias[0]]
    }
  }

  let sourceTypeTemplate = ''

  const linkTemplate = urlHost ? (
    <StyledLinkWrapper
      onClick={() => {
        window.open(link_customization, '_blank')
      }}
    >
      <Image src="/assets/pinterest_arrow_link.svg" width="16px" height="16px" />
      <Text ml="s" fontSize="m" fontWeight="bold" color={PINTEREST_COLORS.LAST_LIGHT_BLUE}>
        {urlHost}
      </Text>
    </StyledLinkWrapper>
  ) : null

  const generateImageSourceTypeTemplate = ({ data }) => {
    const {
      0: {
        url: firstColumnImage,
        id: firstColumnImageId,
        [network]: networkErrors = {},
        isNew: firstColumnImageIsNew,
        type: firstColumnType,
      } = {},
    } = data

    const { hasResolutionError, hasSizeError } = networkErrors || {}

    const postImageError = hasResolutionError || hasSizeError

    return (
      <SourceWrapper>
        <StyledBox
          maxHeight="37.5em"
          width="100%"
          alignItems="center"
          justifyContent="center"
          hasError={postImageError}
          position="relative"
        >
          {firstColumnImageIsNew ? (
            <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
              <ClipLoader size="50" color={colors.primary} />
            </Flex>
          ) : (
            <Fragment>
              {linkTemplate}
              {firstColumnType === IMAGE && (
                <StyledFirstImage
                  src={firstColumnImage}
                  width="100%"
                  height="100%"
                  maxHeight="37.5em"
                  name={firstColumnImageId}
                  id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${firstColumnImageId}`}
                />
              )}
              {firstColumnType === IMAGE_DYNAMIC && (
                <DynamicMedia media={data[0]} post={post} profiles={[profile]} isSmall={smallPreview} />
              )}
            </Fragment>
          )}
        </StyledBox>
      </SourceWrapper>
    )
  }

  const generateVideoSourceTypeTemplate = ({ data }) => {
    const { width, height, type, id: videoId, thumbnail_url, isNew, url: videoUrl } = data[0] || {}

    const { new_width, new_height } = fitMediaProportionsToBox({
      width,
      height,
      maxWidth: smallPreview ? 250 : 510,
      maxHeight: null,
      type,
      media_gid: videoId,
      post,
    })

    let videoThumbnailUrl = ''
    if (typeof isNew === 'undefined' && thumbnail_url) {
      videoThumbnailUrl = thumbnail_url
    }

    return (
      <Fragment>
        {noVideoAttachment ? (
          <Flex justifyContent="center" alignItems="center" height="17.5em" position="relative">
            {type === VIDEO && (
              <Fragment>
                <Box position="absolute" top={space.xs} right={space.xs}>
                  <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
                </Box>
                <StyledVideoThumbnailImage
                  height="100%"
                  width="100%"
                  source={videoThumbnailUrl || DEFAULT_VIDEO_THUMBNAIL}
                  fallbackSource={DEFAULT_VIDEO_THUMBNAIL}
                  name={videoId}
                  id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${videoId}`}
                />
              </Fragment>
            )}
            {type === VIDEO_DYNAMIC && (
              <Flex width="35em" height="17.5em" justifyContent="center" alignItems="center" mx="auto">
                <DynamicMedia media={data[0]} post={post} profiles={[profile]} isSmall={smallPreview} />
              </Flex>
            )}
          </Flex>
        ) : (
          <StyledBox position="relative" width="100%" justifyContent="center" hasError={postVideoError}>
            {isNew ? (
              <ClipLoader size="50" color={colors.primary} />
            ) : (
              <Fragment>
                {type === VIDEO && (
                  <VideoWithFallback
                    id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${videoId}`}
                    url={videoUrl}
                    controls
                    width={`${new_width || MAX_PREVIEW_WIDTH}px`}
                    height={`${new_height || MAX_PREVIEW_HEIGHT}px`}
                    style={{ background: COLOR_CONSTANTS.BLACK, borderRadius: '2em 2em 0px 0px' }}
                    config={{
                      file: {
                        attributes: {
                          poster: videoThumbnailUrl,
                        },
                      },
                    }}
                    name={videoId}
                    processedVideoEnding={PROCESSED_VIDEO_ENDING}
                  />
                )}
                {type === VIDEO_DYNAMIC && (
                  <DynamicMedia media={data[0]} post={post} profiles={[profile]} isSmall={smallPreview} />
                )}
              </Fragment>
            )}
          </StyledBox>
        )}
      </Fragment>
    )
  }

  switch (customizedPostComponent) {
    case POST_IMAGE:
      if (postImagesUpdated.length !== 0) {
        sourceTypeTemplate = generateImageSourceTypeTemplate({ data: postImagesUpdated })
      }
      break
    case POST_VIDEO:
      if (
        customizedPostVideos.length !== 0 &&
        (customizedPostVideos[0].url ||
          customizedPostVideos[0].thumbnail_url ||
          customizedPostVideos[0].type === VIDEO_DYNAMIC)
      ) {
        sourceTypeTemplate = generateVideoSourceTypeTemplate({ data: customizedPostVideos })
      }
      break
    case POST_LINK:
      if (picture_url) {
        sourceTypeTemplate = (
          <SourceWrapper>
            <StyledBox maxHeight="600px" width="100%" alignItems="center" justifyContent="center" position="relative">
              {linkTemplate}
              {picture_url && (
                <Thumbnail
                  source={picture_url}
                  fallbackSourceWidth="0px"
                  fallbackSourceHeight="0px"
                  name={linkId}
                  id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${linkId}`}
                />
              )}
            </StyledBox>
          </SourceWrapper>
        )
      }
      break
    case POST_MIXED_MEDIA:
      if (postMixedMediasUpdated.length !== 0) {
        if (postMixedMediasUpdated[0].type === IMAGE || postMixedMediasUpdated[0].type === IMAGE_DYNAMIC) {
          sourceTypeTemplate = generateImageSourceTypeTemplate({ data: postMixedMediasUpdated })
        } else if (postMixedMediasUpdated[0].type === VIDEO || postMixedMediasUpdated[0].type === VIDEO_DYNAMIC) {
          sourceTypeTemplate = generateVideoSourceTypeTemplate({ data: postMixedMediasUpdated })
        }
      }
      break
    default:
      break
  }

  let gridTemplateColumns = 'auto'

  if (hasPreview && isEditable) {
    if (isCustomizationsOpen) {
      gridTemplateColumns = `282px calc(100% - 300px)`

      if (process.browser) {
        if (window.innerWidth < Number(breakpoints.desktop.replace('px', ''))) {
          gridTemplateColumns = 'repeat(1, 1fr)'
        }
      }
    } else {
      gridTemplateColumns = `auto 40px`
    }
  } else if (!isEditable && smallPreview) {
    gridTemplateColumns = `minmax(250px, auto)`
  }

  return (
    <Flex flexDirection="column" height="100%">
      {isEditable && (
        <PreviewPromptComponent
          networkLimits={networkLimits}
          hasErrors={hasErrors}
          flags={flags}
          warning_flags={warning_flags}
          network={network}
          display={display}
          customizedPostComponent={customizedPostComponent}
          customizedPostImages={customizedPostImages}
          customizedPostVideos={customizedPostVideos}
          customizedPostMixedMedias={postMixedMediasUpdated}
          initialCustomizedPostMixedMedias={customizedPostMixedMedias}
        />
      )}

      <Grid gridGap="m" gridTemplateColumns={gridTemplateColumns} height="100%">
        {hasPreview && (
          <Flex
            flexDirection="column"
            width="100%"
            height="100%"
            position="relative"
            bg={isEditable ? COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL : COLOR_CONSTANTS.WHITE}
            p={isEditable ? 'm' : 0}
          >
            <Flex flexDirection="column" width="100%" height="100%" position="relative">
              <StyledFlex
                minHeight="max-content"
                flexDirection="column"
                isPublished={published_link}
                isEditable={smallPreview}
                isPostFormDirty={isPostFormDirty}
              >
                {isPostFormDirty ? (
                  <Flex
                    flexDirection="column"
                    width="100%"
                    onClick={() => {
                      if (published_link) {
                        window.open(published_link)
                      }
                    }}
                  >
                    <Box mb="0.5em">
                      <Flex my="0.5em" pt="0.5em" pb="0.25em" justifyContent="space-between" width="100%">
                        <Flex alignItems="center">
                          <StyledIconWrapper alignItems="center" justifyContent="center">
                            <Image src="/assets/pinterest_arrow_left.svg" width="1.25em" height="1.25em" />
                          </StyledIconWrapper>
                          <StyledIconWrapper alignItems="center" justifyContent="center">
                            <Image src="/assets/pinterest_more.svg" width="1.25em" height="1.25em" />
                          </StyledIconWrapper>
                          <StyledIconWrapper alignItems="center" justifyContent="center">
                            <Image src="/assets/pinterest_send.svg" width="1.25em" height="1.25em" />
                          </StyledIconWrapper>
                        </Flex>

                        <Flex
                          mx="0.5em"
                          pl="0.25em"
                          alignItems="center"
                          width="14.75em"
                          height="3.75em"
                          justifyContent="flex-end"
                        >
                          <StyledButtonWrapper
                            alignItems="center"
                            justifyContent="center"
                            bg="#efefef"
                            minWidth="3.75em"
                          >
                            <Text color={PINTEREST_COLORS.GLUON_GREY} fontWeight="bold" px="1em" fontSize="1em">
                              Visit
                            </Text>
                          </StyledButtonWrapper>
                          <StyledButtonWrapper
                            ml="0.5em"
                            alignItems="center"
                            justifyContent="center"
                            bg={PINTEREST_COLORS.MAIN}
                            minWidth="4.375em"
                          >
                            <Text color="white" fontWeight="bold" px="1em" fontSize="1em">
                              Save
                            </Text>
                          </StyledButtonWrapper>
                        </Flex>
                      </Flex>
                      {customizedPostComponent && <SourceWrapper>{sourceTypeTemplate}</SourceWrapper>}
                      <Flex px="1em" mt="1em" alignItems="center" justifyContent="space-between">
                        <Flex>
                          {isEditable ? (
                            <StyledImg
                              source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                              fallbackSource={fallbackLogoImage}
                              width="3em"
                              height="3em"
                            />
                          ) : (
                            <SocialProfileImage
                              code={network}
                              picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
                              width="3em"
                              height="3em"
                              imageBorderRadius={radius.pill}
                            />
                          )}
                          <Flex ml="0.5em" flexDirection="column" justifyContent="center">
                            <Flex
                              alignItems="center"
                              onMouseEnter={(e) => {
                                if (!isMultipleProfilesPreview) {
                                  tooltipRef.current.handleShowTooltip({
                                    contentComp: (
                                      <ProfileItemTooltip
                                        profile={{
                                          code: network,
                                          picture_url: profilePictureUrl,
                                          profile_url: profileUrl,
                                          name: profileName,
                                          username: profileUsername,
                                          display,
                                          profile_id,
                                          type: profile_type,
                                        }}
                                        message=""
                                      />
                                    ),
                                    wrapperComp: e.currentTarget,
                                  })
                                }
                              }}
                              onMouseLeave={() => {
                                tooltipRef.current.handleHideTooltip()
                              }}
                            >
                              <StyledLink
                                as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'p'}
                                href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                                target="_blank"
                                color={PINTEREST_COLORS.GLUON_GREY}
                                fontWeight="medium"
                                fontSize="1em"
                              >
                                {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : truncate(profileName, 25)}
                              </StyledLink>
                            </Flex>
                          </Flex>
                        </Flex>
                        <Flex alignItems="center" justifyContent="flex-end">
                          <StyledButtonWrapper
                            alignItems="center"
                            justifyContent="center"
                            bg="#efefef"
                            minWidth="3.75em"
                          >
                            <Text color={PINTEREST_COLORS.GLUON_GREY} fontWeight="bold" px="1em" fontSize="1em">
                              Follow
                            </Text>
                          </StyledButtonWrapper>
                        </Flex>
                      </Flex>
                      <Flex flexDirection="column" width="100%">
                        <Flex flexDirection="column" px="1em">
                          {urlHost && (
                            <StyledHostLink
                              as="a"
                              href={link_customization}
                              mt="0.5em"
                              color={PINTEREST_COLORS.GLUON_GREY}
                              target="_blank"
                              fontSize="0.875em"
                            >
                              {urlHost}
                            </StyledHostLink>
                          )}
                          {transformed_text_to_html_for_textarea && (
                            <Flex mt="0.5em">
                              <Box justifyContent="center" width="100%">
                                <Text
                                  dangerouslySetInnerHTML={{
                                    __html: transformed_text_to_html_for_textarea,
                                  }}
                                  fontWeight="bold"
                                  fontSize="2.25em"
                                  color={PINTEREST_COLORS.GLUON_GREY}
                                  whiteSpace="pre-line"
                                />
                              </Box>
                            </Flex>
                          )}
                          {transformed_description_to_html_for_textarea && (
                            <Flex mt="0.5em" mb="2em">
                              <Box justifyContent="center" width="100%">
                                <Text
                                  dangerouslySetInnerHTML={{
                                    __html: transformed_description_to_html_for_textarea,
                                  }}
                                  fontWeight="normal"
                                  fontSize="0.875em"
                                  color={PINTEREST_COLORS.GLUON_GREY}
                                  whiteSpace="pre-line"
                                />
                              </Box>
                            </Flex>
                          )}
                        </Flex>
                      </Flex>
                    </Box>
                  </Flex>
                ) : (
                  <Box mt="m" px="s" mb="s" height="100%">
                    <NoPost />
                  </Box>
                )}
              </StyledFlex>
            </Flex>
          </Flex>
        )}

        {isEditable && (
          <Flex minHeight="max-content" flexDirection="column" height="100%" borderRadius={radius.l}>
            <CustomizationsSlider
              user={user}
              isCustomizationsOpen={isCustomizationsOpen}
              setIsCustomizationsOpen={setIsCustomizationsOpen}
              post={post}
              profile={profile}
              CustomizationsComponent={
                <Flex flexDirection="column" borderRadius={radius.l} pr="s" pb="m">
                  <Flex flexDirection="column" width="100%">
                    {hasCustomCaption && (
                      <Flex alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          {isPostTextEditOpen ? (
                            <BodyWrapper>
                              <CloseIconWrapper onClick={() => setIsPostTextEditOpen(false)}>
                                <Image width="8px" height="8px" src="/assets/clear.svg" />
                              </CloseIconWrapper>
                              <Flex flexDirection="column">
                                <TextAreaComponentWithControls
                                  user={user}
                                  handleUpdateText={(text) => {
                                    if (text === postText) {
                                      handleUpdateCustomizationDebounce({ type: 'message', value: '' })
                                    } else {
                                      handleUpdateCustomizationDebounce({ type: 'message', value: text })
                                    }
                                  }}
                                  text={updatedPostText}
                                  textMaxLimitNumber={MAX_TEXT_LENGTH}
                                  textVariantOffsets={textVariantOffsets}
                                  textareaPlaceholder="Write your title ..."
                                  errorText={postTextMaxLimit ? 'Your pin title is too long' : ''}
                                  selectedEntities={selectedEntities}
                                  selectedProfiles={selectedProfiles}
                                  counterComp={
                                    <Counter
                                      counter={updatedPostTextWithMentions.length}
                                      max={MAX_TEXT_LENGTH}
                                      fontSize="xxs"
                                    />
                                  }
                                />
                              </Flex>
                            </BodyWrapper>
                          ) : (
                            <StyledCaptionWrapper
                              onClick={() => {
                                setIsPostTextEditOpen(true)
                              }}
                            >
                              <BodyWrapper>
                                <Text
                                  dangerouslySetInnerHTML={{
                                    __html: transformed_text_to_html_for_textarea || 'Click to edit caption',
                                  }}
                                  fontSize="xs"
                                  color={
                                    transformed_text_to_html_for_textarea
                                      ? COLOR_CONSTANTS.DENIM
                                      : COLOR_CONSTANTS.COSMIC_ENERGY
                                  }
                                  whiteSpace="pre-line"
                                />
                              </BodyWrapper>
                              <Box>
                                <Counter counter={updatedPostText.length} max={MAX_TEXT_LENGTH} fontSize="xxs" />
                              </Box>
                            </StyledCaptionWrapper>
                          )}
                        </Flex>
                        <TooltipPromptComponent prompt_key="caption" network={PINTEREST} />
                      </Flex>
                    )}

                    {hasCustomMedias && (
                      <Fragment>
                        <Flex mt="m" alignItems="center" width="100%">
                          <Flex flexDirection="column" width="calc(100% - 18px)">
                            <CustomizationsMediaComponent
                              uploadMediaComponentRef={uploadMediaComponentRef}
                              user={user}
                              network={network}
                              profileId={profileId}
                              selectedProfiles={selectedProfiles}
                              selectedEntities={selectedEntities}
                              customized_post_data={{
                                postComponent: customizedPostComponent,
                                postImages: customizedPostImages,
                                postVideos: customizedPostVideos,
                                postMixedMedias: customizedPostMixedMedias,
                              }}
                              showLinkComponent={false}
                              showDocumentComponent={false}
                              mediasPreviewDisplay={
                                (customizedPostComponent === POST_IMAGE &&
                                  customizedPostImages &&
                                  customizedPostImages.length !== 0) ||
                                (customizedPostComponent === POST_VIDEO &&
                                  customizedPostVideos &&
                                  customizedPostVideos.length !== 0) ||
                                (customizedPostComponent === POST_MIXED_MEDIA &&
                                  customizedPostMixedMedias &&
                                  customizedPostMixedMedias.length !== 0)
                                  ? 'block'
                                  : 'none'
                              }
                              mediasPreviewSizeSmall
                            />
                          </Flex>
                          <TooltipPromptComponent prompt_key="media" />
                        </Flex>
                      </Fragment>
                    )}

                    {hasMobileNotificationOption && (
                      <Flex minHeight="max-content" flexDirection="column" position="relative">
                        <Flex flexDirection="column">
                          <PublicationOptionComponent
                            handleUpdateCustomization={handleUpdateCustomization}
                            publishing_option={publishing_option}
                            device_gid={device_gid}
                            devices={devices}
                            notificationDeviceLengthLimit={notificationDeviceLengthLimit}
                          />
                        </Flex>
                      </Flex>
                    )}

                    <Flex mt="m" alignItems="center" width="100%">
                      <Flex flexDirection="column" width="100%">
                        {isDescriptionTextEditOpen ? (
                          <BodyWrapper>
                            <CloseIconWrapper onClick={() => setIsDescriptionTextEditOpen(false)}>
                              <Image width="8px" height="8px" src="/assets/clear.svg" />
                            </CloseIconWrapper>
                            <Flex flexDirection="column">
                              <TextAreaComponentWithControls
                                user={user}
                                handleUpdateText={(text) => {
                                  handleUpdateCustomizationDebounce({ type: 'description', value: text })
                                }}
                                text={description || ''}
                                textMaxLimitNumber={MAX_DESCRIPTION_LENGTH}
                                textVariantOffsets={textVariantOffsets}
                                textareaPlaceholder="Optional description"
                                errorText={descriptionTextLengthLimit ? 'Your pin description is too long' : ''}
                                selectedEntities={selectedEntities}
                                selectedProfiles={selectedProfiles}
                              />
                            </Flex>
                          </BodyWrapper>
                        ) : (
                          <StyledCaptionWrapper
                            onClick={() => {
                              setIsDescriptionTextEditOpen(true)
                            }}
                          >
                            <BodyWrapper>
                              <Text
                                dangerouslySetInnerHTML={{
                                  __html: transformed_description_to_html_for_textarea || 'Pin description',
                                }}
                                fontSize="xs"
                                color={
                                  transformed_description_to_html_for_textarea
                                    ? COLOR_CONSTANTS.DENIM
                                    : COLOR_CONSTANTS.COSMIC_ENERGY
                                }
                                whiteSpace="pre-line"
                              />
                            </BodyWrapper>
                            <Box>
                              <Counter counter={description.length} max={MAX_DESCRIPTION_LENGTH} fontSize="xxs" />
                            </Box>
                          </StyledCaptionWrapper>
                        )}
                      </Flex>
                      <TooltipPromptComponent prompt_key="description" network={PINTEREST} />
                    </Flex>

                    <Flex mt="m" alignItems="center" width="100%">
                      <Flex flexDirection="column" width="100%">
                        <Input
                          placeholder="Pin link"
                          defaultValue={link_customization}
                          onInput={(e) => {
                            const { value = '' } = e.target

                            handleUpdateCustomizationDebounce({
                              type: 'link',
                              value,
                            })
                          }}
                          type="text"
                          width="100%"
                          color={COLOR_CONSTANTS.DENIM}
                        />
                      </Flex>
                      <TooltipPromptComponent prompt_key="pin_link" network={PINTEREST} />
                    </Flex>
                  </Flex>

                  <Flex mt="m" alignItems="center" width="100%">
                    <Flex flexDirection="column" width="100%">
                      <DropDown
                        placeholder="Select a board"
                        value={board_id ? transformToReactSelectData(boards.find(({ id }) => board_id === id)) : null}
                        onChange={(option) => {
                          const { value = null, label = null } = option || {}
                          handleUpdateCustomization({
                            type: 'board_id',
                            value,
                          })
                          handleUpdateCustomization({
                            type: 'board_id_name',
                            value: label,
                          })

                          if (board_id) {
                            handleUpdateCustomization({
                              type: 'section_id',
                              value: null,
                            })
                            handleUpdateCustomization({
                              type: 'section_id_name',
                              value: null,
                            })
                          }
                        }}
                        options={mapArrayToReactSelect(boards)}
                        openMenuOnFocus
                        error={noBoardSelected ? 'Please select a board for posting your pin' : ''}
                        noOptionsMessage={() => {
                          return boards.length === 0
                            ? 'No Pinterest boards found. Please be sure to create them or give the right permissions to Vista Social.'
                            : null
                        }}
                        menuPlacement="top"
                      />
                    </Flex>
                    <TooltipPromptComponent
                      prompt_key="board"
                      network={PINTEREST}
                      mt={noBoardSelected ? '-18px' : '0'}
                    />
                  </Flex>

                  {board_id && (
                    <Flex mt="m" alignItems="center" width="100%">
                      <Flex flexDirection="column" width="100%">
                        <DropDown
                          placeholder="Select board section"
                          value={
                            section_id ? transformToReactSelectData(sections.find(({ id }) => section_id === id)) : null
                          }
                          onChange={(option) => {
                            const { value = null, label = null } = option || {}
                            handleUpdateCustomization({
                              type: 'section_id',
                              value,
                            })

                            handleUpdateCustomization({
                              type: 'section_id_name',
                              value: label,
                            })
                          }}
                          options={mapArrayToReactSelect(sections)}
                          openMenuOnFocus
                          menuPlacement="top"
                          noOptionsMessage={() => {
                            return sections.length === 0
                              ? 'No Pinterest board sections found. Please be sure to create them.'
                              : null
                          }}
                        />
                      </Flex>
                      <TooltipPromptComponent prompt_key="board_section" network={PINTEREST} />
                    </Flex>
                  )}

                  {!isAdvocacy && (
                    <BoostsDropdownComponent
                      boosts={boosts && boosts.length !== 0 ? boosts : null}
                      handleUpdateCustomization={handleUpdateCustomization}
                      boostId={boost_gid}
                      user={user}
                      profile={profile}
                    />
                  )}

                  {applyCustomizationsToAllNetworksTemplate && (
                    <Flex width="100%" mt="m">
                      {applyCustomizationsToAllNetworksTemplate}
                    </Flex>
                  )}
                </Flex>
              }
            />
          </Flex>
        )}
      </Grid>
      <CalendarItemTooltip ref={tooltipRef} />
    </Flex>
  )
}

PinterestPost.defaultProps = {
  post: {},
  profile: {},
  onChangeUpdatePost: () => {},
  dataForCustomizations: {},
  setDataForCustomizations: () => {},
  isPostFormDirty: false,
  postErrors: { networksErrors: {} },
  agencyMentions: [],
  isEditable: true,
  hasPreview: true,
  noVideoAttachment: false,
  featuresEnabled: {},
  previewProfileNumber: 1,
  selectedEntities: [],
  devices: [],
  savedVariables: [],
  applyCustomizationsToAllNetworksTemplate: null,
  hasCustomCaption: true,
  hasCustomMedias: true,
  forceCloseNetworkPreviewCaption: null,
  boosts: [],
  isSmallPreview: false,
  isAdvocacy: false,
}

PinterestPost.propTypes = {
  user: PropTypes.object.isRequired,
  post: PropTypes.object,
  profile: PropTypes.object,
  isPostFormDirty: PropTypes.bool,
  onChangeUpdatePost: PropTypes.func,
  dataForCustomizations: PropTypes.object,
  setDataForCustomizations: PropTypes.func,
  postErrors: PropTypes.object,
  agencyMentions: PropTypes.array,
  isEditable: PropTypes.bool,
  hasPreview: PropTypes.bool,
  noVideoAttachment: PropTypes.bool,
  featuresEnabled: PropTypes.object,
  previewProfileNumber: PropTypes.number,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  devices: PropTypes.array,
  savedVariables: PropTypes.array,
  applyCustomizationsToAllNetworksTemplate: PropTypes.node,
  hasCustomCaption: PropTypes.bool,
  hasCustomMedias: PropTypes.bool,
  forceCloseNetworkPreviewCaption: PropTypes.number,
  boosts: PropTypes.array,
  isSmallPreview: PropTypes.bool,
  isAdvocacy: PropTypes.bool,
}

export default PinterestPost
