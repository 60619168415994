import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { radius, COLOR_CONSTANTS, colors, fontWeights, space } from 'theme'
import { PLAN_UPGRADE } from 'consts'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import CalendarItemTooltip from '../../CalendarItemTooltip'

const StyledCustomizationsBlockWrapper = styled(Flex)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  border-radius: ${radius.xl};
`

const BackgroundWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  position: absolute;
`

const StyledSampleWrapper = styled(Box)`
  width: 90px;
  height: 1px;
`

const Sample = styled(Flex)`
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  width: 150px;
  background-color: ${COLOR_CONSTANTS.AEGEAN};
  cursor: pointer;
`

const StyledMessageWrapper = styled(Flex)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledText = styled(Text)`
  & > a {
    color: ${colors.white};
    font-weight: ${fontWeights.medium};
  }
`

const StyledLearnMore = styled(Text)`
  text-decoration: underline;
`

const StyledHoverMessageWrapper = styled(Flex)`
  max-width: 330px;
  align-items: center;
  justify-content: center;
  padding: ${space.s};
  a {
    color: ${colors.primary};
    text-decoration: none;
  }
`

const CustomizationsBlockComponent = ({
  message,
  showMessage,
  showUpgradeMessage,
  showUpgradePlan,
  top,
  left,
  fontSize,
}) => {
  const tooltipRef = useRef(null)
  return (
    <StyledCustomizationsBlockWrapper>
      <Box position="relative" width="100%" height="100%">
        <BackgroundWrapper />
        {showUpgradePlan && (
          <Box height="0" position="absolute" top="0" right="0" zIndex="1">
            <StyledSampleWrapper>
              <Sample top={top} left={left} justifyContent="center">
                <Flex
                  py="7px"
                  width="100%"
                  height="100%"
                  justifyContent="center"
                  onMouseEnter={(e) => {
                    tooltipRef.current.handleShowTooltip({
                      contentComp: (
                        <StyledHoverMessageWrapper>
                          <Text px="m" dangerouslySetInnerHTML={{ __html: message }} color="primaryText" />
                        </StyledHoverMessageWrapper>
                      ),
                      wrapperComp: e.currentTarget,
                    })
                  }}
                  onMouseLeave={() => {
                    tooltipRef.current.handleHideTooltip()
                  }}
                >
                  <Text fontSize={fontSize} color="white" fontWeight="bold">
                    {PLAN_UPGRADE}
                  </Text>
                </Flex>
              </Sample>
            </StyledSampleWrapper>
          </Box>
        )}
        {showMessage && (
          <StyledMessageWrapper>
            <StyledText
              color="white"
              px={showUpgradePlan ? 'l' : 's'}
              fontWeight="medium"
              dangerouslySetInnerHTML={{ __html: message }}
            />{' '}
            {showUpgradeMessage && (
              <StyledLearnMore color="white" as="a" href="/pricing" target="_blank" fontWeight="medium">
                Please upgrade
              </StyledLearnMore>
            )}
          </StyledMessageWrapper>
        )}
      </Box>
      <CalendarItemTooltip ref={tooltipRef} />
    </StyledCustomizationsBlockWrapper>
  )
}

CustomizationsBlockComponent.defaultProps = {
  message: `Your plan doesn't offer this feature. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  showMessage: true,
  showUpgradeMessage: true,
  showUpgradePlan: true,
  fontSize: 'xs',
  top: '24px',
  left: '-24px',
}

CustomizationsBlockComponent.propTypes = {
  message: PropTypes.string,
  showMessage: PropTypes.bool,
  showUpgradeMessage: PropTypes.bool,
  showUpgradePlan: PropTypes.bool,
  fontSize: PropTypes.string,
  top: PropTypes.string,
  left: PropTypes.string,
}

export default CustomizationsBlockComponent
