import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'next/router'
import { transparentize } from 'polished'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import { PROFILE_STATUSES, PERMISSION_ERROR, PERMISSION_PROFILES, WARNING_PROFILE_LINKS_MAPPING } from 'consts'
import { hasEntitiesAccess, hasGlobalAccessManage } from 'utils/feature'
import { Text } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Checkbox from 'components/atoms/Checkbox'
import Icon from 'components/atoms/Icon'
import Image from 'components/atoms/Image'
import { connectProfileFunction, getProfileStatusTextAndColor } from 'routes/Settings/Profiles/helpers'
import SocialProfileImage from './SocialProfileImage'
import ProfileItemTooltip from './ProfileItemTooltip'
import CalendarItemTooltip from './CalendarItemTooltip'
import { formatTimezoneToGMT, filterSocialProfiles } from '../helpers'
import SampleComponent from '../../Reports/components/SampleComponent'
import AddProfileModal from '../../Settings/Profiles/components/AddProfileModal'

const TitleWrapper = styled(Flex)`
  cursor: pointer;
`

const StyledArrowWrapper = styled(Flex)`
  border-radius: ${radius.pill};
  cursor: pointer;
  &:hover {
    background-color: ${COLOR_CONSTANTS.DAISY};
    svg {
      fill: ${colors.primary};
    }
  }
`

const TotalBadge = styled(Flex)`
  justify-content: center;
  align-items: center;
  min-width: ${({ isWide }) => (isWide ? '20px' : '16px')};
  height: 16px;
  background-color: ${transparentize(0.8, colors.primary)};
  border-radius: ${radius.pill};
  padding: ${space.xs};
  margin-left: ${space.xs};
`

const StyledTextWrappedName = styled(Text)`
  max-width: ${({ width }) => width || '150px'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`

const Tree = ({
  user,
  selectedProfiles,
  handleClickSelectProfile,
  handleClickSelectAllProfiles,
  canSelectLostAccessProfiles,
  blockedSocialProfileChecker,
  socialNetworkFilter,
  router,
  accessLevels,
  permission,
  ...props
}) => {
  const tooltipRef = useRef(null)
  const [entities, setEntities] = useState(props.entities)
  const [isAddProfileModalOpen, setIsAddProfileModalOpen] = useState(false)

  useEffect(() => {
    setEntities([...props.entities])
  }, [props.entities])

  const handleClickOpenEntityProfiles = ({ entityId }) => {
    for (let i = 0; i < entities.length; i++) {
      if (entities[i].id === entityId) {
        entities[i].isOpen = !entities[i].isOpen
        break
      }
    }
    setEntities([...entities])
  }

  const sortedEntities = entities.sort((a, b) =>
    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0
  )

  const handleClickOpenAddProfileModal = ({ selectedEntity }) => {
    setIsAddProfileModalOpen({ ...selectedEntity })
  }

  const handleClickCloseAddProfileModal = () => {
    setIsAddProfileModalOpen(null)
  }

  const canManageProfiles = hasGlobalAccessManage({ user, permission: PERMISSION_PROFILES })

  return (
    <Flex flexDirection="column" height="100%" width="100%">
      {sortedEntities.map(
        ({
          name,
          id: entityId,
          type: entityType,
          picture_url: entityPictureUrl,
          isSample,
          profiles = [],
          isOpen,
          timezone,
          shortenerText = {},
        }) => {
          const { description: shortenerDescription = '' } = shortenerText || {}

          const accessMessage = hasEntitiesAccess({
            user,
            permission,
            entity_gid: entityId,
            accessLevels,
          })

          let selectedEntityProfiles = []
          let filteredSocialProfiles = []

          let filteredSocialProfilesLength = filteredSocialProfiles.length
          let allEntityProfilesChecked = false

          if (!accessMessage) {
            selectedEntityProfiles = selectedProfiles.filter((item) => item.entityId === entityId)
            filteredSocialProfiles = filterSocialProfiles({ data: profiles, filter: true, socialNetworkFilter })

            filteredSocialProfilesLength = filteredSocialProfiles.length

            if (blockedSocialProfileChecker) {
              filteredSocialProfilesLength = filteredSocialProfiles.filter(
                ({ network: { code, display }, profile_url, status, id }) =>
                  !blockedSocialProfileChecker({
                    profile: { network: { code, display }, profile_url, status, id },
                  }).blocked
              ).length
            }

            allEntityProfilesChecked = selectedEntityProfiles.length === filteredSocialProfilesLength
          }

          return (
            <Box key={entityId} mt="s" pr="s" position="relative">
              {isSample && <SampleComponent hoverText="" />}
              <TitleWrapper alignItems="center" justifyContent="space-between">
                <Flex alignItems="center">
                  <Flex alignItems="center">
                    <Flex
                      alignItems="center"
                      onMouseEnter={(e) => {
                        tooltipRef.current.handleShowTooltip({
                          contentComp: (
                            <Flex justifyContent="center" flexDirection="column" width="300px" p="s">
                              <Text>
                                {name} ({entityType.toLowerCase()})
                              </Text>
                              <Text>{formatTimezoneToGMT(timezone).long}</Text>
                              {shortenerDescription && <Text>{shortenerDescription}</Text>}
                            </Flex>
                          ),
                          wrapperComp: e.currentTarget,
                        })
                      }}
                      onMouseLeave={() => {
                        tooltipRef.current.handleHideTooltip()
                      }}
                    >
                      <Checkbox
                        isChecked={allEntityProfilesChecked}
                        onClick={() => {
                          if (!accessMessage) {
                            if (allEntityProfilesChecked) {
                              handleClickSelectAllProfiles({ clickType: 'clear', entityId, entityType })
                            } else {
                              handleClickSelectAllProfiles({ clickType: 'select', entityId, entityType, profiles })
                            }
                          }
                        }}
                      >
                        <StyledTextWrappedName ml="m" pl="s" fontWeight="light" fontSize="s" width="160px">
                          {name}
                        </StyledTextWrappedName>
                      </Checkbox>
                    </Flex>
                  </Flex>

                  {selectedEntityProfiles.length > 0 && (
                    <Flex alignItems="center">
                      <TotalBadge
                        onMouseEnter={(e) => {
                          tooltipRef.current.handleShowTooltip({
                            contentComp: (
                              <Flex alignItems="center" width="150px" p="s">
                                <Text>
                                  {`${selectedEntityProfiles.length} selected ${
                                    selectedEntityProfiles.length === 1 ? 'profile' : 'profiles'
                                  }`}
                                </Text>
                              </Flex>
                            ),
                            wrapperComp: e.currentTarget,
                          })
                        }}
                        onMouseLeave={() => {
                          tooltipRef.current.handleHideTooltip()
                        }}
                        isWide={selectedEntityProfiles.length > 9}
                      >
                        <Text as="span" color={colors.primary} fontSize="9px">
                          {selectedEntityProfiles.length}
                        </Text>
                      </TotalBadge>
                    </Flex>
                  )}
                </Flex>
                <StyledArrowWrapper
                  width="24px"
                  height="24px"
                  alignItems="center"
                  justifyContent="center"
                  onClick={() => {
                    handleClickOpenEntityProfiles({ entityId })
                  }}
                >
                  {isOpen ? <Icon.VistaSocialChevronUp /> : <Icon.VistaSocialChevronDown />}
                </StyledArrowWrapper>
              </TitleWrapper>
              {accessMessage ? (
                <Flex mt="s" mr="s" justifyContent="center" display={isOpen ? 'flex' : 'none'}>
                  <TitleWrapper alignItems="center">
                    <Flex alignItems="center">
                      <Flex
                        alignItems="center"
                        onMouseEnter={(e) => {
                          tooltipRef.current.handleShowTooltip({
                            contentComp: (
                              <Flex alignItems="center" p="s">
                                <Text>{PERMISSION_ERROR}</Text>
                              </Flex>
                            ),
                            wrapperComp: e.currentTarget,
                          })
                        }}
                        onMouseLeave={() => {
                          tooltipRef.current.handleHideTooltip()
                        }}
                      >
                        <Text color="error" fontSize="xs">
                          {accessMessage}
                        </Text>
                      </Flex>
                    </Flex>
                  </TitleWrapper>
                </Flex>
              ) : (
                <Fragment>
                  {profiles.length === 0 ? (
                    <Flex mt="s" mr="s" justifyContent="center" display={isOpen ? 'flex' : 'none'}>
                      {canManageProfiles && (
                        <TitleWrapper
                          alignItems="center"
                          onClick={() => {
                            handleClickOpenAddProfileModal({
                              selectedEntity: {
                                value: entityId,
                                label: name,
                                type: entityType,
                                picture_url: entityPictureUrl,
                              },
                            })
                          }}
                        >
                          <Image source="/assets/plus.svg" mr="xs" width="16px" height="16px" />
                          <Text color="primary" fontSize="xs">
                            Add Profiles
                          </Text>
                        </TitleWrapper>
                      )}
                    </Flex>
                  ) : (
                    <Flex mt="s" ml="m" pl="s" flexDirection="column" display={isOpen ? 'flex' : 'none'}>
                      {filteredSocialProfiles.map((profile) => {
                        const {
                          name,
                          username,
                          nickname,
                          id,
                          isSample,
                          picture_url,
                          profile_url,
                          network: { code, display } = {},
                          status,
                          warning,
                          description,
                          profile_id,
                          type,
                          scope,
                        } = profile

                        const isDisabled =
                          (blockedSocialProfileChecker &&
                            blockedSocialProfileChecker({
                              profile: { network: { code, display }, profile_url, status, id },
                            }).blocked) ||
                          (!canSelectLostAccessProfiles && status === PROFILE_STATUSES.BLOCKED)

                        const { errorColor, hoverMessage, reconnectUrl, reconnectType } = getProfileStatusTextAndColor({
                          profile,
                          origin: router.asPath,
                        })

                        return (
                          <Box key={id} mb="m">
                            <Checkbox
                              isChecked={
                                (isSample && !isDisabled) ||
                                selectedProfiles.findIndex((value) => value.id === id && value.entityId === entityId) >
                                  -1
                              }
                              onClick={() => {
                                if (!isDisabled) {
                                  handleClickSelectProfile({
                                    id,
                                    picture_url,
                                    profile_url,
                                    code,
                                    name,
                                    entityId,
                                    entityType,
                                    display,
                                    status,
                                    warning,
                                    profile_id,
                                    type,
                                    scope,
                                  })
                                }
                              }}
                              disabled={isDisabled}
                            >
                              <Flex alignItems="center" justifyContent="space-between" width="100%">
                                <Flex alignItems="center">
                                  <Flex alignItems="center">
                                    <Flex
                                      ml="m"
                                      pl="s"
                                      alignItems="center"
                                      onMouseEnter={(e) => {
                                        tooltipRef.current.handleShowTooltip({
                                          contentComp: (
                                            <ProfileItemTooltip
                                              profile={{
                                                code,
                                                picture_url,
                                                profile_url,
                                                name,
                                                username,
                                                display,
                                                description,
                                                profile_id,
                                                type,
                                              }}
                                              message={
                                                blockedSocialProfileChecker
                                                  ? blockedSocialProfileChecker({
                                                      profile: {
                                                        network: { code, display },
                                                        profile_url,
                                                        status,
                                                        id,
                                                        scope,
                                                      },
                                                    }).blockedProfileHoverText
                                                  : ''
                                              }
                                              error={hoverMessage}
                                              errorColor={errorColor}
                                            />
                                          ),
                                          wrapperComp: e.currentTarget,
                                        })
                                      }}
                                      onMouseLeave={() => {
                                        tooltipRef.current.handleHideTooltip()
                                      }}
                                    >
                                      <SocialProfileImage
                                        picture_url={picture_url}
                                        code={code}
                                        width="20px"
                                        height="20px"
                                        id={id}
                                      />
                                      <StyledTextWrappedName
                                        ml="s"
                                        fontWeight="light"
                                        fontSize="xs"
                                        width={status === PROFILE_STATUSES.BLOCKED || warning ? '135px' : '150px'}
                                        color={status === PROFILE_STATUSES.BLOCKED ? 'error' : 'primaryText'}
                                      >
                                        {nickname || name || username}
                                      </StyledTextWrappedName>
                                      {(status === PROFILE_STATUSES.BLOCKED || warning) && (
                                        <Flex
                                          alignItems="center"
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            if (warning && WARNING_PROFILE_LINKS_MAPPING[warning]) {
                                              window.open(WARNING_PROFILE_LINKS_MAPPING[warning].link, '_blank')
                                            } else {
                                              connectProfileFunction({ reconnectUrl, reconnectType })
                                            }
                                          }}
                                        >
                                          <Icon.Warning fill={errorColor} />
                                        </Flex>
                                      )}
                                    </Flex>
                                  </Flex>
                                </Flex>
                              </Flex>
                            </Checkbox>
                          </Box>
                        )
                      })}
                    </Flex>
                  )}
                </Fragment>
              )}
            </Box>
          )
        }
      )}
      <CalendarItemTooltip ref={tooltipRef} />
      {isAddProfileModalOpen && (
        <AddProfileModal
          handleDismiss={handleClickCloseAddProfileModal}
          isOpen={!!isAddProfileModalOpen}
          origin={router.asPath}
          selectedEntity={isAddProfileModalOpen}
          user={user}
        />
      )}
    </Flex>
  )
}

Tree.defaultProps = {
  canSelectLostAccessProfiles: false,
  blockedSocialProfileChecker: null,
}

Tree.propTypes = {
  user: PropTypes.object.isRequired,
  entities: PropTypes.array.isRequired,
  selectedProfiles: PropTypes.array.isRequired,
  handleClickSelectProfile: PropTypes.func.isRequired,
  handleClickSelectAllProfiles: PropTypes.func.isRequired,
  canSelectLostAccessProfiles: PropTypes.bool,
  blockedSocialProfileChecker: PropTypes.func,
  socialNetworkFilter: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  accessLevels: PropTypes.array.isRequired,
  permission: PropTypes.string.isRequired,
}

export default withRouter(Tree)
