import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AutoSizer, List } from 'react-virtualized'
import { COLOR_CONSTANTS, colors, radius, space, fontSizes } from 'theme'
import { DEFAULT_SOCIAL_PROFILE_SOURCE } from 'consts'
import { getSocialNetworkIconByName } from 'utils/socialNetworks'
import Image from 'components/atoms/Image'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import DropdownMenu from 'components/molecules/DropdownMenu'
import { TABS } from './consts'
import ProfileItemTooltip from '../ProfileItemTooltip'
import SocialProfileImage from '../SocialProfileImage'
import {
  MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS,
  MIN_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS,
  STATUS_COLORS,
  STATUS_FILTERS,
} from '../../consts'

const StyledNetworkTabsWrapper = styled(Flex)`
  gap: ${space.s};
  align-items: center;
  justify-content: center;
  width: 100%;
`

const StyledNetworkTabWrapper = styled(Flex)`
  height: 44px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 ${space.xs};
  margin-bottom: ${space.xs};
  cursor: pointer;
  border-radius: ${radius.l};
  ${({ isActive }) =>
    isActive &&
    `
    box-shadow: rgb(37 64 102 / 8%) 0px 2px 4px, rgb(118 139 168 / 14%) 0px 8px 50px; 
    background-color: ${COLOR_CONSTANTS.WHITE}
  `}
  &:hover {
    box-shadow: rgb(37 64 102 / 8%) 0px 2px 4px, rgb(118 139 168 / 14%) 0px 8px 50px;
  }
`

const StyledNetworkImage = styled(ImageWithFallback)`
  width: 22px;
  height: 22px;
  border-radius: ${radius.l};
  object-fit: contain;
`

const NetworkImageWrapper = styled(Flex)`
  position: absolute;
  bottom: 16px;
  right: 0px;
  background-color: ${colors.white};
  border-radius: ${radius.m};
  cursor: pointer;
`

const StyledNotIncludedProfilesWrapper = styled(Flex)`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: ${fontSizes.xxs};
  border-radius: ${radius.pill};
  width: 24px;
  height: 24px;
  margin-top: -${space.s};
  background: ${COLOR_CONSTANTS.SOLITUDE};
`

const ShowMoreProfilesComponent = ({ activeTab, handleClickChangePost }) => {
  return (
    <Fragment>
      {activeTab.notIncludedPostsLength > 0 && (
        <StyledNetworkTabWrapper>
          <DropdownMenu
            WrapperComp={
              <StyledNotIncludedProfilesWrapper>+{activeTab.notIncludedPostsLength}</StyledNotIncludedProfilesWrapper>
            }
            right={0}
            isTriangleVisible={false}
            isDismissedOnClickInside
            top={30}
            minWidth="250px"
            zIndex={12}
          >
            <AutoSizer disableHeight>
              {({ width }) => (
                <List
                  height={
                    activeTab.notIncludedPostsLength > MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS
                      ? 300
                      : activeTab.notIncludedPostsLength * 30
                  }
                  overscanRowCount={10}
                  noRowsRenderer={() => {}}
                  rowCount={activeTab.notIncludedPostsLength}
                  rowHeight={30}
                  width={width}
                  rowRenderer={({ index, key, style }) => {
                    const post = activeTab.notIncludedPosts[index]

                    const {
                      id: postId,
                      profile: {
                        profileName: name,
                        profilePictureUrl: picture_url,
                        network: code,
                        profileUsername: username,
                      },
                      status,
                    } = post

                    let updatedName = username || name

                    if (updatedName) {
                      updatedName = `@${updatedName}`
                    }

                    const isActive = activeTab.post.id === postId

                    return (
                      <Flex flexDirection="column" style={style} key={key}>
                        <DropdownMenu.Item
                          label={updatedName}
                          PictureComp={
                            <Flex position="relative" alignItems="center" justifyContent="center">
                              <Flex
                                mx="xxs"
                                key={status}
                                width="8px"
                                height="8px"
                                borderRadius={radius.pill}
                                bg={STATUS_COLORS[status]}
                              />
                              <Flex alignItems="center" mx="s">
                                <SocialProfileImage picture_url={picture_url} code={code} width="22px" height="22px" />
                              </Flex>
                            </Flex>
                          }
                          onClick={() => {
                            handleClickChangePost({ post })
                          }}
                          iconNameEnding="Checkmark"
                          iconWidthEnding={isActive ? '10px' : '0px'}
                          iconColorEnding={colors.primaryText}
                        />
                      </Flex>
                    )
                  }}
                />
              )}
            </AutoSizer>
          </DropdownMenu>
        </StyledNetworkTabWrapper>
      )}
    </Fragment>
  )
}

ShowMoreProfilesComponent.propTypes = {
  activeTab: PropTypes.object.isRequired,
  handleClickChangePost: PropTypes.func.isRequired,
}

const TabsComponent = ({
  activeTab,
  networks,
  handleClickChangeNetwork,
  handleClickChangePost,
  isSmallPreview,
  tooltipRef,
}) => {
  return (
    <Fragment>
      {isSmallPreview ? (
        <Fragment>
          <StyledNetworkTabsWrapper>
            {TABS.map((network) => {
              const foundNetwork = networks.find((item) => item.network === network)
              if (!foundNetwork) {
                return null
              }

              const { statuses } = foundNetwork

              return (
                <StyledNetworkTabWrapper
                  key={network}
                  flexDirection="column"
                  onClick={() => {
                    handleClickChangeNetwork({ network })
                  }}
                  isActive={activeTab.network === network}
                >
                  <Flex flexDirection="column">
                    <StyledNetworkImage src={getSocialNetworkIconByName(network).enabled} />
                    <Flex mt="xs" width="100%" justifyContent="center">
                      {statuses.map(({ status }) => (
                        <Flex
                          mx="xxs"
                          key={status}
                          width="8px"
                          height="8px"
                          borderRadius={radius.pill}
                          bg={STATUS_COLORS[status]}
                        />
                      ))}
                    </Flex>
                  </Flex>
                </StyledNetworkTabWrapper>
              )
            })}
          </StyledNetworkTabsWrapper>
          <Flex mt="m" alignItems="center" justifyContent="center">
            {activeTab.activePosts.length > MIN_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS && (
              <Grid
                mx="s"
                gridTemplateColumns={`repeat(${
                  activeTab.notIncludedPostsLength > 0 ? 8 : activeTab.activePosts.length
                }, 1fr)`}
                gridGap="s"
              >
                {activeTab.activePosts.map((post) => {
                  const {
                    id: postId,
                    profile: { profilePictureUrl: picture_url, network: code },
                    status,
                  } = post

                  const networkImage = getSocialNetworkIconByName(code).enabled
                  return (
                    <Flex
                      key={postId}
                      onClick={() => {
                        handleClickChangePost({ post })
                      }}
                    >
                      <Flex>
                        <StyledNetworkTabWrapper position="relative" isActive={activeTab.post.id === postId}>
                          <StyledNetworkImage
                            source={picture_url || DEFAULT_SOCIAL_PROFILE_SOURCE}
                            fallbackSource={DEFAULT_SOCIAL_PROFILE_SOURCE}
                          />
                          <Flex mt="xs" width="100%" justifyContent="center">
                            <Flex
                              mx="xxs"
                              key={status}
                              width="8px"
                              height="8px"
                              borderRadius={radius.pill}
                              bg={STATUS_COLORS[status]}
                            />
                          </Flex>
                          <NetworkImageWrapper justifyContent="center" alignItems="center">
                            <Image src={networkImage} width="12px" height="12px" />
                          </NetworkImageWrapper>
                        </StyledNetworkTabWrapper>
                      </Flex>
                    </Flex>
                  )
                })}

                <ShowMoreProfilesComponent activeTab={activeTab} handleClickChangePost={handleClickChangePost} />
              </Grid>
            )}
          </Flex>
        </Fragment>
      ) : (
        <Fragment>
          {TABS.map((network) => {
            const foundNetwork = networks.find((item) => item.network === network)
            if (!foundNetwork) {
              return null
            }

            const { statuses } = foundNetwork

            let hoverNetworkText = ''

            statuses.forEach(({ status, posts_counter }) => {
              const foundStatus = STATUS_FILTERS.find(({ id }) => id === status)
              if (foundStatus) {
                hoverNetworkText += `${
                  hoverNetworkText ? ', ' : ''
                }${posts_counter} ${foundStatus.name.toLowerCase()} ${posts_counter === 1 ? 'post' : 'posts'}`
              }
            })

            return (
              <Flex key={network}>
                <StyledNetworkTabWrapper
                  flexDirection="column"
                  onClick={() => {
                    handleClickChangeNetwork({ network })
                  }}
                  isActive={activeTab.network === network}
                >
                  <Flex
                    flexDirection="column"
                    onMouseEnter={(e) => {
                      tooltipRef.current.handleShowTooltip({
                        contentComp: (
                          <Box width="300px" p="s">
                            <Text textAlign="center">{hoverNetworkText}</Text>
                          </Box>
                        ),
                        wrapperComp: e.currentTarget,
                      })
                    }}
                    onMouseLeave={() => {
                      tooltipRef.current.handleHideTooltip()
                    }}
                  >
                    <StyledNetworkImage src={getSocialNetworkIconByName(network).enabled} />
                    <Flex mt="xs" width="100%" justifyContent="center">
                      {statuses.map(({ status }) => (
                        <Flex
                          mx="xxs"
                          key={status}
                          width="8px"
                          height="8px"
                          borderRadius={radius.pill}
                          bg={STATUS_COLORS[status]}
                        />
                      ))}
                    </Flex>
                  </Flex>
                </StyledNetworkTabWrapper>
                {activeTab.network === network &&
                  activeTab.activePosts.length > MIN_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS && (
                    <Grid mx="s" gridTemplateColumns="repeat(5, 1fr)" gridGap="s">
                      {activeTab.activePosts.map((post) => {
                        const {
                          id: postId,
                          profile: {
                            profileName: name,
                            profilePictureUrl: picture_url,
                            network: code,
                            display,
                            profileUsername: username,
                            profileUrl: profile_url,
                            profile_id,
                            type: profile_type,
                          },
                          status,
                        } = post

                        const networkImage = getSocialNetworkIconByName(code).enabled
                        return (
                          <Flex
                            key={postId}
                            onClick={() => {
                              handleClickChangePost({ post })
                            }}
                          >
                            <Flex>
                              <StyledNetworkTabWrapper
                                position="relative"
                                onMouseEnter={(e) => {
                                  tooltipRef.current.handleShowTooltip({
                                    contentComp: (
                                      <ProfileItemTooltip
                                        profile={{
                                          code,
                                          picture_url,
                                          profile_url,
                                          name,
                                          username,
                                          display,
                                          profile_id,
                                          type: profile_type,
                                        }}
                                      />
                                    ),
                                    wrapperComp: e.currentTarget,
                                  })
                                }}
                                onMouseLeave={() => {
                                  tooltipRef.current.handleHideTooltip()
                                }}
                                isActive={activeTab.post.id === postId}
                              >
                                <StyledNetworkImage
                                  source={picture_url || DEFAULT_SOCIAL_PROFILE_SOURCE}
                                  fallbackSource={DEFAULT_SOCIAL_PROFILE_SOURCE}
                                />
                                <Flex mt="xs" width="100%" justifyContent="center">
                                  <Flex
                                    mx="xxs"
                                    key={status}
                                    width="8px"
                                    height="8px"
                                    borderRadius={radius.pill}
                                    bg={STATUS_COLORS[status]}
                                  />
                                </Flex>
                                <NetworkImageWrapper justifyContent="center" alignItems="center">
                                  <Image src={networkImage} width="12px" height="12px" />
                                </NetworkImageWrapper>
                              </StyledNetworkTabWrapper>
                            </Flex>
                          </Flex>
                        )
                      })}

                      <ShowMoreProfilesComponent activeTab={activeTab} handleClickChangePost={handleClickChangePost} />
                    </Grid>
                  )}
              </Flex>
            )
          })}
        </Fragment>
      )}
    </Fragment>
  )
}

TabsComponent.propTypes = {
  activeTab: PropTypes.object.isRequired,
  networks: PropTypes.array.isRequired,
  handleClickChangeNetwork: PropTypes.func.isRequired,
  handleClickChangePost: PropTypes.func.isRequired,
  isSmallPreview: PropTypes.bool.isRequired,
  tooltipRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]).isRequired,
}

export default TabsComponent
