import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'components/atoms/Typography'
import styled from 'styled-components'
import { fontSizes, COLOR_CONSTANTS } from 'theme'

const CounterText = styled(Text)`
  text-align: right;
`
const CounterTextError = styled(Text)`
  text-align: right;
  color: ${COLOR_CONSTANTS.RED};
`

const Counter = ({ counter, max, fontSize }) => {
  return counter <= max ? (
    <CounterText fontSize={fontSize}>
      {counter} / {max}
    </CounterText>
  ) : (
    <CounterTextError fontSize={fontSize}>
      {counter} / {max}
    </CounterTextError>
  )
}

Counter.defaultProps = {
  max: 1000,
  fontSize: fontSizes.xs,
}

Counter.propTypes = {
  counter: PropTypes.number.isRequired,
  max: PropTypes.number,
  fontSize: PropTypes.string,
}

export default Counter
