import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import { radius, colors, COLOR_CONSTANTS } from 'theme'

const Wrapper = styled(Flex)`
  user-select: none;
  position: relative;
  cursor: pointer;
  ${({ disabled }) => disabled && `cursor:not-allowed; opacity:0.6;`}
`

const StyledFlex = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  position: absolute;
  border: ${({ isChecked }) => (isChecked ? `1px solid ${colors.primaryText}` : `1px solid ${COLOR_CONSTANTS.COTTON}`)};
  border-radius: ${radius.s};
  background-color: ${({ isChecked, selectedColor }) => (isChecked ? selectedColor : colors.white)};
`

const Checkbox = ({ isChecked, onClick, children, disabled = false }) => {
  return (
    <Wrapper alignItems="center" onClick={onClick} disabled={disabled}>
      <StyledFlex isChecked={isChecked} selectedColor={COLOR_CONSTANTS.WHITE}>
        {isChecked && <Image source="/assets/vistasocial/checkmark.svg" />}
      </StyledFlex>
      {children}
    </Wrapper>
  )
}

Checkbox.defaultProps = {
  disabled: false,
  onClick: () => {},
}

Checkbox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
}

export default Checkbox
