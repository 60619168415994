import React, { forwardRef, Fragment, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import Alert from 'react-s-alert'
import { Scrollbars } from 'react-custom-scrollbars-2'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment-timezone'
import debounce from 'lodash.debounce'
import { transparentize } from 'polished'
import { useDropzone } from 'react-dropzone'
import useDrivePicker from 'react-google-drive-picker-no-default-scope'
import { COLOR_CONSTANTS, colors, radius, space, fontSizes } from 'theme'
import {
  AUDIO,
  ERROR_MESSAGE,
  FEATURE_FIND_CONTENT,
  FEATURE_CANVA,
  FEATURE_DRIVES,
  IMAGE,
  IMAGE_DYNAMIC,
  LABELS_TYPE_MEDIA,
  MEDIA_TYPE_UNUSED,
  PERMISSION_PUBLISH,
  VIDEO,
  VIDEO_DYNAMIC,
  DOCUMENT,
} from 'consts'
import request from 'utils/request'
import { checkIfPlanHasFeatureEnabled, hasGlobalAccessManage } from 'utils/feature'
import errorHelper from 'utils/errorHelper'
import { getLabels } from 'shared/LabelEditModal/helpers'
import LabelEditModal from 'shared/LabelEditModal'
import { Text } from 'components/atoms/Typography'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import Icon from 'components/atoms/Icon'
import Image from 'components/atoms/Image'
import Divider from 'components/atoms/Divider'
import DropdownMenu from 'components/molecules/DropdownMenu'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import { DEFAULT_FILTERS, DEFAULT_MEDIA_USAGE_TYPES } from './consts'
import {
  AUDIO_EXTENSIONS,
  AUDIO_MIME_TYPES,
  DOCUMENT_EXTENSIONS,
  DOCUMENT_MIME_TYPES,
  IMAGE_EXTENSIONS,
  IMAGE_MIME_TYPES,
  IMPORT_FROM_FIND_GIFS,
  IMPORT_FROM_FIND_IMAGES,
  IMPORT_FROM_FIND_VIDEOS,
  IMPORT_FROM_INSTAGRAM_HASHTAG,
  IMPORT_FROM_INSTAGRAM_USER,
  MAX_DOCUMENTS_ATTACHMENTS_ALLOWED,
  MAX_IMAGES_ATTACHMENTS_ALLOWED,
  MAX_VIDEOS_ATTACHMENTS_ALLOWED,
  POST_IMAGE,
  POST_VIDEO,
  POST_DOCUMENT,
  ROUTE_MEDIA,
  VIDEO_EXTENSIONS,
  VIDEO_MIME_TYPES,
  MAX_MIXED_MEDIAS_ATTACHMENTS_ALLOWED,
  POST_MIXED_MEDIA,
} from '../Calendar/consts'
import CalendarItemTooltip from '../Calendar/components/CalendarItemTooltip'
import ImageEditModal from '../Calendar/components/ImageEditModal'
import VideoThumbnailModal from '../Calendar/components/VideoThumbnailModal'
import { getSelectedMediaFiltersFromStorage, handleMediaFileChange, saveSelectedMediaFiltersToStorage } from './helper'
import Item from './components/Item'
import MediaSettings from './MediaSettings'
import MetaInformationModal from './components/MetaInformationModal'
import PublisherWrapper from '../Calendar/components/PublisherWrapper'
import {
  dropboxPickerConfiguration,
  googleDrivePickerConfiguration,
  oneDrivePickerConfiguration,
  uploadMediaToServerByFormDataHelper,
  getSelectedProfileGroupsAndTimezone,
} from '../Calendar/helpers'
import MediaFilters from './components/MediaFilters'
import CanvaDesignModal from '../Calendar/components/CanvaDesignModal'
import VistaCreateDesignModal from '../Calendar/components/VistaCreateDesignModal'
import MediaDetailModal from './components/MediaDetailModal'
import MediaUploadByLinkModal from '../Calendar/components/MediaUploadByLinkModal'
import FavoriteItem from './components/FavoriteItem'
import MediaTrendingModal from './components/MediaTrendingModal'
import VideoPickSoundModal from './components/VideoPickSoundModal'
import SliderPostsPreview from '../Calendar/components/SliderPostsPreview'
import DynamicMediaModal from '../Calendar/components/DynamicMediaModal'
import AddFolderModal from './components/AddFolderModal'
import FolderItem from './components/FolderItem'

const DEFAULT_MENU_HEIGHT = '34px'

const StyledDialogBodyWrapper = styled(Flex)`
  height: 100%;
  overflow: hidden;
`

const MediaWrapper = styled(Flex)`
  width: 100%;
  position: relative;
  border-radius: ${radius.l};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  &:hover {
    box-shadow: 0px 0px 10px 5px rgb(39 40 49 / 5%);
  }
`

const MediaSectionWrapper = styled(Flex)`
  border-top: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
`

const FavoriteMediaWrapper = styled(Flex)`
  width: 100px;
  height: 100px;
  position: relative;
  border-radius: ${radius.l};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  &:hover {
    box-shadow: 0px 0px 10px 5px rgb(39 40 49 / 5%);
  }
`

const StyledProgressWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
  background-color: ${transparentize(0.5, COLOR_CONSTANTS.WHITE)};
`

const StyledRoundedButton = styled(Button.Gray)`
  padding: 0;
  min-width: 32px;
  min-height: 32px;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: ${radius.pill};
  &:hover {
    background-color: ${COLOR_CONSTANTS.DAISY};
  }
`

const StyledFooter = styled(Flex)`
  box-shadow: rgb(0 0 0 / 5%) 0px -0.25rem 0.3125rem;
  backdrop-filter: blur(0.5rem);
  border-top-right-radius: ${radius.l};
  border-top-left-radius: ${radius.l};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  border-bottom: none;
`

const StyledText = styled(Text)`
  cursor: pointer;
`

const StyledDropDownSubmenuWrapper = styled(Flex)`
  display: none;
  flex-direction: column;
  position: absolute;
  width: 210px;

  padding: ${space.xs} 0;
  background: ${COLOR_CONSTANTS.WHITE};
  ${({ showAddMediaDropdownPosition }) => (showAddMediaDropdownPosition === 'left' ? 'left: -211px' : 'right: -211px')};
`

const StyledDropDownMenuItem = styled('button')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${space.s};
  text-align: left;
  border: none;
  background: none;
  outline: none;
  color: ${colors.primaryText};
  min-width: 175px;
  cursor: pointer;
  height: 30px;
  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white} !important;

    svg {
      stroke: ${colors.white};
      fill: ${colors.white};
    }

    p {
      color: ${colors.white};
    }
  }
`

const StyledHiddenFilters = styled(Flex)`
  align-items: center;
  visibility: ${({ visibility }) => visibility};
`

const StyledFilesWrapper = styled(Flex)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  background-color: rgba(39, 40, 49, 0.3);
  z-index: 2147483002;
  transition: border 0.24s ease-in-out;
  border: 2px dashed ${COLOR_CONSTANTS.WHITE};
  visibility: hidden;
  ${({ show }) => show && `visibility: visible;`}
`

const StyledTrendingSoundsWrapper = styled(Flex)`
  border-radius: 3px;
  cursor: pointer;
`

const StyledFolderBackNavigationText = styled(Text)`
  cursor: pointer;
  text-decoration: none;
`

const StyledIconChevronLeft = styled(Icon.VistaSocialChevronRight)`
  transform: rotate(-180deg);
  cursor: pointer;
  fill: ${colors.secondaryText};
`

const RemoveMoveItemsWrapper = styled(Box)`
  position: absolute;
  top: -9px;
  right: -16px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledUploadProgressIndicatorText = styled(Text)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${fontSizes.xs};
  color: ${colors.primary};
`

const { MEDIA_SHOW_TYPE_FAVORITE, MEDIA_SHOW_TYPE_ALL } = {
  MEDIA_SHOW_TYPE_FAVORITE: 'favorite',
  MEDIA_SHOW_TYPE_ALL: 'all',
}

const { GROUPED_MEDIA_TYPE_TODAY, GROUPED_MEDIA_TYPE_WEEK, GROUPED_MEDIA_TYPE_MONTH, GROUPED_MEDIA_TYPE_EARLIER } = {
  GROUPED_MEDIA_TYPE_TODAY: 'today',
  GROUPED_MEDIA_TYPE_WEEK: 'week',
  GROUPED_MEDIA_TYPE_MONTH: 'month',
  GROUPED_MEDIA_TYPE_EARLIER: 'earlier',
}

const IMAGE_EXTENSIONS_JOINED = IMAGE_EXTENSIONS.join(',')
const VIDEO_EXTENSIONS_JOINED = VIDEO_EXTENSIONS.join(',')
const AUDIO_EXTENSIONS_JOINED = [...AUDIO_EXTENSIONS, ...VIDEO_EXTENSIONS].join(',')
const DOCUMENT_EXTENSIONS_JOINED = DOCUMENT_EXTENSIONS.join(',')

const DEFAULT_MEDIA_SETTINGS_MODAL_OPTIONS = {
  isOpen: false,
  uploadedMedias: [],
}

const DEFAULT_PUBLISHER_WRAPPER_MODAL_OPTIONS = {
  isOpen: false,
  type: null,
  post: null,
  findContentMenuItemType: null,
  findContentMediaType: null,
}

const DEFAULT_MEDIA_LABELS_MODAL_OPTIONS = { isOpen: false, data: null }

const DEFAULT_MEDIA_DETAIL_MODAL_OPTIONS = { isOpen: false, data: null }

const MEDIA_UPLOAD_BY_LINK_MODAL_OPTIONS = {
  isOpen: false,
  type: null,
  hasError: '',
}

const MEDIA_TRENDING_MODAL_OPTIONS = {
  isOpen: false,
  type: null,
}

const DYNAMIC_MEDIA_MODAL_OPTIONS = {
  isOpen: false,
  media: null,
}

const DEFAULT_GROUPED_MEDIAS = [
  { type: GROUPED_MEDIA_TYPE_TODAY, title: 'Today', medias: [] },
  { type: GROUPED_MEDIA_TYPE_WEEK, title: 'Last 7 days', medias: [] },
  { type: GROUPED_MEDIA_TYPE_MONTH, title: 'Last 30 days', medias: [] },
  { type: GROUPED_MEDIA_TYPE_EARLIER, title: 'Earlier', medias: [] },
]

const DROPDOWN_INTERVALS = {
  [IMAGE]: null,
  [VIDEO]: null,
  [AUDIO]: null,
  [DOCUMENT]: null,
}

const DEFAULT_BLOCK_MEDIA_OPTIONS = {
  iconNameEnding: 'Lock',
  iconWidthEnding: '14px',
  iconColorEnding: colors.error,
}

const DEFAULT_ADD_FOLDER_MODAL_OPTIONS = {
  isOpen: false,
  data: null,
}

const SearchInputComponent = forwardRef(({ handleFilterMediaBySearch, isSmall }, inputSearchText) => {
  const [searchString, setSearchString] = useState('')

  useImperativeHandle(inputSearchText, () => ({
    getSearchString() {
      return searchString
    },
  }))

  const handleChangePostText = (text) => {
    setSearchString(text)
    handleFilterMediaBySearch(text)
  }

  return (
    <Input
      placeholder="Search"
      label=""
      value={searchString}
      onChange={(e) => {
        handleChangePostText(e.target.value)
      }}
      height={DEFAULT_MENU_HEIGHT}
      width={isSmall ? '80px' : { mobile: '80px', tablet: '135px', desktopWide: '150px' }}
    />
  )
})

SearchInputComponent.propTypes = {
  handleFilterMediaBySearch: PropTypes.func.isRequired,
  isSmall: PropTypes.bool.isRequired,
}

const MediaBodyComponent = forwardRef(
  (
    {
      user,
      confirm,
      entities,
      dragMediaOptions,
      setDragMediaOptions,
      onDropMedia,
      isSmall,
      isSelectable,
      isFavorable,
      hasItemControls,
      handleClickCreatePost,
      handleUpdateComponentsAfterMediasUpdate,
      handleSpecialItemOnClick,
      defaultTypes: DEFAULT_TYPES,
      showAddMediaDropdownPosition,
      shouldSaveFolderPath,
      ...props
    },
    mediaComponentRef
  ) => {
    const tooltipRef = useRef(null)
    const inputImageFileRef = useRef(null)
    const inputVideoFileRef = useRef(null)
    const inputAudioFileRef = useRef(null)
    const inputDocumentFileRef = useRef(null)
    const mediaFiltersRef = useRef(null)
    const inputSearchText = useRef(null)
    const scrollbarsRef = useRef(null)

    const router = useRouter()

    const { query: { id: routerFolderId } = {}, asPath } = router

    const [uploadedMedias, setUploadedMedias] = useState([])
    const [favoriteMedias, setFavoriteMedias] = useState([])
    const [folderMedias, setFolderMedias] = useState([])
    const [selectedMedias, setSelectedMedias] = useState([])
    const [selectedMediaOptionsForMoving, setSelectedMediaOptionsForMoving] = useState({
      path: '',
      media_ids: {},
      counter: 0,
      isMoving: false,
    })
    const [isGettingMedia, setIsGettingMedia] = useState(true)
    const [isMediaUpdating, setIsMediaUpdating] = useState(false)
    const [mediaCounter, setMediaCounter] = useState(0)
    const [groupedMedias, setGroupedMedias] = useState([...DEFAULT_GROUPED_MEDIAS])

    const [uploadingMedias, setUploadingMedias] = useState([])
    const [selectedMedia, setSelectedMedia] = useState({})
    const [isOpenVideoThumbnailModal, setIsOpenVideoThumbnailModal] = useState(false)
    const [isOpenVideoPickSoundModal, setIsOpenVideoPickSoundModal] = useState(false)
    const [isThumbnailUpdating, setIsThumbnailUpdating] = useState(false)
    const [isOpenImageEditModal, setIsOpenImageEditModal] = useState(false)
    const [isMetaInformationModalOpen, setIsMetaInformationModalOpen] = useState(false)
    const [mediaSettingsOptions, setMediaSettingsOptions] = useState({ ...DEFAULT_MEDIA_SETTINGS_MODAL_OPTIONS })
    const [publisherWrapperModalOptions, setPublisherWrapperModalOptions] = useState({
      ...DEFAULT_PUBLISHER_WRAPPER_MODAL_OPTIONS,
    })
    const [mediaLabelsModalOptions, setMediaLabelsModalOptions] = useState({ ...DEFAULT_MEDIA_LABELS_MODAL_OPTIONS })
    const [mediaDetailModalOptions, setMediaDetailModalOptions] = useState({ ...DEFAULT_MEDIA_DETAIL_MODAL_OPTIONS })
    const [mediaUploadByLinkOptions, setMediaUploadByLinkOptions] = useState({ MEDIA_UPLOAD_BY_LINK_MODAL_OPTIONS })
    const [mediaTrendingModalOptions, setMediaTrendingModalOptions] = useState({ ...MEDIA_TRENDING_MODAL_OPTIONS })
    const [dynamicMediaOptions, setDynamicMediaOptions] = useState({ ...DYNAMIC_MEDIA_MODAL_OPTIONS })

    const [labels, setLabels] = useState([])
    const [extensions, setExtensions] = useState({ [IMAGE]: [], [VIDEO]: [], [AUDIO]: [], [DOCUMENT]: [] })
    const [users, setUsers] = useState([])

    const [isFinalPage, setIsFinalPage] = useState(false)
    const [page, setPage] = useState(0)
    const [isInitial, setIsInitial] = useState(true)
    const [featuresEnabled, setFeaturesEnabled] = useState({
      [FEATURE_FIND_CONTENT]: { enabled: true },
      [FEATURE_CANVA]: { enabled: true },
      [FEATURE_DRIVES]: { enabled: true },
    })
    const [newMedias, setNewMedias] = useState([])
    const [canvaDesignOptions, setCanvaDesignOptions] = useState({ isOpen: false })
    const [vistaCreateDesignOptions, setVistaCreateDesignOptions] = useState({ isOpen: false })
    const [mediaShowType, setMediaShowType] = useState(null)
    const [uploadingMediasAmount, setUploadingMediasAmount] = useState(0)
    const [sliderPostsPreviewData, setSliderPostsPreviewData] = useState(null)
    const [openPicker, authResponse] = useDrivePicker()
    const [savedGoogleDocsAuthResponse, setSavedGoogleDocsAuthResponse] = useState({})
    const [sharedDocsForUpload, setSharedDocsForUpload] = useState([])
    const [addFolderModalOptions, setAddFolderModalOptions] = useState({ ...DEFAULT_ADD_FOLDER_MODAL_OPTIONS })
    const [folderId, setFolderId] = useState(props.folderId)
    const [folder, setFolder] = useState(null)

    const isMediaUploading = uploadingMedias.length !== 0

    let selectedTypes = []

    if (mediaFiltersRef && mediaFiltersRef.current) {
      ;({
        filters: { selectedTypes },
      } = mediaFiltersRef.current.getFiltersData())
    }

    useImperativeHandle(mediaComponentRef, () => ({
      getMedias() {
        return uploadedMedias
      },
      setMedias({ medias }) {
        setUploadedMedias([...medias])
      },
      getUploadingMedias() {
        return uploadingMedias
      },
      setUploadingMedias({ uploadingMedias }) {
        setUploadingMedias([...uploadingMedias])
      },
      handleMediaFileChange({ acceptedFiles }) {
        if (acceptedFiles.length > 0 && uploadingMedias.length === 0 && !mediaSettingsOptions.isOpen) {
          handleMediaFileChange({ acceptedFiles, setUploadingMedias, setUploadingMediasAmount })
        }
      },
    }))

    const collectFiltersToQueryParameters = ({ selectedFilters, saveToStorage }) => {
      let updatedSelectedFilters = selectedFilters || mediaFiltersRef.current.getFiltersData()

      const { filters } = updatedSelectedFilters || {}

      if (!filters || JSON.stringify(filters) === JSON.stringify(DEFAULT_FILTERS)) {
        if (isSmall) {
          updatedSelectedFilters = { filters: { selectedTypes: DEFAULT_TYPES } }
        } else {
          const { selectedMediaFiltersFromStorage } = getSelectedMediaFiltersFromStorage({ id: user.id })
          updatedSelectedFilters = selectedMediaFiltersFromStorage || {
            filters: DEFAULT_FILTERS,
          }
        }
      }

      const {
        filters: {
          selectedLabels = [],
          selectedExtensions = [],
          selectedUsers = [],
          selectedTypes = [],
          selectedUsage = [],
        } = {},
      } = updatedSelectedFilters || {}

      const query_items = []

      if (props.selectedEntities && props.selectedEntities.length !== 0 && !folder) {
        query_items.push(`entities=${props.selectedEntities.map(({ id }) => id).join(',')}`)
      }

      if (folder) {
        query_items.push(`media_path=${folder.media_path.join(',')}`)
      }

      if (selectedTypes.length !== 0) {
        query_items.push(`types=${selectedTypes.map(({ value }) => value).join(',')}`)
      }

      if (selectedLabels.length !== 0) {
        query_items.push(`labels=${encodeURIComponent(selectedLabels.map(({ value }) => value).join(','))}`)
      }

      if (selectedExtensions.length !== 0) {
        query_items.push(`extensions=${selectedExtensions.map(({ mime }) => mime).join(',')}`)
      }

      if (selectedUsers.length !== 0) {
        query_items.push(`users=${selectedUsers.map(({ value }) => value).join(',')}`)
      }

      if (selectedUsage.length !== 0) {
        query_items.push(`usage=${selectedUsage.map(({ value }) => value).join(',')}`)
      }

      const searchTextValue = inputSearchText.current.getSearchString()

      query_items.push(`q=${encodeURIComponent(searchTextValue)}`)

      if (!isSmall && saveToStorage) {
        saveSelectedMediaFiltersToStorage({
          filters: {
            selectedTypes,
            selectedLabels,
            selectedExtensions,
            selectedUsers,
            searchTextValue,
            selectedUsage,
          },
          id: user.id,
        })
      }

      return query_items.join('&')
    }

    const searchForUploadedMedias = async ({ selectedFilters, page }) => {
      try {
        if (tooltipRef && tooltipRef.current) {
          tooltipRef.current.handleHideTooltip()
        }

        setIsGettingMedia(true)

        let path = `${ROUTE_MEDIA}?is_initial=${isInitial}&page=${page}`

        path += `&${collectFiltersToQueryParameters({ selectedFilters, saveToStorage: true })}`

        const response = await request({
          path,
        })

        const { error, data, usersForFilter, medias_count = [], hasNextPage } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          setIsFinalPage(!hasNextPage)

          if (page === 0) {
            setUploadedMedias([...data])
          } else {
            setUploadedMedias([...uploadedMedias, ...data])
          }

          if (isInitial) {
            setIsInitial(false)
          }

          if (usersForFilter) {
            setUsers(usersForFilter)
          }

          const { 0: { total } = {} } = medias_count

          if (typeof total !== 'undefined') {
            setMediaCounter(total)
          }
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: MediaBodyComponent.displayName,
          functionName: 'searchForUploadedMedias',
          errorMessage: `Can't get your uploaded medias. Please contact support.`,
        })
      } finally {
        setIsGettingMedia(false)
      }
    }

    const getExtensions = async () => {
      const updatedExtensions = { [IMAGE]: [], [VIDEO]: [], [AUDIO]: [], [DOCUMENT]: [] }
      try {
        const response = await request({
          path: `${ROUTE_MEDIA}/formats?type=all`,
        })

        if (response && !response.error) {
          response.forEach(({ mime }) => {
            if (IMAGE_MIME_TYPES[mime]) {
              updatedExtensions[IMAGE].push({
                value: IMAGE_MIME_TYPES[mime].map((item) => item.toLowerCase()).join(','),
                label: IMAGE_MIME_TYPES[mime][0].toUpperCase(),
                mime,
              })
            }
            if (VIDEO_MIME_TYPES[mime]) {
              updatedExtensions[VIDEO].push({
                value: VIDEO_MIME_TYPES[mime].map((item) => item.toLowerCase()).join(','),
                label: VIDEO_MIME_TYPES[mime][0].toUpperCase(),
                mime,
              })
            }
            if (AUDIO_MIME_TYPES[mime]) {
              updatedExtensions[AUDIO].push({
                value: AUDIO_MIME_TYPES[mime].map((item) => item.toLowerCase()).join(','),
                label: AUDIO_MIME_TYPES[mime][0].toUpperCase(),
                mime,
              })
            }
            if (DOCUMENT_MIME_TYPES[mime]) {
              updatedExtensions[DOCUMENT].push({
                value: DOCUMENT_MIME_TYPES[mime].map((item) => item.toLowerCase()).join(','),
                label: DOCUMENT_MIME_TYPES[mime][0].toUpperCase(),
                mime,
              })
            }
          })
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: MediaBodyComponent.displayName,
          functionName: 'getExtensions',
          showAlert: false,
        })
      } finally {
        setExtensions(updatedExtensions)
      }
    }

    const getFavoriteMedias = async ({ userId, selectedFilters }) => {
      if (isSmall) {
        setIsGettingMedia(true)
      }
      try {
        const path = `${ROUTE_MEDIA}/favorite?userId=${userId || ''}`

        // for the time being search would not affect favorite medias
        // path += `&${collectFiltersToQueryParameters({ selectedFilters })}` // this must be updated not to include entities maybe

        const response = await request({
          path,
        })

        const { error, data } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else if (isSmall) {
          setUploadedMedias(data)
        } else {
          setFavoriteMedias(data)
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: MediaBodyComponent.displayName,
          functionName: 'getFavoriteMedias',
          errorMessage: `Can't get your favorite medias. Please contact support.`,
        })
      } finally {
        if (isSmall) {
          setIsGettingMedia(false)
        }
      }
    }

    const getFolderMedias = async ({ selectedFilters }) => {
      try {
        let path = `${ROUTE_MEDIA}/folders`

        path += `?${collectFiltersToQueryParameters({ selectedFilters })}`

        const response = await request({
          path,
        })

        const { error, data } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          setFolderMedias([...data])
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: MediaBodyComponent.displayName,
          functionName: 'getFolderMedias',
          errorMessage: `Can't get your folders. Please contact support.`,
        })
      }
    }

    const getFolderData = async () => {
      try {
        const response = await request({
          path: `${ROUTE_MEDIA}/folder/${folderId}`,
        })

        const { error, data } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
          // eslint-disable-next-line no-use-before-define
          redirectToMediaLibrary()
        } else {
          setFolder({ ...data })
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: MediaBodyComponent.displayName,
          functionName: 'getFolderData',
        })
        // eslint-disable-next-line no-use-before-define
        redirectToMediaLibrary()
      }
    }

    const uploadedMediasForMediaPage = (event) => {
      const { detail: { medias } = {} } = event
      setNewMedias([...medias])
    }

    const getLabelsFunction = () => {
      getLabels({
        setLabels: (data) => {
          setLabels([...data.map((label) => ({ value: label, label })), { value: 'none', label: 'none' }])
        },
        type: LABELS_TYPE_MEDIA,
      })
    }

    useEffect(() => {
      getLabelsFunction()

      if (!folderId) {
        getExtensions()
      }

      setFeaturesEnabled(
        checkIfPlanHasFeatureEnabled({ user, features: [FEATURE_FIND_CONTENT, FEATURE_CANVA, FEATURE_DRIVES] })
      )

      window.addEventListener('uploadedMediasForMediaPage', uploadedMediasForMediaPage)

      return () => window.removeEventListener('uploadedMediasForMediaPage', uploadedMediasForMediaPage)
    }, [])

    // it could be that searchForUploadedMedias with passed page is wrong and must be setPage(0) and then called (useEffect)
    useEffect(() => {
      if (props.selectedEntities && !folderId) {
        searchForUploadedMedias({ page })
        getFolderMedias({})
      }
    }, [props.selectedEntities, folderId])

    useEffect(() => {
      if (folderId) {
        if (folder) {
          searchForUploadedMedias({ page })
          getFolderMedias({})
          setFavoriteMedias([])
        }
      } else if (!isSmall) {
        getFavoriteMedias({})
      }
    }, [folder])

    useEffect(() => {
      if (folderId) {
        getFolderData()
      }

      if (favoriteMedias.length !== 0) {
        setFavoriteMedias([])
      }

      if (uploadedMedias.length !== 0) {
        setUploadedMedias([])
      }

      if (folderMedias.length !== 0) {
        setFolderMedias([])
      }

      if (!isGettingMedia) {
        setIsGettingMedia(true)
      }

      if (isFinalPage) {
        setIsFinalPage(false)
      }

      if (page !== 0) {
        setPage(0)
      }
    }, [folderId])

    useEffect(() => {
      if (!isGettingMedia && !isFinalPage && !isInitial) {
        if (process.browser && page === 0 && scrollbarsRef && scrollbarsRef.current) {
          let top = 0

          const el = document.getElementById('media-section-wrapper')

          if (el) {
            const bounding = el.getBoundingClientRect()

            // eslint-disable-next-line prefer-destructuring
            top = bounding.top
          }

          scrollbarsRef.current.scrollTop(top)
        }

        searchForUploadedMedias({ page })
      }
    }, [page])

    useEffect(() => {
      if (shouldSaveFolderPath && ((!routerFolderId && folderId) || (routerFolderId && !folderId))) {
        setFolder(null)
        setFolderId(routerFolderId || null)

        if (!routerFolderId) {
          getFavoriteMedias({})
        }
      }
    }, [routerFolderId])

    useEffect(() => {
      if (uploadingMedias.length !== 0) {
        const findNotUploadedMedia = uploadingMedias.find(({ tempId }) => tempId)
        if (findNotUploadedMedia) {
          if (findNotUploadedMedia.url) {
            // eslint-disable-next-line no-use-before-define
            uploadMediaToServerByStringData(findNotUploadedMedia)
          } else if (findNotUploadedMedia.isDuplicate) {
            // eslint-disable-next-line no-use-before-define
            uploadDuplicateMediaToServer(findNotUploadedMedia)
          } else {
            // eslint-disable-next-line no-use-before-define
            uploadMediaToServerByFormData(findNotUploadedMedia)
          }
        } else {
          setMediaSettingsOptions({ ...{ isOpen: true, uploadedMedias: uploadingMedias } })
        }
      }
    }, [uploadingMedias])

    useEffect(() => {
      if (newMedias.length !== 0) {
        const foundShowDetailMedia = newMedias.find(({ showDetail }) => showDetail)

        if (foundShowDetailMedia) {
          // eslint-disable-next-line no-use-before-define
          handleClickOpenMediaDetailModal({ media: foundShowDetailMedia, index: -1 })
        }

        const { filters } = mediaFiltersRef.current.getFiltersData()
        const {
          selectedLabels = [],
          selectedUsers = [],
          selectedTypes = [],
          selectedExtensions = [],
          selectedUsage = [],
        } = filters || {}

        const searchTextValue = inputSearchText.current.getSearchString()

        setUploadingMedias([])

        let newMediasFiltered = newMedias

        if (selectedLabels.length) {
          newMediasFiltered = []
        }

        if (searchTextValue) {
          newMediasFiltered = []
        }

        if (selectedUsers.length) {
          newMediasFiltered = newMediasFiltered.filter((media) =>
            selectedUsers.find(({ value }) => value === media.user_gid)
          )
        }

        if (selectedUsage.length) {
          const foundUnusedType = selectedUsage.find(({ value }) => value === MEDIA_TYPE_UNUSED)
          if (!foundUnusedType) {
            newMediasFiltered = []
          }
        }

        if (selectedExtensions.length) {
          newMediasFiltered = newMediasFiltered.filter((media) =>
            selectedExtensions.find(({ value }) => value.includes(media.extension))
          )
        }

        const updatedSelectedTypes = [...selectedTypes]
        if (selectedTypes.length) {
          newMediasFiltered = newMediasFiltered.filter((media) => {
            const foundType = updatedSelectedTypes.find(({ value }) => value === media.type)

            if (!foundType) {
              updatedSelectedTypes.push(DEFAULT_TYPES.find(({ value }) => value === media.type))
            }

            return foundType
          })
        }

        for (let i = 0; i < newMediasFiltered.length; i++) {
          const { media_gid } = newMediasFiltered[i]

          const foundUploadedMedia = uploadedMedias.find((media) => media.media_gid === media_gid)
          if (foundUploadedMedia) {
            newMediasFiltered.splice(i, 1)
            i--
          }
        }

        setUploadedMedias([...newMediasFiltered, ...uploadedMedias])
        setNewMedias([])

        if (updatedSelectedTypes.length !== selectedTypes.length) {
          mediaFiltersRef.current.handleChangeFilters({ key: 'selectedTypes', value: updatedSelectedTypes })
        }
      }
    }, [newMedias])

    useEffect(() => {
      const today_start = moment()
        .startOf('day')
        .valueOf()

      const today_end = moment()
        .endOf('day')
        .valueOf()

      const week_start = moment()
        .subtract(7, 'day')
        .valueOf()

      const month_start = moment()
        .subtract(30, 'day')
        .valueOf()

      const groupedMedias = JSON.parse(JSON.stringify(DEFAULT_GROUPED_MEDIAS))

      uploadedMedias.forEach((item, index) => {
        item.index = index
        const { created_at_time } = item

        if (created_at_time >= today_start && created_at_time <= today_end) {
          groupedMedias[0].medias.push(item)
        } else if (created_at_time >= week_start && created_at_time <= today_end) {
          groupedMedias[1].medias.push(item)
        } else if (created_at_time >= month_start && created_at_time <= today_end) {
          groupedMedias[2].medias.push(item)
        } else {
          groupedMedias[3].medias.push(item)
        }
      })

      for (let i = 0; i < groupedMedias.length; i++) {
        if (groupedMedias[i].medias.length !== 0) {
          groupedMedias[i].showSelector = true
          break
        }
      }

      setGroupedMedias([...groupedMedias])
    }, [uploadedMedias])

    useEffect(() => {
      if (isInitial) {
        let updatedSelectedFilters
        if (isSmall) {
          updatedSelectedFilters = { selectedTypes: DEFAULT_TYPES }
        } else {
          const { selectedMediaFiltersFromStorage } = getSelectedMediaFiltersFromStorage({ id: user.id })
          updatedSelectedFilters = selectedMediaFiltersFromStorage
            ? selectedMediaFiltersFromStorage.filters
            : DEFAULT_FILTERS
        }

        mediaFiltersRef.current.setFiltersData({ data: updatedSelectedFilters })
      }
    }, [labels, extensions, users])

    useEffect(() => {
      if (mediaShowType) {
        if (mediaShowType === MEDIA_SHOW_TYPE_FAVORITE) {
          getFavoriteMedias({})
        } else {
          searchForUploadedMedias({ page })
        }
      }
    }, [mediaShowType])

    useEffect(() => {
      if (authResponse && authResponse.access_token) {
        setSavedGoogleDocsAuthResponse({ ...{ ...savedGoogleDocsAuthResponse, ...authResponse } })
      }
    }, [authResponse])

    useEffect(() => {
      if (sharedDocsForUpload && sharedDocsForUpload.length !== 0) {
        const { type } = savedGoogleDocsAuthResponse

        setUploadingMedias([
          ...uploadingMedias,
          ...sharedDocsForUpload.map(({ url }) => {
            const uploadingId = new Date().getTime() + Math.floor(Math.random() * 1000)
            return {
              url: savedGoogleDocsAuthResponse.access_token
                ? `${url}&access_token=${savedGoogleDocsAuthResponse.access_token}`
                : url,
              tempId: uploadingId,
              type,
              id: uploadingId,
            }
          }),
        ])
        setSharedDocsForUpload([])
      }
    }, [sharedDocsForUpload])

    const onDrop = useCallback((acceptedFiles) => {
      if (acceptedFiles.length > 0 && uploadingMedias.length === 0 && !mediaSettingsOptions.isOpen) {
        handleMediaFileChange({ acceptedFiles, setUploadingMedias, setUploadingMediasAmount })
      }
    }, [])

    const { getRootProps, isDragActive } = useDropzone({ onDrop })

    const handleClickRemoveMedia = async ({ medias }) => {
      try {
        const response = await request({
          method: 'DELETE',
          path: `${ROUTE_MEDIA}`,
          body: { medias },
        })

        const { error, errors = [], removed = [] } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else if (errors.length !== 0) {
          Alert.error(`Some of the selected medias can't be removed. Please contact support.`, { timeout: 5000 })
        } else {
          Alert.success(removed.length === 1 ? `Media has been removed.` : `Medias have been removed.`, {
            timeout: 5000,
          })

          removed.forEach((media_gid) => {
            const foundUploadedMediaIndex = uploadedMedias.findIndex((media) => media.id === media_gid)
            if (foundUploadedMediaIndex > -1) {
              uploadedMedias.splice(foundUploadedMediaIndex, 1)
            }

            const foundFavoriteMediaIndex = favoriteMedias.findIndex((media) => media.id === media_gid)
            if (foundFavoriteMediaIndex > -1) {
              favoriteMedias.splice(foundFavoriteMediaIndex, 1)
            }

            const foundSelectedMediaIndex = selectedMedias.findIndex((media) => media.id === media_gid)
            if (foundSelectedMediaIndex > -1) {
              selectedMedias.splice(foundSelectedMediaIndex, 1)
            }
          })

          // eslint-disable-next-line no-use-before-define
          handleClickCloseMediaDetailModal()

          setFavoriteMedias([...favoriteMedias])
          setUploadedMedias([...uploadedMedias])
          setSelectedMedias([...selectedMedias])
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: MediaBodyComponent.displayName,
          functionName: 'handleClickRemoveMedia',
        })
      }
    }

    const handleClickOpenImageEditModal = ({ ...props }) => {
      setSelectedMedia({ ...props })
      setIsOpenImageEditModal(true)
    }

    const handleClickCloseImageEditModal = () => {
      setSelectedMedia({})
      setIsOpenImageEditModal(false)
    }

    const handleClickOpenVideoThumbnailModal = ({ ...props }) => {
      setSelectedMedia({ ...props })
      setIsOpenVideoThumbnailModal(true)
    }

    const handleClickCloseVideoThumbnailModal = () => {
      setSelectedMedia({})
      setIsOpenVideoThumbnailModal(false)
    }

    const handleClickOpenVideoPickSoundModal = ({ ...props }) => {
      setSelectedMedia({ ...props })
      setIsOpenVideoPickSoundModal(true)
    }

    const handleClickCloseVideoPickSoundModal = () => {
      setSelectedMedia({})
      setIsOpenVideoPickSoundModal(false)
    }

    const handleClickOpenMediaMetaInformationModal = ({ media }) => {
      media.needThumbnail = true
      setSelectedMedia({ ...media })
      setIsMetaInformationModalOpen(true)
    }

    const handleClickCloseMediaMetaInformationModal = () => {
      setIsMetaInformationModalOpen(false)
    }

    const handleClickCloseMediaSettingsModal = () => {
      setMediaSettingsOptions({ ...DEFAULT_MEDIA_SETTINGS_MODAL_OPTIONS })
    }

    const handleClickOpenPublisherWrapper = ({ ...props }) => {
      setPublisherWrapperModalOptions({ isOpen: true, ...props })
    }

    const handleClickClosePublisherWrapper = () => {
      setPublisherWrapperModalOptions({ ...DEFAULT_PUBLISHER_WRAPPER_MODAL_OPTIONS })
    }

    const handleClickOpenMediaLabelsModal = () => {
      const labelsTemp = {}
      const ids = []
      selectedMedias.forEach(({ labels = [], id }) => {
        labels.forEach((label) => {
          labelsTemp[label] = label
        })
        ids.push(id)
      })
      setMediaLabelsModalOptions({ isOpen: true, data: { labels: Object.keys(labelsTemp), ids } })
    }

    const handleClickCloseMediaLabelsModal = () => {
      setMediaLabelsModalOptions({ ...DEFAULT_MEDIA_LABELS_MODAL_OPTIONS })
    }

    const handleClickOpenMediaDetailModal = ({ media, index }) => {
      media.index = index
      setMediaDetailModalOptions({ isOpen: true, data: media })
    }

    const handleClickCloseMediaDetailModal = () => {
      setMediaDetailModalOptions({ ...DEFAULT_MEDIA_DETAIL_MODAL_OPTIONS })
    }

    const handleClickCloseCanvaModal = () => {
      setCanvaDesignOptions({ isOpen: false })
    }
    const handleClickCloseVistaCreateModal = () => {
      setVistaCreateDesignOptions({ isOpen: false })
    }

    const handleClickOpenMediaUploadByLinkModal = ({ type }) => {
      setMediaUploadByLinkOptions({ ...{ isOpen: true, type } })
    }

    const handleClickCloseMediaUploadByLinkModal = () => {
      setMediaUploadByLinkOptions({ ...MEDIA_UPLOAD_BY_LINK_MODAL_OPTIONS })
    }

    const handleClickOpenMediaTrendingModal = ({ type }) => {
      setMediaTrendingModalOptions({ ...{ isOpen: true, type } })
    }

    const handleClickCloseMediaTrendingModal = () => {
      setMediaTrendingModalOptions({ ...MEDIA_TRENDING_MODAL_OPTIONS })
    }

    const handleClickOpenMediaDynamicModal = ({ media }) => {
      setDynamicMediaOptions({ ...{ isOpen: true, media } })
    }

    const handleClickCloseMediaDynamicModal = () => {
      setDynamicMediaOptions({ ...DYNAMIC_MEDIA_MODAL_OPTIONS })
    }

    const handleClickGoogleDrive = ({ type }) => {
      const { access_token } = authResponse || {}

      if (access_token) {
        savedGoogleDocsAuthResponse.access_token = access_token
      }
      savedGoogleDocsAuthResponse.type = type

      setSavedGoogleDocsAuthResponse({ ...savedGoogleDocsAuthResponse })

      let MIME_TYPES = ''
      let VIEW_ID = 'DOCS_IMAGES_AND_VIDEOS'

      if (type === IMAGE) {
        MIME_TYPES = Object.keys(IMAGE_MIME_TYPES)
          .map((mime) => `image/${mime}`)
          .join(',')
      } else if (type === VIDEO) {
        MIME_TYPES = Object.keys(VIDEO_MIME_TYPES)
          .map((mime) => `video/${mime}`)
          .join(',')
      } else if (type === AUDIO) {
        MIME_TYPES = `${Object.keys(AUDIO_MIME_TYPES)
          .map((mime) => `audio/${mime}`)
          .join(',')},${Object.keys(VIDEO_MIME_TYPES)
          .map((mime) => `video/${mime}`)
          .join(',')}`

        VIEW_ID = 'DOCS'
      } else if (type === DOCUMENT) {
        MIME_TYPES = Object.keys(DOCUMENT_MIME_TYPES)
          .map((mime) => `application/${mime}`)
          .join(',')
        VIEW_ID = 'DOCS'
      }

      const { config } = googleDrivePickerConfiguration({
        access_token,
        mime_types: MIME_TYPES,
        view_id: VIEW_ID,
        callbackFunction: (data) => {
          const { docs = [] } = data
          setSharedDocsForUpload([...docs])
        },
      })

      openPicker(config)
    }

    const handleClickDropBox = ({ type }) => {
      savedGoogleDocsAuthResponse.access_token = null
      savedGoogleDocsAuthResponse.type = type
      setSavedGoogleDocsAuthResponse({ ...savedGoogleDocsAuthResponse })

      let EXTENSIONS = []

      if (type === IMAGE) {
        EXTENSIONS = IMAGE_EXTENSIONS
      } else if (type === VIDEO) {
        EXTENSIONS = VIDEO_EXTENSIONS
      } else if (type === AUDIO) {
        EXTENSIONS = [...AUDIO_EXTENSIONS, ...VIDEO_EXTENSIONS]
      } else if (type === DOCUMENT) {
        EXTENSIONS = DOCUMENT_EXTENSIONS
      }

      const { config } = dropboxPickerConfiguration({
        extensions: EXTENSIONS,
        callbackFunction: (files) => {
          setSharedDocsForUpload([...files.map(({ link }) => ({ url: link }))])
        },
      })
      Dropbox.choose(config)
    }

    const handleClickOneDrive = ({ type }) => {
      savedGoogleDocsAuthResponse.access_token = null
      savedGoogleDocsAuthResponse.type = type
      setSavedGoogleDocsAuthResponse({ ...savedGoogleDocsAuthResponse })

      let EXTENSIONS = []

      if (type === IMAGE) {
        EXTENSIONS = IMAGE_EXTENSIONS
      } else if (type === VIDEO) {
        EXTENSIONS = VIDEO_EXTENSIONS
      } else if (type === AUDIO) {
        EXTENSIONS = [...AUDIO_EXTENSIONS, ...VIDEO_EXTENSIONS]
      } else if (type === DOCUMENT) {
        EXTENSIONS = DOCUMENT_EXTENSIONS
      }

      const { config } = oneDrivePickerConfiguration({
        extensions: EXTENSIONS.join(','),
        callbackFunction: (files) => {
          if (files && files.value && files.value.length !== 0) {
            setSharedDocsForUpload([...files.value.map((item) => ({ url: item['@microsoft.graph.downloadUrl'] }))])
          }
        },
      })

      OneDrive.open(config)
    }

    const handleUpdateFavoriteMedias = ({ data }) => {
      let needUpdate = false
      if (isSmall) {
        data.forEach((media) => {
          const foundFavoriteMediaIndex = uploadedMedias.findIndex(({ id }) => id === media.id)
          if (foundFavoriteMediaIndex > -1) {
            uploadedMedias[foundFavoriteMediaIndex] = media
            needUpdate = true
          }
        })

        if (needUpdate) {
          setUploadedMedias([...uploadedMedias])
        }
      } else {
        data.forEach((media) => {
          const foundFavoriteMediaIndex = favoriteMedias.findIndex(({ id }) => id === media.id)
          if (foundFavoriteMediaIndex > -1) {
            favoriteMedias[foundFavoriteMediaIndex] = media
            needUpdate = true
          }
        })

        if (needUpdate) {
          setFavoriteMedias([...favoriteMedias])
        }
      }
    }

    const handleUpdatePostLabels = ({ labels }) => {
      uploadedMedias.forEach((item, index) => {
        if (item.selected) {
          uploadedMedias[index].labels = labels
        }
      })
      setUploadedMedias([...uploadedMedias])
      handleClickCloseMediaLabelsModal()
      handleUpdateFavoriteMedias({ data: uploadedMedias })
      getLabelsFunction()
    }

    const handleClickSendToCompose = ({ post }) => {
      if (handleClickCreatePost) {
        handleClickCreatePost({ post })
        handleClickCloseMediaDetailModal()
      } else {
        handleClickOpenPublisherWrapper({ type: 'publish', post })
      }

      if (isOpenVideoPickSoundModal) {
        handleClickCloseVideoPickSoundModal()
      }
    }

    const handleUpdateMediaInMediaDetailModal = ({ data }) => {
      mediaDetailModalOptions.updatedData = data
      setMediaDetailModalOptions({ ...mediaDetailModalOptions })
    }

    const handleUpdateMedia = async ({ id, data }) => {
      try {
        setIsMediaUpdating(true)
        const response = await request({
          method: 'PATCH',
          body: data,
          path: `${ROUTE_MEDIA}/${id}`,
        })

        const { error, id: media_gid, data: updatedData } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          Alert.success(`Media has been updated.`, { timeout: 5000 })
          for (let i = 0; i < uploadedMedias.length; i++) {
            if (uploadedMedias[i].id === media_gid) {
              uploadedMedias[i] = { ...uploadedMedias[i], ...updatedData }

              // this is update for media detail modal information
              const { title, filename } = uploadedMedias[i]
              if (title || filename) {
                const foundInformationFileNameIndex = uploadedMedias[i].information.findIndex(
                  ({ title }) => title === 'FILE NAME'
                )
                if (foundInformationFileNameIndex > -1) {
                  uploadedMedias[i].information[foundInformationFileNameIndex].description = `${title}${
                    title && filename ? ' - ' : ''
                  }${filename}`
                }
              }

              let needToRemoveMedia = true

              if (props.selectedEntities.length !== 0) {
                for (let j = 0; j < uploadedMedias[i].entities.length; j++) {
                  const foundEntity = props.selectedEntities.find(
                    ({ id }) => id === uploadedMedias[i].entities[j].value
                  )

                  if (foundEntity) {
                    needToRemoveMedia = false
                    break
                  }
                }
              }

              const { filters } = mediaFiltersRef.current.getFiltersData()

              const { selectedLabels = [] } = filters || {}

              if (selectedLabels && selectedLabels.length !== 0) {
                for (let i = 0; i < labels.length; i++) {
                  const foundLabel = selectedLabels.find(({ id }) => id === labels[i].id)
                  if (selectedLabels.length !== 0 && !foundLabel) {
                    needToRemoveMedia = true
                    break
                  }
                }
              }

              handleUpdateMediaInMediaDetailModal({ data: uploadedMedias[i] })
              handleUpdateFavoriteMedias({ data: [uploadedMedias[i]] })
              handleUpdateComponentsAfterMediasUpdate({ medias: [uploadedMedias[i]] })

              if (needToRemoveMedia) {
                uploadedMedias.splice(i, 1)
              }

              break
            }
          }

          setUploadedMedias([...uploadedMedias])

          if (isMetaInformationModalOpen) {
            handleClickCloseMediaMetaInformationModal()
          }
          if (isOpenImageEditModal) {
            handleClickCloseImageEditModal()
          }
          getLabelsFunction()
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: MediaBodyComponent.displayName,
          functionName: 'handleUpdateMedia',
        })
      } finally {
        setIsMediaUpdating(false)
      }
    }

    const handleUpdateVideoThumbnail = async ({ id, base64url, thumb_offset }) => {
      if (base64url) {
        try {
          setIsThumbnailUpdating(true)
          const response = await request({
            method: 'PATCH',
            body: { thumb_offset, base64url },
            path: `${ROUTE_MEDIA}/${id}/thumbnail`,
          })

          const { error, id: media_gid, thumbnail_url, thumb_offset: thumb_offset2, url, updated_at_time } =
            response || {}

          if (!response || error) {
            Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
          } else {
            Alert.success('Video thumbnail has been updated', { timeout: 5000 })

            for (let i = 0; i < uploadedMedias.length; i++) {
              if (uploadedMedias[i].id === media_gid) {
                uploadedMedias[i].thumbnail_url = thumbnail_url
                uploadedMedias[i].thumbnail_url_small = thumbnail_url
                uploadedMedias[i].thumb_offset = thumb_offset2
                uploadedMedias[i].url = url
                uploadedMedias[i].updated_at_time = updated_at_time

                handleUpdateFavoriteMedias({ data: [uploadedMedias[i]] })
                handleUpdateMediaInMediaDetailModal({ data: uploadedMedias[i] })
                handleUpdateComponentsAfterMediasUpdate({ medias: [uploadedMedias[i]] })
                break
              }
            }
            setUploadedMedias([...uploadedMedias])

            handleClickCloseVideoThumbnailModal()
          }
        } catch (error) {
          errorHelper({
            error,
            componentName: MediaBodyComponent.displayName,
            functionName: 'handleUpdateVideoThumbnail',
          })
        } finally {
          setIsThumbnailUpdating(false)
        }
      } else {
        handleClickCloseVideoThumbnailModal()
      }
    }

    const handleUpdateAudioCover = ({ id, thumbnail_url, thumbnail_url_small, url }) => {
      for (let i = 0; i < uploadedMedias.length; i++) {
        if (uploadedMedias[i].id === id) {
          uploadedMedias[i].thumbnail_url = thumbnail_url
          uploadedMedias[i].thumbnail_url_small = thumbnail_url_small
          uploadedMedias[i].url = url

          handleUpdateFavoriteMedias({ data: [uploadedMedias[i]] })
          handleUpdateMediaInMediaDetailModal({ data: uploadedMedias[i] })
          handleUpdateComponentsAfterMediasUpdate({ medias: [uploadedMedias[i]] })
          break
        }
      }
      setUploadedMedias([...uploadedMedias])
    }

    const handleUpdateVideoPickSound = ({ id, url, updated_at_time }) => {
      for (let i = 0; i < uploadedMedias.length; i++) {
        if (uploadedMedias[i].id === id) {
          uploadedMedias[i].url = url
          uploadedMedias[i].updated_at_time = updated_at_time

          handleUpdateFavoriteMedias({ data: [uploadedMedias[i]] })
          handleUpdateMediaInMediaDetailModal({ data: uploadedMedias[i] })
          handleUpdateComponentsAfterMediasUpdate({ medias: [uploadedMedias[i]] })
          break
        }
      }
      setUploadedMedias([...uploadedMedias])
    }

    const handleUpdateDynamicMedia = ({ media }) => {
      for (let i = 0; i < uploadedMedias.length; i++) {
        if (uploadedMedias[i].id === media.id) {
          uploadedMedias[i].media = media

          handleUpdateFavoriteMedias({ data: [uploadedMedias[i]] })
          handleUpdateMediaInMediaDetailModal({ data: uploadedMedias[i] })
          handleUpdateComponentsAfterMediasUpdate({ medias: [uploadedMedias[i]] })
          break
        }
      }
      setUploadedMedias([...uploadedMedias])
      handleClickCloseMediaDynamicModal()
    }

    const uploadMediaToServerByFormData = async ({ file, tempId: uploadingId, replacement_type }) => {
      const { tempId, media, error } = await uploadMediaToServerByFormDataHelper({
        file,
        uploadingId,
        replacement_type,
      })

      const findUploadedMediaIndex = uploadingMedias.findIndex((media) => media.tempId === Number(tempId))

      if (error) {
        if (findUploadedMediaIndex > -1) {
          uploadingMedias.splice(findUploadedMediaIndex, 1)
          setUploadingMedias([...uploadingMedias])
        }
        Alert.error(error, { timeout: 5000 })
      } else if (findUploadedMediaIndex > -1) {
        delete media.tempId
        uploadingMedias[findUploadedMediaIndex] = media
        setUploadingMedias([...uploadingMedias])
      }
    }

    const handleMediaFile = async (event, replacementType) => {
      if (event.currentTarget.files.length !== 0) {
        handleMediaFileChange({ acceptedFiles: event.currentTarget.files, setUploadingMedias, replacementType })
      }
    }

    const handleAddDesignMedias = ({ url, type }) => {
      const uploadingId = new Date().getTime() + Math.floor(Math.random() * 1000)
      setUploadingMedias([...uploadingMedias, { tempId: uploadingId, id: uploadingId, url, type }])
    }

    const handleAddUrlMedias = ({ selectedMedias }) => {
      setUploadingMedias([...uploadingMedias, ...selectedMedias])
    }

    const handleAddTrendingMedias = ({ selectedMedias }) => {
      setUploadingMedias([...uploadingMedias, ...selectedMedias])
      handleClickCloseMediaTrendingModal()
    }

    const uploadDuplicateMediaToServer = async ({ id: mediaId, tempId: uploadingId, entity_gids = [] }) => {
      const el = document.getElementById('grouped-medias-wrapper')
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }

      let tempId

      try {
        const response = await request({
          method: 'POST',
          body: { mediaId, entity_gids },
          path: `${ROUTE_MEDIA}/duplicate?tempId=${uploadingId}`,
        })

        const { error, ...media } = response || {}
        ;({ tempId } = response || {})
        const findUploadedMediaIndex = uploadingMedias.findIndex((media) => media.tempId === Number(tempId))
        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
          if (findUploadedMediaIndex > -1) {
            uploadingMedias.splice(findUploadedMediaIndex, 1)
            setUploadingMedias([...uploadingMedias])
          }
        } else if (findUploadedMediaIndex > -1) {
          Alert.success(`Copy has been created.`, { timeout: 5000 })
          delete media.tempId
          uploadingMedias[findUploadedMediaIndex] = media
          setUploadingMedias([...uploadingMedias])
        }
      } catch (error) {
        const findUploadedMediaIndex = uploadingMedias.findIndex((media) => media.tempId === Number(tempId))

        if (findUploadedMediaIndex > -1) {
          uploadingMedias.splice(findUploadedMediaIndex, 1)
          setUploadingMedias([...uploadingMedias])
        }

        errorHelper({
          error,
          componentName: MediaBodyComponent.displayName,
          functionName: 'uploadDuplicateMediaToServer',
        })
      }
    }

    const uploadMediaToServerByStringData = async ({ url: mediaURL, tempId: uploadingId, type, share }) => {
      let tempId

      try {
        let path = `${ROUTE_MEDIA}?tempId=${uploadingId}`
        let body = { mediaURL, type }

        if (type === AUDIO) {
          path = `${ROUTE_MEDIA}/audio?tempId=${uploadingId}`
        }

        if (share) {
          body = { share }
        }

        const response = await request({
          method: 'POST',
          body,
          path,
        })

        const { error, ...media } = response || {}

        ;({ tempId } = response || {})

        const { media_gid } = media || {}

        const findUploadedMediaIndex = uploadingMedias.findIndex((media) => media.tempId === Number(tempId))

        if (!response || error || !media_gid) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
          if (findUploadedMediaIndex > -1) {
            uploadingMedias.splice(findUploadedMediaIndex, 1)
            setUploadingMedias([...uploadingMedias])
          }
          if (mediaUploadByLinkOptions.isOpen) {
            mediaUploadByLinkOptions.hasError = error || ERROR_MESSAGE
            setMediaUploadByLinkOptions({ ...mediaUploadByLinkOptions })
          }
        } else if (findUploadedMediaIndex > -1) {
          delete media.tempId
          uploadingMedias[findUploadedMediaIndex] = media
          setUploadingMedias([...uploadingMedias])
          if (mediaUploadByLinkOptions.isOpen) {
            handleClickCloseMediaUploadByLinkModal()
          }
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: MediaBodyComponent.displayName,
          functionName: 'uploadMediaToServerByStringData',
        })

        const findUploadedMediaIndex = uploadingMedias.findIndex((media) => media.tempId === Number(tempId))

        if (findUploadedMediaIndex > -1) {
          uploadingMedias.splice(findUploadedMediaIndex, 1)
          setUploadingMedias([...uploadingMedias])
        }

        if (mediaUploadByLinkOptions.isOpen) {
          mediaUploadByLinkOptions.hasError = error || ERROR_MESSAGE
          setMediaUploadByLinkOptions({ ...mediaUploadByLinkOptions })
        }
      }
    }

    const handleFilterMediaBySearch = debounce(() => {
      if (page === 0) {
        searchForUploadedMedias({ page })
        getFolderMedias({})
      } else {
        setIsFinalPage(false)
        setPage(0)
      }
    }, 300)

    const handleClickSelectMedia = ({ media }) => {
      const foundSelectedMediaIndex = selectedMedias.findIndex(({ id }) => id === media.id)
      if (foundSelectedMediaIndex > -1) {
        selectedMedias.splice(foundSelectedMediaIndex, 1)
      } else {
        selectedMedias.push(media)
      }
      setSelectedMedias([...selectedMedias])
    }

    const handleClickAddToFavorite = async ({ media }) => {
      try {
        const { id: media_gid } = media

        const response = await request({
          method: 'POST',
          path: `${ROUTE_MEDIA}/favorite/${media_gid}`,
        })
        const { error, favorite_media_gid } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else if (!folderId) {
          favoriteMedias.push({ ...media, favorite_media_gid })
          setFavoriteMedias([...favoriteMedias])
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: MediaBodyComponent.displayName,
          functionName: 'handleClickAddToFavorite',
        })
      }
    }

    const handleClickRemoverFromFavorite = async ({ media }) => {
      try {
        const { favorite_media_gid } = media

        const response = await request({
          method: 'DELETE',
          path: `${ROUTE_MEDIA}/favorite/${favorite_media_gid}`,
        })
        const { error, id } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          const foundMediaIndex = favoriteMedias.findIndex((media) => media.favorite_media_gid === id)
          if (foundMediaIndex > -1) {
            favoriteMedias.splice(foundMediaIndex, 1)
            setFavoriteMedias([...favoriteMedias])
          }

          if (media.index === -1) {
            handleClickCloseMediaDetailModal()
          }
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: MediaBodyComponent.displayName,
          functionName: 'handleClickRemoverFromFavorite',
        })
      }
    }

    const handleClickOpenAddMediaItemMenu = ({ func, feature = FEATURE_FIND_CONTENT }) => {
      if (featuresEnabled[feature].enabled) {
        func()
      } else {
        Alert.error(featuresEnabled[feature].description, { timeout: 5000 })
      }
    }

    const onDragStart = ({ event, media }) => {
      const img = document.getElementById(`drag-media-${media.id}`)
      if (img) {
        event.dataTransfer.setDragImage(img, 0, 0)
      }
      setDragMediaOptions({ ...{ dragMedia: media, isActive: true } })
    }

    const handleClickDuplicateMedia = ({ media }) => {
      setUploadingMedias([
        {
          id: media.id,
          tempId: new Date().getTime(),
          isDuplicate: true,
          entity_gids: media.entities.map(({ value }) => value),
        },
        ...selectedMedias,
      ])
    }

    const handleClickOpenAddFolderModal = ({ media }) => {
      setAddFolderModalOptions({ ...{ isOpen: true, data: media } })
    }

    const handleClickCloseAddFolderModal = (props) => {
      const { data } = props || {}

      if (data) {
        const foundFolderMediaIndex = folderMedias.findIndex(({ id }) => id === data.id)

        if (foundFolderMediaIndex > -1) {
          folderMedias[foundFolderMediaIndex] = data
        } else {
          folderMedias.unshift(data)
        }

        setFolderMedias([...folderMedias])

        getLabelsFunction()
      }

      setAddFolderModalOptions({ ...DEFAULT_ADD_FOLDER_MODAL_OPTIONS })
    }

    const handleClickRemoveFolderMedia = async ({ media }) => {
      try {
        const { id } = media

        const response = await request({
          method: 'DELETE',
          path: `${ROUTE_MEDIA}/folder/${id}`,
        })
        const { error } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          const foundMediaIndex = folderMedias.findIndex((media) => media.id === id)
          if (foundMediaIndex > -1) {
            folderMedias.splice(foundMediaIndex, 1)
            setFolderMedias([...folderMedias])
          }
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: MediaBodyComponent.displayName,
          functionName: 'handleClickRemoveFolderMedia',
        })
      }
    }

    const handleClickSelectSelectedMediasForMoving = (data) => {
      if (!selectedMediaOptionsForMoving.isMoving) {
        const media_ids = {}
        let counter = 0

        if (data && data.id) {
          media_ids[data.id] = data.id
          counter++
        } else {
          selectedMedias.forEach(({ id }) => {
            const foundMedia = uploadedMedias.find((media) => media.id === id)
            if (foundMedia) {
              media_ids[id] = id
              counter++
            }
          })
        }

        if (counter === 0) {
          Alert.error('Please select at least one media in the current folder to move.', { timeout: 5000 })
        } else {
          Alert.info(
            `Please navigate to a folder to complete your move. ${counter} ${
              counter === 1 ? 'media is' : 'medias are'
            } selected.`,
            { timeout: 5000 }
          )
          setSelectedMediaOptionsForMoving({ ...{ path: asPath, media_ids, counter } })
        }
      } else {
        Alert.error('Please wait a bit longer while media moving is finished.', { timeout: 5000 })
      }
    }

    const handleClickMoveMedia = async () => {
      try {
        setSelectedMediaOptionsForMoving({ ...{ ...selectedMediaOptionsForMoving, isMoving: true } })

        const response = await request({
          method: 'POST',
          path: `${ROUTE_MEDIA}/move-medias`,
          body: {
            pathTo: folder ? folder.media_path : [],
            media_gids: Object.keys(selectedMediaOptionsForMoving.media_ids),
          },
        })
        const { error } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
          setSelectedMediaOptionsForMoving({ ...{ ...selectedMediaOptionsForMoving, isMoving: false } })
        } else {
          Alert.success('Media items have been moved.', { timeout: 5000 })
          searchForUploadedMedias({ page: 0 })
          setSelectedMediaOptionsForMoving({ ...{ path: '', media_ids: {}, counter: 0, isMoving: false } })
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: MediaBodyComponent.displayName,
          functionName: 'handleClickMoveMedia',
        })

        setSelectedMediaOptionsForMoving({ ...{ ...selectedMediaOptionsForMoving, isMoving: false } })
      }
    }

    const redirectToMediaLibrary = () => {
      router.push('/media', undefined, { shallow: true })
    }

    const handleClickFolderNavigation = ({ folder, type }) => {
      let url
      let folder_gid = null

      if (type === 'forward') {
        folder_gid = folder.media_gid
      } else if (type === 'back' && folder.parent) {
        folder_gid = folder.parent.media_gid
      }

      if (folder_gid) {
        url = `/media/${folder_gid}`
      }

      if (shouldSaveFolderPath) {
        router.push(url || '/media', undefined, { shallow: true })
      }

      setFolder(null)
      setFolderId(folder_gid)
    }

    const allMediasSelected = selectedMedias.length === uploadedMedias.length
    let allFavoriteMediasSelected = true

    for (let i = 0; i < favoriteMedias.length; i++) {
      const { id } = favoriteMedias[i]

      if (!selectedMedias.find((media) => media.id === id)) {
        allFavoriteMediasSelected = false
        break
      }
    }

    let blockedAddMediaOptions = {}
    let blockedAddMediaCanvaOptions = {}
    let blockedAddMediaDriversOptions = {}

    if (!featuresEnabled[FEATURE_FIND_CONTENT].enabled) {
      blockedAddMediaOptions = DEFAULT_BLOCK_MEDIA_OPTIONS
    }
    if (!featuresEnabled[FEATURE_CANVA].enabled) {
      blockedAddMediaCanvaOptions = DEFAULT_BLOCK_MEDIA_OPTIONS
    }
    if (!featuresEnabled[FEATURE_DRIVES].enabled) {
      blockedAddMediaDriversOptions = DEFAULT_BLOCK_MEDIA_OPTIONS
    }

    const ADD_MEDIA_DROPDOWN_VALUES = [
      {
        key: IMAGE,
        title: 'Add image',
        icon: <Icon.Camera width="16px" fill={COLOR_CONSTANTS.COSMIC_ENERGY} height="100%" />,
        iconEnding: <Icon.VistaSocialChevronRight width="14px" fill={COLOR_CONSTANTS.COSMIC_ENERGY} height="12px" />,
        children: [
          {
            key: 'computer',
            label: isMediaUploading ? 'Uploading...' : 'Upload from computer',
            iconName: 'Upload',
            iconWidth: '14px',
            iconColor: COLOR_CONSTANTS.COSMIC_ENERGY,
            onClick: () => {
              if (!isMediaUploading) {
                inputImageFileRef.current.click()
              }
            },
            isCursorPointer: true,
          },
          {
            key: 'url',
            label: 'Upload from URL',
            iconName: 'Link',
            iconWidth: '14px',
            iconColor: COLOR_CONSTANTS.COSMIC_ENERGY,
            onClick: () => {
              handleClickOpenMediaUploadByLinkModal({ type: IMAGE })
            },
            isCursorPointer: true,
          },

          { type: 'divider' },

          {
            key: 'instagram_hashtags',
            label: 'Instagram hashtags',
            iconName: 'HashTag',
            iconWidth: '14px',
            iconColor: COLOR_CONSTANTS.COSMIC_ENERGY,
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickOpenPublisherWrapper({
                    type: 'findContent',
                    findContentMenuItemType: IMPORT_FROM_INSTAGRAM_HASHTAG,
                    findContentMediaType: IMAGE,
                  })
                },
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaOptions,
          },
          {
            key: 'instagram_users',
            label: 'Instagram users',
            iconName: 'Avatar',
            iconWidth: '14px',
            iconColor: COLOR_CONSTANTS.COSMIC_ENERGY,
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickOpenPublisherWrapper({
                    type: 'findContent',
                    findContentMenuItemType: IMPORT_FROM_INSTAGRAM_USER,
                    findContentMediaType: IMAGE,
                  })
                },
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaOptions,
          },
          {
            key: 'find_images',
            label: 'Find images',
            iconName: 'Camera',
            iconWidth: '14px',
            iconColor: COLOR_CONSTANTS.COSMIC_ENERGY,
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickOpenPublisherWrapper({
                    type: 'findContent',
                    findContentMenuItemType: IMPORT_FROM_FIND_IMAGES,
                  })
                },
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaOptions,
          },
          {
            key: 'find_gifs',
            label: 'Find GIFs',
            iconName: 'GIF',
            iconWidth: '14px',
            iconColor: COLOR_CONSTANTS.COSMIC_ENERGY,
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickOpenPublisherWrapper({
                    type: 'findContent',
                    findContentMenuItemType: IMPORT_FROM_FIND_GIFS,
                  })
                },
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaOptions,
          },

          { type: 'divider' },

          {
            key: 'google_drive',
            label: 'Google drive',
            iconName: 'GoogleDrive',
            iconWidth: '14px',
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickGoogleDrive({ type: IMAGE })
                },
                feature: FEATURE_DRIVES,
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaDriversOptions,
          },
          {
            key: 'dropbox',
            label: 'Dropbox',
            iconName: 'Dropbox',
            iconWidth: '14px',
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickDropBox({ type: IMAGE })
                },
                feature: FEATURE_DRIVES,
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaDriversOptions,
          },
          {
            key: 'one_drive',
            label: 'OneDrive',
            iconName: 'OneDrive',
            iconWidth: '18px',
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickOneDrive({ type: IMAGE })
                },
                feature: FEATURE_DRIVES,
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaDriversOptions,
          },

          {
            key: 'canva',
            label: 'Design with Canva',
            iconName: 'Canva',
            iconWidth: '14px',
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  setCanvaDesignOptions({ isOpen: true })
                },
                feature: FEATURE_CANVA,
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaCanvaOptions,
          },
        ],
      },
      {
        key: VIDEO,
        title: 'Add video',
        icon: <Icon.VideoCamera width="16px" fill={COLOR_CONSTANTS.COSMIC_ENERGY} height="100%" />,
        iconEnding: <Icon.VistaSocialChevronRight width="14px" fill={COLOR_CONSTANTS.COSMIC_ENERGY} height="12px" />,
        children: [
          {
            key: 'computer',
            label: isMediaUploading ? 'Uploading...' : 'Upload from computer',
            iconName: 'Upload',
            iconWidth: '14px',
            iconColor: COLOR_CONSTANTS.COSMIC_ENERGY,
            onClick: () => {
              if (!isMediaUploading) {
                inputVideoFileRef.current.click()
              }
            },
            isCursorPointer: true,
          },
          {
            key: 'url',
            label: 'Upload from URL',
            iconName: 'Link',
            iconWidth: '14px',
            iconColor: COLOR_CONSTANTS.COSMIC_ENERGY,
            onClick: () => {
              handleClickOpenMediaUploadByLinkModal({ type: VIDEO })
            },
            isCursorPointer: true,
          },

          { type: 'divider' },

          {
            key: 'instagram_hashtags',
            label: 'Instagram hashtags',
            iconName: 'HashTag',
            iconWidth: '14px',
            iconColor: COLOR_CONSTANTS.COSMIC_ENERGY,
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickOpenPublisherWrapper({
                    type: 'findContent',
                    findContentMenuItemType: IMPORT_FROM_INSTAGRAM_HASHTAG,
                    findContentMediaType: VIDEO,
                  })
                },
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaOptions,
          },
          {
            key: 'instagram_users',
            label: 'Instagram users',
            iconName: 'Avatar',
            iconWidth: '14px',
            iconColor: COLOR_CONSTANTS.COSMIC_ENERGY,
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickOpenPublisherWrapper({
                    type: 'findContent',
                    findContentMenuItemType: IMPORT_FROM_INSTAGRAM_USER,
                    findContentMediaType: VIDEO,
                  })
                },
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaOptions,
          },
          {
            key: 'find_videos',
            label: 'Find videos',
            iconName: 'VideoCamera',
            iconWidth: '14px',
            iconColor: COLOR_CONSTANTS.COSMIC_ENERGY,
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickOpenPublisherWrapper({
                    type: 'findContent',
                    findContentMenuItemType: IMPORT_FROM_FIND_VIDEOS,
                  })
                },
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaOptions,
          },
          {
            key: 'find_gifs',
            label: 'Find GIFs',
            iconName: 'GIF',
            iconWidth: '14px',
            iconColor: COLOR_CONSTANTS.COSMIC_ENERGY,
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickOpenPublisherWrapper({
                    type: 'findContent',
                    findContentMenuItemType: IMPORT_FROM_FIND_GIFS,
                  })
                },
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaOptions,
          },

          { type: 'divider' },

          {
            key: 'google_drive',
            label: 'Google drive',
            iconName: 'GoogleDrive',
            iconWidth: '14px',
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickGoogleDrive({ type: VIDEO })
                },
                feature: FEATURE_DRIVES,
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaDriversOptions,
          },
          {
            key: 'dropbox',
            label: 'Dropbox',
            iconName: 'Dropbox',
            iconWidth: '14px',
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickDropBox({ type: VIDEO })
                },
                feature: FEATURE_DRIVES,
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaDriversOptions,
          },
          {
            key: 'one_drive',
            label: 'OneDrive',
            iconName: 'OneDrive',
            iconWidth: '18px',
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickOneDrive({ type: VIDEO })
                },
                feature: FEATURE_DRIVES,
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaDriversOptions,
          },

          // { type: 'divider' },
          // {
          //   key: 'vista_create',
          //   label: 'Design with Vista Create',
          //   iconName: 'Canva',
          //   iconWidth: '14px',
          //   iconHeight: '100%',
          //   iconColor: COLOR_CONSTANTS.COSMIC_ENERGY,
          //   onClick: () => {
          //     handleClickOpenAddMediaItemMenu({
          //       func: () => {
          //         setVistaCreateDesignOptions({ isOpen: true })
          //       },
          //     })
          //   },
          //   isCursorPointer: true,
          //   ...blockedAddMediaOptions,
          // },
        ],
      },
      {
        key: AUDIO,
        title: 'Add sound',
        icon: <Icon.Clavis width="16px" fill={COLOR_CONSTANTS.COSMIC_ENERGY} height="100%" />,
        iconEnding: <Icon.VistaSocialChevronRight width="14px" fill={COLOR_CONSTANTS.COSMIC_ENERGY} height="12px" />,
        children: [
          {
            key: 'computer',
            label: isMediaUploading ? 'Uploading...' : 'Upload from computer',
            iconName: 'Upload',
            iconWidth: '14px',
            iconColor: COLOR_CONSTANTS.COSMIC_ENERGY,
            onClick: () => {
              if (!isMediaUploading) {
                inputAudioFileRef.current.click()
              }
            },
            isCursorPointer: true,
          },
          {
            key: 'url',
            label: 'Upload from URL',
            iconName: 'Link',
            iconWidth: '14px',
            iconColor: COLOR_CONSTANTS.COSMIC_ENERGY,
            onClick: () => {
              handleClickOpenMediaUploadByLinkModal({ type: AUDIO })
            },
            isCursorPointer: true,
          },

          { type: 'divider' },

          {
            key: 'instagram_hashtags',
            label: 'Instagram hashtags',
            iconName: 'HashTag',
            iconWidth: '14px',
            iconColor: COLOR_CONSTANTS.COSMIC_ENERGY,
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickOpenPublisherWrapper({
                    type: 'findContent',
                    findContentMenuItemType: IMPORT_FROM_INSTAGRAM_HASHTAG,
                    findContentMediaType: AUDIO,
                  })
                },
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaOptions,
          },
          {
            key: 'instagram_users',
            label: 'Instagram users',
            iconName: 'Avatar',
            iconWidth: '14px',
            iconColor: COLOR_CONSTANTS.COSMIC_ENERGY,
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickOpenPublisherWrapper({
                    type: 'findContent',
                    findContentMenuItemType: IMPORT_FROM_INSTAGRAM_USER,
                    findContentMediaType: AUDIO,
                  })
                },
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaOptions,
          },
          {
            key: 'trending',
            label: 'Trending sounds',
            iconName: 'Clavis',
            iconWidth: '14px',
            iconColor: COLOR_CONSTANTS.COSMIC_ENERGY,
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickOpenMediaTrendingModal({ type: AUDIO })
                },
              })
            },
            isCursorPointer: true,
          },

          { type: 'divider' },

          {
            key: 'google_drive',
            label: 'Google drive',
            iconName: 'GoogleDrive',
            iconWidth: '14px',
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickGoogleDrive({ type: AUDIO })
                },
                feature: FEATURE_DRIVES,
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaDriversOptions,
          },
          {
            key: 'dropbox',
            label: 'Dropbox',
            iconName: 'Dropbox',
            iconWidth: '14px',
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickDropBox({ type: AUDIO })
                },
                feature: FEATURE_DRIVES,
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaDriversOptions,
          },
          {
            key: 'one_drive',
            label: 'OneDrive',
            iconName: 'OneDrive',
            iconWidth: '18px',
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickOneDrive({ type: AUDIO })
                },
                feature: FEATURE_DRIVES,
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaDriversOptions,
          },
        ],
      },
      {
        key: DOCUMENT,
        title: 'Add document',
        icon: <Icon.File width="16px" height="16px" fill={COLOR_CONSTANTS.COSMIC_ENERGY} />,
        iconEnding: <Icon.VistaSocialChevronRight width="14px" fill={COLOR_CONSTANTS.COSMIC_ENERGY} height="12px" />,
        children: [
          {
            key: 'computer',
            label: isMediaUploading ? 'Uploading...' : 'Upload from computer',
            iconName: 'Upload',
            iconWidth: '14px',
            iconColor: COLOR_CONSTANTS.COSMIC_ENERGY,
            onClick: () => {
              if (!isMediaUploading) {
                inputDocumentFileRef.current.click()
              }
            },
            isCursorPointer: true,
          },
          {
            key: 'url',
            label: 'Upload from URL',
            iconName: 'Link',
            iconWidth: '14px',
            iconColor: COLOR_CONSTANTS.COSMIC_ENERGY,
            onClick: () => {
              handleClickOpenMediaUploadByLinkModal({ type: DOCUMENT })
            },
            isCursorPointer: true,
          },

          { type: 'divider' },

          {
            key: 'google_drive',
            label: 'Google drive',
            iconName: 'GoogleDrive',
            iconWidth: '14px',
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickGoogleDrive({ type: DOCUMENT })
                },
                feature: FEATURE_DRIVES,
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaDriversOptions,
          },
          {
            key: 'dropbox',
            label: 'Dropbox',
            iconName: 'Dropbox',
            iconWidth: '14px',
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickDropBox({ type: DOCUMENT })
                },
                feature: FEATURE_DRIVES,
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaDriversOptions,
          },
          {
            key: 'one_drive',
            label: 'OneDrive',
            iconName: 'OneDrive',
            iconWidth: '18px',
            onClick: () => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickOneDrive({ type: DOCUMENT })
                },
                feature: FEATURE_DRIVES,
              })
            },
            isCursorPointer: true,
            ...blockedAddMediaDriversOptions,
          },
        ],
      },
    ]

    const rootProps = !isSmall ? getRootProps({ isDragActive }) : {}
    const sentForUploadMediasLength = uploadingMedias.filter(({ tempId }) => !tempId).length
    const leftMediasForUpload = uploadingMediasAmount - sentForUploadMediasLength

    const isOnlyAudioTabSelected = selectedTypes.length === 1 && selectedTypes[0].value === AUDIO

    return (
      <Fragment>
        <Flex width="100%" height="100%" position="relative" {...rootProps}>
          <StyledFilesWrapper
            show={isDragActive || uploadingMediasAmount > 0}
            alignItems="center"
            justifyContent="center"
          >
            {uploadingMediasAmount === 0 ? (
              <Text fontSize="xxl" fontWeight="bold" color={COLOR_CONSTANTS.WHITE}>
                Drag 'n' drop some files here
              </Text>
            ) : (
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                px="m"
              >
                <Text mb="s" fontSize="xxl" fontWeight="bold" color={COLOR_CONSTANTS.WHITE}>
                  {leftMediasForUpload === 0
                    ? 'Processing uploaded medias. '
                    : `${leftMediasForUpload} of ${uploadingMediasAmount} ${
                        uploadingMediasAmount === 1 ? 'file' : 'files'
                      } left. `}
                  Please wait a moment...
                </Text>
                <ClipLoader size="50" color={colors.primary} />
              </Flex>
            )}
          </StyledFilesWrapper>
          <Flex height="100%" width="100%" flexDirection="column">
            <Flex
              flexDirection="column"
              px={isSmall ? 's' : '0'}
              pt={isSmall ? '0' : 'm'}
              pb={!isSmall && !isOnlyAudioTabSelected ? 'm' : '0'}
            >
              <Flex px="s" justifyContent="space-between" alignItems="center" width={isSmall ? '100%' : 'auto'}>
                <Flex alignItems="center">
                  {!isSmall && !folderId && (
                    <Text color="primary" fontSize="m" ml="s">
                      Media Library
                    </Text>
                  )}

                  {folder && (
                    <Flex ml="s">
                      {!isSmall ? (
                        <Fragment>
                          {folder.media_path.length > 1 && (
                            <Fragment>
                              <StyledFolderBackNavigationText
                                color="primary"
                                fontSize="m"
                                onClick={() => {
                                  handleClickFolderNavigation({ type: 'back', folder: {} })
                                }}
                              >
                                Media Library
                              </StyledFolderBackNavigationText>
                              <Text color={COLOR_CONSTANTS.VIOLET_WHIMSEY} fontSize="m">
                                &nbsp;/&nbsp;
                              </Text>
                              {folder.media_path.length > 2 && (
                                <Fragment>
                                  <Text color="primary" fontSize="m">
                                    ...
                                  </Text>
                                  <Text color={COLOR_CONSTANTS.VIOLET_WHIMSEY} fontSize="m">
                                    &nbsp;/&nbsp;
                                  </Text>
                                </Fragment>
                              )}
                            </Fragment>
                          )}

                          <StyledFolderBackNavigationText
                            color="primary"
                            fontSize="m"
                            onClick={() => {
                              handleClickFolderNavigation({ type: 'back', folder })
                            }}
                          >
                            {folder?.parent?.title || 'Media Library'}
                          </StyledFolderBackNavigationText>
                          <Text color={COLOR_CONSTANTS.VIOLET_WHIMSEY} fontSize="m">
                            &nbsp;/&nbsp;
                          </Text>
                          <Text fontSize="m">{folder.title}</Text>
                        </Fragment>
                      ) : (
                        <StyledRoundedButton
                          mr="s"
                          onClick={() => {
                            handleClickFolderNavigation({ type: 'back', folder })
                          }}
                        >
                          <StyledIconChevronLeft width="16px" height="12px" />
                        </StyledRoundedButton>
                      )}
                    </Flex>
                  )}
                </Flex>

                <Flex alignItems="center" width={isSmall ? '100%' : 'auto'}>
                  {!isSmall && mediaCounter > 0 && (
                    <Flex display={{ mobile: 'none', desktopWide: 'flex' }}>
                      <Text fontSize="s" mx="s">
                        {mediaCounter} media {mediaCounter === 1 ? 'item' : 'items'}
                      </Text>
                    </Flex>
                  )}
                  {isSmall && !folderId && (
                    <StyledRoundedButton
                      mr="s"
                      onClick={() => {
                        if (!isGettingMedia) {
                          if (!mediaShowType || mediaShowType === MEDIA_SHOW_TYPE_ALL) {
                            setMediaShowType(MEDIA_SHOW_TYPE_FAVORITE)
                          } else {
                            setMediaShowType(MEDIA_SHOW_TYPE_ALL)
                          }
                        }
                      }}
                    >
                      {!mediaShowType || mediaShowType === MEDIA_SHOW_TYPE_ALL ? (
                        <Icon.StarEmpty width="14px" height="14px" />
                      ) : (
                        <Icon.Star width="14px" height="14px" />
                      )}
                    </StyledRoundedButton>
                  )}
                  <StyledHiddenFilters
                    visibility={!mediaShowType || mediaShowType === MEDIA_SHOW_TYPE_ALL ? 'visible' : 'hidden'}
                    width={isSmall ? '100%' : 'auto'}
                    justifyContent={isSmall ? 'flex-end' : 'flex-start'}
                  >
                    <SearchInputComponent
                      handleFilterMediaBySearch={handleFilterMediaBySearch}
                      ref={inputSearchText}
                      isSmall={isSmall}
                    />

                    <Flex
                      justifyContent="center"
                      flexDirection="column"
                      width={isSmall ? 'auto' : { mobile: 'auto', tablet: '135px', desktopWide: '150px' }}
                      mx="s"
                    >
                      <MediaFilters
                        ref={mediaFiltersRef}
                        handleFetchMedia={({ filters }) => {
                          if (page === 0) {
                            searchForUploadedMedias({
                              selectedFilters: { filters },
                              page,
                            })
                          } else {
                            setIsFinalPage(false)
                            setPage(0)
                          }

                          getFolderMedias({
                            selectedFilters: { filters },
                          })
                        }}
                        labels={labels}
                        extensions={extensions}
                        users={users}
                        usage={DEFAULT_MEDIA_USAGE_TYPES}
                        types={DEFAULT_TYPES}
                        defaultFilters={DEFAULT_TYPES ? { selectedTypes: DEFAULT_TYPES } : null}
                        showMobileIcon={isSmall ? 'flex' : { mobile: 'flex', desktop: 'none' }}
                        removeMobileIcon={isSmall ? 'none' : { mobile: 'none', desktop: 'flex' }}
                        left={isSmall ? '0px' : 'unset'}
                        right={isSmall ? 'unset' : '0px'}
                      />
                    </Flex>

                    <Button.Gradient
                      mr="s"
                      isSmall
                      height={DEFAULT_MENU_HEIGHT}
                      onClick={() => {
                        handleClickOpenAddFolderModal({})
                      }}
                    >
                      <Flex alignItems="center">
                        <Flex alignItems="center">
                          {isSmall ? (
                            <Icon.Folder fill={COLOR_CONSTANTS.WHITE} width="16px" height="100%" />
                          ) : (
                            <Text>Add folder</Text>
                          )}
                        </Flex>
                      </Flex>
                    </Button.Gradient>

                    <DropdownMenu
                      zIndex={16}
                      WrapperComp={
                        <Button.Gradient isSmall height={DEFAULT_MENU_HEIGHT}>
                          <Flex alignItems="center">
                            <Flex alignItems="center" mr="s">
                              {isSmall ? (
                                <Icon.Plus fill={COLOR_CONSTANTS.WHITE} width="14px" height="14px" />
                              ) : (
                                <Text>Add media</Text>
                              )}
                            </Flex>
                            <Icon.ArrowDropdown fill={COLOR_CONSTANTS.WHITE} />
                          </Flex>
                        </Button.Gradient>
                      }
                      isDismissedOnClickInside
                    >
                      {ADD_MEDIA_DROPDOWN_VALUES.map(
                        ({ key, title, icon, iconEnding, children, onClickFunc }, index) => {
                          const foundDefaultType = DEFAULT_TYPES.find(({ value }) => value === key)
                          if (!foundDefaultType) {
                            return null
                          }
                          return (
                            <Flex
                              key={key}
                              onMouseEnter={() => {
                                const keys = [IMAGE, AUDIO, VIDEO, DOCUMENT]
                                keys.forEach((item) => {
                                  clearInterval(DROPDOWN_INTERVALS[item])
                                  const el = document.getElementById(`add-media-${item}-dropdown`)
                                  if (el) {
                                    el.style.display = item === key ? 'flex' : 'none'
                                  }
                                })
                              }}
                              onMouseLeave={() => {
                                const el = document.getElementById(`add-media-${key}-dropdown`)
                                if (el) {
                                  DROPDOWN_INTERVALS[key] = setInterval(() => {
                                    el.style.display = 'none'
                                    clearInterval(DROPDOWN_INTERVALS[key])
                                  }, 300)
                                }
                              }}
                              onClick={onClickFunc || null}
                            >
                              <StyledDropDownMenuItem type="button">
                                <Flex alignItems="center" width="100%" height="100%" justifyContent="space-between">
                                  <Flex alignItems="center" width="100%" height="100%">
                                    <Flex
                                      alignItems="center"
                                      justifyContent="center"
                                      width="20px"
                                      height="100%"
                                      mr="xs"
                                    >
                                      {icon}
                                    </Flex>
                                    <Flex flexDirection="column">
                                      <Text fontSize="xs" maxWidth="150px">
                                        {title}
                                      </Text>
                                    </Flex>
                                  </Flex>
                                  {iconEnding && (
                                    <Flex
                                      alignItems="center"
                                      justifyContent="center"
                                      width="20px"
                                      height="100%"
                                      mr="xs"
                                    >
                                      {iconEnding}
                                    </Flex>
                                  )}
                                </Flex>
                              </StyledDropDownMenuItem>
                              {children && (
                                <StyledDropDownSubmenuWrapper
                                  onMouseEnter={() => {
                                    const el = document.getElementById(`add-media-${key}-dropdown`)
                                    if (el) {
                                      el.style.display = 'flex'
                                      clearInterval(DROPDOWN_INTERVALS[key])
                                    }
                                  }}
                                  id={`add-media-${key}-dropdown`}
                                  top={`${index * 30}px`}
                                  showAddMediaDropdownPosition={showAddMediaDropdownPosition}
                                >
                                  {children.map(({ key, type, ...props }, index) => {
                                    if (type === 'divider') {
                                      return <Divider height="1px" key={index} />
                                    }
                                    return <DropdownMenu.Item key={key} {...props} />
                                  })}
                                </StyledDropDownSubmenuWrapper>
                              )}
                            </Flex>
                          )
                        }
                      )}
                    </DropdownMenu>
                  </StyledHiddenFilters>
                </Flex>
              </Flex>

              {!isSmall && isOnlyAudioTabSelected && (
                <Flex pl="m" pr="s" my={isSmall ? '0' : 's'}>
                  <StyledTrendingSoundsWrapper
                    alignItems="center"
                    onClick={() => {
                      handleClickOpenAddMediaItemMenu({
                        func: () => {
                          handleClickOpenMediaTrendingModal({ type: AUDIO })
                        },
                      })
                    }}
                  >
                    <Icon.Splash fill={colors.primary} width="12px" height="14px" />
                    <StyledText ml="s" color="primary">
                      Browse trending sounds library
                    </StyledText>
                  </StyledTrendingSoundsWrapper>
                </Flex>
              )}
            </Flex>

            <StyledDialogBodyWrapper pb="m" flex="1" flexDirection="column" position="relative">
              <StyledProgressWrapper
                display={isGettingMedia ? 'flex' : 'none'}
                alignItems="center"
                justifyContent="center"
              >
                <ClipLoader size="100" color={colors.primary} />
              </StyledProgressWrapper>
              <Scrollbars
                ref={scrollbarsRef}
                universal
                style={{ display: 'flex', flexWrap: 'wrap' }}
                onScrollFrame={({ top }) => {
                  if (top >= 0.99) {
                    if (!isGettingMedia) {
                      setPage((page) => {
                        return page + 1
                      })
                    }
                  }
                }}
              >
                {favoriteMedias.length !== 0 && !isSmall && (
                  <Flex flexDirection="column" mt="s" width="100%" px="m">
                    <MediaSectionWrapper flexDirection="column" py="m" width="100%">
                      <Flex alignItems="center" width="100%" justifyContent="space-between">
                        <Flex alignItems="center">
                          <Icon.Star width="16px" height="16px" />
                          <Text ml="s" fontSize="s">
                            Starred Media
                          </Text>
                        </Flex>
                        {!isSmall && (
                          <Flex>
                            <StyledText
                              onClick={() => {
                                if (allFavoriteMediasSelected) {
                                  favoriteMedias.forEach((media) => {
                                    const foundSelectedMediaIndex = selectedMedias.findIndex(
                                      ({ id }) => id === media.id
                                    )
                                    if (foundSelectedMediaIndex > -1) {
                                      selectedMedias.splice(foundSelectedMediaIndex, 1)
                                    }
                                  })
                                  setSelectedMedias([...selectedMedias])
                                } else {
                                  favoriteMedias.forEach((media) => {
                                    const foundSelectedMediaIndex = selectedMedias.findIndex(
                                      ({ id }) => id === media.id
                                    )
                                    if (foundSelectedMediaIndex === -1) {
                                      selectedMedias.push(media)
                                    }
                                  })
                                  setSelectedMedias([...selectedMedias])
                                }
                              }}
                            >
                              {allFavoriteMediasSelected ? 'Unselect all' : 'Select all'}
                            </StyledText>
                          </Flex>
                        )}
                      </Flex>

                      <Flex alignItems="center" width="100%" flexWrap="wrap" mt="s">
                        {favoriteMedias.map((item) => {
                          const { id } = item
                          item.selected = !!selectedMedias.find((media) => media.id === id)
                          return (
                            <FavoriteMediaWrapper mt="s" mr="m" key={id} flexDirection="column">
                              <FavoriteItem
                                media={item}
                                handleClickOpenMediaDetailModal={() => {
                                  if (handleSpecialItemOnClick) {
                                    handleSpecialItemOnClick({ media: item })
                                  } else {
                                    handleClickOpenMediaDetailModal({ media: item, index: -1 })
                                  }
                                }}
                                handleClickRemoverFromFavorite={() => {
                                  handleClickRemoverFromFavorite({ media: item })
                                }}
                                handleClickSelectMedia={() => {
                                  handleClickSelectMedia({ media: item })
                                }}
                                isSelectable={isSelectable}
                                isFavorable={isFavorable}
                                handleClickOpenPosts={({ publications }) => {
                                  setSliderPostsPreviewData({
                                    eventIds: publications,
                                    specialEvents: [],
                                  })
                                }}
                                tooltipRef={tooltipRef}
                              />
                            </FavoriteMediaWrapper>
                          )
                        })}
                      </Flex>
                    </MediaSectionWrapper>
                  </Flex>
                )}

                {folderMedias.length !== 0 && (
                  <Flex flexDirection="column" mt="s" width="100%" px="m">
                    <MediaSectionWrapper flexDirection="column" py="m" width="100%">
                      <Flex alignItems="center">
                        <Icon.Folder width="16px" height="16px" />
                        <Text ml="s" fontSize="s">
                          Folders
                        </Text>
                      </Flex>

                      <Grid
                        gridTemplateColumns={
                          isSmall
                            ? 'repeat(3, 1fr)'
                            : {
                                mobile: 'repeat(1, 1fr)',
                                mobileLarge: 'repeat(2, 1fr)',
                                tablet: 'repeat(3, 1fr)',
                                desktop: 'repeat(4, 1fr)',
                                desktopWide: 'repeat(5, 1fr)',
                                desktopLarge: 'repeat(6, 1fr)',
                              }
                        }
                        width="100%"
                        gridGap="m"
                        justifyItems="center"
                        mt="m"
                      >
                        {folderMedias.map((media, index) => {
                          const { id } = media

                          return (
                            <MediaWrapper key={id} flexDirection="column">
                              <FolderItem
                                media={media}
                                handleClickOpenAddFolderModal={handleClickOpenAddFolderModal}
                                handleClickRemoveFolderMedia={handleClickRemoveFolderMedia}
                                isSmall={isSmall}
                                isFirstInRow={index === 0}
                                hasControls={hasItemControls}
                                handleClickFolderNavigation={() => {
                                  handleClickFolderNavigation({ type: 'forward', folder: media })
                                }}
                              />
                            </MediaWrapper>
                          )
                        })}
                      </Grid>
                    </MediaSectionWrapper>
                  </Flex>
                )}

                <Flex flexDirection="column" width="100%" px="m" id="media-section-wrapper">
                  <MediaSectionWrapper mt="s" flexDirection="column" height="100%">
                    {uploadedMedias.length === 0 && uploadingMedias.length === 0 ? (
                      <Flex
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        bg={isSmall ? COLOR_CONSTANTS.WHITE : COLOR_CONSTANTS.DAISY}
                        mt="xl"
                      >
                        <Box textAlign="center" width="100%">
                          <Image src="/assets/noResultsIllustration.svg" maxWidth="250px" width="100%" />
                          {!isGettingMedia && (
                            <Fragment>
                              {isOnlyAudioTabSelected ? (
                                <Text mt="s" color="secondaryText">
                                  No uploaded sound found, but you can browse our{' '}
                                  <StyledText
                                    as="span"
                                    color="primary"
                                    onClick={() => {
                                      handleClickOpenAddMediaItemMenu({
                                        func: () => {
                                          handleClickOpenMediaTrendingModal({ type: AUDIO })
                                        },
                                      })
                                    }}
                                  >
                                    trending sounds
                                  </StyledText>
                                </Text>
                              ) : (
                                <Text mt="s" color="secondaryText">
                                  No medias found
                                </Text>
                              )}
                            </Fragment>
                          )}
                        </Box>
                      </Flex>
                    ) : (
                      <Flex width="100%" flexDirection="column" id="grouped-medias-wrapper">
                        {groupedMedias.map(({ type, title, medias, showSelector }) => {
                          if (
                            medias.length !== 0 ||
                            (type === GROUPED_MEDIA_TYPE_TODAY && uploadingMedias.length !== 0)
                          ) {
                            return (
                              <Flex key={type} width="100%" flexDirection="column">
                                <Flex my="m" alignItems="center" width="100%" justifyContent="space-between">
                                  <Flex alignItems="center">
                                    <Text>{title}</Text>
                                  </Flex>
                                  {showSelector && !isSmall && (
                                    <Flex>
                                      <StyledText
                                        onClick={() => {
                                          if (allMediasSelected) {
                                            uploadedMedias.forEach((media) => {
                                              const foundSelectedMediaIndex = selectedMedias.findIndex(
                                                ({ id }) => id === media.id
                                              )
                                              if (foundSelectedMediaIndex > -1) {
                                                selectedMedias.splice(foundSelectedMediaIndex, 1)
                                              }
                                            })
                                            setSelectedMedias([...selectedMedias])
                                          } else {
                                            uploadedMedias.forEach((media) => {
                                              const foundSelectedMediaIndex = selectedMedias.findIndex(
                                                ({ id }) => id === media.id
                                              )

                                              if (foundSelectedMediaIndex === -1) {
                                                selectedMedias.push(media)
                                              }
                                            })
                                            setSelectedMedias([...selectedMedias])
                                          }
                                        }}
                                      >
                                        {allMediasSelected ? 'Unselect all' : 'Select all'}
                                      </StyledText>
                                    </Flex>
                                  )}
                                </Flex>
                                <Grid
                                  gridTemplateColumns={
                                    isSmall
                                      ? 'repeat(3, 1fr)'
                                      : {
                                          mobile: 'repeat(1, 1fr)',
                                          mobileLarge: 'repeat(2, 1fr)',
                                          tablet: 'repeat(3, 1fr)',
                                          desktop: 'repeat(4, 1fr)',
                                          desktopWide: 'repeat(5, 1fr)',
                                          desktopLarge: 'repeat(6, 1fr)',
                                        }
                                  }
                                  width="100%"
                                  gridGap="m"
                                  justifyItems="center"
                                >
                                  {type === GROUPED_MEDIA_TYPE_TODAY &&
                                    uploadingMedias.length > 0 &&
                                    uploadingMedias.map(({ id, media_gid }, index) => (
                                      <MediaWrapper
                                        key={id || media_gid || index}
                                        alignItems="center"
                                        justifyContent="center"
                                        minHeight={isSmall ? '100px' : '210px'}
                                      >
                                        <ClipLoader size="50" color={colors.primary} />
                                        <StyledUploadProgressIndicatorText
                                          id={`media-upload-progress-${id || media_gid}`}
                                        />
                                      </MediaWrapper>
                                    ))}
                                  {medias.map((item, index) => {
                                    const { id } = item

                                    item.favorite_media_gid = null

                                    if (!folderId) {
                                      const foundFavoriteMedia = favoriteMedias.find((media) => media.id === id)

                                      if (foundFavoriteMedia) {
                                        item.favorite_media_gid = foundFavoriteMedia.favorite_media_gid
                                      }
                                    }

                                    item.selected = !!selectedMedias.find((media) => media.id === id)

                                    return (
                                      <MediaWrapper
                                        key={id}
                                        flexDirection="column"
                                        minHeight={isSmall ? '100px' : '210px'}
                                        draggable={!!onDropMedia}
                                        onDragStart={(event) => onDragStart({ event, media: item })}
                                        onDragEnd={() => {
                                          dragMediaOptions.isActive = false
                                          setDragMediaOptions({ ...dragMediaOptions })
                                        }}
                                      >
                                        <Item
                                          mediaHtmlId={onDropMedia ? `drag-media-${item.id}` : ''}
                                          media={item}
                                          handleClickOpenImageEditModal={handleClickOpenImageEditModal}
                                          handleClickOpenVideoThumbnailModal={handleClickOpenVideoThumbnailModal}
                                          handleClickOpenVideoPickSoundModal={handleClickOpenVideoPickSoundModal}
                                          handleClickOpenMediaMetaInformationModal={
                                            handleClickOpenMediaMetaInformationModal
                                          }
                                          handleClickRemoveMedia={handleClickRemoveMedia}
                                          handleClickSendToCompose={
                                            hasGlobalAccessManage({ user, permission: PERMISSION_PUBLISH })
                                              ? handleClickSendToCompose
                                              : null
                                          }
                                          handleClickSelectMedia={() => {
                                            handleClickSelectMedia({ media: item })
                                          }}
                                          handleClickOpenMediaDetailModal={() => {
                                            if (handleSpecialItemOnClick) {
                                              handleSpecialItemOnClick({ media: item })
                                            } else {
                                              handleClickOpenMediaDetailModal({ media: item, index: item.index })
                                            }
                                          }}
                                          handleClickAddToFavorite={() => {
                                            handleClickAddToFavorite({ media: item })
                                          }}
                                          handleClickRemoverFromFavorite={() => {
                                            handleClickRemoverFromFavorite({ media: item })
                                          }}
                                          imageExtensions={IMAGE_EXTENSIONS_JOINED}
                                          handleUpdateAudioCover={handleUpdateAudioCover}
                                          isSmall={isSmall}
                                          isSelectable={isSelectable}
                                          isFavorable={isFavorable}
                                          isFirstInRow={index === 0}
                                          handleClickOpenPosts={({ publications }) => {
                                            setSliderPostsPreviewData({
                                              eventIds: publications,
                                              specialEvents: [],
                                            })
                                          }}
                                          handleClickOpenMediaDynamicModal={() => {
                                            handleClickOpenMediaDynamicModal({ media: item })
                                          }}
                                          handleClickDuplicateMedia={() => {
                                            handleClickDuplicateMedia({ media: item })
                                          }}
                                          hasControls={hasItemControls}
                                          tooltipRef={tooltipRef}
                                          handleClickSelectSelectedMediasForMoving={
                                            handleClickSelectSelectedMediasForMoving
                                          }
                                        />
                                      </MediaWrapper>
                                    )
                                  })}
                                </Grid>
                              </Flex>
                            )
                          }
                          return null
                        })}
                      </Flex>
                    )}
                  </MediaSectionWrapper>
                </Flex>
              </Scrollbars>
            </StyledDialogBodyWrapper>
            {(selectedMedias.length > 0 ||
              (selectedMediaOptionsForMoving.counter > 0 && selectedMediaOptionsForMoving.path !== asPath)) && (
              <StyledFooter px="s" mx="m" flexDirection="column" bg={COLOR_CONSTANTS.WHITE}>
                {selectedMediaOptionsForMoving.counter > 0 && selectedMediaOptionsForMoving.path !== asPath && (
                  <Flex py="m" alignItems="center" justifyContent="center" position="relative">
                    <RemoveMoveItemsWrapper
                      onClick={() => {
                        setSelectedMediaOptionsForMoving({ ...{ path: '', media_ids: {}, counter: 0 } })
                      }}
                    >
                      <Image width="8px" height="8px" src="/assets/clear.svg" />
                    </RemoveMoveItemsWrapper>

                    <ButtonWithLoading
                      buttonComp={Button.Gray}
                      loaderColor={COLOR_CONSTANTS.SAPPHIRE}
                      width="320px"
                      isSmall
                      onClick={handleClickMoveMedia}
                      height={DEFAULT_MENU_HEIGHT}
                      isLoading={selectedMediaOptionsForMoving.isMoving}
                    >
                      {selectedMediaOptionsForMoving.isMoving ? 'Moving' : 'Move'}{' '}
                      {selectedMediaOptionsForMoving.counter}{' '}
                      {selectedMediaOptionsForMoving.counter === 1 ? 'selected media item' : 'selected media items'}{' '}
                      here
                    </ButtonWithLoading>
                  </Flex>
                )}

                {selectedMedias.length > 0 &&
                  selectedMediaOptionsForMoving.counter > 0 &&
                  selectedMediaOptionsForMoving.path !== asPath && <Divider height="1px" my="s" />}

                {selectedMedias.length > 0 && (
                  <Flex py="m" justifyContent="space-between" alignItems="center">
                    <Flex width="120px" ml="s">
                      <StyledText
                        onClick={() => {
                          setSelectedMedias([])
                        }}
                      >
                        Unselect all
                      </StyledText>
                    </Flex>

                    <Flex alignItems="center">
                      {hasGlobalAccessManage({ user, permission: PERMISSION_PUBLISH }) && (
                        <Button.Gradient
                          width="320px"
                          isSmall
                          onClick={() => {
                            const post = { isFromFindContentModal: true }

                            const selectedTypesTemp =
                              selectedTypes && selectedTypes.length !== 0 ? selectedTypes : DEFAULT_TYPES

                            if (selectedTypesTemp.find(({ value }) => value === IMAGE)) {
                              post.postImages = selectedMedias
                                .filter(({ type }) => type === IMAGE || type === IMAGE_DYNAMIC)
                                .slice(0, MAX_IMAGES_ATTACHMENTS_ALLOWED)

                              if (post.postImages.length !== 0) {
                                post.postComponent = POST_IMAGE
                              }
                            }

                            if (selectedTypesTemp.find(({ value }) => value === VIDEO)) {
                              post.postVideos = selectedMedias
                                .filter(({ type }) => type === VIDEO || type === VIDEO_DYNAMIC)
                                .slice(0, MAX_VIDEOS_ATTACHMENTS_ALLOWED)

                              if (post.postVideos.length !== 0) {
                                post.postComponent = POST_VIDEO
                              }
                            }

                            if (selectedTypesTemp.find(({ value }) => value === DOCUMENT)) {
                              post.postDocuments = selectedMedias
                                .filter(({ type }) => type === DOCUMENT)
                                .slice(0, MAX_DOCUMENTS_ATTACHMENTS_ALLOWED)

                              if (post.postDocuments.length !== 0) {
                                post.postComponent = POST_DOCUMENT
                              }
                            }

                            let counterMediaComponents = 0

                            const keys = ['postImages', 'postVideos', 'postDocuments']

                            keys.forEach((key) => {
                              if (post[key] && post[key].length !== 0) {
                                counterMediaComponents++
                              }
                            })

                            if (counterMediaComponents > 1) {
                              post.postMixedMedias = selectedMedias.slice(0, MAX_MIXED_MEDIAS_ATTACHMENTS_ALLOWED)
                              post.postComponent = POST_MIXED_MEDIA
                            }

                            handleClickSendToCompose({ post })
                          }}
                          height={DEFAULT_MENU_HEIGHT}
                        >
                          Create a post
                        </Button.Gradient>
                      )}
                    </Flex>

                    <Flex justifyContent="flex-end" width="120px">
                      <Box>
                        <Box>
                          <StyledRoundedButton
                            onClick={handleClickSelectSelectedMediasForMoving}
                            onMouseEnter={(e) => {
                              tooltipRef.current.handleShowTooltip({
                                contentComp: <Text p="s">Move selected media</Text>,
                                wrapperComp: e.currentTarget,
                              })
                            }}
                            onMouseLeave={() => {
                              tooltipRef.current.handleHideTooltip()
                            }}
                          >
                            <Icon.FileMove width="15px" height="15px" />
                          </StyledRoundedButton>
                        </Box>
                      </Box>

                      <Box>
                        <Box>
                          <StyledRoundedButton
                            ml="s"
                            onClick={handleClickOpenMediaLabelsModal}
                            onMouseEnter={(e) => {
                              tooltipRef.current.handleShowTooltip({
                                contentComp: <Text p="s">Label selected media</Text>,
                                wrapperComp: e.currentTarget,
                              })
                            }}
                            onMouseLeave={() => {
                              tooltipRef.current.handleHideTooltip()
                            }}
                          >
                            <Icon.VistaSocialLabel width="15px" height="15px" />
                          </StyledRoundedButton>
                        </Box>
                      </Box>

                      <Box>
                        <Box>
                          <StyledRoundedButton
                            ml="s"
                            onClick={() => {
                              confirm({
                                fn: () => () => handleClickRemoveMedia({ medias: selectedMedias.map(({ id }) => id) }),
                                message: `Are you sure you want to remove this ${
                                  selectedMedias.length === 1 ? 'media' : 'medias'
                                }?`,
                                action: 'Remove',
                              })
                            }}
                            onMouseEnter={(e) => {
                              tooltipRef.current.handleShowTooltip({
                                contentComp: <Text p="s">Remove selected media</Text>,
                                wrapperComp: e.currentTarget,
                              })
                            }}
                            onMouseLeave={() => {
                              tooltipRef.current.handleHideTooltip()
                            }}
                          >
                            <Icon.Trash />
                          </StyledRoundedButton>
                        </Box>
                      </Box>
                    </Flex>
                  </Flex>
                )}
              </StyledFooter>
            )}
          </Flex>
        </Flex>

        <CalendarItemTooltip ref={tooltipRef} />

        {isMetaInformationModalOpen && (
          <MetaInformationModal
            selectedMedia={selectedMedia}
            isOpen={isMetaInformationModalOpen}
            handleDismiss={handleClickCloseMediaMetaInformationModal}
            handleUpdate={handleUpdateMedia}
            entities={entities}
            isMediaUpdating={isMediaUpdating}
          />
        )}

        {isOpenImageEditModal && (
          <ImageEditModal
            handleDismiss={handleClickCloseImageEditModal}
            handleUpdate={handleUpdateMedia}
            isOpen={isOpenImageEditModal}
            selectedImage={selectedMedia}
            isImageUpdating={isMediaUpdating}
          />
        )}

        {isOpenVideoThumbnailModal && (
          <VideoThumbnailModal
            user={user}
            handleDismiss={handleClickCloseVideoThumbnailModal}
            handleUpdate={handleUpdateVideoThumbnail}
            isOpen={isOpenVideoThumbnailModal}
            selectedVideo={selectedMedia}
            isThumbnailUpdating={isThumbnailUpdating}
          />
        )}

        {isOpenVideoPickSoundModal && (
          <VideoPickSoundModal
            user={user}
            handleDismiss={handleClickCloseVideoPickSoundModal}
            isOpen={isOpenVideoPickSoundModal}
            selectedVideo={selectedMedia}
            handleUpdateMedia={handleUpdateVideoPickSound}
            featuresEnabled={featuresEnabled}
            handleClickOpenMediaUploadByLinkModal={() => {
              handleClickOpenMediaUploadByLinkModal({ type: AUDIO })
            }}
            handleClickGoogleDrive={() => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickGoogleDrive({ type: AUDIO })
                },
                feature: FEATURE_DRIVES,
              })
            }}
            handleClickDropbox={() => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickDropBox({ type: AUDIO })
                },
                feature: FEATURE_DRIVES,
              })
            }}
            handleClickOneDrive={() => {
              handleClickOpenAddMediaItemMenu({
                func: () => {
                  handleClickOneDrive({ type: AUDIO })
                },
                feature: FEATURE_DRIVES,
              })
            }}
            handleClickOpenMediaUploadByFileModal={() => {
              inputAudioFileRef.current.click()
            }}
            uploadingMedias={uploadingMedias}
            newMedias={newMedias}
            handleClickSendToCompose={
              hasGlobalAccessManage({ user, permission: PERMISSION_PUBLISH }) ? handleClickSendToCompose : null
            }
          />
        )}

        {mediaSettingsOptions.isOpen && (
          <MediaSettings
            user={user}
            uploadedMedias={mediaSettingsOptions.uploadedMedias}
            isOpen={mediaSettingsOptions.isOpen}
            handleDismiss={handleClickCloseMediaSettingsModal}
            handleUpdateUploadedMedias={() => {
              setUploadingMediasAmount(0)
            }}
            folderPath={folder?.media_path}
          />
        )}

        {publisherWrapperModalOptions.isOpen && (
          <PublisherWrapper
            isPublisherWrapperOpen={publisherWrapperModalOptions.isOpen}
            handleClickClosePublisherWrapper={handleClickClosePublisherWrapper}
            user={user}
            {...publisherWrapperModalOptions}
            folderPath={folder?.media_path}
          />
        )}

        {mediaLabelsModalOptions.isOpen && (
          <LabelEditModal
            handleDismiss={handleClickCloseMediaLabelsModal}
            isOpen={mediaLabelsModalOptions.isOpen}
            data={mediaLabelsModalOptions.data}
            handleUpdateDataLabels={handleUpdatePostLabels}
            type={LABELS_TYPE_MEDIA}
            modalTitle="Labels"
            modalDescription=""
          />
        )}

        {vistaCreateDesignOptions.isOpen && (
          <VistaCreateDesignModal
            handleDismiss={handleClickCloseVistaCreateModal}
            isOpen={vistaCreateDesignOptions.isOpen}
            handleClickAddMedias={(url) => {
              handleAddDesignMedias({ url, type: VIDEO })
            }}
          />
        )}

        {canvaDesignOptions.isOpen && (
          <CanvaDesignModal
            handleDismiss={handleClickCloseCanvaModal}
            isOpen={canvaDesignOptions.isOpen}
            handleClickAddMedias={(url) => {
              handleAddDesignMedias({ url, type: IMAGE })
            }}
          />
        )}

        {mediaDetailModalOptions.isOpen && (
          <MediaDetailModal
            data={mediaDetailModalOptions.data}
            handleClickSendToCompose={
              hasGlobalAccessManage({ user, permission: PERMISSION_PUBLISH }) ? handleClickSendToCompose : null
            }
            medias={uploadedMedias}
            handleDismiss={handleClickCloseMediaDetailModal}
            isOpen={mediaDetailModalOptions.isOpen}
            handleClickRemoverFromFavorite={handleClickRemoverFromFavorite}
            handleClickAddToFavorite={handleClickAddToFavorite}
            handleClickRemoveMedia={handleClickRemoveMedia}
            confirm={confirm}
            handleClickOpenImageEditModal={handleClickOpenImageEditModal}
            handleClickOpenVideoThumbnailModal={handleClickOpenVideoThumbnailModal}
            handleClickOpenVideoPickSoundModal={handleClickOpenVideoPickSoundModal}
            handleClickOpenMediaMetaInformationModal={handleClickOpenMediaMetaInformationModal}
            handleUpdateAudioCover={handleUpdateAudioCover}
            imageExtensions={IMAGE_EXTENSIONS_JOINED}
            updatedData={mediaDetailModalOptions.updatedData}
            handleUpdateMediaInMediaDetailModal={handleUpdateMediaInMediaDetailModal}
            handleClickOpenPosts={({ publications }) => {
              setSliderPostsPreviewData({
                eventIds: publications,
                specialEvents: [],
              })
            }}
            handleClickOpenMediaDynamicModal={handleClickOpenMediaDynamicModal}
          />
        )}

        {mediaUploadByLinkOptions.isOpen && (
          <MediaUploadByLinkModal
            handleDismiss={handleClickCloseMediaUploadByLinkModal}
            isOpen={mediaUploadByLinkOptions.isOpen}
            handleClickAddMedias={handleAddUrlMedias}
            type={mediaUploadByLinkOptions.type}
            hasError={mediaUploadByLinkOptions.hasError}
          />
        )}

        {mediaTrendingModalOptions.isOpen && (
          <MediaTrendingModal
            handleDismiss={handleClickCloseMediaTrendingModal}
            type={mediaTrendingModalOptions.type}
            handleClickAddMedias={handleAddTrendingMedias}
            isOpen={mediaTrendingModalOptions.isOpen}
          />
        )}

        {dynamicMediaOptions.isOpen && (
          <DynamicMediaModal
            user={user}
            isOpen={dynamicMediaOptions.isOpen}
            handleDismiss={handleClickCloseMediaDynamicModal}
            media={dynamicMediaOptions.media}
            handleUpdateDynamicMedia={handleUpdateDynamicMedia}
            selectedEntities={props.selectedEntities}
            selectedProfiles={props.selectedProfiles}
            folderPath={folder?.media_path}
          />
        )}

        {sliderPostsPreviewData && (
          <SliderPostsPreview
            user={user}
            isOpen={!!sliderPostsPreviewData}
            data={sliderPostsPreviewData || {}}
            setSliderPostsPreviewData={setSliderPostsPreviewData}
            handleClickEvent={() => {}}
            timezone={
              getSelectedProfileGroupsAndTimezone({
                user,
              }).selectedTimezone
            }
            handleDeletePost={() => {}}
            isPostDeleting={false}
            handleUpdateCalendarPostLabels={() => {}}
            hasComponentControls={false}
          />
        )}

        {addFolderModalOptions.isOpen && (
          <AddFolderModal
            user={user}
            isMediaUpdating={isMediaUpdating}
            isOpen={addFolderModalOptions.isOpen}
            handleDismiss={handleClickCloseAddFolderModal}
            selectedMedia={addFolderModalOptions.data}
            entities={entities}
            folderPath={folder?.media_path}
          />
        )}

        <input
          hidden
          type="file"
          multiple
          ref={inputImageFileRef}
          onChange={(e) => handleMediaFile(e)}
          accept={IMAGE_EXTENSIONS_JOINED}
          onClick={(event) => {
            event.target.value = null
          }}
        />
        <input
          hidden
          type="file"
          multiple
          ref={inputVideoFileRef}
          onChange={(e) => handleMediaFile(e)}
          accept={VIDEO_EXTENSIONS_JOINED}
          onClick={(event) => {
            event.target.value = null
          }}
        />
        <input
          hidden
          type="file"
          multiple
          ref={inputAudioFileRef}
          onChange={(e) => handleMediaFile(e, AUDIO)}
          accept={AUDIO_EXTENSIONS_JOINED}
          onClick={(event) => {
            event.target.value = null
          }}
        />
        <input
          hidden
          type="file"
          multiple
          ref={inputDocumentFileRef}
          onChange={(e) => handleMediaFile(e)}
          accept={DOCUMENT_EXTENSIONS_JOINED}
          onClick={(event) => {
            event.target.value = null
          }}
        />
      </Fragment>
    )
  }
)

MediaBodyComponent.defaultProps = {
  selectedEntities: null,
  selectedProfiles: null,
  entities: [],
  setDragMediaOptions: () => {},
  dragMediaOptions: {},
  onDropMedia: null,
  isSmall: false,
  isSelectable: true,
  isFavorable: true,
  hasItemControls: true,
  handleClickCreatePost: null,
  handleUpdateComponentsAfterMediasUpdate: () => {},
  handleSpecialItemOnClick: null,
  showAddMediaDropdownPosition: 'left',
  shouldSaveFolderPath: false,
  folderId: null,
}

MediaBodyComponent.propTypes = {
  user: PropTypes.object.isRequired,
  confirm: PropTypes.func.isRequired,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  entities: PropTypes.array,
  setDragMediaOptions: PropTypes.func,
  dragMediaOptions: PropTypes.object,
  onDropMedia: PropTypes.func,
  isSmall: PropTypes.bool,
  isSelectable: PropTypes.bool,
  isFavorable: PropTypes.bool,
  hasItemControls: PropTypes.bool,
  handleClickCreatePost: PropTypes.func,
  handleUpdateComponentsAfterMediasUpdate: PropTypes.func,
  handleSpecialItemOnClick: PropTypes.func,
  defaultTypes: PropTypes.array.isRequired,
  showAddMediaDropdownPosition: PropTypes.string,
  shouldSaveFolderPath: PropTypes.bool,
  folderId: PropTypes.string,
}

MediaBodyComponent.displayName = 'MediaBodyComponent'

export default MediaBodyComponent
