import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ClipLoader from 'react-spinners/ClipLoader'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { COLOR_CONSTANTS, colors, radius } from 'theme'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import { H4, Text } from 'components/atoms/Typography'
import { Box, Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'
import Icon from 'components/atoms/Icon'
import SetTimezoneModal from '../SetTimezoneModal'
import { getApproversData, getSelectedProfileGroupsAndTimezone } from '../../helpers'
import AssignModal from '../AssignModal'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 600px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0;
    height: 250px;
    display: flex;
    flex-direction: column;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  background-color: ${COLOR_CONSTANTS.BOYSENBERRY_SHADOW};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const StyledProgressWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
`

const MessageWrapper = styled(Flex)`
  background-color: ${({ color }) => transparentize(0.9, color || COLOR_CONSTANTS.FLUORESCENT_RED)};
  border-radius: ${radius.l};
  width: 100%;
`

const StyledGroupEqualIcon = styled(Icon.GroupEqual)`
  fill: ${COLOR_CONSTANTS.COSMIC_ENERGY};
`

const BulkSubmitPostModal = ({
  user,
  isOpen,
  handleDismiss,
  handleSubmit,
  numberOfPosts,
  numberOfIdeas,
  isPostSubmitting,
  handleUpdateEntities,
  workflow,
  setWorkflow,
  ...props
}) => {
  const [selectedProfiles, setSelectedProfiles] = useState([])
  const [selectedEntities, setSelectedEntities] = useState([])
  const [isSetTimezoneModalOpen, setIsSetTimezoneModalOpen] = useState(false)
  const [approversData, setApproversData] = useState({})
  const [isGettingApprovers, setIsGettingApprovers] = useState(true)
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false)
  const [entityPublishValues, setEntityPublishValues] = useState([])

  const getApprovers = async () => {
    const { is_approver, approvers = [] } = await getApproversData({
      selectedProfiles: props.selectedProfiles,
      publication_group_gid: null,
    })
    setIsGettingApprovers(false)
    setApproversData({ ...{ is_approver, approvers } })
  }

  useEffect(() => {
    if (isOpen) {
      if (numberOfPosts > 0) {
        getApprovers()
      } else {
        setIsGettingApprovers(false)
      }

      const { selectedEntitiesWithSelectedProfiles } = getSelectedProfileGroupsAndTimezone({
        user,
        selectedProfiles: props.selectedProfiles,
        selectedEntities: props.selectedEntities,
      })

      setEntityPublishValues(
        selectedEntitiesWithSelectedProfiles.map(({ id, name, picture_url }) => ({
          entityId: id,
          name,
          entityPictureUrl: picture_url,
        }))
      )

      setSelectedProfiles([...props.selectedProfiles])
      setSelectedEntities([...props.selectedEntities])
    }
  }, [isOpen])

  const handleClickOpenSetTimezoneModal = () => {
    setIsSetTimezoneModalOpen(true)
  }

  const handleClickCloseSetTimezoneModal = () => {
    setIsSetTimezoneModalOpen(false)
  }

  const handleClickOpenAssignModal = () => {
    setIsAssignModalOpen(true)
  }

  const handleClickCloseAssignModal = () => {
    setIsAssignModalOpen(false)
  }

  const handleClickSaveWorkflow = (body) => {
    setWorkflow([...body])
    handleClickCloseAssignModal()
  }

  const handleSubmitPosts = () => {
    if (!isGettingApprovers && !isPostSubmitting) {
      let shouldSetEntityTimezone = false
      selectedProfiles.forEach((profile) => {
        const foundEntity = selectedEntities.find((entity) => entity.id === profile.entityId)
        if (foundEntity && !foundEntity.timezone) {
          shouldSetEntityTimezone = true
        }
      })
      if (shouldSetEntityTimezone) {
        handleClickOpenSetTimezoneModal()
      } else {
        handleSubmit()
      }
    }
  }

  const handleClickCloseModal = () => {
    if (!isPostSubmitting) {
      handleDismiss()
    }
  }

  const hasWorkflow = workflow && workflow.length !== 0

  let submitButtonText = isPostSubmitting ? 'Saving' : 'Save'

  if (numberOfPosts > 0) {
    if (!approversData.is_approver) {
      submitButtonText = isPostSubmitting ? 'Sending' : 'Send for Approval'
    } else {
      submitButtonText = isPostSubmitting ? 'Scheduling' : 'Schedule'
    }
  } else if (numberOfIdeas > 0) {
    submitButtonText = isPostSubmitting ? 'Saving' : 'Save'
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="auto" width="100%" height="100%" p="l" position="relative">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" $isTop>
            <H4 my="m">Bulk schedule</H4>
          </StyledDialogEnvironmentWrapper>
          <Flex flex="1" alignItems="center" px="m" position="relative" flexDirection="column">
            <StyledProgressWrapper
              display={isGettingApprovers ? 'flex' : 'none'}
              alignItems="center"
              justifyContent="center"
            >
              <ClipLoader size="100" color={colors.primary} />
            </StyledProgressWrapper>

            {hasWorkflow && (
              <MessageWrapper alignItems="center" px="m" py="s" mt="m" color={COLOR_CONSTANTS.FLUORESCENT_RED}>
                <Flex alignItems="center" justifyContent="space-between" width="100%">
                  <Text fontWeight="bold" color={COLOR_CONSTANTS.FLUORESCENT_RED}>
                    {numberOfPosts === 1 ? 'This post is assigned for review' : 'This posts are assigned for review'}
                  </Text>
                  <Flex alignItems="center" justifyContent="flex-end">
                    <Button.Gray isSmall onClick={handleClickOpenAssignModal}>
                      Change
                    </Button.Gray>
                    <Button.Gray isSmall ml="m" onClick={() => handleClickSaveWorkflow([])}>
                      Cancel
                    </Button.Gray>
                  </Flex>
                </Flex>
              </MessageWrapper>
            )}

            {!isGettingApprovers && numberOfPosts > 0 && (
              <Text textAlign="center" fontSize="m" mt="m">
                {`You are about to schedule ${numberOfPosts} ${numberOfPosts === 1 ? 'post' : 'posts'} to ${
                  selectedProfiles.length
                } ${selectedProfiles.length === 1 ? 'profile' : 'profiles'}.`}
              </Text>
            )}

            {numberOfIdeas > 0 && (
              <Text textAlign="center" fontSize="m" mt="m">
                {`You are about to create ${numberOfIdeas} ${numberOfIdeas === 1 ? 'idea' : 'ideas'}.`}
              </Text>
            )}
          </Flex>

          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Button.Gray mr="m" isSmall onClick={handleClickCloseModal} isDisabled={isPostSubmitting}>
              Return to editing
            </Button.Gray>
            {!isGettingApprovers && (
              <Flex>
                {approversData.is_approver && !hasWorkflow && numberOfPosts > 0 && (
                  <Button.Gray isSmall onClick={handleClickOpenAssignModal}>
                    <Flex alignItems="center">
                      <StyledGroupEqualIcon width="22px" height="14px" color="primaryText" />
                      <Text ml="s">Assign</Text>
                    </Flex>
                  </Button.Gray>
                )}
                <ButtonWithLoading ml="m" isSmall onClick={handleSubmitPosts} isLoading={isPostSubmitting}>
                  {submitButtonText}
                </ButtonWithLoading>
              </Flex>
            )}
          </StyledDialogEnvironmentWrapper>

          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal} isDisabled={isPostSubmitting}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
      <SetTimezoneModal
        user={user}
        isOpen={isSetTimezoneModalOpen}
        handleDismiss={handleClickCloseSetTimezoneModal}
        entitiesForSettingTimezone={selectedEntities}
        handleUpdateEntities={handleUpdateEntities}
      />

      {isAssignModalOpen && (
        <AssignModal
          user={user}
          isOpen={isAssignModalOpen}
          handleDismiss={handleClickCloseAssignModal}
          handleSave={handleClickSaveWorkflow}
          workflow={workflow}
          approvers={approversData.approvers || []}
          entityPublishValues={entityPublishValues}
        />
      )}
    </StyledDialogOverlay>
  )
}

BulkSubmitPostModal.defaultProps = {
  workflow: [],
  setWorkflow: () => {},
}

BulkSubmitPostModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  numberOfPosts: PropTypes.number.isRequired,
  numberOfIdeas: PropTypes.number.isRequired,
  isPostSubmitting: PropTypes.bool.isRequired,
  selectedProfiles: PropTypes.array.isRequired,
  selectedEntities: PropTypes.array.isRequired,
  handleUpdateEntities: PropTypes.func.isRequired,
  workflow: PropTypes.array,
  setWorkflow: PropTypes.func,
}

export default BulkSubmitPostModal
