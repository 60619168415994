import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import debounce from 'lodash.debounce'
import { COLOR_CONSTANTS, radius } from 'theme'
import { FACEBOOK } from 'consts'
import Select from 'shared/DropDown'
import { H4 } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'
import PromptComponent from 'components/atoms/PromptComponent'
// eslint-disable-next-line import/no-cycle
import { formatOptionLabel, getAccounts } from 'routes/Calendar/helpers'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 530px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0;
    height: auto;
    min-height: 340px;
    display: flex;
    flex-direction: column;
    outline: none;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  background-color: ${COLOR_CONSTANTS.BOYSENBERRY_SHADOW};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  height: 100%;
  overflow: hidden;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const code = FACEBOOK

const FacebookCollaboratorsModal = ({ isOpen, handleDismiss, data, handleSaveCollaborators, savedCollaborators }) => {
  const [collaborators, setCollaborators] = useState([])
  const [getSuggestedOptionsErrors, setGetSuggestedOptionsErrors] = useState({})

  useEffect(() => {
    if (isOpen) {
      const { collaborators = [] } = data
      setCollaborators([...collaborators.map((item) => ({ value: item.id, label: item.name }))])
    }
  }, [isOpen])

  const handleClickSaveCollaborators = () => {
    handleSaveCollaborators({
      collaborators: collaborators.map((item) => ({ id: item.value, name: item.label })),
    })
  }

  const loadSuggestedOptions = React.useCallback(
    debounce((loadData, callback) => {
      getAccounts(loadData).then((options) => callback([...options]))
    }, 1000),
    []
  )

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent tabIndex={0}>
          <StyledDialogEnvironmentWrapper px="m" $isTop>
            <H4 my="m">Invite collaborators</H4>
          </StyledDialogEnvironmentWrapper>
          <StyledDialogBodyWrapper
            flex="1"
            heigth="100%"
            width="100%"
            flexDirection="column"
            justifyContent="flex-start"
            px="m"
            pt="m"
          >
            <Select
              placeholder="Page name"
              value={collaborators || []}
              onChange={(options) => {
                if (options) {
                  setCollaborators([...options])
                } else {
                  setCollaborators([])
                }
              }}
              loadOptions={(value, callback) =>
                loadSuggestedOptions(
                  {
                    value,
                    network: code,
                    getSuggestedOptionsErrors,
                    setGetSuggestedOptionsErrors,
                    id: data.profileId,
                  },
                  callback
                )
              }
              defaultValue={[]}
              isMulti
              isClearable
              defaultOptions={savedCollaborators}
              openMenuOnFocus
              dropDownType="AsyncSelect"
              formatOptionLabel={(props) => {
                return formatOptionLabel({ ...props, specialSymbol: '@' })
              }}
              noOptionsMessage={() => {
                return getSuggestedOptionsErrors[code]
                  ? 'No results found. Please be sure to enter the exact username/handle for the given network.'
                  : null
              }}
            />

            <Flex mt="xs" flexDirection="column" width="100%">
              <PromptComponent
                text="When invited collaborators accept the invitation, the reel will immediately be published on their Facebook Page if the reel has been published, or the reel will be published on their Page when you publish the reel on your Page. <a href='https://support.vistasocial.com/hc/en-us/articles/30621568692251' target='_blank'>Learn more</a>"
                isShown
                type="warning"
              />
            </Flex>
          </StyledDialogBodyWrapper>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Button.Gray isSmall onClick={handleDismiss}>
              Cancel
            </Button.Gray>

            <Button.Gradient isSmall onClick={handleClickSaveCollaborators}>
              Save
            </Button.Gradient>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleDismiss}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

FacebookCollaboratorsModal.defaultProps = {}

FacebookCollaboratorsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleSaveCollaborators: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  savedCollaborators: PropTypes.array.isRequired,
}

export default FacebookCollaboratorsModal
