import React, { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { position } from 'styled-system'
import Slider from 'react-slick'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import { Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import ProfileItemTooltip from '../../ProfileItemTooltip'
import SocialProfileImage from '../../SocialProfileImage'
import CalendarItemTooltip from '../../CalendarItemTooltip'

const Arrow = styled.img`
  position: absolute;
  transform: translate(0px, -50%);
  z-index: 2;
  cursor: pointer;
  ${position};
`

const StyledSelectedProfileWrapper = styled(Flex)`
  border-bottom: 3px solid ${({ isSelected }) => (isSelected ? colors.primary : 'transparent')};
  padding-bottom: ${space.s};
  &:hover {
    div {
      display: flex;
    }
  }
`

const StyledErrorWrapper = styled(Flex)`
  box-shadow: 0 0 0 0 ${COLOR_CONSTANTS.FLUORESCENT_RED};
  animation: pulse 2s cubic-bezier(0.66, 0, 0, 1) infinite;
  border-radius: ${radius.pill};
  background: ${COLOR_CONSTANTS.WHITE};
`

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 10,
  slidesToScroll: 1,
  initialSlide: 0,
  nextArrow: <Arrow src="/assets/vistasocial/chevron_right.svg" right="-16px" />,
  prevArrow: <Arrow src="/assets/vistasocial/chevron_left.svg" left="-16px" />,
}

const SelectedProfilesSlider = ({ profiles, activeProfile, handleClickSelectProfile, postsByProfile }) => {
  const tooltipRef = useRef(null)
  return (
    <Fragment>
      <Slider {...settings}>
        {profiles.map((profile, index) => {
          const {
            id,
            picture_url,
            profile_url,
            name,
            username,
            network: { code, display },
            profile_id,
            type,
          } = profile
          const isSelected = activeProfile && activeProfile.id === id
          return (
            <StyledSelectedProfileWrapper
              alignItems="center"
              key={`${id}${index}`}
              mx="s"
              maxWidth="24px"
              width="24px"
              maxHeight="44px"
              height="100%"
              position="relative"
              onClick={() => {
                handleClickSelectProfile({ profile })
              }}
              isSelected={isSelected}
            >
              <Flex
                alignItems="center"
                onMouseEnter={(e) => {
                  tooltipRef.current.handleShowTooltip({
                    contentComp: (
                      <ProfileItemTooltip
                        profile={{ code, picture_url, profile_url, name, username, display, profile_id, type }}
                      />
                    ),
                    wrapperComp: e.currentTarget,
                  })
                }}
                onMouseLeave={() => {
                  tooltipRef.current.handleHideTooltip()
                }}
              >
                <SocialProfileImage picture_url={picture_url} code={code} />
              </Flex>
              {postsByProfile[id] && postsByProfile[id].hasError && (
                <StyledErrorWrapper position="absolute" width="16px" height="16px" bottom="1px" right="-3px">
                  <Image src="/assets/bell_red.svg" width="16px" height="16px" />
                </StyledErrorWrapper>
              )}
            </StyledSelectedProfileWrapper>
          )
        })}
      </Slider>
      <CalendarItemTooltip ref={tooltipRef} />
    </Fragment>
  )
}

SelectedProfilesSlider.defaultProps = {
  profiles: [],
  activeProfile: null,
}

SelectedProfilesSlider.propTypes = {
  profiles: PropTypes.array,
  handleClickSelectProfile: PropTypes.func.isRequired,
  activeProfile: PropTypes.object,
  postsByProfile: PropTypes.object.isRequired,
}

export default SelectedProfilesSlider
