import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Scrollbars } from 'react-custom-scrollbars-2'
import ClipLoader from 'react-spinners/ClipLoader'
import { transparentize } from 'polished'
import Alert from 'react-s-alert'
import moment from 'moment-timezone'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import { IMAGE, VIDEO, INSTAGRAM, TIKTOK, IMAGE_DYNAMIC, VIDEO_DYNAMIC } from 'consts'
import { Text } from 'components/atoms/Typography'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import Icon from 'components/atoms/Icon'
import MediaCarousel from 'components/molecules/MediaCarousel'
import DynamicMedia from 'components/molecules/DynamicMedia'
import { getHeaderTitleAndColor, getInstagramProfileMedias, getTikTokProfileMedias } from 'routes/Calendar/helpers'
import {
  POST_IMAGE,
  POST_VIDEO,
  POST_LINK,
  PUBLISH_AS_REELS,
  PUBLISH_AS_STORY,
  INSTAGRAM_COLORS,
} from 'routes/Calendar/consts'
import {
  GRID_TYPE_FEED,
  GRID_TYPE_REELS,
  GRID_TYPE_STORY,
  TABLE_POSTS_ID_PREFIX,
  PHONE_POSTS_ID_PREFIX,
} from '../consts'

const StyledPhoneWrapper = styled(Flex)`
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  background-size: 100% auto;
  background: url('/assets/phone_background.svg') no-repeat;
  background-size: cover;
`

const StyledPhoneContentWrapper = styled(Flex)`
  border: unset !important;
  position: absolute;
  top: 6px;
  bottom: 10px;
  left: 20px;
  right: 20px;
  background: ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
  border-radius: 30px;
  overflow: hidden !important;
  overflow-anchor: none;
  touch-action: auto;
`

const StyledUserImage = styled(ImageWithFallback)`
  border-radius: ${radius.pill};
`

const StyledHiddenIconWrapper = styled(Flex)`
  display: none;
  position: absolute;
  background: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
  border-radius: ${radius.pill};
  padding: ${space.xs};
  z-index: 1;
`

const StyledMediaWrapper = styled(Box)`
  position: relative;
  cursor: pointer;
  width: 106px;
  border: 3px solid ${({ borderColor }) => borderColor};
  border-radius: ${radius.l};
  &:hover {
    ${StyledHiddenIconWrapper} {
      display: flex;
    }
    box-shadow: 0px 0px 5px 5px rgb(39 40 49 / 30%) !important;
  }
`

const StyledPostMedia = styled(Flex)`
  border-radius: ${radius.l};
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-image: url(${({ backgroundImage }) => backgroundImage});
`

const StyledMedia = styled(ImageWithFallback)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: ${radius.l};
`

const StyledProgressWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
  background: ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
`

const StyledBox = styled(Box)`
  cursor: pointer;
  ${({ isScheduled }) => isScheduled && `border: 3px solid #64A7DB;`}
  width: 106px;
  border-radius: ${radius.l};
`

const StyledGridTypeWrapper = styled(Flex)`
  cursor: pointer;
  padding: ${space.s};
  border-radius: ${radius.l};
  background: ${({ isSelected }) => (isSelected ? transparentize(0.9, colors.primary) : 'transparent')};
  &:hover {
    background: ${transparentize(0.9, colors.primary)};
  }
`

const DropMediaWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  border: 1px dashed ${colors.primary};
`

const StyledVistaSocialPlusWrapper = styled(Flex)`
  background: ${transparentize(0.8, colors.primary)};
  align-items: center;
  justify-content: center;
  border-radius: ${radius.pill};
`

const StyledUserImageWrapper = styled(Flex)`
  background-image: none;
  background-size: 100% auto;
  background-size: cover;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  ${({ hasBackground }) =>
    hasBackground && `background-image: url('/assets/vistasocial/instagram_story_ring.svg'); cursor: pointer;`}
`

const StyledStoryPlusWrapper = styled(Flex)`
  background: #3399ff;
  align-items: center;
  justify-content: center;
  border-radius: ${radius.pill};
  border: 3px solid ${COLOR_CONSTANTS.WHITE};
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 2px;
  right: 2px;
  cursor: pointer;
`

const StyledStoryHeaderWrapper = styled(Flex)`
  align-items: stretch;
  background: linear-gradient(180deg, rgba(38, 38, 38, 0.8) 0%, rgba(38, 38, 38, 0) 100%);
  border: 0;
  box-sizing: border-box;
  flex-direction: column;
  flex-shrink: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 20px ${space.m} ${space.l} ${space.m};
  position: absolute;
  top: 0;
  vertical-align: baseline;
  width: 100%;
  z-index: 1;
`

const StyledStoryHeaderItem = styled(Flex)`
  align-items: stretch;
  background: rgba(255, 255, 255, ${({ isActive }) => (isActive ? 1 : 0.35)});
  border: 0;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  vertical-align: baseline;
`

const StyledFlex = styled(Flex)`
  cursor: pointer;
`

const StyledStoryFooterWrapper = styled(Flex)`
  background: linear-gradient(180deg, rgba(38, 38, 38, 0) 0%, rgba(38, 38, 38, 0.6) 100%);
  align-items: stretch;
  border: 0;
  bottom: 0;
  box-sizing: border-box;
  flex-direction: column;
  flex-shrink: 0;
  font: inherit;
  font-size: 100%;
  height: 150px;
  justify-content: flex-end;
  left: 0;
  margin: 0;
  margin-top: auto;
  padding: 0 ${space.m} ${space.m};
  pointer-events: none;
  position: absolute;
  vertical-align: baseline;
  width: 100%;
  z-index: 999;
`

const StyledStoryPublishTime = styled(Text)`
  color: ${COLOR_CONSTANTS.WHITE};
  opacity: 0.6;
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const MAX_COLUMNS = 3
const DEFAULT_FALLBACK_IMAGE = '/assets/landscape.svg'
const REELS_HEIGHT = '177px'
const TIKTOK_VIDEO_HEIGHT = '140px'

const GRID_TYPES = [
  { type: GRID_TYPE_FEED, icon: '/assets/vistasocial/instagram_grid.svg' },
  { type: GRID_TYPE_REELS, icon: '/assets/vistasocial/instagram_reel.svg' },
]

const MAX_PREVIEW_WIDTH = 336
const MAX_PREVIEW_HEIGHT = 484

const onMouseOver = ({ over, id }) => {
  const el = document.getElementById(`${TABLE_POSTS_ID_PREFIX}-${id}`)
  if (el) {
    el.style.background = over ? COLOR_CONSTANTS.SOLITUDE : 'none'
  }
}

const PhoneComponent = forwardRef(
  (
    {
      posts,
      activeProfile,
      setPostsByProfile,
      postsByProfile,
      dragMediaOptions,
      handleClickRemovePost,
      onDropMedia,
      handleClickEditPost,
      network,
    },
    phoneComponentRef
  ) => {
    const [showGridType, setShowGridType] = useState()
    const [dataForCustomizations, setDataForCustomizations] = useState({})
    const [currentMediaIndex, setCurrentMediaIndex] = useState(0)

    const { id: profileId, picture_url, name, username, code, timezone } = activeProfile || {}
    const { [profileId]: { isGettingPublications, hasStory } = {} } = postsByProfile

    const { [profileId]: networkDataForCustomizations } = dataForCustomizations.networkDataForCustomizations || {}
    const { [showGridType || GRID_TYPE_FEED]: showGridTypeMedias = {} } = networkDataForCustomizations || {}
    const { medias = [], isGettingGridMedia = true } = showGridTypeMedias || {}

    useImperativeHandle(phoneComponentRef, () => ({
      getShowGridType() {
        return showGridType || GRID_TYPE_FEED
      },
    }))

    const getInstagramMedias = () => {
      const postIds = posts.map(({ id }) => id)
      getInstagramProfileMedias({
        id: activeProfile.id,
        dataForCustomizations,
        setDataForCustomizations,
        postIds,
        type: showGridType || GRID_TYPE_FEED,
      })
      getInstagramProfileMedias({
        id: activeProfile.id,
        dataForCustomizations,
        setDataForCustomizations,
        postIds,
        type: GRID_TYPE_STORY,
      })
    }

    const getTikTokMedias = () => {
      getTikTokProfileMedias({
        id: activeProfile.id,
        dataForCustomizations,
        setDataForCustomizations,
        postIds: posts.map(({ id }) => id),
      })
    }

    useEffect(() => {
      if (activeProfile && postsByProfile[profileId] && postsByProfile[profileId].searchedForPublications) {
        if (network === INSTAGRAM) {
          getInstagramMedias()
        } else if (network === TIKTOK) {
          getTikTokMedias()
        }
      }
    }, [activeProfile, postsByProfile])

    useEffect(() => {
      if (showGridType && activeProfile) {
        getInstagramMedias()
      }
    }, [showGridType])

    const swapMedia = (fromBox, toBox) => {
      let fromIndex = -1
      let toIndex = -1

      for (let i = 0; i < posts.length; i++) {
        if (fromIndex > -1 && toIndex > -1) {
          break
        }
        if (posts[i].id === fromBox) {
          fromIndex = i
        }
        if (posts[i].id === toBox) {
          toIndex = i
        }
      }

      if (fromIndex !== -1 && toIndex !== -1) {
        const { fromId, ...fromRest } = posts[fromIndex]
        const { toId, ...toRest } = posts[toIndex]
        posts[fromIndex] = { id: fromBox, ...toRest, publish_at: fromRest.publish_at, hasChanged: true }
        posts[toIndex] = { id: toBox, ...fromRest, publish_at: toRest.publish_at, hasChanged: true }

        postsByProfile[activeProfile.id].posts = posts
        setPostsByProfile({ ...postsByProfile })
      }
    }

    const handleMediaDragStart = ({ id, target }) => {
      target.dataTransfer.setData('dragContent', id)
    }

    const handleMediaDragOver = ({ target }) => {
      target.preventDefault()
      return false
    }

    const handleMediaDrop = ({ id, target }) => {
      target.preventDefault()
      swapMedia(target.dataTransfer.getData('dragContent'), id)
      return false
    }

    let stories = []
    let story_publish_at

    if (showGridType === GRID_TYPE_STORY) {
      stories = posts.filter((post) => {
        const { customizations, isRemoved } = post

        if (isRemoved) {
          return false
        }

        const {
          [code]: {
            [profileId]: { media_type },
          },
        } = customizations

        if (media_type === PUBLISH_AS_STORY) {
          return post
        }
        return false
      })

      stories.sort((x, y) => x.publish_at_time - y.publish_at_time)

      if (stories.length !== 0 && stories[currentMediaIndex]) {
        story_publish_at = stories[currentMediaIndex].publish_at

        if (story_publish_at) {
          if (story_publish_at.includes('queue') || story_publish_at.includes('now')) {
            story_publish_at = `${story_publish_at.charAt(0).toUpperCase()}${story_publish_at
              .slice(1)
              .replace(/_/g, ' ')}`
          } else {
            story_publish_at = moment(story_publish_at).format('MMM D, YYYY h:mm a')
          }
        }
      }
    }

    let defaultVideoHeight = '106px'
    if (network === INSTAGRAM) {
      if (showGridType === GRID_TYPE_REELS) {
        defaultVideoHeight = REELS_HEIGHT
      }
    } else if (network === TIKTOK) {
      defaultVideoHeight = TIKTOK_VIDEO_HEIGHT
    }

    return (
      <StyledPhoneWrapper flexDirection="column">
        <StyledPhoneContentWrapper flexDirection="column">
          {showGridType === GRID_TYPE_STORY ? (
            <Flex
              flexDirection="column"
              position="relative"
              bg={COLOR_CONSTANTS.BLACK}
              height="100%"
              onDrop={(e) => {
                e.stopPropagation()
                onDropMedia({ dropMediaType: PUBLISH_AS_STORY })
              }}
            >
              <StyledStoryHeaderWrapper>
                <Flex justifyContent="space-between" mb="s">
                  {stories.map(({ id }, index) => {
                    return (
                      <Flex key={id} flexGrow="1" height="2px" mr="xxs" position="relative">
                        <StyledStoryHeaderItem isActive={index === currentMediaIndex} />
                      </Flex>
                    )
                  })}
                </Flex>
                <Flex flex="0 0 auto" justifyContent="space-between" alignItems="center" alignContent="stretch">
                  <Flex alignItems="center">
                    <StyledUserImage source={picture_url} width="32px" height="32px" />
                    <Flex
                      ml="s"
                      flex="0 0 auto"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      alignContent="stretch"
                    >
                      <Text color="white" fontSize="s">
                        {username || name}
                      </Text>
                    </Flex>
                  </Flex>
                  <StyledFlex
                    alignItems="center"
                    onClick={() => {
                      setShowGridType(GRID_TYPE_FEED)
                    }}
                  >
                    <Image width="20px" height="20px" src="/assets/clear_white.svg" />
                  </StyledFlex>
                </Flex>
              </StyledStoryHeaderWrapper>
              <MediaCarousel
                width={MAX_PREVIEW_WIDTH}
                height={MAX_PREVIEW_HEIGHT}
                medias={stories}
                activeDotsColor="transparent"
                showLegend={false}
                sliderElements={stories.map((post) => {
                  const { id, postComponent, postImages, postVideos, link } = post
                  let media

                  if (postComponent === POST_IMAGE) {
                    // eslint-disable-next-line prefer-destructuring
                    media = postImages[0]
                  } else if (postComponent === POST_VIDEO) {
                    // eslint-disable-next-line prefer-destructuring
                    media = postVideos[0]
                  } else if (postComponent === POST_LINK && link) {
                    media = { type: IMAGE, url: link.picture_url, thumbnail_url: link.picture_url }
                  }

                  const { url, thumbnail_url, type = IMAGE } = media

                  return (
                    <Flex
                      width={`${MAX_PREVIEW_WIDTH}px`}
                      height={`${MAX_PREVIEW_HEIGHT}px`}
                      id={`${PHONE_POSTS_ID_PREFIX}-${id}`}
                      key={id}
                      onClick={() => {
                        handleClickEditPost({ profile_gid: profileId, post, timezone })
                      }}
                      onMouseEnter={() => {
                        onMouseOver({ over: true, id })
                      }}
                      onMouseLeave={() => {
                        onMouseOver({ over: false, id })
                      }}
                    >
                      <Flex
                        position="relative"
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                        bg={INSTAGRAM_COLORS.BACKGROUND}
                      >
                        <Flex
                          width="100%"
                          height="100%"
                          alignItems="center"
                          justifyContent="center"
                          bg={INSTAGRAM_COLORS.GLUON_GREY}
                          maxHeight={`${MAX_PREVIEW_HEIGHT}px`}
                          maxWidth={`${MAX_PREVIEW_WIDTH}px`}
                        >
                          {type === IMAGE && url && (
                            <ImageWithFallback
                              src={url}
                              width="100%"
                              height="100%"
                              maxHeight={`${MAX_PREVIEW_HEIGHT}px`}
                            />
                          )}

                          {type === VIDEO && (
                            <VideoWithFallback
                              url={thumbnail_url ? null : url}
                              controls={false}
                              width="100%"
                              style={{ position: 'relative', borderRadius: radius.l }}
                              height="100%"
                              config={{
                                file: {
                                  attributes: {
                                    poster: thumbnail_url || '',
                                  },
                                },
                              }}
                              name={id}
                              fallbackSourceImage={thumbnail_url}
                            />
                          )}

                          {(type === IMAGE_DYNAMIC || type === VIDEO_DYNAMIC) && (
                            <DynamicMedia media={media} post={post} profiles={[activeProfile]} />
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                  )
                })}
                setCurrentMedia={setCurrentMediaIndex}
              />
              <StyledStoryFooterWrapper>
                <Flex alignItems="center" justifyContent="space-between">
                  <Flex alignItems="center">
                    <StyledStoryPublishTime fontSize="s">{story_publish_at}</StyledStoryPublishTime>
                  </Flex>
                  <Flex alignItems="center">
                    <Flex p="s" alignItems="center" justifyContent="center">
                      <Image src="/assets/instagram_heart_white.svg" />
                    </Flex>
                    <Flex p="s" alignItems="center" justifyContent="center">
                      <Image src="/assets/instagram_send_white.svg" />
                    </Flex>
                  </Flex>
                </Flex>
              </StyledStoryFooterWrapper>
            </Flex>
          ) : (
            <Scrollbars universal autoHide>
              <Flex my="s" alignItems="center" px="m" flexDirection={network === TIKTOK ? 'column' : 'row'}>
                <StyledUserImageWrapper
                  hasBackground={hasStory}
                  onClick={() => {
                    if (network === INSTAGRAM) {
                      if (hasStory) {
                        setShowGridType(GRID_TYPE_STORY)
                      } else {
                        Alert.info(`Drag and drop media from library to create a story.`, { timeout: 5000 })
                      }
                    }
                  }}
                  position="relative"
                  onDrop={(e) => {
                    e.stopPropagation()
                    if (network === INSTAGRAM) {
                      onDropMedia({ dropMediaType: PUBLISH_AS_STORY })
                    }
                  }}
                >
                  {network === INSTAGRAM && dragMediaOptions.isActive ? (
                    <DropMediaWrapper height="50px" width="50px" borderRadius={radius.pill}>
                      <StyledVistaSocialPlusWrapper width="32px" height="32px">
                        <Icon.VistaSocialPlus />
                      </StyledVistaSocialPlusWrapper>
                    </DropMediaWrapper>
                  ) : (
                    <StyledUserImage source={picture_url} width="50px" height="50px" />
                  )}

                  {network === INSTAGRAM && !hasStory && (
                    <StyledStoryPlusWrapper>
                      <Icon.Plus fill={COLOR_CONSTANTS.WHITE} />
                    </StyledStoryPlusWrapper>
                  )}
                </StyledUserImageWrapper>

                <Flex ml="s">
                  <Text color="secondaryText">{username ? `@${username}` : name || ''}</Text>
                </Flex>
              </Flex>
              <Flex flexDirection="column" px="s">
                <Flex width="100%" flexDirection="column" position="relative">
                  <StyledProgressWrapper
                    width="100%"
                    height="475px"
                    display={isGettingGridMedia || isGettingPublications ? 'flex' : 'none'}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ClipLoader size="100" color={colors.primary} />
                  </StyledProgressWrapper>
                  {network === INSTAGRAM && (
                    <Grid gridTemplateColumns="repeat(2, 1fr)" gridGap="s" px="xxs">
                      {GRID_TYPES.map(({ type, icon }) => (
                        <StyledGridTypeWrapper
                          key={type}
                          alignItems="center"
                          justifyContent="center"
                          isSelected={showGridType === type || (type === GRID_TYPE_FEED && !showGridType)}
                          onClick={() => {
                            setShowGridType(type)
                          }}
                        >
                          <Image src={icon} height="18px" width="18px" />
                        </StyledGridTypeWrapper>
                      ))}
                    </Grid>
                  )}
                  <Grid mt="s" gridTemplateColumns={`repeat(${MAX_COLUMNS}, 1fr)`} gridGap="xxs">
                    {dragMediaOptions.isActive && (
                      <DropMediaWrapper height={defaultVideoHeight} width="106px" borderRadius={radius.l}>
                        <StyledVistaSocialPlusWrapper width="40px" height="40px">
                          <Icon.VistaSocialPlus />
                        </StyledVistaSocialPlusWrapper>
                      </DropMediaWrapper>
                    )}
                    {posts.map((post) => {
                      const {
                        id,
                        postComponent,
                        postImages,
                        postVideos,
                        link,
                        customizations,
                        status,
                        isRemoved,
                      } = post
                      const { [code]: codeCustomizations } = customizations || {}
                      const { [profileId]: profileCustomizations } = codeCustomizations || {}
                      const { media_type, share_to_feed } = profileCustomizations || {}

                      if (isRemoved) {
                        return null
                      }

                      // here we filter out videos that are not included in feed and images for reels tab
                      if (media_type === PUBLISH_AS_STORY) {
                        return null
                      } else if (
                        showGridType === GRID_TYPE_FEED &&
                        postComponent === POST_VIDEO &&
                        media_type === PUBLISH_AS_REELS &&
                        !share_to_feed
                      ) {
                        return null
                      } else if (
                        showGridType === GRID_TYPE_REELS &&
                        (postComponent === POST_IMAGE ||
                          postComponent === POST_LINK ||
                          (postComponent === POST_VIDEO && media_type !== PUBLISH_AS_REELS))
                      ) {
                        return null
                      }

                      let media
                      let isCarousel = false

                      if (postComponent === POST_IMAGE) {
                        // eslint-disable-next-line prefer-destructuring
                        media = postImages[0]
                        isCarousel = postImages.length > 1
                      } else if (postComponent === POST_VIDEO) {
                        // eslint-disable-next-line prefer-destructuring
                        media = postVideos[0]
                        isCarousel = postVideos.length > 1
                      } else if (postComponent === POST_LINK && link) {
                        media = { type: IMAGE, url: link.picture_url, thumbnail_url: link.picture_url }
                      }
                      if (!media) {
                        return null
                      } else {
                        const { url, thumbnail_url, thumbnail_url_small, type = IMAGE } = media

                        const { bgColor } = getHeaderTitleAndColor({ status, network, post })

                        return (
                          <StyledMediaWrapper
                            key={id}
                            id={`${PHONE_POSTS_ID_PREFIX}-${id}`}
                            height={defaultVideoHeight}
                            borderColor={bgColor}
                            draggable="true"
                            onDragStart={(target) => {
                              handleMediaDragStart({ id, target })
                            }}
                            onDragOver={(target) => {
                              handleMediaDragOver({ id, target })
                            }}
                            onDrop={(target) => {
                              handleMediaDrop({ id, target })
                            }}
                            position="relative"
                            onClick={() => {
                              handleClickEditPost({ profile_gid: profileId, post, timezone })
                            }}
                            onMouseEnter={() => {
                              onMouseOver({ over: true, id })
                            }}
                            onMouseLeave={() => {
                              onMouseOver({ over: false, id })
                            }}
                          >
                            <StyledHiddenIconWrapper
                              top={space.xxs}
                              left={space.xxs}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleClickRemovePost({ profile_gid: profileId, post_gid: id })
                              }}
                            >
                              <Icon.Dismiss fill={COLOR_CONSTANTS.WHITE} width="10px" height="10px" />
                            </StyledHiddenIconWrapper>
                            {isCarousel && (
                              <Box position="absolute" top={space.xs} right={space.xs} draggable="false">
                                <StyledPlayImage src="/assets/vistasocial/instagram_carousel.svg" />
                              </Box>
                            )}

                            {type === IMAGE && url && (
                              <StyledPostMedia
                                backgroundImage={thumbnail_url || url || DEFAULT_FALLBACK_IMAGE}
                                name={id}
                              />
                            )}

                            {type === VIDEO && (
                              <VideoWithFallback
                                url={thumbnail_url_small || thumbnail_url ? null : url}
                                controls={false}
                                width="100%"
                                style={{ position: 'relative', borderRadius: radius.l }}
                                height="100%"
                                config={{
                                  file: {
                                    attributes: {
                                      poster: thumbnail_url_small || thumbnail_url || '',
                                    },
                                  },
                                }}
                                playIcon={
                                  <Box position="absolute" top={space.xs} right={space.xs} draggable="false">
                                    <StyledPlayImage
                                      src={
                                        isCarousel
                                          ? '/assets/vistasocial/instagram_carousel.svg'
                                          : '/assets/vistasocial/instagram_play.svg'
                                      }
                                    />
                                  </Box>
                                }
                                name={id}
                                fallbackSourceImage={thumbnail_url_small || thumbnail_url}
                              />
                            )}

                            {(type === IMAGE_DYNAMIC || type === VIDEO_DYNAMIC) && (
                              <DynamicMedia media={media} post={post} profiles={[activeProfile]} isSmall />
                            )}

                            <StyledHiddenIconWrapper bottom={space.xxs} right={space.xxs}>
                              <Icon.Drag fill={COLOR_CONSTANTS.WHITE} width="10px" height="10px" />
                            </StyledHiddenIconWrapper>
                          </StyledMediaWrapper>
                        )
                      }
                    })}
                    {medias &&
                      medias.map(({ id, url, media_type, thumbnail_url, permalink, is_scheduled }) => {
                        let template = ''
                        if (media_type.toLowerCase() === IMAGE || (media_type === 'CAROUSEL_ALBUM' && !thumbnail_url)) {
                          template = <StyledMedia source={url} fallbackSource={DEFAULT_FALLBACK_IMAGE} name={id} />
                        } else if (
                          media_type.toLowerCase() === VIDEO ||
                          (media_type === 'CAROUSEL_ALBUM' && thumbnail_url)
                        ) {
                          template = (
                            <VideoWithFallback
                              url={url}
                              controls
                              width="100%"
                              style={{ position: 'relative', borderRadius: radius.l }}
                              height="100%"
                              config={{
                                file: {
                                  attributes: {
                                    poster: thumbnail_url || '',
                                  },
                                },
                              }}
                              fallbackSourceImage={thumbnail_url}
                              name={id}
                            />
                          )
                        }
                        return (
                          <StyledBox
                            key={id}
                            height={defaultVideoHeight}
                            position="relative"
                            isScheduled={is_scheduled}
                            onClick={() => {
                              if (permalink) {
                                window.open(permalink, '_blank')
                              }
                            }}
                          >
                            {media_type === 'CAROUSEL_ALBUM' && (
                              <Box position="absolute" top={space.xs} right={space.xs}>
                                <StyledPlayImage src="/assets/vistasocial/instagram_carousel.svg" />
                              </Box>
                            )}
                            {template}
                          </StyledBox>
                        )
                      })}
                  </Grid>
                </Flex>
              </Flex>
            </Scrollbars>
          )}
        </StyledPhoneContentWrapper>
      </StyledPhoneWrapper>
    )
  }
)

PhoneComponent.defaultProps = {
  activeProfile: null,
  posts: [],
  postsByProfile: {},
  dragMediaOptions: {},
}

PhoneComponent.propTypes = {
  activeProfile: PropTypes.object,
  posts: PropTypes.array,
  setPostsByProfile: PropTypes.func.isRequired,
  postsByProfile: PropTypes.object,
  dragMediaOptions: PropTypes.object,
  handleClickRemovePost: PropTypes.func.isRequired,
  onDropMedia: PropTypes.func.isRequired,
  handleClickEditPost: PropTypes.func.isRequired,
  network: PropTypes.string.isRequired,
}

export default PhoneComponent
