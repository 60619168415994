import React, { Fragment, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ClipLoader from 'react-spinners/ClipLoader'
import { COLOR_CONSTANTS, colors, fontWeights, radius, space } from 'theme'
import { linkTransformer } from 'helpers'
import withConfirm from 'utils/withConfirm'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import DropdownMenu from 'components/molecules/DropdownMenu'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'
import Initials from 'components/atoms/Initials'
import InputComponent from 'routes/Tasks/components/InputComponent'

const StyledTypeDataWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  border-radius: ${radius.l};
  margin: ${space.m};
  padding: ${space.m};
`

const ThreeDots = styled(Image)`
  cursor: pointer;
`

const StyledControlImageWrapper = styled(Flex)`
  cursor: pointer;
`

const StyledUserImage = styled(ImageWithFallback)`
  border-radius: ${radius.pill};
`

const StyledCommentText = styled(Text)`
  word-break: break-word;
  white-space: pre-line;
`

const CommentItem = ({
  user,
  data,
  usersForMention,
  handleRemove,
  handleSave,
  confirm,
  sharedCalendarData,
  isSmallPreview,
}) => {
  const commentTextAreaRef = useRef(null)
  const [isEditing, setIsEditing] = useState(false)
  const [isUpdatingComment, setIsUpdatingComment] = useState(false)

  const { email: shared_calendar_user_email } = sharedCalendarData || {}

  const {
    _id,
    message,
    message_transformed,
    created_since,
    created_by: { name, picture_url, id: created_by_id },
    internal = false,
  } = data

  const handleClickEditComment = () => {
    setIsEditing(true)
  }

  const handleClickSaveComment = async () => {
    let value = ''

    if (commentTextAreaRef && commentTextAreaRef.current) {
      value = commentTextAreaRef.current.getCommentTextAreaValue()
    }

    if (value) {
      setIsUpdatingComment(true)
      await handleSave({ _id, message: value })
      setIsUpdatingComment(false)
      setIsEditing(false)
    }
  }

  const handleClickRemove = async () => {
    setIsUpdatingComment(true)
    await handleRemove({ _id })
    setIsUpdatingComment(false)
  }

  return (
    <StyledTypeDataWrapper
      alignItems="flex-start"
      position="relative"
      bg={internal ? COLOR_CONSTANTS.CARROT : COLOR_CONSTANTS.WHITE}
    >
      {picture_url ? (
        <StyledUserImage source={picture_url} width="32px" height="32px" />
      ) : (
        <Initials name={name} height="32px" width="32px" isSmall isCircle isCalculatedColor />
      )}

      {isEditing ? (
        <Flex ml="m" flexDirection="column" width="100%">
          <InputComponent
            users={usersForMention}
            comment={message}
            ref={commentTextAreaRef}
            className={internal ? '' : 'post-comment-textarea'}
          />
          <Flex justifyContent="flex-end">
            <StyledControlImageWrapper
              alignItems="center"
              justifyContent="center"
              onClick={() => {
                if (!isUpdatingComment) {
                  setIsEditing(false)
                }
              }}
              width="16px"
              height="24px"
              mr="l"
            >
              <Image width="16px" height="16px" src="/assets/clear.svg" />
            </StyledControlImageWrapper>
            <StyledControlImageWrapper
              alignItems="center"
              justifyContent="center"
              onClick={() => {
                if (!isUpdatingComment) {
                  handleClickSaveComment()
                }
              }}
              width="24px"
              height="24px"
            >
              {!isUpdatingComment ? (
                <Image src="/assets/vistasocial/send.svg" width="24px" height="24px" />
              ) : (
                <ClipLoader size="24" color={colors.primary} />
              )}
            </StyledControlImageWrapper>
          </Flex>
        </Flex>
      ) : (
        <Fragment>
          {((user && user.id === created_by_id) ||
            (shared_calendar_user_email && shared_calendar_user_email === created_by_id)) && (
            <Box position="absolute" top="0" right="4px">
              <DropdownMenu WrapperComp={<ThreeDots source="/assets/vistasocial/more.svg" />} isDismissedOnClickInside>
                <DropdownMenu.Item onClick={handleClickEditComment} isCursorPointer label="Edit" />
                {handleRemove && (
                  <DropdownMenu.Item
                    onClick={() => {
                      if (!isUpdatingComment) {
                        confirm({
                          fn: () => () => handleClickRemove(),
                          message: `Are you sure you want to remove this comment?`,
                          action: 'Remove',
                        })
                      }
                    }}
                    isCursorPointer
                    label="Remove"
                  />
                )}
              </DropdownMenu>
            </Box>
          )}
          <Flex flexDirection="column" ml="m">
            <Flex alignItems="center" flexWrap={isSmallPreview ? 'wrap' : 'initial'}>
              <Text as="span" fontSize="xxs" color="primaryText">
                Created by {name} &nbsp;
              </Text>
              <Text as="span" fontSize="xxs" color="secondaryText">
                {created_since}
              </Text>
            </Flex>
            <StyledCommentText
              dangerouslySetInnerHTML={{
                __html: linkTransformer({
                  body: message_transformed || message,
                  color: colors.primary,
                  fontWeight: fontWeights.normal,
                }),
              }}
            />
          </Flex>
        </Fragment>
      )}
    </StyledTypeDataWrapper>
  )
}

CommentItem.defaultProps = {
  user: null,
  handleRemove: null,
  handleSave: () => {},
  usersForMention: [],
  sharedCalendarData: null,
  isSmallPreview: false,
}

CommentItem.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object.isRequired,
  handleRemove: PropTypes.func,
  handleSave: PropTypes.func,
  confirm: PropTypes.func.isRequired,
  usersForMention: PropTypes.array,
  sharedCalendarData: PropTypes.object,
  isSmallPreview: PropTypes.bool,
}

export default withConfirm(CommentItem)
