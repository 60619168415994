import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space as styledSpace } from 'styled-system'
import { Scrollbars } from 'react-custom-scrollbars-2'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import { COLOR_CONSTANTS, colors, radius, space, fontSizes, fontWeights } from 'theme'
import { ERROR_MESSAGE, PERMISSION_PUBLISH, ROUTE_COMMENTS, COMMENT_TYPE_IDEA, COMMENT_TYPE_NOTE } from 'consts'
import { hasGlobalAccessManage } from 'utils/feature'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { Flex, Box } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import NoData from 'components/molecules/NoData'
import { Text } from 'components/atoms/Typography'
import Tooltip from 'components/molecules/Tooltip'
import InputComponent from 'routes/Tasks/components/InputComponent'
import CommentItem from './components/CommentItem'
import EntitiesSelectorModal from './components/EntitiesSelectorModal'

const StyledPreviewWrapper = styled(Flex)`
  position: relative;
  width: ${({ $isSmallPreview }) => ($isSmallPreview ? '250px' : '560px')};
  padding: 0;
  border-radius: ${radius.s};
  ${styledSpace};
  height: 100%;
  display: flex;
  flex-direction: column;
`

const StyledSendMessageWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  border-radius: ${radius.l};
  padding: ${space.m};
`

const StyledSendImageWrapper = styled(Flex)`
  cursor: pointer;
`

const StyledChangeText = styled(Text)`
  cursor: pointer;
  color: ${colors.primary};
  &:hover {
    text-decoration: underline;
  }
`

const StyledEntityNamesWrapper = styled(Flex)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
`

const StyledBox = styled(Box)`
  margin-bottom: -2px;
  margin-right: ${space.l};
  font-size: ${fontSizes.s};
  color: ${colors.secondaryText};
  font-weight: ${fontWeights.bold};
  position: relative;
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    `
    color:${colors.primaryText};
    opacity: 1;
    border-bottom: solid ${colors.primaryText};
  `}
`

const COMMENTS_POLLING_INTERVAL = 10000

const DEFAULT_ENTITIES_SELECTOR_MODAL_OPTIONS = { isOpen: false, selectedEntities: [], entitiesForDropdown: [] }

const { COMMENT_TYPE_EXTERNAL, COMMENT_TYPE_INTERNAL } = {
  COMMENT_TYPE_EXTERNAL: 'external',
  COMMENT_TYPE_INTERNAL: 'internal',
}

const COMMENT_TYPES_SHARED = [
  {
    id: COMMENT_TYPE_EXTERNAL,
    name: '',
    placeholder: 'Please enter your comment.',
  },
]

const COMMENT_TYPES = [
  {
    id: COMMENT_TYPE_INTERNAL,
    name: 'Internal comment',
    placeholder:
      'Enter your comment here. Type @ to mention a teammate. This comment is viewable only inside Vista Social.',
  },
  {
    id: COMMENT_TYPE_EXTERNAL,
    name: 'External comment',
    placeholder:
      'Enter your comment here. Type @ to mention a teammate. This comment is viewable internally and in shared calendar.',
  },
]

const SliderCommentsBody = forwardRef(
  (
    { user, data, handleDismiss, commentCallback, showProfileGroupSelector, isSmallPreview, ...props },
    commentsBodyRef
  ) => {
    const commentTextAreaRef = useRef(null)
    const scrollbarsRef = useRef(null)

    const [isGettingData, setIsGettingData] = useState(true)
    const [comments, setComments] = useState([])
    const [isSubmittingComment, setIsSubmittingComment] = useState(false)
    const [usersForMention, setUsersForMention] = useState([])
    const [sharedCalendarData, setSharedCalendarData] = useState(null)
    const [scrollToBottom, setScrollToBottom] = useState(false)
    const [entitiesSelectorModalOptions, setEntitiesSelectorModalOptions] = useState({
      ...DEFAULT_ENTITIES_SELECTOR_MODAL_OPTIONS,
    })
    const [selectedEntitiesForComment, setSelectedEntitiesForComment] = useState(null)
    const [entitiesConvertedFromData, setEntitiesConvertedFromData] = useState([])
    const [commentTabs, setCommentTabs] = useState([])
    const [activeCommentTab, setActiveCommentTab] = useState(COMMENT_TYPES[0])

    const { comment_type_id, entity_gids = [], timezone, type, context } = data

    const { sharedCalendarId, sign } = props.sharedCalendarData || {}

    const { entities = [] } = user || {}

    useImperativeHandle(commentsBodyRef, () => ({
      getCommentsCounter() {
        return comments.length
      },
    }))

    const getTypeData = async ({ showLoading = true }) => {
      try {
        if (showLoading) {
          setIsGettingData(true)
        }

        const response = await request({
          path: ROUTE_COMMENTS,
          method: 'POST',
          body: { id: comment_type_id, type, entity_gids, shared_calendar_id: sharedCalendarId },
        })

        const { error, data } = response

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
          setUsersForMention([])
        } else {
          const { users_for_mentioning = [], comments = [] } = data || {}

          setComments([...comments])
          setUsersForMention(
            users_for_mentioning.filter((user) => hasGlobalAccessManage({ user, permission: PERMISSION_PUBLISH }))
          )
        }
      } catch (error) {
        errorHelper({ error, componentName: SliderCommentsBody.displayName, functionName: 'getTypeData' })
        setUsersForMention([])
      } finally {
        if (showLoading) {
          setIsGettingData(false)
        }
      }
    }

    const isCommentEmpty = ({ value }) => {
      let isTextAreaEmpty = true

      if (value) {
        const values = value.split('\n')

        for (let i = 0; i < values.length; i++) {
          if (values[i]) {
            isTextAreaEmpty = false
            break
          }
        }
      }

      return isTextAreaEmpty
    }

    const handleKeyDownSubmitComment = (e) => {
      if (!isSubmittingComment && commentTextAreaRef && commentTextAreaRef.current) {
        const { current } = commentTextAreaRef

        const focus = current.getFocus()
        const enterState = current.getEnterState()

        if (!enterState) {
          if (focus) {
            if (e.key === 'Enter' && (e.ctrlKey || e.metaKey || e.shiftKey)) {
              current.setCommentTextAreaValue(`${current.getCommentTextAreaValue()}\n`)
            } else if (e.key === 'Enter') {
              e.preventDefault()

              const isTextAreaEmpty = isCommentEmpty({ value: current.getCommentTextAreaValue() })

              if (isTextAreaEmpty) {
                current.setCommentTextAreaValue('')
              } else {
                const submitResponseButton = document.getElementById('send-comment')
                if (submitResponseButton) {
                  submitResponseButton.click()
                }
              }
            }
          }
        } else {
          current.unblockEnterState()
        }
      }
    }

    useEffect(() => {
      let commentTabs = []

      if (props.sharedCalendarData) {
        window.dispatchEvent(
          new CustomEvent('checkSharedCalendarUserData', {
            detail: {
              func: async ({ sharedCalendarUserData }) => {
                setSharedCalendarData({ ...props.sharedCalendarData, ...sharedCalendarUserData })
              },
              openUserDataModalOptions: false,
            },
          })
        )

        commentTabs = COMMENT_TYPES_SHARED
      } else if (data.type === COMMENT_TYPE_IDEA || data.type === COMMENT_TYPE_NOTE) {
        commentTabs = [COMMENT_TYPES[0]]
      } else {
        commentTabs = COMMENT_TYPES
      }

      setCommentTabs([...commentTabs])
      setActiveCommentTab(commentTabs[0])

      getTypeData({})

      const entitiesForDropdown = []

      entity_gids.forEach((id) => {
        const foundEntity = entities.find((entity) => entity.id === id)

        if (foundEntity) {
          entitiesForDropdown.push({ value: foundEntity.id, label: foundEntity.name })
        }
      })
      setEntitiesConvertedFromData([...entitiesForDropdown])

      document.addEventListener('keydown', handleKeyDownSubmitComment, false)
      return () => {
        window.removeEventListener('keydown', handleKeyDownSubmitComment, false)
      }
    }, [])

    useEffect(() => {
      const commentsFetchInterval = setInterval(() => {
        getTypeData({ showLoading: false })
      }, COMMENTS_POLLING_INTERVAL)
      return () => clearInterval(commentsFetchInterval)
    })

    useEffect(() => {
      if (comments.length !== 0 && scrollToBottom) {
        if (scrollbarsRef && scrollbarsRef.current) {
          scrollbarsRef.current.scrollToBottom()
        }

        setScrollToBottom(null)
      }
    }, [comments])

    const handleClickOpenEntitiesSelectorModal = () => {
      const data = { isOpen: true }

      const entitiesForDropdown = []

      entity_gids.forEach((id) => {
        const foundEntity = entities.find((entity) => entity.id === id)

        if (foundEntity) {
          entitiesForDropdown.push({ value: foundEntity.id, label: foundEntity.name })
        }
      })

      if (selectedEntitiesForComment) {
        data.selectedEntities = [...selectedEntitiesForComment]
      } else {
        data.selectedEntities = [...entitiesForDropdown]
      }

      data.entitiesForDropdown = entitiesForDropdown

      setEntitiesSelectorModalOptions({ ...data })
    }

    const handleClickCloseEntitiesSelectorModal = () => {
      setEntitiesSelectorModalOptions({ ...DEFAULT_ENTITIES_SELECTOR_MODAL_OPTIONS })
    }

    const handleSaveSelectedEntities = ({ entities }) => {
      setSelectedEntitiesForComment([...entities])
      handleClickCloseEntitiesSelectorModal()
    }

    const handleClickSubmitComment = async ({ sharedCalendarUserData = {} }) => {
      if (commentTextAreaRef && commentTextAreaRef.current) {
        const value = commentTextAreaRef.current.getCommentTextAreaValue()

        const isTextAreaEmpty = isCommentEmpty({ value })

        if (isTextAreaEmpty) {
          Alert.error(`Please enter your comment`)
        } else {
          let entity_gids_temp = []

          if (selectedEntitiesForComment) {
            entity_gids_temp = selectedEntitiesForComment.map(({ value }) => value)
          } else {
            entity_gids_temp = entity_gids
          }

          setIsSubmittingComment(true)
          try {
            const response = await request({
              path: `${ROUTE_COMMENTS}/${comment_type_id}`,
              method: 'POST',
              body: {
                message: value,
                type,
                entity_gids,
                timezone,
                internal: sharedCalendarId ? false : activeCommentTab.id === COMMENT_TYPE_INTERNAL,
                id: sharedCalendarId,
                sign,
                ...sharedCalendarUserData,
                context: {
                  ...context,
                  entity_gids: entity_gids_temp,
                },
              },
            })

            const { error, data } = response || {}

            if (error || !response) {
              Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
            } else {
              const { mentions } = data
              setScrollToBottom(true)
              //  Alert.success('Your comment has been submitted.')
              commentTextAreaRef.current.setCommentTextAreaValue('')
              comments.push(data)
              setComments([...comments])
              commentCallback({ mentions })
            }
          } catch (error) {
            errorHelper({
              error,
              componentName: SliderCommentsBody.displayName,
              functionName: 'handleClickSubmitComment',
            })
          } finally {
            setIsSubmittingComment(false)
          }
        }
      }
    }

    const handleClickUpdateComment = async ({ _id, message, sharedCalendarUserData }) => {
      const isTextAreaEmpty = isCommentEmpty({ value: message })

      if (isTextAreaEmpty) {
        Alert.error(`Please enter your comment`)
      } else {
        try {
          const response = await request({
            path: `${ROUTE_COMMENTS}/${_id}`,
            method: 'PATCH',
            body: {
              message,
              type,
              entity_gids,
              timezone,
              id: sharedCalendarId,
              sign,
              ...sharedCalendarUserData,
            },
          })

          const {
            error,
            id: responseId,
            message: responseMessage,
            message_transformed: responseMessageTransformed,
            mentions,
          } = response || {}

          if (!response || error) {
            Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
          } else {
            // Alert.success('Comment has been updated.')
            const foundCommentIndex = comments.findIndex((comment) => comment._id === responseId)
            if (foundCommentIndex > -1) {
              comments[foundCommentIndex].message = responseMessage
              comments[foundCommentIndex].message_transformed = responseMessageTransformed
              setComments([...comments])
              commentCallback({ mentions })
            }
          }
        } catch (error) {
          errorHelper({
            error,
            componentName: SliderCommentsBody.displayName,
            functionName: 'handleClickUpdateComment',
          })
        }
      }
    }

    const handleClickRemoveComment = async ({ _id, sharedCalendarUserData }) => {
      try {
        const response = await request({
          path: `${ROUTE_COMMENTS}/${_id}`,
          method: 'DELETE',
          body: { id: sharedCalendarId, type, sign, ...sharedCalendarUserData },
        })

        const { error } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          // Alert.success('Comment has been removed.')
          const foundCommentIndex = comments.findIndex((comment) => comment._id === _id)
          if (foundCommentIndex > -1) {
            comments.splice(foundCommentIndex, 1)
            setComments([...comments])
          }
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: SliderCommentsBody.displayName,
          functionName: 'handleClickRemoveComment',
        })
      }
    }

    const handleSubmitComment = async () => {
      if (sharedCalendarId) {
        window.dispatchEvent(
          new CustomEvent('checkSharedCalendarUserData', {
            detail: {
              func: async ({ sharedCalendarUserData }) => {
                setSharedCalendarData({ ...props.sharedCalendarData, ...sharedCalendarUserData })
                await handleClickSubmitComment({
                  sharedCalendarUserData,
                })
              },
            },
          })
        )
      } else {
        await handleClickSubmitComment({})
      }
    }

    const handleUpdateComment = async (data) => {
      if (sharedCalendarId) {
        window.dispatchEvent(
          new CustomEvent('checkSharedCalendarUserData', {
            detail: {
              func: async ({ sharedCalendarUserData }) => {
                setSharedCalendarData({ ...props.sharedCalendarData, ...sharedCalendarUserData })
                await handleClickUpdateComment({
                  ...data,
                  sharedCalendarUserData,
                })
              },
            },
          })
        )
      } else {
        await handleClickUpdateComment(data)
      }
    }

    const handleRemoveComment = async (data) => {
      if (sharedCalendarId) {
        window.dispatchEvent(
          new CustomEvent('checkSharedCalendarUserData', {
            detail: {
              func: async ({ sharedCalendarUserData }) => {
                setSharedCalendarData({ ...props.sharedCalendarData, ...sharedCalendarUserData })
                await handleClickRemoveComment({
                  ...data,
                  sharedCalendarUserData,
                })
              },
            },
          })
        )
      } else {
        await handleClickRemoveComment(data)
      }
    }

    const handleClickCloseDrawer = () => {
      if (handleDismiss) {
        handleDismiss({ commentsCounter: comments.length, data })
      }
    }

    return (
      <Flex minWidth={isSmallPreview ? '250px' : '560px'} maxWidth="560px" height="100%" flexDirection="column">
        {isGettingData ? (
          <Flex alignItems="center" justifyContent="center" height="100%" width="100%">
            <ClipLoader size="50" color={colors.primary} />
          </Flex>
        ) : (
          <Flex flexDirection="column" height="100%">
            <Scrollbars universal ref={scrollbarsRef}>
              <Flex flexDirection="column" height="100%">
                <StyledPreviewWrapper $isSmallPreview={isSmallPreview}>
                  {comments.length > 0 ? (
                    <Fragment>
                      {comments.map((comment) => (
                        <CommentItem
                          key={comment._id}
                          user={user}
                          data={comment}
                          handleSave={handleUpdateComment}
                          handleRemove={handleRemoveComment}
                          usersForMention={usersForMention}
                          sharedCalendarData={sharedCalendarData}
                          isSmallPreview={isSmallPreview}
                        />
                      ))}
                    </Fragment>
                  ) : (
                    <Flex width="100%" height="100%" alignItems="center" justifyContent="center">
                      <NoData message="No comments yet" showHeaderText={false} showImage={false} />
                    </Flex>
                  )}
                </StyledPreviewWrapper>
              </Flex>
            </Scrollbars>

            <Flex flexDirection="column" width="100%" mt="m" px="m" pb="m">
              {showProfileGroupSelector && !sharedCalendarId && entity_gids.length > 1 && (
                <Flex alignItems="center" mb="s">
                  {!selectedEntitiesForComment ||
                  (selectedEntitiesForComment &&
                    selectedEntitiesForComment.length === entitiesConvertedFromData.length) ? (
                    <Flex alignItems="center" justifyContent="space-between" width="100%">
                      <Text fontSize="s">
                        Share with all profile groups.{' '}
                        <StyledChangeText as="span" onClick={handleClickOpenEntitiesSelectorModal}>
                          Change
                        </StyledChangeText>
                      </Text>
                      {entitiesConvertedFromData.length > 0 && (
                        <Tooltip
                          wrapperComp={
                            <StyledEntityNamesWrapper alignItems="center" width="100%">
                              <Text fontSize="s">{entitiesConvertedFromData[0].label}</Text>
                              {entitiesConvertedFromData.length > 1 && (
                                <Text fontSize="xs" ml="s" textAlign="center">
                                  +{entitiesConvertedFromData.length - 1} more
                                </Text>
                              )}
                            </StyledEntityNamesWrapper>
                          }
                          message={entitiesConvertedFromData.map(({ label }) => label).join(', ')}
                          left="unset"
                          right="0"
                          isTriangleVisible={false}
                        />
                      )}
                    </Flex>
                  ) : (
                    <Flex alignItems="center" justifyContent="space-between" width="100%">
                      <Text fontSize="s">
                        Share with selected profile groups.{' '}
                        <StyledChangeText as="span" onClick={handleClickOpenEntitiesSelectorModal}>
                          Change
                        </StyledChangeText>
                      </Text>
                      {selectedEntitiesForComment.length > 0 && (
                        <Tooltip
                          wrapperComp={
                            <StyledEntityNamesWrapper alignItems="center" width="100%">
                              <Text fontSize="s">{selectedEntitiesForComment[0].label}</Text>
                              {selectedEntitiesForComment.length > 1 && (
                                <Text fontSize="xs" ml="s" textAlign="center">
                                  +{selectedEntitiesForComment.length - 1} more
                                </Text>
                              )}
                            </StyledEntityNamesWrapper>
                          }
                          message={selectedEntitiesForComment.map(({ label }) => label).join(', ')}
                          left="unset"
                          right="0"
                          isTriangleVisible={false}
                        />
                      )}
                    </Flex>
                  )}
                </Flex>
              )}

              {!sharedCalendarId && (
                <Flex flexDirection="column" px="m" pb="m">
                  <Flex mb="xs" pt="m" pb="xxs" position="relative" alignItems="center">
                    <Flex width="100%" flexWrap="wrap">
                      {commentTabs.map((commentTab) => {
                        const { id, name } = commentTab

                        return (
                          <StyledBox
                            key={id}
                            onClick={() => {
                              if (commentTab.id !== activeCommentTab.id) {
                                setActiveCommentTab({ ...commentTab })
                                commentTextAreaRef.current.setFocus()
                              }
                            }}
                            isActive={id === activeCommentTab.id}
                          >
                            {name}
                          </StyledBox>
                        )
                      })}
                    </Flex>
                  </Flex>
                </Flex>
              )}

              <StyledSendMessageWrapper
                bg={activeCommentTab.id === COMMENT_TYPE_INTERNAL ? COLOR_CONSTANTS.CARROT : COLOR_CONSTANTS.WHITE}
              >
                <Flex flexDirection="column" width="100%">
                  <InputComponent
                    users={usersForMention}
                    comment=""
                    ref={commentTextAreaRef}
                    minHeight={44}
                    placeholder={activeCommentTab.placeholder}
                    className={activeCommentTab.id === COMMENT_TYPE_INTERNAL ? '' : 'post-comment-textarea'}
                  />
                  <Flex justifyContent="flex-end" zIndex="1">
                    <StyledSendImageWrapper
                      id="send-comment"
                      alignItems="center"
                      justifyContent="center"
                      onClick={() => {
                        if (!isSubmittingComment) {
                          handleSubmitComment()
                        }
                      }}
                      width="24px"
                      height="24px"
                    >
                      {!isSubmittingComment ? (
                        <Image src="/assets/vistasocial/send.svg" width="24px" height="24px" />
                      ) : (
                        <ClipLoader size="24" color={colors.primary} />
                      )}
                    </StyledSendImageWrapper>
                  </Flex>
                </Flex>
              </StyledSendMessageWrapper>
            </Flex>

            <Flex width="0px" height="0px" onClick={handleClickCloseDrawer} className="modal-close-icon" />
          </Flex>
        )}

        {entitiesSelectorModalOptions.isOpen && (
          <EntitiesSelectorModal
            handleDismiss={handleClickCloseEntitiesSelectorModal}
            handleSaveSelectedEntities={handleSaveSelectedEntities}
            isOpen={entitiesSelectorModalOptions.isOpen}
            selectedEntities={entitiesSelectorModalOptions.selectedEntities}
            entitiesForDropdown={entitiesSelectorModalOptions.entitiesForDropdown}
          />
        )}
      </Flex>
    )
  }
)

SliderCommentsBody.defaultProps = {
  data: {},
  sharedCalendarData: null,
  handleDismiss: null,
  commentCallback: () => {},
  showProfileGroupSelector: false,
  isSmallPreview: false,
}

SliderCommentsBody.propTypes = {
  handleDismiss: PropTypes.func,
  data: PropTypes.object,
  user: PropTypes.object.isRequired,
  sharedCalendarData: PropTypes.object,
  commentCallback: PropTypes.func,
  showProfileGroupSelector: PropTypes.bool,
  isSmallPreview: PropTypes.bool,
}

SliderCommentsBody.displayName = 'SliderCommentsBody'

export default SliderCommentsBody
