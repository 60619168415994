import React, { Fragment, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ClipLoader from 'react-spinners/ClipLoader'
import { IMAGE_DYNAMIC, IMAGE } from 'consts'
import { colors } from 'theme'
import { Text } from 'components/atoms/Typography'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import CoolLightBox from 'components/molecules/CoolLightBox'
import Image from 'components/atoms/Image'
import DynamicMedia from 'components/molecules/DynamicMedia'
import CalendarItemTooltip from '../../CalendarItemTooltip'
import { PREVIEW_MEDIA_CLASS_NAME } from '../../../consts'

const StyledBox = styled(Box)`
  cursor: pointer;
  ${({ hasError }) => hasError && `border: 3px solid ${colors.error};`}
`

const ImagesCounterWrapper = styled(Flex)`
  position: absolute;
  cursor: pointer;
  color: #fff;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  ${({ hasError }) => hasError && `border: 3px solid ${colors.error};`}
`

const StyledFirstImage = styled(Image)`
  object-fit: contain;
`

const ImagesGridVertical = ({
  gridHeight,
  images,
  network,
  post,
  socialPreviewProfiles,
  dynamicMediaIsSmall,
  firstImageBorderRadius,
}) => {
  const tooltipRef = useRef(null)
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)
  const [currentImageIndex, setCurrentIndex] = useState(0)

  const imagesCount = images.length

  const {
    0: {
      url: firstColumnImage,
      id: firstColumnImageId,
      [network]: networkErrors = {},
      isNew,
      type: firstColumnType = IMAGE,
    } = {},
  } = images

  const { hasAspectRatioError: firstImageAspectRatioError = false, hasSizeError: firstImageSizeError = false } =
    networkErrors || {}

  const secondColumnImages = images.slice(1, 4)
  const secondColumnImagesCount = secondColumnImages.length

  const lastImages = images.slice(4)
  const lastImagesCount = lastImages.length
  const lasImagesHasError = lastImages.find(({ [network]: networkErrors = {} }) => {
    const { hasAspectRatioError = false, hasSizeError = false } = networkErrors || {}
    return hasAspectRatioError || hasSizeError
  })

  const updatedGridHeight = imagesCount === 1 ? 'auto' : gridHeight

  return (
    <Flex width="100%" maxHeight={updatedGridHeight}>
      <CoolLightBox
        currentImageIndex={currentImageIndex}
        setCurrentIndex={setCurrentIndex}
        isOpen={isLightBoxOpen}
        onClose={() => setIsLightBoxOpen(false)}
        images={images.map(({ url, id, description, [network]: networkErrors = {} }) => {
          const { hasAspectRatioError = false, hasSizeError = false } = networkErrors || {}
          const hasError = hasAspectRatioError || hasSizeError
          return {
            src: url,
            alt: description || '',
            width: 1280,
            height: 600,
            name: id,
            className: hasError ? 'image-error' : '',
          }
        })}
      />
      {imagesCount !== 0 && (
        <Grid
          width="100%"
          maxHeight={updatedGridHeight}
          gridTemplateColumns={imagesCount <= 2 ? `repeat(${imagesCount}, 1fr)` : `repeat(1, 3fr 1fr);`}
        >
          <StyledBox
            onClick={() => {
              setCurrentIndex(0)
              setIsLightBoxOpen(true)
            }}
            width="100%"
            maxHeight={updatedGridHeight}
            hasError={firstImageAspectRatioError || firstImageSizeError}
            position="relative"
          >
            {isNew ? (
              <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
                <ClipLoader size="50" color={colors.primary} />
              </Flex>
            ) : (
              <Fragment>
                {firstColumnType === IMAGE && (
                  <StyledFirstImage
                    id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${firstColumnImageId}`}
                    name={firstColumnImageId}
                    src={firstColumnImage}
                    width="100%"
                    height="100%"
                    borderRadius={firstImageBorderRadius}
                  />
                )}
                {firstColumnType === IMAGE_DYNAMIC && (
                  <DynamicMedia
                    media={images[0]}
                    post={post}
                    profiles={socialPreviewProfiles}
                    isSmall={dynamicMediaIsSmall || imagesCount > 1}
                  />
                )}
              </Fragment>
            )}
          </StyledBox>
          {imagesCount > 1 && (
            <Box pl="s" width="100%" maxHeight={updatedGridHeight}>
              {secondColumnImages.map((media, index) => {
                const { url, id, [network]: networkErrors = {}, isNew, type } = media
                const { hasAspectRatioError = false, hasSizeError = false } = networkErrors || {}
                const hasError = hasAspectRatioError || hasSizeError
                return (
                  <StyledBox
                    key={id || index}
                    width="100%"
                    height={`${100 / secondColumnImagesCount}%`}
                    onClick={() => {
                      setCurrentIndex(index + 1)
                      setIsLightBoxOpen(true)
                    }}
                  >
                    <Box height="100%" position="relative">
                      <StyledBox height="100%" width="100%" position="absolute" hasError={hasError}>
                        {isNew ? (
                          <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
                            <ClipLoader size="50" color={colors.primary} />
                          </Flex>
                        ) : (
                          <Box
                            width={secondColumnImagesCount === 1 ? '100%' : '98%'}
                            height={secondColumnImagesCount === 1 ? '100%' : '98%'}
                          >
                            {type === IMAGE && (
                              <Image
                                id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
                                name={id}
                                src={url}
                                width="inherit"
                                height="inherit"
                                objectFit={secondColumnImagesCount === 1 ? 'contain' : 'cover'}
                              />
                            )}

                            {type === IMAGE_DYNAMIC && (
                              <DynamicMedia media={media} post={post} profiles={socialPreviewProfiles} isSmall />
                            )}
                          </Box>
                        )}
                      </StyledBox>
                      {lastImagesCount !== 0 && index === secondColumnImagesCount - 1 && (
                        <ImagesCounterWrapper
                          alignItems="center"
                          justifyContent="center"
                          hasError={!hasError && lasImagesHasError}
                        >
                          <Text fontSize="2.25em">+{lastImagesCount + 1}</Text>
                        </ImagesCounterWrapper>
                      )}
                    </Box>
                  </StyledBox>
                )
              })}
            </Box>
          )}
        </Grid>
      )}
      <CalendarItemTooltip ref={tooltipRef} />
    </Flex>
  )
}
ImagesGridVertical.defaultProps = {
  images: [],
  gridHeight: '360px',
  post: null,
  socialPreviewProfiles: [],
  dynamicMediaIsSmall: false,
  firstImageBorderRadius: '',
}

ImagesGridVertical.propTypes = {
  images: PropTypes.array,
  gridHeight: PropTypes.string,
  network: PropTypes.string.isRequired,
  post: PropTypes.object,
  socialPreviewProfiles: PropTypes.array,
  dynamicMediaIsSmall: PropTypes.bool,
  firstImageBorderRadius: PropTypes.string,
}

export default ImagesGridVertical
