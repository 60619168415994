import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DialogOverlay } from '@reach/dialog'
import { transparentize } from 'polished'
import Alert from 'react-s-alert'
import { COLOR_CONSTANTS } from 'theme'
import { ERROR_MESSAGE } from 'consts'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { Box } from 'components/atoms/Layout'
import { ROUTE_MEDIA } from '../Calendar/consts'
import { getSelectedProfileGroupsAndTimezone } from '../Calendar/helpers'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ isQuiet }) => (isQuiet ? 'transparent' : transparentize(0.2, COLOR_CONSTANTS.SALUTE))};
    z-index: ${({ isQuiet }) => (isQuiet ? '-1' : '2147483003')} !important;
    visibility: ${({ isQuiet }) => (isQuiet ? 'hidden' : 'visible')};
  }
`

const MediaSettings = ({
  user,
  isOpen,
  handleDismiss,
  handleUpdateUploadedMedias,
  uploadedMedias,
  isQuiet,
  selectedEntities,
  selectedProfiles,
  folderPath,
}) => {
  const collectAllData = async () => {
    const { selectedEntitiesWithSelectedProfiles } = getSelectedProfileGroupsAndTimezone({
      user,
      selectedEntities,
      selectedProfiles,
    })

    const uploadedMediasTemp = JSON.parse(JSON.stringify(uploadedMedias))

    uploadedMediasTemp.forEach((media) => {
      media.title = media.title || ''
      media.description = media.description || ''
      media.labels = []
      media.entities = selectedEntitiesWithSelectedProfiles.map(({ id, name, type }) => ({
        value: id,
        label: name,
        type,
      }))
    })

    const body = uploadedMediasTemp.map(
      ({
        media_gid,
        labels,
        description,
        title,
        entities,
        duration,
        size,
        width,
        height,
        aspect_ratio,
        codec_name,
        codec_long_name,
        r_frame_rate,
        time_base,
        bit_rate,
        pix_fmt,
        color_transfer,
      }) => {
        return {
          media_gid,
          labels,
          description,
          title,
          entity_gids: entities.map(({ value }) => value),
          duration,
          width,
          height,
          aspect_ratio,
          size,
          codec_name,
          codec_long_name,
          r_frame_rate,
          time_base,
          bit_rate,
          pix_fmt,
          color_transfer,
          media_path: folderPath,
        }
      }
    )

    try {
      const response = await request({
        method: 'PUT',
        body,
        path: `${ROUTE_MEDIA}/batch`,
      })

      const { error, medias: mediasForUpdate } = response || {}
      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        if (!isQuiet) {
          Alert.success(uploadedMediasTemp.length === 1 ? 'Media has been updated' : 'Medias have been updated')
        }

        mediasForUpdate.forEach((media) => {
          const foundMediaIndex = uploadedMediasTemp.findIndex(({ media_gid }) => media.media_gid === media_gid)

          if (foundMediaIndex > -1) {
            uploadedMediasTemp[foundMediaIndex] = {
              ...uploadedMediasTemp[foundMediaIndex],
              ...media,
            }
          }
        })

        window.dispatchEvent(new CustomEvent('uploadedMediasForMediaPage', { detail: { medias: uploadedMediasTemp } }))

        handleUpdateUploadedMedias({ medias: uploadedMediasTemp })
      }
    } catch (error) {
      errorHelper({ error, componentName: MediaSettings.displayName, functionName: 'collectAllData' })
    } finally {
      handleDismiss()
    }
  }

  useEffect(() => {
    if (isOpen) {
      collectAllData()
    }
  }, [isOpen])

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}} isQuiet={isQuiet}>
      <Box m="0 auto" width="100%" height="100%" p="l" />
    </StyledDialogOverlay>
  )
}

MediaSettings.defaultProps = {
  handleUpdateUploadedMedias: () => {},
  isQuiet: true,
  selectedEntities: null,
  selectedProfiles: null,
  folderPath: null,
}

MediaSettings.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleUpdateUploadedMedias: PropTypes.func,
  uploadedMedias: PropTypes.array.isRequired,
  isQuiet: PropTypes.bool,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  folderPath: PropTypes.array,
}

MediaSettings.displayName = 'MediaSettings'

export default MediaSettings
