import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'theme'
import { Flex } from 'components/atoms/Layout'
import Tooltip from 'components/molecules/Tooltip'
import Icon from 'components/atoms/Icon'
import { NETWORK_CUSTOMIZATIONS_PROMPT_TEXT } from '../../../consts'

const InfoIcon = styled(Icon.Info)`
  fill: ${colors.secondaryText};
  cursor: pointer;
`

const TooltipPromptComponent = ({ prompt_key, network, mt, ...tooltipProps }) => {
  let data = {}

  if (network) {
    data = NETWORK_CUSTOMIZATIONS_PROMPT_TEXT[network][prompt_key]
  } else {
    data = NETWORK_CUSTOMIZATIONS_PROMPT_TEXT[prompt_key]
  }

  const { text, link } = data || {}

  if (!text) {
    return null
  }

  return (
    <Tooltip
      onClick={() => {
        if (link) {
          window.open(link, '_blank')
        }
      }}
      wrapperComp={
        <Flex alignItems="center" mt={mt} ml="xs">
          <InfoIcon />
        </Flex>
      }
      message={`${text}${link ? ' Click to learn more.' : ''}`}
      width="285px"
      isTriangleVisible={false}
      left="unset"
      right="0"
      defaultTopOffset={10}
      {...tooltipProps}
    />
  )
}

TooltipPromptComponent.defaultProps = {
  network: '',
  mt: '0',
}

TooltipPromptComponent.propTypes = {
  prompt_key: PropTypes.string.isRequired,
  network: PropTypes.string,
  mt: PropTypes.string,
}

export default TooltipPromptComponent
