import React from 'react'
import PropTypes from 'prop-types'
import Alert from 'react-s-alert'
import { colors } from 'theme'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import DropDown from 'shared/DropDown'
import { PUBLISHING_OPTION_DIRECT, PUBLISHING_OPTION_NOTIFICATION, PUBLISHING_OPTIONS } from 'routes/Calendar/consts'

import TooltipPromptComponent from './TooltipPromptComponent'

const formatOptionLabel = ({ value, label, picture_url, location }) => {
  const { app_version = '', operating_system_version = '' } = location || {}

  const details = []

  if (app_version) {
    details.push(`App version: ${app_version}`)
  }
  if (operating_system_version) {
    details.push(operating_system_version)
  }

  return (
    <Flex alignItems="center" key={value}>
      <ImageWithFallback source={picture_url} width="20px" height="20px" />
      <Flex ml="s" flexDirection="column">
        <Text fontSize="xs">{label}</Text>
        {details.length > 0 && (
          <Text color="secondaryText" fontSize="xxs">
            {details.join(', ')}
          </Text>
        )}
      </Flex>
    </Flex>
  )
}

const PublicationOptionComponent = ({
  publishing_option,
  handleUpdateCustomization,
  device_gid,
  devices,
  checkChangeOptionFunction,
  notificationDeviceLengthLimit,
}) => {
  return (
    <Flex flexDirection="column" width="100%">
      <Flex mt="m" alignItems="center" width="100%">
        <Flex flexDirection="column" width="100%">
          <DropDown
            placeholder="Publishing options"
            value={
              publishing_option
                ? PUBLISHING_OPTIONS.find(({ value }) => value === publishing_option)
                : PUBLISHING_OPTIONS[0]
            }
            onChange={(option) => {
              if (option.value === PUBLISHING_OPTION_NOTIFICATION && devices.length === 0) {
                Alert.error(
                  `You don't have any registered mobile devices. <a style="color: ${colors.primary};" href="https://support.vistasocial.com/hc/en-us/articles/7612460663579-Instagram-Story-Publishing-with-Vista-Social" target="_blank">Learn more</a>.`,
                  { timeout: 5000 }
                )
              } else {
                const answer = publishing_option !== option.value ? checkChangeOptionFunction(option) : false

                if (answer) {
                  handleUpdateCustomization({
                    type: 'publishing_option',
                    value: option.value,
                  })
                  if (device_gid && option.value === PUBLISHING_OPTION_DIRECT) {
                    handleUpdateCustomization({
                      type: 'device_gid',
                      value: null,
                    })
                    handleUpdateCustomization({
                      type: 'device_name',
                      value: '',
                    })
                  }
                }
              }
            }}
            options={PUBLISHING_OPTIONS}
            openMenuOnFocus
            isSearchable={false}
          />
        </Flex>
        <TooltipPromptComponent prompt_key="publishing_option" />
      </Flex>

      {publishing_option === PUBLISHING_OPTION_NOTIFICATION && (
        <Flex mt="m" alignItems="center" width="100%">
          <Flex flexDirection="column" width="100%">
            {devices.length !== 0 ? (
              <Flex flexDirection="column">
                <DropDown
                  placeholder="Select a device"
                  value={device_gid ? devices.find(({ value }) => device_gid === value) : null}
                  onChange={(option) => {
                    const { value = null, label = null } = option || {}
                    handleUpdateCustomization({
                      type: 'device_gid',
                      value,
                    })
                    handleUpdateCustomization({
                      type: 'device_name',
                      value: value ? label : '',
                    })
                  }}
                  formatOptionLabel={({ ...props }) => {
                    return formatOptionLabel({ ...props })
                  }}
                  options={devices}
                  openMenuOnFocus
                  error={notificationDeviceLengthLimit ? 'Device is required when scheduling reminders.' : ''}
                />
              </Flex>
            ) : (
              <Flex alignItems="center">
                <Text>You do not have any registered mobile devices.</Text>
              </Flex>
            )}
          </Flex>

          <TooltipPromptComponent prompt_key="device" />
        </Flex>
      )}
    </Flex>
  )
}

PublicationOptionComponent.defaultProps = {
  publishing_option: null,
  device_gid: null,
  devices: [],
  checkChangeOptionFunction: () => {
    return true
  },
  notificationDeviceLengthLimit: false,
}

PublicationOptionComponent.propTypes = {
  publishing_option: PropTypes.string,
  handleUpdateCustomization: PropTypes.func.isRequired,
  device_gid: PropTypes.string,
  devices: PropTypes.array,
  checkChangeOptionFunction: PropTypes.func,
  notificationDeviceLengthLimit: PropTypes.bool,
}

export default PublicationOptionComponent
